import React, { useState, useContext } from "react";
import { Grid, Paper, TextField, Typography, Button, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LocalizationContext } from '../../context/localizationContext';
import { AuthContext } from '../../context/authContext';
import APICall from '../../functional/APIRequests';
import { useSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  cartButton: {
    width: '100%',
    marginBottom: 10
  }
}));
const OrganizationPage = props => {
  const classes = useStyles();
  const { getTranslation } = useContext(LocalizationContext);
  const { enqueueSnackbar } = useSnackbar();
  const { loading, setLoading } = useContext(AuthContext);
  const [data, setData] = useState()
  const [clients, setClients] = useState()
  const [customerId, setCustomerId] = useState();
  document.title = getTranslation('invoices_pdf_uploader_page_title') + ' | Prodiagnostics';

  const handleFiles = (files) => {
    if (files.length > 0) {
      sendFile(files[0]);
    }
  }
  const sendFile = (file) => {
    setLoading(true);
    var form = new FormData();
    form.append("file", file);
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/oldpdf/upload`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    }).then((res) => {
      setLoading(false);
      if (res.data) {
        setData(res.data)
      }
    })
  }
  const fetchClients = () => {
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `prodiagnostics/1/customers?filter=&sort&offset=0&limit=0`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setClients(res.data.filter(item => item.sys_id !== '1' && item.sys_id !== '2'));
    })
  }
  const submitData = () => {
    setLoading(true);
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/oldpdf/approve/${data.id}${customerId ? `?change_organisation_id=${customerId}` : ''}`,
      verb: 'put',
      server: 'api',
    }).then((res) => {
      enqueueSnackbar(getTranslation('generic_submited_success'), { variant: "success" });
      setLoading(false);
      setCustomerId();
      setData()
    })
  }
  if (!clients) {
    fetchClients();
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        <Paper className={classes.paperTitle} elevation={0}>
          <Typography variant="h6" gutterBottom>
            {`${getTranslation('PDF_uploader_page_title')}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {getTranslation('PDF_uploader_page_subtitle')}
          </Typography>
        </Paper>
        {!data &&
          <React.Fragment>
            {loading && <LinearProgress />}
            <Paper className={classes.paper}>
              <Grid container style={{ marginBottom: 30 }}>
                <Grid item xs={12}>
                  <DropzoneArea
                    disabled={loading}
                    onChange={handleFiles}
                    acceptedFiles={['application/pdf']}
                    showPreviewsInDropzone={false}
                    maxFileSize={5000000}
                    showAlerts={false}
                    filesLimit={1}
                    dropzoneText={getTranslation('PDF_uploader_dropzone_text')}
                  />
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        }
        {data &&
          <React.Fragment>
            {loading && <LinearProgress />}
            <Paper className={classes.paper}>
              <Grid container style={{ marginBottom: 30 }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                    {getTranslation('PDF_uploader_form_title')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 30 }}>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <Typography variant="body2" gutterBottom style={data.customer_number === "0" ? { padding: '16px 0px' } : {}}>
                    <b>{getTranslation('PDF_uploader_form_customer_nr')}</b>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <b>{getTranslation('PDF_uploader_form_patient')}</b>
                  </Typography>
                  <Typography variant="body2" gutterBottom >
                    <b>{getTranslation('PDF_uploader_form_customer')}</b>
                  </Typography>
                  <Typography variant="body2" gutterBottom >
                    <b>{getTranslation('PDF_uploader_form_order_nr')}</b>
                  </Typography>
                  <Typography variant="body2" gutterBottom >
                    <b>{getTranslation('PDF_uploader_form_specimen_type')}</b>
                  </Typography>
                  <Typography variant="body2" gutterBottom >
                    <b>{getTranslation('PDF_uploader_form_information')}</b>
                  </Typography>
                  <Typography variant="body2" gutterBottom >
                    <b>{getTranslation('PDF_uploader_form_lab_number')}</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={8} md={9} lg={10}>
                  {data.customer_number !== "0" &&
                    <Typography variant="body2" gutterBottom style={{ paddingLeft: 10 }} >
                      {data.customer_number}
                    </Typography>
                  }
                  {data.customer_number === "0" &&
                    <Autocomplete
                      options={clients}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setCustomerId(!newValue ? newValue : newValue.sys_id);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  }
                  <Typography variant="body2" gutterBottom style={{ paddingLeft: 10 }}>
                    {data.patient}
                  </Typography>
                  <Typography variant="body2" gutterBottom style={{ paddingLeft: 10 }} >
                    {data.company_info}
                  </Typography>
                  <Typography variant="body2" gutterBottom style={{ paddingLeft: 10 }} >
                    {data.id_number}
                  </Typography>
                  <Typography variant="body2" gutterBottom style={{ paddingLeft: 10 }} >
                    {data.specimen_type}
                  </Typography>
                  <Typography variant="body2" gutterBottom style={{ paddingLeft: 10 }} >
                    {data.information}
                  </Typography>
                  <Typography variant="body2" gutterBottom style={{ paddingLeft: 10 }} >
                    {data.lab_number}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 30 }}>
                {Object.keys(data.result_rows).map((key, ind) =>
                  <React.Fragment key={ind}>
                    <Grid item xs={12} >
                      <Typography variant="body2" gutterBottom style={{ marginBottom: 5 }}>
                        <u>{data.result_rows[key].title}</u> {` - `}
                        {data.result_rows[key].portal_product_name? 
                            <b>{`${getTranslation('pdf_uploader_portal_name')}: ${data.result_rows[key].portal_product_name}`}</b>
                            :
                            <b style={{color:'red'}}>{`${getTranslation('pdf_uploader_no_portal_match')}`}</b>
                            }
                      </Typography>
                      <Typography variant="body2" gutterBottom style={{ marginBottom: 20 }}>
                        {data.result_rows[key].description}
                      </Typography>
                    </Grid>
                    {Object.keys(data.result_rows[key].sub_rows).map((subkey, subind) =>
                      <Grid container key={subind} style={{ marginBottom: 5 }}>
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                          <Typography variant="body2" gutterBottom >
                          {data.result_rows[key].sub_rows[subkey].title} {` - `}
                            {data.result_rows[key].sub_rows[subkey].portal_product_name? 
                            <b>{`${getTranslation('pdf_uploader_portal_name')}: ${data.result_rows[key].sub_rows[subkey].portal_product_name}`}</b>
                            :
                            <b style={{color:'red'}}>{`${getTranslation('pdf_uploader_no_portal_match')}`}</b>
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={8} md={9} lg={10}>
                          <Typography variant="body2" gutterBottom >
                            {data.result_rows[key].sub_rows[subkey].description}
                          </Typography>
                        </Grid>
                      </Grid >
                    )}
                  </React.Fragment>
                )}
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}>
                  <Button
                    style={{ margin: '0px 5px' }}
                    variant="outlined"
                    color="primary"
                    onClick={() => setData()}
                  >{getTranslation('generic_button_back')}</Button>
                  <Button
                    disabled={data.customer_number === '0' && !customerId}
                    variant="contained"
                    color="primary"
                    onClick={submitData}
                  >{getTranslation('generic_button_save')}</Button>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        }
      </Grid>
    </Grid >
  );
};
export default OrganizationPage;
