import React, { useState, createContext, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import APICall from '../functional/APIRequests';
import { AuthContext } from './authContext';
import { LocalizationContext } from './localizationContext';
const OrgContext = createContext({});

const OrgProvider = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { perm, setLoading, loading, user } = useContext(AuthContext)
  const { getTranslation } = useContext(LocalizationContext)
  const [wacom, setWacom] = useState(false)
  const [infoSolutions, setInfoSolutions] = useState(null)
  //States
  const [selectedOrg, setSelectedOrg] = useState(null);//{id: , type: 1/2(prod/customer), perm: 1/2(admin/user)}

  const updateStore = () => {
    if (selectedOrg) {
      localStorage.setItem(process.env.REACT_APP_GUID + 'so', JSON.stringify(selectedOrg));
    }
  };
  const rehydrate = (params) => {
    let lsSelOrg = localStorage.getItem(process.env.REACT_APP_GUID + 'so');
    // let lsUser = localStorage.getItem(process.env.REACT_APP_GUID + 'ud');
    if (!!lsSelOrg && lsSelOrg !== selectedOrg) {
      setSelectedOrg({ ...JSON.parse(lsSelOrg) });
    }
  }
  const getOrganization = (params) => {
    setLoading(true);
    let url = params ? `prodiagnostics/1/customers${params}` : 'prodiagnostics/1/customers';
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      /* url: 'prodiagnostics/1/customers' + params,*/
      url: url,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false);
      return res;
    })
  }
  const getOrganizationById = (params) => {
    const { noLoad } = params;
    if (!noLoad) {
      setLoading(true);
    }
    let url = params.idOrg === '1' ? `prodiagnostics/1/profile` : `customer/${params.idOrg}/customerprofile`
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: url,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      if (!noLoad) {
        setLoading(false);
      }
      if (res && res.data) {
        setSelectedOrg({...selectedOrg, patientmodule: res.data.patientmodule ? Number(res.data.patientmodule) : false, custNr: res.data.customer_number})
        setWacom(res.data.allow_signpad === '1')
        setInfoSolutions({ customerId: res.data.infosolutions_tosystemid, autoSync: res.data.autosync === "1" })
        return res.data;
      } else {
        return {}
      }
    })
  }
  const changeOrg = async (params) => {
    if (params.id) {
      let cust = false;
      if (Number(params) !== 1) {
        cust = await getOrganizationById({ idOrg: params })
      }
      setSelectedOrg({ ...selectedOrg, ...params, custNr: cust ? cust.customer_number : false, infoSolutions: { customerId: cust ? cust.infosolutions_tosystemid : false }, patientmodule: (cust && cust.patientmodule) ? Number(cust.patientmodule) : false});
    } else {
      let opt = perm.options.find(o => Number(o.id) === Number(params));
      let cust = false;
      if (Number(params) !== 1) {
        cust = await getOrganizationById({ idOrg: params })
      }
      setSelectedOrg({
        pro: cust ? cust.pro : false,
        workplace: cust ? cust.workplace : false,
        mro: opt.mro ? opt.mro : false,
        id: opt.id,
        custNr: cust ? cust.customer_number : false,
        type: opt.section === "prodiagnostics" ? 1 : 2,
        perm: (opt.superuser || opt.admin) ? 1 : 2,
        infoSolutions: { customerId: cust ? cust.infosolutions_tosystemid : false },
        patientmodule: (cust  && cust.patientmodule) ? Number(cust.patientmodule) : "",
        consult: opt.consult ? opt.consult : false,
        customer: opt.customer ? opt.customer: false,
        admin: opt.admin ? opt.admin : false,
        superuser: opt.superuser ? opt.superuser : false
      })
    }
  }
  const createNewOrganization = (params) => {
    setLoading(true)
    return APICall({
      setLoading,
      hasRefresh: true,
      formData: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/customer/create`,
      verb: 'post',
      server: 'api',
      data: params
    }).then((res) => {
      setLoading(false);
      enqueueSnackbar(getTranslation('generic_submited_success'), { variant: "success" });
      return true;
    })
  }
  const patchStatusOrganization = (params) => {
    setLoading(true)
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/customer/${params.idOrg}/${params.status ? 'activate' : 'deactivate'}`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false)
      enqueueSnackbar(getTranslation('generic_submited_success'), { variant: "success" });
      return res;
    })
  }
  const patchAuthTypeOrganization = (params) => {
    setLoading(true)
    var form = new FormData();
    form.append("data[allow_twofactor]", params.TFA ? '1' : '0');
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/customer/${params.idOrg}`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    }).then((res) => {
      setLoading(false);
      enqueueSnackbar(getTranslation('generic_submited_success'), { variant: "success" });
      return res;
    })
  }
  const toogleWacom = orgId => {
    setLoading(true);
    let formData = new FormData();
    formData.append("data[allow_signpad]", !wacom ? '1' : '0');
    setWacom(!wacom);
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/customer/${orgId}`,
      verb: 'POST',
      data: formData,
      server: 'api',
    }).then((res) => {

      setLoading(false);
    })
  }

  const toggleWorkplace = params => {
    setLoading(true);
    let form = new FormData();
    form.append("data[workplace]", params.workplace ? '1' : '0');
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/customer/${params.idOrg}`,
      verb: "post",
      server: "api",
      formData: true,
      data: form
    })
      .then(res => {
        setLoading(false);
        enqueueSnackbar(getTranslation('generic_submited_success'), {variant: "success"});
        return res;
      })
      .catch(e => setLoading(false));
  };

  const toggleProfessional = params => {
    setLoading(true);
    let form = new FormData();
    form.append("data[pro]", params.professional ? '1' : '0');
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/customer/${params.idOrg}`,
      verb: "post",
      server: "api",
      formData: true,
      data: form
    })
      .then(res => {
        setLoading(false);
        enqueueSnackbar(getTranslation('generic_submited_success'), {variant: "success"});
        return res;
      })
      .catch(e => setLoading(false))
  };


  // //LifeCicles
  useEffect(() => {
    rehydrate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!selectedOrg && localStorage.getItem(process.env.REACT_APP_GUID + 'so')) {
      rehydrate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, perm, infoSolutions, loading]);
  useEffect(() => {
    updateStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg]);
  return (
    <OrgContext.Provider value={{
      //States
      selectedOrg,
      wacomCheck: wacom,
      infoSolutions,
      //UpdateStates
      setSelectedOrg,
      //Functions
      toogleWacom,
      changeOrg, getOrganization, getOrganizationById, createNewOrganization, patchStatusOrganization, patchAuthTypeOrganization, rehydrate,
      setLoading, APICall, enqueueSnackbar, toggleWorkplace, toggleProfessional
    }}>
      {props.children}
    </OrgContext.Provider >
  );
};

export { OrgContext, OrgProvider };
