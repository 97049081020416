import React, {Fragment, useContext, useEffect, useState} from "react";
import {Grid, Typography, Button, Hidden} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import {LocalizationContext} from "../../../context/localizationContext";
import {OrgContext} from "../../../context/orgContext";
import {RefContext} from "../../../context/refContext";
import {useParams} from "react-router-dom";
import {AuthContext} from "../../../context/authContext";
import {PersonelContext} from "../../../context/personelContext";
import {useSnackbar} from "notistack";
import APICall from "../../../functional/APIRequests";
import colors from "../../../styles/Theme-settings";
import SignButtons from "./referralReviewSign/indexV3";
import ReviewReferralModal from "../../../components/ReviewReferralModalV3";
import {apiServer} from "../../../functional/generateEnvUrls";
import clsx from "clsx";
import {Print} from "@material-ui/icons";
import SVGSig from "./referralReviewSign/sigSvg";

const ReviewV3 = (props) => {
  const classes = useStyles();
  const [orgData, setOrgData] = useState({});
  const {getTranslation} = useContext(LocalizationContext);
  const {
    selectedProduct,
    referalData,
    setReferalData,
    testMethods,
    setTitleBox,
    setSelectedProduct,
    setOtherChemIntake,
    otherChemIntake,
    formType,
    getPadInfo,
    getPatientInitials,
    submitForm,
    sendStep,
    setSendStep,
    complete,
    submited,
    setComplete,
    setSubmited,
    activeStep
  } = useContext(RefContext);
  const {selectedOrg, getOrganizationById} = useContext(OrgContext);
  const {setLoading} = useContext(AuthContext);
  const {getPersonel} = useContext(PersonelContext);
  const {enqueueSnackbar} = useSnackbar();
  const {id} = useParams();
  const [quickTests, setQuickTests] = useState([]);
  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [sent, setSent] = useState(false);
  const [tempRefId, setTempRefId] = useState(0);
  const [patient, setPatient] = useState({name: "", pnr: ""})
  const [check, setCheck] = useState(false);

  const {handleBack, isMobile} = props;

  const pathName = props.history.location.pathname;

  const fetchData = () => {
    setLoading(true)
    getOrganizationById({idOrg: selectedOrg.id}).then((resOrg) => {
      APICall({
        setLoading,
        hasRefresh: true,
        enqueueSnackbar,
        url: `customer/${selectedOrg.id}/referral/${id}`,
        verb: 'get',
        server: 'api',
      }).then((res) => {
        if (res) {
          setReferalData(prevState => ({
            ...prevState,
            sys_id: res.data.referral.sys_id,
            id: res.data.referral.eferal_id,
            name: res.data.referral.tester_name,
            testMethodId: res.data.referral.test_method_id,
            email: res.data.referral.tester_email,
            date: moment.unix(res.data.referral.test_date).format('YYYY-MM-DD HH:mm:ss'),
            historic: res.data.referral.drug_history,
            labComments: res.data.referral.comments,
            warranty: res.data.referral.warranty,
            patient_name: res.data.referral.patient_name,
            patient_pnr: res.data.referral.patient_pnr,
            initials: res.data.referral.patient_pnr ? getPatientInitials(res.data.referral.patient_name) : res.data.referral.patient_name,
            quickTestId: res.data.referral.quick_test_id,
            warrantyLotId: res.data.referral.warranty_lot_id,
            warrantyComments: res.data.referral.warranty_comments,
            warrantyTestDate: moment.unix(res.data.referral.warranty_test_date).format('YYYY-MM-DD HH:mm:ss'),
            notify: res.data.notify_users,
            referral_id: res.data.referral.referral_id,
            blockDigitalSign: res.data.referral.length > 0,
            allowSignPad: resOrg.allow_signpad === '1',
            status_id: res.data.referral.status_id,
            patient_id: res.data.referral.patient_id,
            signature_file_id: res.data.referral.signature_file_id
          }))
          setSelectedProduct(res.data.analysis)
          if (res.data.analysis.length > 0) {
            res.data.analysis.forEach(test => {
              if (test.test_type === 'dateAndDose' || test.test_type === 'freeText') {
                setOtherChemIntake([{
                  ...otherChemIntake,
                  chemName: test.data.substance,
                  chemAmmount: test.data.dosage,
                  date: moment.unix(test.data.testdatetime).format('YYYY-MM-DD HH:mm'),
                  chemIntakeDate: moment.unix(test.data.dosagedatetime).format('YYYY-MM-DD HH:mm')
                }]);
              }
            })
          }
          fetchOrg(res.data.referral.organisation_id);
        }
        setLoading(false);
      })
    })
  }

  const fetchQuickTests = (e) => {
    try {
      e.preventDefault()
    } catch (err) {
    }
    ;
    if (referalData !== undefined) {
      APICall({
        enqueueSnackbar,
        url: `quicktest`,
        verb: 'get',
        server: 'api',
      }).then((res) => {
        if (res) {
          setQuickTests(res.data)
        }
      })
    }
  }
  const fetchOrg = (orgId) => {
    getOrganizationById({idOrg: orgId}).then((res) => {
      if (res) {
        setReferalData(prevState => ({
          ...prevState,
          allowSignPad: res.allow_signpad === '1',
        }))
        setTitleBox(res.name + ' - ' + res.orgnr)
        setOrgData(res);
        fetchPersonnel();
      }
    })
  }
  const fetchPersonnel = () => {
    getPersonel('', selectedOrg.id).then(res => {
      setUserList((res.data || []));
    })
  }

  const isReferralSent = () => {
    if (id) {
      parseInt(referalData.status_id) < 3 ? setSent(false) : setSent(true)
    } else {
      setSent(complete);
    }
  };

  const fetchPatientData = patientId => {
    setLoading(true);

    APICall({
      setLoading,
      url: `customer/${selectedOrg.id}/patients?limit=1&page=1&sort=id desc&filter[id]=${patientId}`,
      verb: "get",
      server: "api"
    })
      .then(res => {
        if (res) {
          if (res.data.items.length > 0) {
            setPatient({name: res.data.items[0].name, pnr: res.data.items[0].pnr})
          }
        }
        setLoading(false);
      })
  };

  useEffect(() => {
    if (!isNaN(id)) {
      setSendStep(2);
      fetchData();
    } else {
      setSendStep(2);
      fetchOrg(selectedOrg.id);
    }
    if (formType === 2) {
      fetchQuickTests();
    }
    getPadInfo();

    if (!isNaN(id)) {
      setSubmited(true);
    } else {
      setSubmited(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referalData)
      isReferralSent()
  }, [referalData])

  useEffect(() => {
    if (!isNaN(id) && referalData && Number(selectedOrg.patientmodule) === 1) {
      fetchPatientData(referalData.patient_id);
    }
  }, [referalData]);

  //const imgUrl = referalData ? `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${id || referalData.sys_id}/barcode?height=30` : '';
  const imgUrl = referalData ? `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${id || referalData.sys_id}/barcode?height=30` : '';

  if (!referalData) {
    return false;
  }

  let verifiedTests = [];
  let normalTests = [];
  if (selectedProduct) {
    selectedProduct.forEach((test, index) => {
      if (test.verified || test.test_type === 'verify') {
        verifiedTests.push(test)
      } else {
        normalTests.push(test)
      }
    })
  }

  const methodName = () => {
    const method = testMethods.find(item => (item.active === '1' && item.sys_id !== '0' && item.sys_id === referalData.testMethodId))
    if (method)
      return method.name;
    return '';
  }

  const handleSaveClick = () => {
    submitForm()
      .then((res) => {
        if (res) {
          if (res.data) {
            setReferalData({...referalData, sys_id: res.data})
            enqueueSnackbar(getTranslation('referal_submited_box_title'), {variant: "success"});
            setSubmited(true);
            setComplete(false);
            isReferralSent();
          }
        }
        setLoading(false);
      })
    setModal(false);
  }

  const handleSaveMobile = () => {
    submitForm()
      .then((res) => {
        if (res) {
          if (res.data) {
            setReferalData({...referalData, sys_id: res.data})
            enqueueSnackbar(getTranslation('referal_submited_box_title'), {variant: "success"});
            setSubmited(true);
            setComplete(false);
            isReferralSent();
          }
        }

        if (referalData.canvasSignature || referalData.signature) {
          let form = new FormData();
          form.append("signature", isMobile ? referalData.canvasSignature : referalData.signature);
          APICall({
            setLoading,
            url: `customer/${selectedOrg.id}/referral/${res.data}/savesignature`,
            verb: 'post',
            server: 'api',
            formData: true,
            data: form,
          })
            .then(response => {
              if (response) {
                setLoading(false);
                setModal(false);
              }
            })
        } else {
          setLoading(false);
          setModal(false);
        }
      })
  };

  const sendToLabWithSignature = () => {
    let form = new FormData();
    form.append("data[skip_signature]", '0');
    form.append("data[send_digital]", '1');
    if (!submited) {
      submitForm()
        .then((res) => {
          if (res.data) {
            setReferalData({...referalData, sys_id: res.data})
            enqueueSnackbar(getTranslation('referal_submited_box_title'), {variant: "success"});
            setSubmited(true);
            setTempRefId(res.data);
          }
          let formData = new FormData();
          formData.append("signature", isMobile ? referalData.canvasSignature : referalData.signature);
          APICall({
            setLoading,
            // enqueueSnackbar,
            url: `customer/${selectedOrg.id}/referral/${res.data}/savesignature`,
            verb: 'post',
            server: 'api',
            formData: true,
            data: formData,
          })
            .then(() => {
              APICall({
                setLoading,
                // enqueueSnackbar,
                url: `customer/${selectedOrg.id}/referral/${res.data}/setcomplete`,
                verb: 'post',
                server: 'api',
                formData: true,
                data: form,
              }).then(() => {
                setComplete(true);
                setSendStep(3);
                setLoading(false);
                setModal(false);
                setSent(true);
              })
            })
        })
    } else {
      let _id = id || referalData.sys_id;

      let formData = new FormData();
      formData.append("signature", isMobile ? referalData.canvasSignature : referalData.signature);
      APICall({
        setLoading,
        url: `customer/${selectedOrg.id}/referral/${_id}/savesignature`,
        verb: 'post',
        server: 'api',
        formData: true,
        data: formData,
      })
        .then(() => {
          APICall({
            setLoading,
            url: `customer/${selectedOrg.id}/referral/${_id}/setcomplete`,
            verb: 'post',
            server: 'api',
            formData: true,
            data: form,
          })
            .then(() => {
              setComplete(true);
              setSendStep(3);
              setLoading(false);
              setModal(false);
              setSent(true);
            })
        })
    }
  };

  const sendToLabWithNoSignature = () => {
    let form = new FormData();
    //Alawys skips digital signature and sends digitally
    form.append("data[skip_signature]", '1');
    form.append("data[send_digital]", '1');
    if (!submited) {
      submitForm()
        .then((res) => {
          if (res.data) {
            setReferalData({...referalData, sys_id: res.data})
            enqueueSnackbar(getTranslation('referal_submited_box_title'), {variant: "success"});
            setSubmited(true);
            setTempRefId(res.data);
          }
          APICall({
            setLoading,
            url: `customer/${selectedOrg.id}/referral/${res.data}/setcomplete`,
            verb: 'post',
            server: 'api',
            formData: true,
            data: form,
          }).then((res) => {
            setComplete(true);
            setSendStep(3);
            setLoading(false);
            setModal(false);
            setSent(true);
          })
        })
    } else {
      let _id = id || referalData.sys_id
      APICall({
        setLoading,
        url: `customer/${selectedOrg.id}/referral/${_id}/setcomplete`,
        verb: 'post',
        server: 'api',
        formData: true,
        data: form,
      }).then((res) => {
        setComplete(true);
        setSendStep(3);
        setLoading(false);
        setModal(false);
        setSent(true);
      })
    }
  };

  const sendToLab = () => {
    if (referalData.canvasSignature || referalData.signature) {
      sendToLabWithSignature();
    } else {
      sendToLabWithNoSignature();
    }
  }

  const handlePrint = () => {
    if (!sent || (!isNaN(id) && Number(referalData.status_id) === 1)) {
      let form = new FormData();
      form.append("data[skip_signature]", '1');
      form.append("data[send_digital]", '1');
      APICall({
        setLoading,
        // enqueueSnackbar,
        url: `customer/${selectedOrg.id}/referral/${id ? referalData.sys_id : tempRefId}/setcomplete`,
        verb: 'post',
        server: 'api',
        formData: true,
        data: form,
      }).then((res) => {
        setComplete(true);
        setLoading(false)
        setModal(false);
        setSent(true);
        setSendStep(3);
        props.openPDF();
      })
    }
    props.openPDF();
  };

  const showContinueButton = () => {
    if (sendStep < 2) {
      return false;
    } else {
      if (!check) {
        return true;
      } else if (check && (referalData.signature || referalData.canvasSignature)) {
        return true;
      }
    }
    return false;
  };

  const otherIntake = (otherChemIntake && otherChemIntake.some(x => x.type === 1));
  const isPossibleToUsePad = referalData.allowSignPad && !referalData.blockDigitalSign && !otherIntake;
  return (
    <>
      {selectedProduct &&
      <>
        <Grid container justify="space-between" spacing={2}>
          {/*Left side*/}
          <Grid item xs={12} md={6}>
            <Grid container direction="column">
              <Grid item xs={12}>
                {/* First section*/}
                <Grid item xs={12}>
                  <div style={{backgroundColor: "#727272", color: "#fefefe", padding: "3px 5px"}}>
                    <Typography variant="body1"
                                className={classes.bold}>{getTranslation("referal_review_title_general_information")}</Typography>
                  </div>
                  {/*First section left side*/}
                  <Grid container style={{paddingTop: "10px"}}>
                    {/*Customer ID / Organization*/}
                    <Grid item xs={12} sm={6}>
                      <Grid container direction="column">
                        <Grid item xs={12} style={{display: "flex"}}>
                          <Typography variant="body2" gutterBottom
                                      className={classes.bold}>{getTranslation('referal_review_cart_title_customer_id')}: &nbsp;</Typography>
                          <Typography variant="body2"
                                      gutterBottom> {orgData.customer_number || selectedOrg.id}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" gutterBottom
                                      className={classes.bold}>{getTranslation('referal_review_cart_title_organization')}: &nbsp;</Typography>
                          <Typography variant="body2" gutterBottom>{orgData.name}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/*Address*/}
                    <Grid item xs={12} sm={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body2" gutterBottom
                                      className={classes.bold}> {getTranslation('referal_review_cart_title_address')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">{orgData.address}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">{orgData.address2}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">{orgData.postal_code}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">{orgData.city}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/*Second section*/}
                <Grid item xs={12} style={{marginTop: 13}}>
                  <div style={{backgroundColor: "#727272", color: "#fefefe", padding: "3px 5px"}}>
                    <Typography variant="body1"
                                className={classes.bold}>{getTranslation("referal_review_title_about_test")}</Typography>
                  </div>
                  {/*Second section left side*/}
                  <Grid container style={{paddingTop: "10px"}}>
                    {/*Test date / Email / Additional recipients / Sampler*/}
                    <Grid item xs={12} sm={6}>
                      <Grid container direction="column">
                        <Grid item xs={12} className={classes.flex}>
                          <Typography variant="body2" gutterBottom
                                      className={classes.bold}>{getTranslation('referal_review_box_label_sample_date')}: &nbsp;</Typography>
                          <Typography variant="body2"
                                      gutterBottom>{moment(referalData.date).format('YYYY-MM-DD HH:mm')}</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.flex}>
                          <Typography variant="body2" gutterBottom
                                      className={classes.bold}>{getTranslation('referal_review_box_label_email')}: &nbsp;</Typography>
                          <Typography variant="body2" gutterBottom>{referalData.email}</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.flex}>
                          <Typography variant="body2"
                                      className={classes.bold}
                                      gutterBottom>{getTranslation('referal_review_box_label_notify_users')}: &nbsp;</Typography>
                          <Typography variant="body2" gutterBottom>
                            {referalData.usersNotify && referalData.usersNotify.map((uNot, ind) => {
                              if (userList.length > 0) {
                                return `${userList.find(item => Number(item.sys_id) === Number(uNot)).name} ${ind + 1 < referalData.usersNotify.length ? ', ' : ''}`;
                              } else {
                                return false;
                              }
                            })}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.flex}>
                          <Typography variant="body2" gutterBottom
                                      className={classes.bold}>{getTranslation('referal_review_box_label_tester')}: &nbsp;</Typography>
                          <Typography variant="body2" gutterBottom>{referalData.name}</Typography>
                        </Grid>


                        {(pathName.search('warranty') > -1 && activeStep === 3 && !id) &&
                        <>
                          <Grid item xs={12} className={classes.flex}>
                            <Typography variant="body2" className={classes.bold} gutterBottom>
                              {`${getTranslation('referal_warranty_test_date_label')}:`}&nbsp;
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {moment(referalData.warrantyTestDate).format('YYYY-MM-DD HH:mm')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.flex}>
                            <Typography variant="body2" className={classes.bold} gutterBottom>
                              {`${getTranslation('referal_warranty_lot_id_label')}:`}&nbsp;
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {referalData.warrantyLotId}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.flex}>
                            <Typography variant="body2" className={classes.bold} gutterBottom>
                              {`${getTranslation('referal_warranty_comments_label')}:`}&nbsp;
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {referalData.warrantyComments}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.flex}>
                            <Typography variant="body2" className={classes.bold} gutterBottom>
                              {`${getTranslation('referal_warranty_quicktest_label')}:`}&nbsp;
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {referalData.quickTestIdName}
                            </Typography>
                          </Grid>
                        </>
                        }


                      </Grid>
                    </Grid>
                    {/* Second section right side (Barcode)*/}
                    <Grid item xs={12} sm={6} style={{textAlign: "left"}}>
                      <Grid container direction={"column"}>
                        <Grid item xs={12} style={{marginRight: 15}}>
                          <Typography variant="body2"
                                      className={classes.bold}>{getTranslation('referal_review_box_label_barcode')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {(id || referalData.sys_id) ?
                            <img alt='barcode' src={imgUrl}/>
                            :
                            <Typography variant="body2" className={classes.bold}>{referalData.id}</Typography>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/*Third section*/}
                <Grid item xs={12} style={{marginTop: 13}}>
                  <div style={{backgroundColor: "#727272", color: "#fefefe", padding: "3px 5px"}}>
                    <Typography variant="body1"
                                className={classes.bold}>{getTranslation('referal_review_box_label_patient_information')}</Typography>
                  </div>
                  <Grid container style={{paddingTop: "10px"}}>
                    {/*SSN / Initials/ Drug history / Comments*/}
                    {((patient.pnr || referalData.patient_pnr) && (Number(selectedOrg.patientmodule) === 1) || Number(selectedOrg.id) === 1) &&
                    <>
                      <Grid item xs={12} className={classes.flex}>
                        <Typography variant="body2" gutterBottom
                                    className={classes.bold}>{getTranslation('referal_review_box_label_pnr')}: &nbsp;</Typography>
                        <Typography variant="body2"
                                    gutterBottom>{!isNaN(id) ? patient.pnr : referalData.patient_pnr}</Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.flex}>
                        <Typography variant="body2" gutterBottom
                                    className={classes.bold}>{getTranslation('referal_review_box_label_patient_name')}: &nbsp;</Typography>
                        <Typography variant="body2"
                                    gutterBottom>{!isNaN(id) ? patient.name : referalData.patient_name}</Typography>
                      </Grid>
                    </>
                    }
                    {(Number(selectedOrg.patientmodule) === 0 && Number(selectedOrg.id) !== 0) &&
                    <Grid item xs={12} className={classes.flex}>
                      <Typography variant="body2" gutterBottom
                                  className={classes.bold}>{getTranslation('referal_review_box_label_paciente_initial')}: &nbsp;</Typography>
                      <Typography variant="body2" gutterBottom>{referalData.initials}</Typography>
                    </Grid>
                    }
                    <Grid item xs={12} className={classes.flex}>
                      <Typography variant="body2" gutterBottom
                                  className={classes.bold}>{getTranslation('referal_review_box_label_drugs_history')}: &nbsp;</Typography>
                      <Typography variant="body2">{referalData.historic}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.flex}>
                      <Typography variant="body2" gutterBottom
                                  className={classes.bold}>{getTranslation('referal_form_label_comments')}: &nbsp;</Typography>
                      <Typography variant="body2">{referalData.labComments}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/*Fourth section*/}
                <Grid item xs={12} style={{marginTop: 13}}>
                  <div style={{backgroundColor: "#727272", color: "#fefefe", padding: "3px 5px"}}>
                    <Typography variant="body1"
                                className={classes.bold}>{getTranslation('referal_form_label_analyzes')}</Typography>
                  </div>

                  {/*Matrix*/}
                  <Grid item xs={12} className={classes.flex} style={{paddingTop: "10px"}}>
                    <Typography variant="body2"
                                className={classes.bold}>{getTranslation('referal_form_label_matrix')}: &nbsp;</Typography>
                    {testMethods && <Typography variant="body2">{methodName()}</Typography>}
                  </Grid>

                  <Grid container style={{paddingTop: "10px"}}>
                    {/*Desired analyzes*/}
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom
                                    className={classes.bold}>{getTranslation('referal_review_box_label_selected_exams')}</Typography>
                      </Grid>
                      {verifiedTests.map((test, index) => (
                        <Grid item xs={12} style={{display: "flex", marginBottom: "0.35em"}}>
                          <CheckIcon/>
                          <Typography variant="body2">{test.name}</Typography>
                        </Grid>
                      ))}
                      {normalTests.map((test, index) => (
                        <Grid item xs={12} style={{display: "flex", marginBottom: "0.35em"}}>
                          <CheckIcon/>
                          <Typography variant="body2">{test.name}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                    {/*Ohter*/}
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom
                                    className={classes.bold}> {getTranslation('referal_review_box_label_other')}</Typography>
                      </Grid>
                      {otherChemIntake && otherChemIntake.map((item, index) => {
                        return item.type === 1 ?
                          <React.Fragment key={index}>
                            <Typography variant="body2" gutterBottom>
                              {getTranslation('referal_review_box_label_other_substance_freetext')}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {item.freeText}
                            </Typography>
                          </React.Fragment>
                          :
                          <React.Fragment key={index}>
                            <Typography variant="body2" gutterBottom>
                              {getTranslation('referal_review_box_label_other_substance_name')}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {item.chemName}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {getTranslation('referal_review_box_label_other_substance_dosage')}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {item.chemAmmount}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {getTranslation('referal_review_box_label_other_substance_intake_date')}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {moment(item.date).format('YYYY-MM-DD HH:mm')}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {getTranslation('referal_review_box_label_othe_chem_test_date')}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {moment(item.chemIntakeDate).format('YYYY-MM-DD HH:mm')}
                            </Typography>
                          </React.Fragment>
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*Right side*/}
          <Grid item xs={12} md={6}
                style={{
                  border: "1px solid #efefef",
                  paddingRight: 0,
                  paddingLeft: 0,
                  borderTop: "none",
                  paddingTop: 0,
                  marginTop: 8
                }}>
            <Grid container direction="column">

              {/*First section*/}
              {(sendStep >= 1 || referalData.warranty) &&
              <>
                <Grid item xs={12}>
                  <div className={(sendStep > 1 || Number(referalData.status_id) >= 1) ? classes.green : classes.gray}>
                    <Typography variant="body1"
                                className={classes.bold}>{getTranslation('referal_review_box_label_send_to_lab')}</Typography>
                  </div>
                  <Grid item xs={12} style={{paddingTop: 10, paddingLeft: 15}}>
                    <Typography variant="body1">{getTranslation("referal_review_send_to_lab_content")}</Typography>
                  </Grid>
                  <Grid item xs={12} style={{paddingTop: 10, paddingLeft: 10}}>
                    <SignButtons selectedOrg={selectedOrg} referalData={referalData} otherIntake={otherIntake}
                                 setReferalData={setReferalData}
                                 sent={sent} setSent={setSent} handlePrint={handlePrint} check={check}
                                 setCheck={setCheck}
                                 submited={submited} {...props} />
                  </Grid>
                  {referalData.signature &&
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2">{getTranslation("referal_review_confirmed_signature_text")}
                    </Typography>
                  </Grid>
                  }
                  {showContinueButton() &&
                  <Grid item xs={12} style={{marginBottom: 25}}>
                    <Grid item xs={12} style={{paddingTop: 20, paddingLeft: 15}}>
                      <Button
                        style={{padding: '8px 19px'}}
                        variant={"contained"}
                        color="primary"
                        // disabled={}
                        disabled={submited || (check && !referalData.canvasSignature)}
                        onClick={() => setModal(true)}
                      >
                        {getTranslation('referal_review_confirm_button_text')}
                      </Button>
                    </Grid>
                  </Grid>
                  }
                </Grid>
                <Grid item xs={12} style={submited ? {marginTop: -15} : {}}>
                  <Grid container>
                    {(!sent && submited) &&
                    <Grid item xs={12} sm={6} style={{padding: 5, paddingLeft: 15}}>
                      <div
                        className={clsx(classes.printButtonGroup, classes.green)} style={{padding: 15}}
                        onClick={sendToLab}>
                        <div className={classes.icon}>
                          <SVGSig/>
                        </div>
                        <div>
                          <Typography variant="subtitle1" className={classes.text}>
                            {getTranslation('button_send_digital_without_signature')}
                          </Typography>
                          <Typography className={classes.underText}>
                            {getTranslation('button_send_digital_without_signature_undertext')}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    }
                    <Grid item xs={12} sm={6} style={(complete || !isNaN(id)) ? {padding: 5, paddingLeft: 15} : {padding: 5}}>
                      {(id || referalData.sys_id) &&
                      <div
                        className={clsx(classes.printButtonGroup, isPossibleToUsePad ? classes.gray : classes.green)}
                        style={{padding: 15}}
                        onClick={handlePrint}>
                        <div className={classes.icon}>
                          <Print style={{fill: colors.white, width: 30, height: 30}}/>
                        </div>
                        <div>
                          <Typography variant="subtitle1" className={classes.text}>
                            {getTranslation(isPossibleToUsePad ? 'referal_form_review_print' : 'referal_form_review_print_no_digital')}
                          </Typography>
                          <Typography className={classes.underText}>
                            {getTranslation(isPossibleToUsePad ? 'referal_form_review_print_undertext' : 'referal_form_review_print_undertext_no_digital')}
                          </Typography>
                        </div>
                      </div>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </>
              }

              {/*Second section*/}
              {(sendStep === 3 || parseInt(referalData.status_id) >= 3) &&
              <>
                <Grid item xs={12} style={{marginTop: "-57px"}}>
                  <div
                    className={(sendStep === 3 || parseInt(referalData.status_id) >= 3) ? classes.green : classes.gray}>
                    <Typography variant="body1"
                                className={classes.bold}>{getTranslation('referal_review_box_label_receipt')}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} style={{paddingTop: 10, paddingLeft: 10}}>
                  <Grid container>
                    <Grid item xs={12} className={classes.flex}>
                      <Typography variant="body1"
                                  className={classes.bold}>{getTranslation('referal_review_box_label_sent_to_lab_status')}: &nbsp;</Typography>
                      <Typography
                        variant="body1">{getTranslation('referal_review_box_sent_to_lab_status_yes')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
              }
            </Grid>
          </Grid>
          {isMobile &&
          <Grid item xs={12} className={classes.btnsWrap}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
              className={classes.backBtn}
              style={selectedProduct.length > 0 ? {marginRight: 10} : {}}
            >
              {getTranslation("referral_previous_step_btn")}
            </Button>
          </Grid>
          }
        </Grid>
        {/*Mobile View*/}
        <Hidden mdUp>
          <ReviewReferralModal open={modal} onSave={handleSaveMobile} onSend={sendToLab}
                               onClose={() => setModal(false)} submited={submited}/>
        </Hidden>
        {/*Desktop View*/}
        <Hidden smDown>
          <ReviewReferralModal open={modal} onSave={handleSaveClick} onSend={sendToLab}
                               onClose={() => setModal(false)} submited={submited}/>
        </Hidden>
      </>
      }
    </>
  );
}

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: "bold"
  },
  flex: {
    display: "flex"
  },
  printButtonGroup: {
    cursor: 'pointer',
    borderRadius: 3,
    color: colors.white,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row'
  },
  gray: {
    background: colors.textGray,
    color: "#fefefe",
    padding: "3px 5px"
  },
  green: {
    background: colors.green,
    color: "#fefefe",
    padding: "3px 5px"
  },
  btnsWrap: {
    textAlign: "right",
    marginTop: 15,
  },
  backBtn: {
    borderWidth: 2
  },
  text: {
    fontSize: 15,
  },
  underText: {
    fontSize: 10,
  },
}));

export default ReviewV3;
