import React, {useEffect, useContext} from "react";
import {useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import colorConfig from "../../../styles/Theme-settings";
import {LocalizationContext} from "../../../context/localizationContext";
import {TestcaseContext} from "../../../context/testcaseContext";
import Mobile from "./mobile";
import Tablet from "./tablet";

const ListTestOccasions = (props) => {
  const classes = useStyles();

  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const desktop = useMediaQuery("(min-width: 1025px)");

  const {getTranslation} = useContext(LocalizationContext);
  const {getAllUserTestCases, openTests} = useContext(TestcaseContext);

  useEffect(() => {
    getAllUserTestCases();
  }, []);

  return (
    <>
      {desktop && <Tablet getTranslation={getTranslation} data={openTests} classes={classes} {...props}/>}
      {tablet && <Tablet getTranslation={getTranslation} data={openTests} classes={classes} {...props}/>}
      {mobile && <Mobile getTranslation={getTranslation} data={openTests} classes={classes} {...props}/>}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingBottom: 30
  },
  row: {
    marginBottom: 20
  },
  backButton: {
    color: colorConfig.lightBlack
  },
  backButtonMobile: {
    fontSize: 18
  },
  backIconMobile: {
    fill: colorConfig.lightBlack,
    width: 20,
    height: 20
  },
  backIcon: {
    fill: colorConfig.lightBlack
  },
  paperMobile: {
    width: "100%",
    padding: 12
  },
  paper: {
    paddingLeft: 10,
    minHeight: 70
  },
  testNrsMobile: {
    display: "flex"
  },
  testNrs: {
    display: "flex",
    justifyContent: "center"
  },
  testsDone: {
    color: colorConfig.green,
    fontSize: "1.1rem"
  },
  totalTests: {
    color: "#bababa",
    fontSize: "1.1rem"
  },
  editContainerMobile: {
    backgroundColor: "#222222",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0",
    cursor: 'pointer'
  },
  editContainer: {
    backgroundColor: "#222222",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 0",
    minHeight: 70,
    cursor: "pointer"
  },
  editIcon: {
    fill: "#ffffff",
    width: 28,
    height: 28
  },
  chooseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#d8102e",
    cursor: "pointer"
  },
  chooseContainerMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#d8102e",
    cursor: "pointer"
  },
  chooseText: {
    color: "#ffffff",
    textTransform: "uppercase",
    fontWeight: "bold"
  },
  arrowIcon: {
    fill: "#ffffff",
    width: 22,
    height: 22,
    marginLeft: 3
  },
  arrowIconMobile: {
    fill: "#ffffff",
    width: 22,
    height: 22,
    marginLeft: 7
  }
}));

export default ListTestOccasions;