import React, {createContext, useContext, useState} from "react";
import {AuthContext} from "./authContext";
import APICall from "../functional/APIRequests";
import {useSnackbar} from "notistack";
import {OrgContext} from "./orgContext";
import {emailRegex} from "../functional/etc";
import moment from "moment";
import {LocalizationContext} from "./localizationContext";
import {PersonelContext} from "./personelContext";

const TestcaseContext = createContext({});
const TestcaseProvider = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {selectedOrg} = useContext(OrgContext);
  const {user, setLoading} = useContext(AuthContext);
  const {getTranslation} = useContext(LocalizationContext);
  const {getPersonel} = useContext(PersonelContext);

  const [testCaseData, setTestCaseData] = useState({});
  const [openTests, setOpenTests] = useState([]);
  const [usersList, setUsersList] = useState([]);

  const [products, setProducts] = useState({});
  const [rawProducts, setRawProducts] = useState([]);
  const [testMethods, setTestMethods] = useState([]);
  const [selectedTestMethod, setSelectedTestMethod] = useState();
  const [mros, setMros] = useState([]);
  const [selectedMRO, setSelectedMRO] = useState();
  const [testReasons, setTestReasons] = useState([]);
  const [selectedTestReason, setSelectedTestReason] = useState();

  const [userData, setUserData] = useState({});

  const [idOpen, setIdOpen] = useState(false);
  const [portraitOpen, setPortraitOpen] = useState(false);
  const [portraitImage, setPortraitImage] = useState("");
  const [barcodeInUse, setBarcodeInUse] = useState(false);
  const [ssnInUse, setSsnInUse] = useState(false);

  const identification_documents = [
    {
      id: "0",
      name: getTranslation("document_passport")
    },
    {
      id: "1",
      name: getTranslation("document_id_card")
    },
    {
      id: "2",
      name: getTranslation("document_drivers_license")
    },
    {
      id: "3",
      name: getTranslation("document_other")
    },
  ];

  //Create or edit test case
  const createTestCase = id => {
    setLoading(true);

    let testers = testCaseData.tester_ids.join();
    let test_ids = testCaseData.test_ids.join();

    let form = new FormData();
    form.append("data[title]", "testcase test");
    form.append("data[organisation_id]", Number(testCaseData.organisation_id));
    form.append("data[title]", testCaseData.title);
    form.append("data[contact_person]", testCaseData.contact_person);
    form.append("data[email]", testCaseData.email);
    form.append("data[phone]", testCaseData.phone);
    form.append("data[location]", testCaseData.location);
    form.append("data[tester_ids]", testers);
    form.append("data[test_ids]", test_ids);
    form.append("data[date]", moment(testCaseData.date).unix());
    form.append("data[mro_id]", testCaseData.mro);
    form.append("data[test_reason_id]", testCaseData.test_reason);

    if (testCaseData.note) {
      form.append("data[note]", testCaseData.note);
    }

    const url = id ? `customer/${selectedOrg.id}/testcase/${id}/update` : `customer/${selectedOrg.id}/testcase/create`

    return APICall({
      setLoading,
      enqueueSnackbar,
      url: url,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    }).then(res => {
      if (testCaseData.file) {
        let fileForm = new FormData();
        fileForm.append("file", testCaseData.file)
        return APICall({
          setLoading,
          enqueueSnackbar,
          url: `customer/${selectedOrg.id}/testcase/${typeof res.data === 'object' ? res.data.sys_id : res.data}/users/import`,
          verb: 'post',
          server: 'api',
          formData: true,
          data: fileForm,
        })
      }
    }).then(resFile => {
      if (resFile) {
        createTestList(resFile.data)
      }
    })
  }

  const createTestList = id => {
    let form = new FormData();
    form.append('quantity', Number(testCaseData.quantity));

    return APICall({
      url: `customer/${selectedOrg.id}/testcase/${id}/users/create_test_list`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form
    })
      .then(res => console.log(res))
  }

  const getTestCase = (id, page = "") => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/testcase/${id}`,
      verb: "get",
      server: "api",
      formData: false
    }).then(res => {
      if (res) {
        getMethodOfProduct(res.data.test_ids.split(",")[0]);

        if (page === "personal") {
          getMethodOfProduct(res.data.test_ids.split(",")[0]);
          setUserData(prevState => ({
            ...prevState,
            tests: res.data.test_ids.split(","),
            organisation_id: res.data.organisation_id,
            tester_ids: res.data.tester_ids.split(","),
            location: res.data.location,
            date: moment.unix(res.data.date),
            title: res.data.title,
            closed: res.data.closed
          }));

          //setTestCaseData(res.data);
        } else {
          setTestCaseData(prevState => ({
            ...prevState,
            organisation_id: res.data.organisation_id,
            title: res.data.title,
            contact_person: res.data.contact_person,
            email: res.data.email,
            phone: res.data.phone,
            location: res.data.location,
            tester_ids: res.data.tester_ids.split(","),
            date: moment.unix(res.data.date),
            note: res.data.note,
            test_ids: res.data.test_ids.split(","),
            tests_done: res.data.tests_done,
            tests_total: res.data.tests_total,
            cdate: res.data.cdate,
            closed: res.data.closed,
            mro: res.data.mro_id,
            test_reason: res.data.test_reason_id,
            organisation_name: res.data.organisation_name
          }));
          setSelectedMRO(res.data.mro_id);
          setSelectedTestReason(res.data.test_reason_id);
        }
      }
    });
    setLoading(false);
  };

  const userInfoFormValidator = () => {
    let error = [];

    if (!userData.ssn) {
      error.push("ssn");
    }
    if (!userData.name) {
      error.push("name");
    }
    if (!userData.phone) {
      error.push("phone");
    }
    if (!userData.email || userData.email.length === 0 || !emailRegex.test(userData.email)) {
      error.push("email");
    }

    return error.length === 0;
  }

  const personalDataFormValidator = () => {
    let error = [];

    if (!userData.ssn) {
      error.push("ssn");
    }
    if (!userData.name) {
      error.push("name");
    }
    if (!userData.phone) {
      error.push("phone");
    }
    if (!userData.email || userData.email.length === 0 || !emailRegex.test(userData.email)) {
      error.push("email");
    }
    if (!userData.id_document) {
      error.push("id_document");
    }
    if (userData.id_document && userData.id_document.id === "3") {
      if (!userData.certified_by) {
        error.push("certified_by");
      }
    }
    if (!userData.location || userData.location.length === 0) {
      error.push("location");
    }
    if (!userData.date) {
      error.push("date");
    }
    if (!userData.tester_ids || userData.tester_ids.length === 0) {
      error.push("tester_ids");
    }
    if (!userData.sampler_check) {
      error.push("sampler_check");
    }
    if (!userData.barcode || barcodeInUse) {
      error.push("barcode");
    }
    if (!userData.signature) {
      error.push("signature");
    }

    return error.length === 0
  };

  const getAllUserTestCases = () => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/testcase?data[closed]=0`,
      verb: "get",
      server: "api",
      formData: false
    }).then(res => {
      if (res) {
        setOpenTests(res.data);
      }
    });
    setLoading(false);
  };

  const getMethodOfProduct = (id) => {
    for (let i in Object.values(products)) {
      let methodId = Object.values(products)[i];
      let product = methodId.find(i => i.sys_id === id)

      if (product) {
        setSelectedTestMethod(testMethods.find(t => t.sys_id === product.test_method_id));
        break;
      }
    }
  };

  const fetchTestMethods = () => {
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `products/methods`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      if (res) {
        setTestMethods(res.data);
        setSelectedTestMethod(res.data[1]);
      }
    })
  };

  const fetchProducts = () => {
    setLoading(true)
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `products?sort=sort_order asc`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setRawProducts(res.data);
      setLoading(false);
    })
  };

  const getMethodByProductId = id => {
    return testMethods.find(m => m.sys_id === id);
  }

  const getTestsById = (id) => {
    return rawProducts.find(r => r.sys_id === id);
  };

  const addUserToTestList = (testId) => {

    const form = new FormData();
    form.append("data[ssn]", userData.ssn);
    form.append("data[name]", userData.name);
    form.append("data[email]", userData.email);
    form.append("data[phone]", userData.phone);

    const url = `customer/${selectedOrg.id}/testcase/${testId}/user/add_to_test_list`
    setLoading(true);
    return APICall({
      setLoading,
      enqueueSnackbar,
      url,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form
    })
  };

  const closeTestCase = testId => {
    setLoading(true);
    return APICall({
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/testcase/${testId}/close`,
      verb: 'POST',
      server: 'api',
    }, false)
  };

  const openTestCase = testId => {
    setLoading(true);
    return APICall({
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/testcase/${testId}/open`,
      verb: 'POST',
      server: 'api',
    }, false)
  };

  const fetchAllMROs = () => {
    setLoading(true);
    return APICall({
      url: `customer/${selectedOrg.id}/mro?prodiagnostics=${selectedOrg.id === "1" ? 1 : 2}`,
      verb: 'GET',
      server: 'api'
    })
      .then(res => {
        setMros(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const fetchPersonnel = () => {
    getPersonel("", selectedOrg.id)
      .then(res => setUsersList(res.data))
  }

  const handleSetUserTested = id => {
    setLoading(true);

    let testersNames = "";
    let testersEmails = "";

    let form = new FormData();
    form.append("data[patient_pnr]", userData.ssn);
    form.append("data[patient_name]", userData.name);
    form.append("data[patient_email]", userData.email);
    form.append("data[patient_phone]", userData.phone);

    if (userData.id_document.id === "3") {
      form.append("data[ident_type]", userData.certified_by);
    } else {
      form.append("data[ident_type]", userData.id_document.id);
    }

    if (userData.id_photo && userData.id_photo.length > 0) {
      form.append("data[ident_photo_file_id]", userData.id_photo);
    }
    if (userData.portrait_photo && userData.portrait_photo.length > 0) {
      form.append("data[photo_file_id]", userData.portrait_photo);
    }

    form.append("data[location]", userData.location);
    form.append("data[test_date]", moment(userData.date).unix());

    form.append("data[test_method_id]", getTestsById(userData.tests[0]).test_method_id);
    userData.tests.forEach(product => {
      form.append(`data[products][${product}][selected]`, '1');
    })

    for (let i in userData.tester_ids) {
      let user = userData.tester_ids[i];

      let tester = usersList.find(u => u.sys_id === user);

      if (tester) {
        testersNames += `${tester.name},`;
        testersEmails += `${tester.email},`;
      }
    }
    let _testersNames = testersNames.slice(0, -1);
    let _testersEmails = testersEmails.slice(0, -1);
    form.append("data[tester_name]", _testersNames);
    form.append("data[tester_email]", _testersEmails);

    form.append("data[referral_id]", userData.barcode);
    form.append("data[signature_file_id]", userData.signature);

    form.append("data[test_case_id]", id);
    form.append("data[organisation_id]", selectedOrg.id);

    return APICall({
      url: `customer/${selectedOrg.id}/referral/create`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    })
  };

  const setUserTested = (testcaseId, patientId) => {
    setLoading(true);
    return APICall({
      setLoading,
      url: `customer/${selectedOrg.id}/testcase/${testcaseId}/user/${patientId}/tested`,
      verb: "GET",
      server: "api"
    })
  };

  const fetchReferral = (ssn, testCaseId) => {
    setLoading(true);
    return APICall({
      setLoading,
      url: `customer/${selectedOrg.id}/referrals?filter[referral.patient_pnr]=${ssn}&filter[workplace]=1&filter[referral.test_case_id]=${testCaseId}`,
      verb: "GET",
      server: "api"
    })
      .then(res => {
        if (res.data && res.data.length > 0) {
          let referral;
          for (let i in res.data) {
            if (res.data[i].referral.test_case_id === testCaseId) {
              referral = res.data[i];
            }
          }

          let identType = referral.referral.ident_type;

          let _identType = identification_documents.find(i => i.id === identType) || identification_documents[3];
          let certified_by = "";

          if (identType !== "0" && identType !== "1" && identType !== "2") {
            certified_by = identType;
          }

          let tests = [];
          for(let i in referral.analysis) {
            let test = referral.analysis[i];
            tests = [...tests, test.sys_id];
          }

          setUserData(prevState => ({
            ...prevState,
            //ssn: referral.referral.patient_pnr,
            referral_sys_id: referral.referral.sys_id,
            id_document: _identType,
            certified_by: certified_by,
            id_photo: referral.referral.ident_photo_file,
            portrait_photo: referral.referral.photo_file,
            info: referral.referral.comments,
            history: referral.referral.drug_history,
            barcode: referral.referral.referral_id,
            signature: referral.referral.signature_file,
            tests: tests
          }));

          //fetchUserReferralInfo(referral.referral.sys_id);

          setLoading(false);
        }
      })
      .catch(() => setLoading(false))
  };

  const fetchUserReferralInfo = refId => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/referral/${refId}/patient`,
      verb: 'get',
      server: 'api'
    })
      .then(res => {
        if(res && res.data) {
          setUserData(prevState => ({
            ...prevState,
            name: res.data.name,
            email: res.data.email,
            phone: res.data.phone
          }))
        }
        setLoading(false);
      })
      .catch(e => setLoading(false))
  }

  const setTestCaseCompete = id => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/1/testcase/${id}/settestcasecomplete`,
      verb: "post",
      server: "api"
    })
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  };

  const fetchTestReasons = () => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/testreasons`,
      verb: "get",
      server: "api"
    })
      .then(res => {
        setTestReasons(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false))
  };

  return (
    <TestcaseContext.Provider value={{
      //States
      testCaseData,
      userData,
      openTests,
      selectedTestMethod,
      products,
      testMethods,
      rawProducts,
      idOpen,
      portraitOpen,
      portraitImage,
      barcodeInUse,
      mros,
      selectedMRO,
      usersList,
      ssnInUse,
      selectedTestReason,
      testReasons,
      //Update states
      setTestCaseData,
      setSelectedTestMethod,
      setProducts,
      setTestMethods,
      setUserData,
      setIdOpen,
      setPortraitOpen,
      setPortraitImage,
      setBarcodeInUse,
      setSelectedMRO,
      setUsersList,
      setSsnInUse,
      setSelectedTestReason,
      //Functions
      createTestCase,
      getTestCase,
      personalDataFormValidator,
      getAllUserTestCases,
      fetchTestMethods,
      fetchProducts,
      getMethodByProductId,
      getTestsById,
      userInfoFormValidator,
      addUserToTestList,
      closeTestCase,
      openTestCase,
      fetchPersonnel,
      fetchAllMROs,
      handleSetUserTested,
      setUserTested,
      fetchReferral,
      setTestCaseCompete,
      fetchTestReasons,
      //Constants
      identification_documents
    }}>
      {props.children}
    </TestcaseContext.Provider>
  );
}

export {TestcaseContext, TestcaseProvider};
