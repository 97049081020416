import React, {useContext, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {AuthContext} from "../context/authContext";

const AuthBankIdHosted = () => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const grandidsession = params.get("grandidsession");

    const {bankIdCheck, setBankIdCode, authType} = useContext(AuthContext);


    useEffect(() => {
        if (grandidsession) {
            setBankIdCode(grandidsession)
            bankIdCheck({code: grandidsession});
        }
    }, []);

    useEffect(() => {
        if(authType && authType.complete) {
            history.push("/");
        }
    },[authType.complete])

    return <div></div>
}

export default AuthBankIdHosted;