import React, {useCallback, useContext, useEffect, useState} from "react";
import {debounce} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import colorConfig from "../../../styles/Theme-settings";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {LocalizationContext} from "../../../context/localizationContext";
import {TestcaseContext} from "../../../context/testcaseContext";
import {OrgContext} from "../../../context/orgContext";
import APICall from "../../../functional/APIRequests";
import {useSnackbar} from "notistack";
import {AuthContext} from "../../../context/authContext";
import {useParams} from "react-router-dom";
import Mobile from "./mobile";
import Tablet from "./tablet";

const AddUser = (props) => {
  const classes = useStyles();

  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const desktop = useMediaQuery("(min-width: 1025px)");

  const {id} = useParams();

  const {enqueueSnackbar} = useSnackbar();

  const {getTranslation} = useContext(LocalizationContext);
  const {setLoading} = useContext(AuthContext);
  const {getOrganizationById} = useContext(OrgContext);
  const {selectedOrg} = useContext(OrgContext);
  const {
    userData,
    setUserData,
    getTestCase,
    userInfoFormValidator,
    addUserToTestList,
    ssnInUse,
    setSsnInUse
  } = useContext(TestcaseContext);
  const [orgName, setOrgName] = useState("");

  const checkUserInTestList = useCallback(
    debounce(ssn => {
      setLoading(true);
      APICall({
        setLoading,
        enqueueSnackbar,
        //Tje 16 is not being used in the backend but is required
        url: `customer/${selectedOrg.id}/testcase/${id}/user/in_list?ssn=${ssn}`,
        verb: 'get',
        server: 'api'
      })
        .then(res => {
          if (res.data) {
            enqueueSnackbar(getTranslation('user_already_in_test_list'), {variant: "error"});
          }
          setSsnInUse(res.data);
          setLoading(false);
        })
        .catch(err => console.log(err))
    }, 1000), []);

  const handleTextChange = e => {
    setUserData({...userData, [e.target.name]: e.target.value})

    if (e.target.name === "ssn" && e.target.value.length > 0)
      checkUserInTestList(e.target.value);
  };

  const onSubmitContinue = () => {
    if (ssnInUse) {
      enqueueSnackbar(getTranslation('user_already_in_test_list'), {variant: "error"});
    }

    if (userInfoFormValidator() && !ssnInUse) {
      addUserToTestList(id)
        .then(res => {
          if (res) {
            setUserData({
              ...userData,
              ssn: res.data.ssn,
              name: res.data.name,
              phone: res.data.phone,
              email: res.data.email
            });
            enqueueSnackbar(getTranslation('user_added_to_test_list'), {variant: "success"});
            props.history.push(`/testcase/${res.data.test_case_id}/user/${res.data.sys_id}`)
          }
        })
    }
  };

  const onSubmit = () => {
    if (ssnInUse) {
      enqueueSnackbar(getTranslation('user_already_in_test_list'), {variant: "error"});
    }

    if (userInfoFormValidator() && !ssnInUse) {
      addUserToTestList(id)
        .then(res => {
          if (res) {
            setUserData({
              ...userData,
              ssn: res.data.ssn,
              name: res.data.name,
              phone: res.data.phone,
              email: res.data.email
            });
            enqueueSnackbar(getTranslation('user_added_to_test_list'), {variant: "success"});
            props.history.push(`/testcase/details/${res.data.test_case_id}`)
          }
        })
    }
  };

  useEffect(() => {
    getTestCase(id, "personal");
  }, [])

  useEffect(() => {
    if (userData.organisation_id) {
      getOrganizationById({idOrg: userData.organisation_id})
        .then(res => setOrgName(res.name))
    }
  }, [userData.organisation_id]);

  return (
    <>
      {desktop &&
      <Tablet
        getTranslation={getTranslation}
        userData={userData}
        handleTextChange={handleTextChange}
        orgName={orgName}
        onSubmitContinue={onSubmitContinue}
        onSubmit={onSubmit}
        classes={classes}
        ssnInUse={ssnInUse}
        {...props}
      />}
      {tablet &&
      <Tablet
        getTranslation={getTranslation}
        userData={userData}
        handleTextChange={handleTextChange}
        orgName={orgName}
        onSubmitContinue={onSubmitContinue}
        onSubmit={onSubmit}
        classes={classes}
        ssnInUse={ssnInUse}
        {...props}
      />}
      {mobile &&
      <Mobile
        getTranslation={getTranslation}
        userData={userData}
        handleTextChange={handleTextChange}
        orgName={orgName}
        onSubmitContinue={onSubmitContinue}
        onSubmit={onSubmit}
        classes={classes}
        ssnInUse={ssnInUse}
        {...props}
      />}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  backButton: {
    color: colorConfig.lightBlack
  },
  backIcon: {
    fill: colorConfig.lightBlack
  },
  marginBottom: {
    marginBottom: 20
  },
  paddingLeft: {
    paddingLeft: "0px !important"
  },
  paddingRight: {
    paddingRight: "24px !important"
  },
  saveButton: {
    textTransform: "uppercase",
    padding: "8px 50px",
    minWidth: 255,
    maxWidth: 255
  },
  textFieldInputLabel: {
    color: `${colorConfig.textGray} !important`,
  },
}));

export default AddUser;
