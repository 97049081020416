import React from "react";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const Mobile = props => {

  const {getTranslation, data, classes} = props;

  return (
    <Grid item xs={12}>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} className={classes.row}>
          <Button
            className={classes.backButtonMobile}
            startIcon={<KeyboardBackspaceIcon className={classes.backIconMobile}/>}
            onClick={() => props.history.goBack()}
          >
            {getTranslation('testcase_button_go_back')}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.row}>
          <Typography variant="h6"><b>{getTranslation("testcase_list_title")}</b></Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {data.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Grid container>
                  <Paper className={classes.paperMobile}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>{moment.unix(item.date).format('YYYY-MM-DD')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>{item.organisation_name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>{item.title}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <div className={classes.testNrsMobile}>
                        {/* <Typography variant="subtitle1" className={classes.testsDone}>{item.tests_done}</Typography> */}
                        <Typography variant="subtitle1" className={classes.totalTests}>{item.tests_total}</Typography>
                      </div>
                    </Grid>
                  </Paper>
                  <Grid item xs={6} className={classes.editContainerMobile} onClick={() => props.history.push(`/testcase/${item.sys_id}`)}>
                    <EditIcon className={classes.editIcon}/>
                  </Grid>
                  <Grid item xs={6} className={classes.chooseContainerMobile} onClick={() => props.history.push(`/testcase/details/${item.sys_id}`)}>
                    <Typography variant="subtitle1"
                                className={classes.chooseText}>{getTranslation("testcase_list_select_button")}</Typography>
                    <ArrowForwardIcon className={classes.arrowIconMobile}/>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Mobile;