import React, { useState, useContext, useEffect } from 'react';
import {
  Grid, Paper, Typography, withWidth
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LocalizationContext } from '../context/localizationContext';
import { OrgContext } from '../context/orgContext';
import { AuthContext } from '../context/authContext';
import { useSnackbar } from 'notistack';
import APICall from '../functional/APIRequests';
import colors from '../styles/Theme-settings'
import { permLevel } from '../functional/etc';
import { Assignment, AddBox, Subscriptions, Headset } from '@material-ui/icons'
const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex'
  },
  paper: {
    width: "100%",
    // padding: 20,
  },
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  greenSquare: {
    border: '1px solid #28A745',
    marginBottom: 20,
    padding: 15,
  },
  greenbox: {
    background: '#28A745',
    fontSize: 40,
    fontWeight: 'bold',
    color: 'white',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentGroup: {
    padding: 20
  },
  shortcutHolder: {
    padding: 10,
  },
  shortcutBox: {
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 3,
    color: '#222222',
    fill: '#222222',
    border: '1px solid #222222',
    padding: 10,
    height: '100%',
    '&:hover': {
      color: colors.primary,
      fill: colors.primary,
      borderColor: colors.primary,
    }
  },
  divider: {
    width: '80%',
  },
  imgHolder: {
    backgroundImage: 'url(/images/background.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '150px',
    borderRadius: '5px',
  }
}));
const Dashboard = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { getTranslation } = useContext(LocalizationContext);
  const { setLoading } = useContext(AuthContext);
  const { selectedOrg } = useContext(OrgContext);
  const [data, setData] = useState({
    msg: 0,
    workers: 0,
  })
  document.title = getTranslation('dashboard_browser_title') + ' | Prodiagnostics';

  useEffect(() => {
    if(selectedOrg.consult)
        return props.history.push("/testcase/list")
  }, [selectedOrg])

  useEffect(() => {
    if(selectedOrg.id) {
      setLoading(true)
      APICall({
        setLoading,
        enqueueSnackbar,
        url: `${selectedOrg.id === "1" ? 'prodiagnostics' : 'customer'}/${selectedOrg.id}/profile/statistics`,
        verb: 'get',
        server: 'api',
      }).then((res) => {
        setData({
          msg: res.data.analysis_last_7_days,
          workers: res.data.active_employees,
        })
        setLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [selectedOrg.id])
  const getPerm = () => {
    return permLevel(selectedOrg);
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        {/* {selectedOrg.algo.algo} */}
        <Paper className={classes.paperTitle} elevation={0}>
          <Typography variant="h6" gutterBottom>
            {getTranslation('dashboard_page_title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {getTranslation('dashboard_page_subtitle')}
          </Typography>
        </Paper>
        <Paper className={classes.paper} elevation={0}>
          <Grid container>
            <Grid item xs={12} sm={5} className={classes.contentGroup}>
              <div className={classes.greenSquare} style={{ padding: 0 }}>
                <Grid container>
                  <Grid item xs={9} style={{ padding: 15 }}>
                    <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                      {getTranslation('dashboard_new_analysis_counter_title')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {getTranslation('dashboard_new_analysis_counter_subtitle')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={classes.greenbox}>
                      {data.msg}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.greenSquare}>
                <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                  {getTranslation('dasboard_shortcut_title')}
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={6} className={classes.shortcutHolder}>
                    <div className={classes.shortcutBox} onClick={() => props.history.push('/analyses')}>
                      <Assignment style={{ fill: 'inherit', height: 35, width: 35 }} />
                      <Typography variant="body2">
                        {getTranslation('dasboard_shortcut_analysis')}
                      </Typography>
                    </div>
                  </Grid>
                  {getPerm() <= 2 &&
                    <Grid item xs={12} sm={12} md={12} lg={6} className={classes.shortcutHolder}>
                      <div className={classes.shortcutBox} onClick={() => props.history.push('/referral')}>
                        <AddBox style={{ fill: 'inherit', height: 35, width: 35 }} />
                        <Typography variant="body2">
                          {getTranslation('dasboard_shortcut_referral')}
                        </Typography>
                      </div>
                    </Grid>
                  }
                  <Grid item xs={12} sm={12} md={12} lg={6} className={classes.shortcutHolder}>
                    <div className={classes.shortcutBox} onClick={() => props.history.push('/educational')}>
                      <Subscriptions style={{ fill: 'inherit', height: 35, width: 35 }} />
                      <Typography variant="body2">
                        {getTranslation('dasboard_shortcut_training')}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.greenSquare}>
                <Headset style={{ fill: '#222222', float: 'right', height: 28, width: 28 }} />
                <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                  {getTranslation('dasboard_support_title')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {getTranslation('dasboard_support_schedule')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {getTranslation('dasboard_support_phone')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {getTranslation('dasboard_support_mail')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {getTranslation('dasboard_support_chat')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} className={classes.contentGroup}>
              <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                {getTranslation('dasboard_about_title')}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {getTranslation('dasboard_about_subtitle')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                {getTranslation('dasboard_company_title')}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {getTranslation('dasboard_company_subtitle')}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default withWidth()(Dashboard);
