import React, {useState, createContext, useEffect, useContext} from 'react';
import {AuthContext} from './authContext';
import {OrgContext} from './orgContext';
import {LocalizationContext} from './localizationContext';
import {useSnackbar} from 'notistack';
import APICall from '../functional/APIRequests';
import moment from 'moment';
import {ContactSupportOutlined} from '@material-ui/icons';
import {emailRegex} from "../functional/etc";

const RefContext = createContext({});
const RefProvider = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {setLoading} = useContext(AuthContext);
  const {selectedOrg, getOrganizationById} = useContext(OrgContext);
  const {getTranslation} = useContext(LocalizationContext);
  const [complete, setComplete] = useState(false);
  const [sendStep, setSendStep] = useState(1);
  const [validSsn, setValidSsn] = useState(false);
  const [pnrInUse, setPnrInUse] = useState(false);
  const [selectedPatientOption, setSelectedPatientOption] = useState(0);
  const [patients, setPatients] = useState([]);

  //States
  let stepsArr = [
    {
      id: 0,
      title: getTranslation('referral_step_one_title'),
      subTitle: getTranslation('referral_step_one_subTitle'),
      valid: false
    },
    {
      id: 1,
      title: getTranslation('referral_step_two_title'),
      subTitle: getTranslation('referral_step_two_subTitle'),
      valid: false
    },
    {
      id: 2,
      title: getTranslation('referral_step_tree_title'),
      subTitle: getTranslation('referral_step_tree_subTitle'),
      valid: false
    },
    {
      id: 3,
      title: getTranslation('referral_step_four_title'),
      subTitle: getTranslation('referral_step_four_subTitle'),
      valid: false
    }
  ]
  const [steps, setSteps] = useState(stepsArr);
  const [validId, setValidId] = useState(false);
  const [titleBox, setTitleBox] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formType, setFormType] = useState(1);//1:Referral, 2:Warranty;
  const [testMethods, setTestMethods] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [referalData, setReferalData] = useState({});
  const [submited, setSubmited] = useState(false);
  const [otherChemIntake, setOtherChemIntake] = useState();
  const [verifiedCheck, setVerifiedCheck] = useState(false);

  const fetchData = id => {
    setLoading(true)
    getOrganizationById({idOrg: selectedOrg.id}).then((resOrg) => {
      APICall({
        setLoading,
        hasRefresh: true,
        enqueueSnackbar,
        url: `customer/${selectedOrg.id}/referral/${id}`,
        verb: 'get',
        server: 'api',
      }).then((res) => {
        if (res) {
          setReferalData(prevState => ({
            ...prevState,
            sys_id: res.data.referral.sys_id,
            id: res.data.referral.referral_id,
            name: res.data.referral.tester_name,
            testMethodId: res.data.referral.test_method_id,
            email: res.data.referral.tester_email,
            date: moment.unix(res.data.referral.test_date).format('YYYY-MM-DD HH:mm:ss'),
            historic: res.data.referral.drug_history,
            labComments: res.data.referral.comments,
            warranty: res.data.referral.warranty,
            patient_name: res.data.referral.patient_name,
            patient_pnr: res.data.referral.patient_pnr,
            initials: res.data.referral.patient_pnr ? getPatientInitials(res.data.referral.patient_name) : res.data.referral.patient_name,
            quickTestId: res.data.referral.quick_test_id,
            warrantyLotId: res.data.referral.warranty_lot_id,
            warrantyComments: res.data.referral.warranty_comments,
            warrantyTestDate: moment.unix(res.data.referral.warranty_test_date).format('YYYY-MM-DD HH:mm:ss'),
            notify: res.data.notify_users,
            referral_id: res.data.referral.referral_id,
            blockDigitalSign: res.data.referral.length > 0,
            allowSignPad: resOrg.allow_signpad === '1',
            status_id: res.data.referral.status_id,
            file_id: res.data.referral.file_id,
            secundary_file_id: res.data.referral.secundary_file_id
          }))
        }
        setLoading(false);
      })
    })
  }

  const fetchHashForSignPad = () => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/referral/${referalData.sys_id}/signablehash`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      if (res && res.message) {
        setReferalData(prevState => ({...prevState, hash: res.message}))
      }
      setLoading(false)
    })
  }
  const submitForm = () => {
    setLoading(true);
    var form = new FormData();
    if(selectedPatientOption === 0 && Number(selectedOrg.patientmodule) === 1) {
      form.append("data[patient_id]", referalData.patient_id);
    }
    form.append("data[organisation_id]", selectedOrg.id);
    form.append("data[referral_id]", referalData.id);
    form.append("data[notify_users]", referalData.usersNotify);
    form.append("data[notify_organisation]", referalData.notifyCheck || false);
    form.append("data[test_method_id]", referalData.testMethodId);
    form.append("data[test_date]", moment(referalData.date).unix());
    form.append("data[tester_name]", referalData.name);
    form.append("data[tester_email]", referalData.email);
    if (referalData.labComments) {
      form.append("data[comments]", referalData.labComments);
    }
    if (referalData.historic) {
      form.append("data[drug_history]", referalData.historic);
    }
    form.append("data[patient_name]", referalData.patient_name ? referalData.patient_name.trim() : referalData.initials.trim());
    {
      if (referalData.patient_pnr)
        form.append("data[patient_pnr]", referalData.patient_pnr.trim() || "");
    }
    form.append("data[warranty]", formType === 2 ? 1 : 0);
    if (formType === 2) {
      form.append("data[quick_test_id]", referalData.quickTestId);
      form.append("data[warranty_lot_id]", referalData.warrantyLotId);
      form.append("data[warranty_test_date]", moment(referalData.dateWarranty).unix());
      form.append("data[warranty_comments]", referalData.warrantyComments || '');
      if (referalData.image) {
        form.append("file", referalData.image);
      }
      if (referalData.secundary_image) {
        form.append("secundary_file", referalData.secundary_image);
      }
    }
    if (otherChemIntake) {
      otherChemIntake.forEach(oci => {
        if (oci.type === 1) {
          form.append(`data[products][${oci.sys_id}][data][freetext]`, oci.freeText);
        } else {
          form.append(`data[products][${oci.sys_id}][data][substance]`, oci.chemName);
          form.append(`data[products][${oci.sys_id}][data][dosage]`, oci.chemAmmount);
          form.append(`data[products][${oci.sys_id}][data][testdatetime]`, moment(oci.date).unix());
          form.append(`data[products][${oci.sys_id}][data][dosagedatetime]`, moment(oci.chemIntakeDate).unix());
        }
      })
    }
    selectedProduct.forEach(product => {
      form.append(`data[products][${product.sys_id}][selected]`, '1');
    })
    return APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/referral/create`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    })
  }
  const cleanStorage = () => {
    setValidId(false);
    setSteps(stepsArr);
    setTitleBox(false);
    setActiveStep(0);
    setTestMethods();
    setSelectedProduct();
    setReferalData({});
    setOtherChemIntake();
    setSubmited(false);
    setComplete(false);
  }
  const changeStateStep = (action, id, value) => {
    switch (action) {
      case 'validChange':
        steps[id].valid = value;
        setSteps(steps)
        break;
      case 1:
        return 'What is an ad group anyways?';
      case 2:
        return 'This is the bit I really care about!';
      default:
        return false;
    }

  }
  const sendToLab = () => {
    setLoading(true);
    if (complete) {
      cleanStorage();
    } else {
      var form = new FormData();
      form.append("data[skip_signature]", '0');
      form.append("data[send_digital]", '0');
      APICall({
        setLoading,
        enqueueSnackbar,
        url: `customer/${selectedOrg.id}/referral/${referalData.sys_id}/setcomplete`,
        verb: 'post',
        server: 'api',
        formData: true,
        data: form,
      }).then((res) => {
        setLoading(false)
        cleanStorage();
      })
    }
  }
  const getPadInfo = () => {
    if (validId && referalData && !Object.keys(referalData).includes('allowSignPad')) {
      getOrganizationById({noLoad: true, idOrg: selectedOrg.id}).then((res) => {
        setReferalData(prevState => ({...prevState, allowSignPad: res.allow_signpad === '1'}))
      })
    }
  }
  const getPatientInitials = (value) => {
    return value.split(' ').filter(item => item && item.trim().length > 0).map(item => item.substring(0, 1).toUpperCase()).join('');
  };

  const formValidator = () => {
    let error = [];
    let fieldsToValidate = formType === 1 ? ['name', 'email', 'patient_name', 'patient_pnr', 'date'] : ['name', 'email', 'patient_name', 'patient_pnr', 'date', 'quickTestId', 'warrantyLotId', 'dateWarranty', 'image']
    if (Number(selectedOrg.patientmodule === 1)) {
      if (formType === 1) {
        fieldsToValidate = ['name', 'email', 'patient_name', 'patient_pnr', 'date']
      } else {
        fieldsToValidate = ['name', 'email', 'patient_name', 'patient_pnr', 'date', 'quickTestId', 'warrantyLotId', 'dateWarranty', 'image']
      }
    } else {
      if (formType === 1) {
        fieldsToValidate = fieldsToValidate = ['name', 'email', 'initials', 'date']
      } else {
        fieldsToValidate = ['name', 'email', 'initials', 'date', 'quickTestId', 'warrantyLotId', 'dateWarranty', 'image']

      }
    }
    fieldsToValidate.forEach((key) => {
      let value = referalData[key];
      switch (key) {
        case 'email':
          if (!value || value.length === 0 || !emailRegex.test(value)) {
            error.push('email')
          }
          break;
        default:
          if (!value || value.length === 0) {
            error.push(key)
          }
          break
      }
    });

    return error.length === 0;
  };

  const fetchOrgPatients = (refresh = false, getAnonymized = false) => {
    if (patients.length > 0 && !refresh)
      return;

    setLoading(true);

    let url = `customer/${selectedOrg.id}/patients?limit=999999&page=1&sort=patient.name asc&filter[archivePatient]=0`;

    if(getAnonymized) {
      url += `&filter[anonymizePatient]=1`;
    } else {
      url += `&filter[anonymizePatient]=0`;
    }

    APICall({
      setLoading,
      enqueueSnackbar,
      url: url,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      if (res) {
        setPatients([{id: 0}, ...res.data.items])
      }
      setLoading(false)
    })
  };

  useEffect(() => {
    let hasSelProds = (!!selectedProduct && selectedProduct.filter(item => !item.verified).length > 0);
    if (hasSelProds && (activeStep === 1 && steps[1].valid === false)) {
      setLoading(true)
      changeStateStep('validChange', 1, true);
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }
    if (!hasSelProds && (activeStep === 1 && steps[1].valid === true)) {
      setLoading(true)
      changeStateStep('validChange', 1, false);
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);
  useEffect(() => {
    if (referalData && referalData.sys_id && !referalData.hash) {
      fetchHashForSignPad();
    }
    getPadInfo();
    // eslint-disable-next-line
  }, [referalData])
  return (
    <RefContext.Provider value={{
      //States
      verifiedCheck,
      selectedProduct,
      referalData,
      testMethods,
      otherChemIntake,
      steps,
      activeStep,
      formType,
      submited,
      titleBox,
      validId,
      validSsn,
      pnrInUse,
      //UpdateStates
      setSelectedProduct,
      setReferalData,
      setTestMethods,
      setOtherChemIntake,
      setSteps,
      setActiveStep,
      setFormType,
      setSubmited,
      setValidId,
      complete,
      setValidSsn,
      setPnrInUse,
      selectedPatientOption,
      patients,
      //Functions
      changeStateStep,
      cleanStorage,
      submitForm,
      setTitleBox,
      setVerifiedCheck,
      sendToLab,
      setComplete,
      getPadInfo,
      getPatientInitials,
      sendStep,
      setSendStep,
      fetchData,
      formValidator,
      setSelectedPatientOption,
      setPatients,
      fetchOrgPatients
    }}>
      {props.children}
    </RefContext.Provider>
  );
};

export {RefContext, RefProvider};
