import React from 'react';
import SVG from './sigSvg';
const containerStyle = {
	cursor: 'pointer',
	background: '#28A745',
	borderRadius: 3,
	color: 'white',
	padding: 15,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-around',
	// flexDirection: 'column',
	fontSize: '1rem',
	fontWeight: 400,
}
const containerDisabledStyle = {
	cursor: 'not-allowed',
	background: '#11481e',
	borderRadius: 3,
	color: 'white',
	padding: 15,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-around',
	// flexDirection: 'column',

}
const textStyle = {
	fontSize: 15,
	fontWeight: 400,
	lineHeight: 1.75,
	fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	letterSpacing: '0.00938em',
	margin: 0,
}
const underTextStyle = {
	fontSize: 10,
	fontWeight: 400,
	lineHeight: 1.75,
	fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	letterSpacing: '0.00938em',
	margin: 0,
}
class Button extends React.Component {
	render() {
		return (
			<div style={this.props.disabled ? containerDisabledStyle : containerStyle} onClick={this.props.funcName} >
				<div>
					<SVG />
				</div>
				<div>
					<h6 style={textStyle} >{this.props.text}</h6>
					<p style={underTextStyle} >{this.props.subText}</p>
				</div>
			</div>

		);
	}
}
export default Button;