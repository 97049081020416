import React, {useContext, useEffect, useState} from "react";
import {
    Grid,
    Typography,
    Button,
    Select,
    MenuItem,
    TextField,
    FormHelperText,
    Checkbox,
    useMediaQuery, InputLabel
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Help from '@material-ui/icons/Help';
import {DateTimePicker} from "@material-ui/pickers";
import {makeStyles} from '@material-ui/core/styles';
import {DropzoneArea} from 'material-ui-dropzone';
import clsx from "clsx";
import {useSnackbar} from "notistack";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {useParams} from "react-router-dom";
import * as XLSX from 'xlsx';
import colorConfig from "../../../styles/Theme-settings";
import APICall from "../../../functional/APIRequests";
import {AuthContext} from "../../../context/authContext";
import {PersonelContext} from "../../../context/personelContext";
import {OrgContext} from "../../../context/orgContext";
import {LocalizationContext} from "../../../context/localizationContext";
import {TestcaseContext} from "../../../context/testcaseContext";
import {emailRegex} from "../../../functional/etc";
import Dialog from "../../../components/Dialog";
import {apiServer} from "../../../functional/generateEnvUrls";
import moment from "moment";

const CreateTestOccasion = (props) => {
    const classes = useStyles();
    const autocompleteClass = autocomplete();

    let {id} = useParams();

    const mobile = useMediaQuery("(max-width: 767px)");

    const {user, loading, setLoading} = useContext(AuthContext);
    const {getPersonel} = useContext(PersonelContext);
    const {selectedOrg} = useContext(OrgContext);
    const {getTranslation} = useContext(LocalizationContext);
    const {
        testCaseData,
        setTestCaseData,
        createTestCase,
        getTestCase,
        selectedTestMethod,
        setSelectedTestMethod,
        products,
        setProducts,
        testMethods,
        fetchTestMethods,
        mros,
        fetchAllMROs,
        selectedMRO,
        setSelectedMRO,
        setTestCaseCompete,
        selectedTestReason,
        setSelectedTestReason,
        fetchTestReasons,
        testReasons,
        openTestCase
    } = useContext(TestcaseContext);
    const {enqueueSnackbar} = useSnackbar();
    const [clients, setClients] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [testCaseTesters, setTestCaseTesters] = useState([]);
    const [file, setFile] = useState(null);
    const [fileRows, setFileRows] = useState(0);
    const [fileColumnsCheck, setFileColumnsCheck] = useState(false);
    const [mroDialogOpen, setMroDialogOpen] = useState(false);
    const [hasExpressReferrals, setHasExpressReferrals] = useState(0);
    const [deleteTestCaseDialog, setDeleteTestCaseDialog] = useState(false);
    const fetchClients = () => {
        setLoading(true);
        APICall({
            setLoading,
            enqueueSnackbar,
            url: `prodiagnostics/1/customers?offset=0&limit=999999`,
            verb: 'get',
            server: 'api',
        }).then((res) => {
            if (res && res.data)
                setClients(res.data.filter(item => item.sys_id !== '1' && item.sys_id !== '2'));
        })
    };

    const fetchProducts = () => {
        setLoading(true)
        APICall({
            setLoading,
            enqueueSnackbar,
            url: `products?sort=sort_order asc`,
            verb: 'get',
            server: 'api',
        }).then((res) => {
            let tempArr = {}
            testMethods.forEach(test => {
                tempArr[test.sys_id] = []
            })
            res.data.forEach(item => tempArr[item.test_method_id].push(item));
            setProducts(tempArr);
            const defaultTestId = tempArr[1].find(item => item.sys_id === "1").sys_id;
            setTestCaseData(prevState => ({...prevState, test_ids: [defaultTestId]}));
            setLoading(false);
        })
    }

    const fetchTestcaseTesters = () => {
        setLoading(true);
        APICall({
            setLoading,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/testcase/${id}/testers`,
            verb: 'get',
            server: 'api'
        })
            .then(res => {
                if (res.data) {
                    setTestCaseTesters(res.data);
                }
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const getTestMethods = (e) => {
        try {
            e.preventDefault()
        } catch (err) {
        }
        ;

        fetchTestMethods();
    };

    const fetchPersonnel = () => {
        getPersonel("", selectedOrg.id)
            .then(res => {
                setUsersList(res.data);
            })
    };

    const fetchReferral = () => {
        setLoading(true);
        APICall({
            setLoading,
            url: `customer/1/referrals?sort=referral.result_date%20desc&limit=1&offset=0&filter[referral.test_case_id]=${id}&filter[workplace]=1`,
            verb: "get",
            server: "api"
        })
            .then(res => {
                if (res) {
                    if (res.data && res.data.length > 0) {
                        setTestCaseData(prevState => ({
                            ...prevState,
                            status_id: res.data[0].referral.status_id,
                        }));
                    } else {
                        setTestCaseData(prevState => ({
                            ...prevState,
                            status_id: 0,
                        }));
                    }
                }
                setLoading(false);
            })
            .catch(() => setLoading(false))
    };

    const fetchTestCaseExpressReferrals = (limit = 20, offset = 0) => {
        let form = new FormData();
        form.append("data[limit]", limit);
        form.append("data[offset]", offset);
        setLoading(true);

        APICall({
            url: `customer/${selectedOrg.id}/testcase/${id}/express_referral?data[test_case_id]=${id}&data[limit]=${limit}&data[offset]=${offset}`,
            verb: 'get',
            server: 'api',
            formData: true,
            data: form,
        })
            .then(res => {
                if (res && res.data) {
                    setHasExpressReferrals(res.total_records);
                }
                setLoading(false);
            })
            .catch(e => setLoading(false))
    }

    const handleTextChange = e => {
        if (e.target.name === 'quantity') {
            let newQuantity = e.target.value > fileRows ? fileRows : e.target.value;
            setTestCaseData(prevState => ({...prevState, ["quantity"]: newQuantity}));
        } else {
            setTestCaseData({...testCaseData, [e.target.name]: e.target.value})
        }
    };

    const handleSelectChange = e => {
        setTestCaseData({...testCaseData, [e.target.name]: e.target.value})
    };

    const handleDateChange = (e, input) => {
        setTestCaseData({...testCaseData, [input]: e});
    };

    const handleFiles = (files) => {
        if (files.length > 0) {
            setFile(files[0]);
            const reader = new FileReader();
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {type: 'binary'});
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const fileData = XLSX.utils.sheet_to_json(ws, {header: 1});

                setFileRows(fileData.length);
                let checkFields = false;
                for (let i in fileData) {
                    let row = fileData[i];
                    if ((typeof row[0] === "string" || typeof row[0] === "undefined") && (typeof row[1] === "string" || typeof row[1] === "number" || typeof row[1] === "undefined") && (typeof row[2] === "string" || typeof row[2] === "number" || typeof row[2] === "undefined") && (typeof row[3] === "string" || typeof row[3] === "undefined")) {
                        checkFields = true;
                    } else {
                        checkFields = false;
                    }
                }
                setFileColumnsCheck(checkFields);

                if (checkFields)
                    setTestCaseData(prevState => ({
                        ...prevState,
                        quantity: fileData.length,
                        file: files[0]
                    }))
            };
            reader.readAsBinaryString(files[0]);
        }
    };

    const formValidator = () => {
        let error = [];
        if (!testCaseData.organisation_id) {
            error.push("client");
        }
        if (!testCaseData.title || testCaseData.title.length === 0) {
            error.push("title");
        }
        if(!selectedOrg.consult) {
            if (!testCaseData.contact_person || testCaseData.contact_person.length === 0) {
                error.push("contact_person");
            }
            if (!testCaseData.email || testCaseData.email.length === 0 || !emailRegex.test(testCaseData.email)) {
                error.push("email");
            }
            if (!testCaseData.phone || testCaseData.phone.length === 0) {
                error.push("phone");
            }
        }
        if (!testCaseData.tester_ids || testCaseData.tester_ids.length === 0) {
            error.push("tester_ids");
        }
        if (!testCaseData.date || (testCaseData.date && testCaseData.date.length === 0)) {
            error.push("date");
        }
        if (!testCaseData.test_ids || testCaseData.test_ids.length === 0) {
            error.push("test_ids");
        }
        /*    if (testCaseData.file) {
              if (!testCaseData.quantity) {
                error.push("quantity");
              }
            }*/
        if (!testCaseData.mro) {
            error.push("mro");
        }
        if (!testCaseData.test_reason) {
            error.push("test_reason");
        }
        return error.length === 0;
    }

    const handleSubmit = () => {
        if (formValidator()) {
            createTestCase(id)
                .then(res => {
                    id ? enqueueSnackbar(getTranslation('testcase_updated_box_title'), {variant: "success"}) : enqueueSnackbar(getTranslation('testcase_submited_box_title'), {variant: "success"});
                    props.history.push("/testcase/list");
                })
        }
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 200,
    });

    const getMRO = () => {
        if (!mros) {
            return "";
        }

        const mro = mros.find(item => item.sys_id === selectedMRO);

        if (!mro) {
            return "";
        } else {
            return mro.sys_id === "0" ? getTranslation(mro.name) : mro.name;
        }
    }

    const openPDF = () => {
        if (testCaseData.tests_done > 0) {
            //let url = `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/testcase/${id}/pdf`;
            let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/testcase/${id}/pdf`;
            window.open(url, "_blank");
        } else {
            enqueueSnackbar(getTranslation("testcase_no_tests_to_print"), {variant: "error"});
        }
    }

    const handleOpen = (testCaseId) => {
        openTestCase(testCaseId).then((res) => {
            if (res.data)
                getTestCase(testCaseId);
        })
    }

    const handleDeleteTestCase = () => {
        setDeleteTestCaseDialog(!deleteTestCaseDialog);
    }

    const deleteTestCase = () => {
        setLoading(true);

        APICall({
            url: `customer/${selectedOrg.id}/testcase/${id}/delete`,
            verb: 'post',
            server: 'api',
        })
            .then(res => {
                if (res.data) {
                    props.history.push('/testcase/list')
                }
                setLoading(false);
            })
            .catch(e => setLoading(false))
    }

    // useEffect(() => {
    //   if (!testCaseData.tester_ids || !id) {
    //     setTestCaseData(prevState => ({...prevState, ["tester_ids"]: [user.user_id]}));
    //   }
    // }, [user])

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    useEffect(() => {
        if (!selectedOrg.consult && !selectedOrg.mro) {
            fetchClients();
            fetchPersonnel();
        }
        getTestMethods();
        fetchAllMROs();
        fetchTestReasons();

        if (!id) {
            setSelectedMRO(null);
            setSelectedTestReason(null);
        }

        if (id && !selectedOrg.consult) {
            fetchReferral();
            fetchTestCaseExpressReferrals();
            fetchTestcaseTesters();
        }

    }, [])

    useEffect(() => {
        if (testMethods.length)
            fetchProducts();
    }, [testMethods])

    useEffect(() => {
        if (id && Object.entries(products).length > 0) {
            getTestCase(id);
        }
        if (!id && selectedOrg.id !== "1") {
            setTestCaseData(prevState => ({...prevState, ["organisation_id"]: selectedOrg.id}))
        }
    }, [products]);

    useEffect(() => {
        if (!id)
            setTestCaseData({});
    }, []);

    return (
        <>
            <Grid item xs={12}>
                <Grid container style={{paddingBottom: 30}}>
                    <Grid item xs={12} className={classes.marginBottom}>
                        <Button
                            className={classes.backButton}
                            startIcon={<KeyboardBackspaceIcon className={classes.backIcon}/>}
                            onClick={() => {
                                props.history.goBack()
                            }}
                        >
                            {getTranslation('testcase_button_go_back')}
                        </Button>
                    </Grid>
                    {(isNumeric(id) && !selectedOrg.consult && !selectedOrg.mro) &&
                        <Grid item xs={9} className={classes.marginBottom}
                              style={{alignItems: 'center', display: 'flex'}}>
                            <Typography variant="h6"><b>{getTranslation('testcase_create_edit_title')}</b></Typography>
                            <Button
                                className={classes.backButton}
                                style={{marginLeft: 16}}
                                startIcon={<FileCopyIcon className={classes.backIcon}/>}
                                onClick={() => {
                                    setTestCaseData({...testCaseData, ["date"]: ''});
                                    props.history.push("/testcase/duplicated");
                                }}
                            >
                                {getTranslation('testcase_button_duplicate')}
                            </Button>
                        </Grid>
                    }
                    {(isNumeric(id) && !selectedOrg.consult && !selectedOrg.mro) &&
                        <Grid item xs={3} className={classes.marginBottom}
                              style={{justifyContent: 'flex-end', display: 'flex'}}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{marginLeft: 16}}
                                onClick={handleDeleteTestCase}
                                disabled={hasExpressReferrals}
                            >
                                {getTranslation('testcase_button_delete')}
                            </Button>
                        </Grid>
                    }
                    {((clients.length > 0 || (testCaseData.organisation_id && id) || selectedOrg.id === '1') && !selectedOrg.consult && !selectedOrg.mro) &&
                        <Grid item xs={12} style={{marginBottom: 30}}>
                            <Autocomplete
                                name="organisation"
                                fullWidth
                                disabled={(id && testCaseData.closed) || selectedOrg.id !== '1'}
                                value={clients.find(c => c.sys_id === testCaseData.organisation_id) || null}
                                style={{marginLeft: -12}}
                                classes={(testCaseData && testCaseData.organisation_id) ? autocompleteClass : classes}
                                options={clients}
                                filterOptions={filterOptions}
                                onChange={(e, value) => setTestCaseData({
                                    ...testCaseData,
                                    ["organisation_id"]: value ? value.sys_id : null
                                })}
                                renderOption={(selected) => (
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">{selected.customer_number}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{selected.name}</Typography>
                                        </Grid>
                                        <Grid item xs={4} style={mobile ? {textAlign: "right"} : {}}>
                                            <Typography variant="body2">{selected.city}</Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={getTranslation('testcase_create_customer_placeholder')}
                                        variant="outlined"
                                        InputLabelProps={{classes: {root: classes.inputLabelRoot}}}
                                    />
                                )}
                                getOptionLabel={(selected) =>
                                    mobile ? `${selected.customer_number}  ${selected.name}` : `${selected.customer_number}       ${selected.name}`
                                }
                            />
                            {!testCaseData.organisation_id &&
                                <FormHelperText
                                    className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
                            }
                        </Grid>}
                    {(selectedOrg.consult || selectedOrg.mro) &&
                        <Grid item xs={12} style={{marginBottom: 30}}>
                            <TextField
                                name="organisation"
                                disabled={(id && testCaseData.closed) || selectedOrg.id !== '1' || selectedOrg.mro}
                                value={testCaseData.organisation_name || ""}
                                variant="outlined"
                                label={testCaseData.organisation_name ? getTranslation("testcase_create_customer_placeholder") : ""}
                                InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                className={classes.textInput}
                                placeholder={getTranslation("testcase_create_customer_placeholder")}
                                onChange={handleTextChange}
                                error={!testCaseData.organisation_name || (testCaseData.organisation_name && testCaseData.organisation_name === 0)}
                                helperText={!testCaseData.organisation_name || testCaseData.organisation_name === 0 ? getTranslation('generic_error_required_field') : ''}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {/*Information*/}
                        <Grid container justify="space-between" spacing={2} className={classes.marginBottom}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"><b> {getTranslation('testcase_create_information_label')}</b></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="title"
                                    disabled={(id && testCaseData.closed) || selectedOrg.consult || selectedOrg.mro}
                                    value={testCaseData.title || ""}
                                    variant="outlined"
                                    label={testCaseData.title ? getTranslation("testcase_title_label") : ""}
                                    InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                    className={classes.textInput}
                                    placeholder={getTranslation("testcase_title_label")}
                                    onChange={handleTextChange}
                                    error={!testCaseData.title || (testCaseData.title && testCaseData.title === 0)}
                                    helperText={!testCaseData.title || testCaseData.title === 0 ? getTranslation('generic_error_required_field') : ''}
                                />
                            </Grid>
                            {!selectedOrg.consult &&
                                <Grid item xs={12}>
                                    <TextField
                                        name="contact_person"
                                        disabled={id && testCaseData.closed}
                                        variant="outlined"
                                        label={testCaseData.contact_person ? getTranslation("testcase_contact_person_label") : ""}
                                        InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                        value={testCaseData.contact_person || ""}
                                        className={classes.textInput}
                                        placeholder={getTranslation("testcase_contact_person_label")}
                                        onChange={handleTextChange}
                                        error={!testCaseData.contact_person || (testCaseData.contact_person && testCaseData.contact_person === 0)}
                                        helperText={!testCaseData.contact_person || testCaseData.contact_person === 0 ? getTranslation('generic_error_required_field') : ''}
                                    />
                                </Grid>
                            }
                            {!selectedOrg.consult &&
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="email"
                                        disabled={id && testCaseData.closed}
                                        variant="outlined"
                                        label={testCaseData.email ? getTranslation("testcase_email_placeholder") : ""}
                                        InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                        value={testCaseData.email || ""}
                                        className={classes.textInput}
                                        placeholder={getTranslation("testcase_email_placeholder")}
                                        error={!testCaseData.email || (testCaseData.email.length > 0 && !emailRegex.test(testCaseData.email))}
                                        onChange={handleTextChange}
                                        helperText={!testCaseData.email || testCaseData.email.length === 0 ? getTranslation('generic_error_required_field') : testCaseData.email.length > 0 && !emailRegex.test(testCaseData.email) ? getTranslation('generic_email_regex_fail') : ''}
                                    />
                                </Grid>
                            }
                            {!selectedOrg.consult &&
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="phone"
                                        disabled={id && testCaseData.closed}
                                        variant="outlined"
                                        label={testCaseData.phone ? getTranslation("testcase_phone_placeholder") : ""}
                                        InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                        value={testCaseData.phone || ""}
                                        className={classes.textInput}
                                        placeholder={getTranslation("testcase_phone_placeholder")}
                                        onChange={handleTextChange}
                                        error={!testCaseData.phone || (testCaseData.phone && testCaseData.phone === 0)}
                                        helperText={!testCaseData.phone || testCaseData.phone === 0 ? getTranslation('generic_error_required_field') : ''}
                                    />
                                </Grid>}
                            <Grid item xs={12}>
                                <TextField
                                    name="location"
                                    disabled={(id && testCaseData.closed) || selectedOrg.mro}
                                    variant="outlined"
                                    label={testCaseData.location ? getTranslation("testcase_primary_sampling_place_placeholder") : ""}
                                    InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                    value={testCaseData.location || ""}
                                    className={classes.textInput}
                                    placeholder={getTranslation("testcase_primary_sampling_place_placeholder")}
                                    onChange={handleTextChange}
                                    error={!testCaseData.location || (testCaseData.location && testCaseData.location === 0)}
                                    helperText={!testCaseData.location || testCaseData.location === 0 ? getTranslation('generic_error_required_field') : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {(!selectedOrg.consult && !selectedOrg.mro)&&
                                    <>
                                        {testCaseData.tester_ids &&
                                            <div
                                                className={classes.selectLabel}>{testCaseData.tester_ids.length > 0 ? getTranslation("testcase_testers_placeholder") : ""}
                                            </div>
                                        }
                                        <Select
                                            displayEmpty
                                            disabled={(id && testCaseData.closed) || selectedOrg.consult}
                                            className={(testCaseData.tester_ids && testCaseData.tester_ids.length > 0) ? classes.fullWidth : clsx(classes.emptySelect, classes.fullWidth)}
                                            variant='outlined'
                                            value={testCaseData.tester_ids || []}
                                            multiple
                                            name="tester_ids"
                                            onChange={handleSelectChange}
                                            renderValue={selected => {
                                                let renderV = [];
                                                selected.forEach(s => {
                                                    let tempHold = usersList.find(u => u.sys_id === s);
                                                    if (tempHold) {
                                                        renderV.push(tempHold.name);
                                                    }
                                                })
                                                // return renderV.join(", ")
                                                return (
                                                    <>
                                                        {(testCaseData && testCaseData.tester_ids && selected.length > 0) ? renderV.join(", ") : getTranslation("testcase_testers_placeholder")}
                                                    </>
                                                )
                                            }}
                                            error={!testCaseData.tester_ids || testCaseData.tester_ids.length === 0}
                                        >
                                            {usersList.map(user =>
                                                <MenuItem key={user.user_id} value={user.sys_id}>
                                                    <Checkbox
                                                        checked={testCaseData.tester_ids ? testCaseData.tester_ids.findIndex(nu => nu === user.sys_id) > -1 : false}
                                                    />
                                                    <div style={{float: "left"}}>
                                                        {user.name}
                                                    </div>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </>
                                }
                                {(selectedOrg.consult || selectedOrg.mro) &&
                                    <TextField
                                        name="tester_ids"
                                        disabled={true}
                                        variant="outlined"
                                        label={getTranslation("testcase_testers_placeholder")}
                                        InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                        value={testCaseTesters ? testCaseTesters.map(tester => tester.name).join(', ') : ''}
                                        className={classes.textInput}
                                        placeholder={getTranslation("testcase_testers_placeholder")}
                                        onChange={handleTextChange}
                                        error={!testCaseData.location || (testCaseData.location && testCaseData.location === 0)}
                                        helperText={!testCaseData.location || testCaseData.location === 0 ? getTranslation('generic_error_required_field') : ''}
                                    />
                                }
                                {(!testCaseData.tester_ids || testCaseData.tester_ids.length === 0) &&
                                    <FormHelperText
                                        className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <DateTimePicker
                                    ampm={false}
                                    disabled={(id && testCaseData.closed) || selectedOrg.mro}
                                    className={clsx(classes.datePicker, "fixSelectAndDates")}
                                    placeholder={getTranslation("testcase_time_for_sampling_placeholder")}
                                    label={testCaseData.date ? getTranslation("testcase_time_for_sampling_placeholder") : ""}
                                    InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                    format="YYYY-MM-DD HH:mm"
                                    autoOk
                                    InputAdornmentProps={{position: "start"}}
                                    // disabled={loading}
                                    value={testCaseData.date || null}
                                    onChange={e => handleDateChange(e, "date")}
                                    error={!testCaseData.date || (testCaseData.date && testCaseData.date.length === 0)}
                                    helperText={!testCaseData.date || testCaseData.date.length === 0 ? getTranslation('generic_error_required_field') : ''}
                                />
                            </Grid>
                            {/*Test reason*/}
                            {(testReasons && testReasons.length > 0) &&
                                <Grid item xs={12} sm={6}>
                                    <div
                                        className={classes.selectLabel}>{getTranslation("testcase_create_select_test_reason_label")}
                                    </div>
                                    <Select
                                        fullWidth
                                        className={!selectedTestReason && classes.emptySelect}
                                        displayEmpty
                                        disabled={testCaseData.closed || selectedOrg.consult || selectedOrg.mro}
                                        variant='outlined'
                                        value={selectedTestReason || ""}
                                        onChange={e => {
                                            setSelectedTestReason(e.target.value.sys_id);
                                            setTestCaseData({...testCaseData, ["test_reason"]: e.target.value.sys_id});
                                        }}
                                        renderValue={selected => {
                                            if (!selectedTestReason) {
                                                return (
                                                    <>
                                                        {selected ? testReasons.find(item => item.sys_id === selectedTestReason).name : getTranslation("testcase_create_select_test_reason_placeholder")}
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        {testReasons.find(item => item.sys_id === selectedTestReason).name}
                                                    </>
                                                )
                                            }
                                        }}
                                        error={!selectedTestReason}
                                    >
                                        {testReasons.map(item => (
                                            <MenuItem key={item.sys_id} value={item}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {!selectedTestReason &&
                                        <FormHelperText
                                            className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
                                    }
                                </Grid>}
                        </Grid>
                        {/*Drug tests*/}
                        <Grid container spacing={2} className={classes.marginBottom}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"><b>{getTranslation("testcase_create_choose_drug_test_label")}</b></Typography>
                            </Grid>
                            {testMethods &&
                                <Grid item xs={12} sm={6}>
                                    <div
                                        className={classes.selectLabel}>{selectedTestMethod ? getTranslation("testcase_create_select_sample_type_placeholder") : ""}</div>
                                    <Select
                                        displayEmpty
                                        disabled={(id && testCaseData.closed) || selectedOrg.mro}
                                        className={!selectedTestMethod ? clsx(classes.emptySelect, classes.fullWidth) : classes.fullWidth}
                                        variant='outlined'
                                        value={selectedTestMethod ? selectedTestMethod.name : ""}
                                        onChange={e => {
                                            setSelectedTestMethod(e.target.value);
                                            setTestCaseData({...testCaseData, ["test_ids"]: []});
                                        }}
                                        renderValue={selected => {
                                            if (!selectedTestMethod) {
                                                return (
                                                    <>
                                                        {selected ? selectedTestMethod.name : getTranslation("testcase_create_select_sample_type_placeholder")}
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        {selectedTestMethod.name}
                                                    </>
                                                )
                                            }
                                        }}
                                        error={!selectedTestMethod}
                                    >
                                        {testMethods.map(test => {
                                            if (test.active === "1" && test.sys_id !== "0") {
                                                return (
                                                    <MenuItem key={test.name} value={test}>
                                                        {test.name}
                                                    </MenuItem>)
                                            }
                                        })}
                                    </Select>
                                    {!selectedTestMethod &&
                                        <FormHelperText
                                            className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
                                    }
                                </Grid>
                            }
                            {products && Object.keys(products).length > 0 && selectedTestMethod &&
                                <Grid item xs={12} sm={6}>
                                    {testCaseData.test_ids &&
                                        <div
                                            className={clsx({
                                                [classes.selectLabel]: true,
                                                [classes.testsWrap]: testCaseData.test_ids.length === 0
                                            })}>{testCaseData.test_ids.length > 0 ? getTranslation("testcase_tests_placeholder") : ""}</div>
                                    }
                                    <Select
                                        displayEmpty
                                        disabled={(id && testCaseData.closed) || selectedOrg.mro}
                                        multiple
                                        name="test_ids"
                                        value={testCaseData.test_ids || []}
                                        onChange={handleSelectChange}
                                        className={(!testCaseData.test_ids || testCaseData.test_ids.length === 0) ? clsx(classes.emptySelect, classes.fullWidth) : classes.fullWidth}
                                        variant='outlined'
                                        renderValue={(selected) => {
                                            let renderV = [];
                                            if (!testCaseData.test_ids || testCaseData.test_ids.length === 0) {
                                                selected.forEach((s) => {
                                                    let tempHold = products[selectedTestMethod.sys_id].find((t) => t.sys_id === s);
                                                    if (tempHold) {
                                                        renderV.push(tempHold.name);
                                                    }
                                                });
                                            } else {
                                                testCaseData.test_ids.forEach(s => {
                                                    let tempHold = products[selectedTestMethod.sys_id].find(t => t.sys_id === s);
                                                    if (tempHold) {
                                                        renderV.push(tempHold.name);
                                                    }
                                                })
                                            }
                                            return (
                                                <>{(testCaseData && testCaseData.test_ids && selected.length > 0) ? renderV.join(", ") : getTranslation("testcase_tests_placeholder")}</>
                                            );
                                        }}
                                        error={!testCaseData.test_ids || testCaseData.test_ids.length === 0}
                                    >
                                        {products && selectedTestMethod && Object.keys(products).length > 0 && products.constructor === Object &&
                                            products[selectedTestMethod.sys_id].map(product => (
                                                <MenuItem key={product.sys_id} value={product.sys_id}>
                                                    <Checkbox
                                                        checked={
                                                            testCaseData.test_ids
                                                                ? testCaseData.test_ids.findIndex((nu) => nu === product.sys_id) > -1
                                                                : false
                                                        }
                                                    />
                                                    <div style={{float: "left"}}>{product.name}</div>
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {(!testCaseData.test_ids || testCaseData.test_ids.length === 0) &&
                                        <FormHelperText
                                            className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
                                    }
                                </Grid>
                            }
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                textAlign: 'center'
                            }}>
                                <div
                                    style={{marginRight: 12}}>{getTranslation('testcase_create_select_mro_placeholder')}
                                </div>
                                <Help style={{cursor: 'pointer'}} onClick={() => setMroDialogOpen(true)}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Select
                                    displayEmpty
                                    disabled={(id && testCaseData.closed) || selectedOrg.consult || selectedOrg.mro}
                                    name="mro"
                                    value={selectedMRO ? getMRO() : ""}
                                    onChange={e => {
                                        setTestCaseData({...testCaseData, [e.target.name]: e.target.value.sys_id});
                                        setSelectedMRO(e.target.value.sys_id);
                                    }}
                                    className={!selectedMRO ? clsx(classes.emptySelect, classes.fullWidth) : classes.fullWidth}
                                    variant='outlined'
                                    renderValue={selected => {
                                        if (!selectedMRO) {
                                            if (selected) {
                                                let mro = mros.find(item => item.sys_id === selectedMRO).name;

                                                if (mro.sys_id === "0") {
                                                    return <>{getTranslation(mro.name)}</>
                                                } else {
                                                    return <>mro.name</>
                                                }
                                            } else {
                                                return <>{getTranslation("testcase_create_select_mro_placeholder")}</>
                                            }
                                        } else {
                                            return (
                                                <>
                                                    {getMRO()}
                                                </>
                                            )
                                        }
                                    }}
                                    error={!selectedMRO}
                                >
                                    {mros && mros.length > 0 && mros.map(item => (
                                        <MenuItem key={item.sys_id} value={item}>
                                            {item.sys_id === "0" ? getTranslation(item.name) : item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!selectedMRO &&
                                    <FormHelperText
                                        className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
                                }
                            </Grid>
                        </Grid>
                        {/*File Upload*/}
                        {/*<Grid container spacing={2} className={classes.marginBottom}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"><b>{getTranslation("testcase_create_file_upload_label")}</b></Typography>
              </Grid>
              <Grid item xs={12} className={id && testCaseData.closed ? classes.disabledDropZone : ""}>
                <DropzoneArea
                  // dropzoneClass={!file ? classes.dropzoneError : ""}
                  disabled={loading || (id && testCaseData.closed) || selectedOrg.consult}
                  onChange={handleFiles}
                  acceptedFiles={[".xlsx"]}
                  showAlerts={['error']}
                  showPreviewsInDropzone={false}
                  maxFileSize={5000000}
                  filesLimit={1}
                  dropzoneText={file ? file.name : getTranslation("testcase_create_drag_file_upload")}
                />
                {file &&
                <div className={classes.fileInfoWrap}>
                  <Typography
                    variant="body2"
                    className={fileColumnsCheck ? classes.fileOkText : classes.fileNotOkText}
                  >
                    {`Columns data format: ${fileColumnsCheck ? "OK" : "Error. Correct type (text, number/text, number/text, text)"}`}
                  </Typography>
                  <Typography variant="body2">{`Number of rows: ${fileRows}`}</Typography>
                </div>
                }
              </Grid>
            </Grid>*/}
                        {/*Number of people to test*/}
                        {/* {(fileColumnsCheck || (id && testCaseData.closed)) &&
            <Grid container className={classes.marginBottom}>
              <Grid item xs={4}>
                <div
                  className={clsx({
                    [classes.selectLabel]: true,
                  })}>
                  {getTranslation("testcase_create_nr_to_test_label")}
                </div>
                <TextField
                  disabled={id && testCaseData.closed}
                  type="number"
                  name="quantity"
                  variant="outlined"
                  value={(id && testCaseData.closed) ? testCaseData.tests_total : (testCaseData.quantity || "")}
                  className={classes.textInput}
                  placeholder={getTranslation("testcase_create_nr_to_test_label")}
                  onChange={handleTextChange}
                  error={!(id && testCaseData.closed) ? !testCaseData.quantity : false}
                  helperText={!testCaseData.quantity ? getTranslation('generic_error_required_field') : ''}
                />
              </Grid>
            </Grid>
            }*/}
                        {/*Notes*/}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"><b>{getTranslation("testcase_create_notes_label")}</b></Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.textAlignRight}>
                                <TextField
                                    name="note"
                                    disabled={(id && testCaseData.closed) || selectedOrg.mro}
                                    variant="outlined"
                                    label={testCaseData.note ? getTranslation("testcase_create_notes_label") : ""}
                                    InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                                    value={testCaseData.note || ""}
                                    rows={5}
                                    multiline
                                    className={classes.textInput}
                                    placeholder={getTranslation("testcase_create_notes_label")}
                                    onChange={handleTextChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} className={classes.textAlignCenter}>
                                <div style={{display: 'flex', justifyContent: "space-around"}}>
                                    {testCaseData.closed &&
                                        <>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="large"
                                                className={classes.saveButton}
                                                onClick={() => openPDF()}
                                            >
                                                <b>{getTranslation("testcase_details_print_forms_button")}</b>
                                            </Button>
                                            {Number(testCaseData.status_id) === 1 &&
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.saveButton}
                                                    onClick={() => setTestCaseCompete(id)}
                                                >
                                                    {getTranslation("testcase_send_digitally_button")}
                                                </Button>}
                                        </>
                                    }
                                    {!testCaseData.closed &&
                                        <>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    disabled={!formValidator() || (id && testCaseData.closed)}
                                                    className={classes.saveButton}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    <b>{getTranslation("testcase_create_save_button")}</b>
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                </div>
                            </Grid>
                            {testCaseData.closed && (!selectedOrg.mro || !selectedOrg.consult) &&
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.saveButton}
                                        style={{width: '100%'}}
                                        onClick={() => handleOpen(id)}
                                    >
                                        <b>{getTranslation("testcase_open_button")}</b>
                                    </Button>
                                </Grid>
                            }
                            {(testCaseData.closed && Number(testCaseData.status_id) > 1) &&
                                <Grid item xs={12} className={classes.sentTextWrap}>
                                    <Typography variant="subtitle1" className={classes.sentText}>
                                        <b>
                                            {getTranslation("testcase_sent_to_lab_status")} : &nbsp;
                                        </b>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {getTranslation("testcase_sent_text").replace("##date##", moment.unix(testCaseData.closed).format("YYYY-MM-DD"))}
                                    </Typography>
                                </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                dialogData={{}}
                open={!!mroDialogOpen}
                maxWidth={'sm'}
                setOpen={setMroDialogOpen}
                /* title={linkedTestsDialog.title}*/
                /*message={linkedTestsDialog.info}*/
                html={getTranslation('testcase_mro_dialog')}
                cancelLabel={getTranslation('generic_button_ok')}
            />
            <Dialog
                dialogData={{}}
                open={!!deleteTestCaseDialog}
                maxWidth={'sm'}
                setOpen={setDeleteTestCaseDialog}
                title={getTranslation('testcase_delete_dialog_title')}
                /*message={linkedTestsDialog.info}*/
                html={getTranslation('testcase_delete_dialog')}
                cancelLabel={getTranslation('generic_button_cancel')}
                submitLabel={getTranslation('generic_button_confirm')}
                callback={deleteTestCase}
            />
        </>
    );
}

const useStyles = makeStyles(theme => ({
    backButton: {
        color: colorConfig.lightBlack
    },
    textInput: {
        padding: 0,
    },
    datePicker: {
        padding: 0,
        "& .MuiInputBase-root": {
            paddingLeft: 12
        }
    },
    saveButton: {
        padding: "8px 70px",
        textTransform: "uppercase",
        marginTop: 24
    },
    emptySelect: {
        color: "#b8b8b8"
    },
    marginBottom: {
        marginBottom: 20
    },
    backIcon: {
        fill: colorConfig.lightBlack
    },
    fullWidth: {
        width: "100%"
    },
    textAlignCenter: {
        textAlign: "center"
    },
    textAlignRight: {
        textAlign: "right"
    },
    notesTime: {
        color: colorConfig.lightBlack
    },
    selectHelperText: {
        color: "#ff1744",
        marginLeft: 14
    },
    dropzoneError: {
        border: "1px solid #ff1744"
    },
    inputRoot: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #ff1744"
        }
    },
    inputLabelRoot: {
        color: "#b8b8b8",
    },
    textFieldInputLabel: {
        color: `${colorConfig.textGray} !important`,
        left: '0px !important',
        top: '0px !important',
    },
    selectLabel: {
        color: `${colorConfig.textGray} !important`,
        fontSize: 12,
        marginBottom: 4
    },
    testsWrap: {
        paddingTop: 15
    },
    fileInfoWrap: {
        marginTop: 10
    },
    fileOkText: {
        color: colorConfig.green
    },
    fileNotOkText: {
        color: "#ff1744"
    },
    helpIconWrapper: {
        paddingLeft: "0px !important",
    },
    helpIcon: {
        cursor: "pointer"
    },
    disabledDropZone: {
        pointerEvents: "none"
    },
    sentTextWrap: {
        marginTop: 20,
        display: "flex"
    },
    sentText: {
        fontSize: 18
    }
}));

const autocomplete = makeStyles(() => ({
    inputRoot: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #d9d9d9"
        }
    }
}));

export default CreateTestOccasion;
