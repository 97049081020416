import React, { useContext } from 'react';
import { Grid, Paper, Typography, List, ListItem, ListItemText, Divider, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../styles/Theme-settings';
import { AuthContext } from '../../context/authContext';
import { OrgContext } from '../../context/orgContext';
import { LocalizationContext } from '../../context/localizationContext';
const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex'
  },
  grid: {
    maxWidth: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  paper: {
    padding: 20,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    width: '80%',
  },
  orgList: {
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      background: colors.halfPrimary
    }
  }
}));
const OrganizationSelector = (props) => {
  const classes = useStyles();
  const { perm, loading } = useContext(AuthContext);
  const { changeOrg } = useContext(OrgContext);
  const { getTranslation } = useContext(LocalizationContext);
  document.title = getTranslation('org_selector_browser_title') + ' | Prodiagnostics';
  if (perm && perm.options.length === 1) {
    return null;
  } else {
    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={10}>
          {loading && <LinearProgress />}
          <Paper className={classes.paper}>
            <Grid item xs={1} className={classes.grid}>
              <Typography variant="h5" component="h5" gutterBottom>{getTranslation('organization_selector_page_title')}</Typography>
            </Grid>
            <Grid item xs={11} className={classes.grid}>
              <Typography variant="h6" component="h6" gutterBottom>{getTranslation('organization_selector_chose_org_label')}</Typography>
              <List className={classes.orgList}>
                {perm && perm.options.map(org => {
                  return <div key={org.id}>
                    <ListItem disabled={!org.current_login_method_valid} className={classes.listItem} alignItems="flex-start" onClick={() => {
                      if (org.current_login_method_valid) {
                        sessionStorage.removeItem('aLFilter')
                        changeOrg(org.id);
                        props.history.push(`/`)
                      }
                    }}>
                      <ListItemText
                        primary={org.name}
                      />
                    </ListItem>
                    <Divider component="li" /></div>
                }
                )}
              </List>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}
export default OrganizationSelector;