import React, { useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem, Badge, ListItemText, ListItemSecondaryAction, ListItem, Button } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { OrgContext } from '../context/orgContext';
import { AuthContext } from '../context/authContext';
import APICall from '../functional/APIRequests';
import { LocalizationContext } from '../context/localizationContext';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import colors from '../styles/Theme-settings';
const useStyles = makeStyles(theme => ({
  notificationTitle: {
    color: colors.primary,
    fontWeight: 'bold !important',
    padding: 13,
    boxShadow: '0px 1px 6px #00000029',
    margin: 0,
    fontSize: 16,
    outline: 'none'
  },
  notificationContainer: {
    borderBottom: '1px solid #00000029',
  },
  notificationItem: {
    padding: 5,
    margin: 0,
    fontSize: 14
  },
  notificationEmpty: {
    padding: 13,
    margin: 0,
    fontSize: 14,
    borderBottom: '1px solid #00000029',
  },
  notificationButtonHolder: {
    display: 'flex',
    justifyContent: 'center',
    padding: 15
  }
}));
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
  list: {
    padding: 0
  },
})(props => (
  <Menu
    elevation={2}
    // className={menu}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
}))(MenuItem);

const NotificationMenuList = () => {
  const classes = useStyles();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrg } = useContext(OrgContext);
  const { setLoading, } = useContext(AuthContext);
  const { getTranslation, } = useContext(LocalizationContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [data, setData] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const fetchData = () => {
    if (!!selectedOrg && !!selectedOrg.id) {
      APICall({
        setLoading,
        hasRefresh: true,
        enqueueSnackbar,
        url: `notification/${selectedOrg.id}/count`,
        verb: 'get',
        server: 'api',
      }).then((res) => {
        if (res)
          setCount(res.data)
        APICall({
          setLoading,
          hasRefresh: true,
          enqueueSnackbar,
          url: `notification/${selectedOrg.id}?limit=10`,
          verb: 'get',
          server: 'api',
        }).then((res) => {
          if (res)
            setData(res.data)
        });
      });

    }
  }
  const setNotificationAsViewed = (all= false, id) => {
    setLoading(true)
    let form = new FormData();
    if(!all) {
      form.append("notification_ids[0]", id);
    } else {
      for(let i in data) {
        form.append(`notification_ids[${i}]`, data[i].sys_id)
      }
    }

    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `notification/${selectedOrg.id}/viewed`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    }).then((res) => {
      setLoading(false);
      fetchData()
    })
  }
  useEffect(() => {
    if (data) {
      setTimeout(() => { fetchData() }, 300000)
    } else {
      fetchData();
    }
    // eslint-disable-next-line
  }, [data])
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [selectedOrg])
  return (
    <div>
      <IconButton color="inherit" onClick={handleClick} >
        <Badge badgeContent={count} color="secondary" overlap="rectangular">
          <NotificationsIcon style={{ width: 23, height: 23 }} />
        </Badge>
      </IconButton>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem className={classes.notificationTitle}>
          <p style={{ margin: 0 }}>
            {getTranslation('notifications_inbox_title')}
          </p>
        </ListItem>
        {data && data.map(d =>
          <StyledMenuItem key={d.sys_id} className={classes.notificationContainer} >
            <ListItemText
              primary={d.subject.length > 30 ? d.subject.slice(0, 27) + '...' : d.subject}
              secondary={`${d.from_organisation_customer_number}, ${d.from_organisation_name} - ${d.from_user_name}`}
              className={classes.notificationItem} />
            <ListItemSecondaryAction onClick={() => setNotificationAsViewed(false, d.sys_id)}>
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </StyledMenuItem>
        )}

        {(!data || data.length === 0) &&
        <ListItemText style={{ textAlign: 'center' }} primary={getTranslation('notifications_inbox_no_data')} className={classes.notificationEmpty} />
        }
        {/* {data && data.length < count &&
          <StyledMenuItem onClick={() => history.push('/notifications')}>
            <ListItemText primary={getTranslation('notifications_see_more')} />
          </StyledMenuItem>
        } */}
        <ListItem onClick={() => {
          handleClose();
          history.push('/notifications')
        }} className={classes.notificationButtonHolder}>
          <Button variant="contained" color="primary">
            {getTranslation('notifications_inbox_go_to_inbox')}
          </Button>
          {/* <ListItemText primary={getTranslation('notifications_inbox_go_to_inbox')} /> */}
        </ListItem>
        <ListItem style={{paddingTop: 0}} onClick={() => setNotificationAsViewed(true)}>
          <Button
            variant="text"
            color="primary"
            style={{paddingTop: 0}}
          >
            {getTranslation('notifications_inbox_set_all_as_read')}
          </Button>
        </ListItem>
      </StyledMenu>

    </div>
  );
}
export default NotificationMenuList;
