import React, {useContext, useEffect} from "react";
import {Grid, Checkbox, Typography, Divider, Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import colorConfig from "../../styles/Theme-settings";
import {LocalizationContext} from "../../context/localizationContext";

const Item = props => {
  const classes = useStyles();
  const {getTranslation} = useContext(LocalizationContext);
  const {data, onRemove, checkReferral} = props;

  const getMethodText = ref => {
    let method = ref.referral.test_method_id;
    let quantity = ref.referral.analysis;

    return quantity ? `${method} (${quantity})` : method || ''
  }

  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid item xs={1}>
          <Checkbox
            checked={data.checked}
            onChange={() => checkReferral(data.referral.sys_id)}
            className={classes.checkboxIcon}
          />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle2" className={classes.referralIdText}>
            {`${getTranslation('slider_item_referral_text')} #: ${data.referral.referral_id}`}
          </Typography>
          <Typography variant="caption" className={classes.referralInfoText}>
            {`${getMethodText(data)}, ${getTranslation('slider_item_test_date')}: ${data.referral.test_date}`}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Remove from list">
            <CloseIcon className={classes.closeIcon} onClick={() => onRemove(data.referral.sys_id)}/>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider/>
    </>
  );
};

const useStyles = makeStyles(() => ({
  referralInfoText: {
    color: colorConfig.textGray,
    fontWeight: 'bold'
  },
  referralIdText: {
    color: colorConfig.secondary,
    fontWeight: 500
  },
  wrapper: {
    marginBottom: 8,
    marginTop: 8
  },
  checkboxIcon: {
    marginTop: "-7px",
    paddingLeft: 5
  },
  closeIcon: {
    cursor: "pointer"
  }
}))

export default Item;