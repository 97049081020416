import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Grid,
  LinearProgress,
  Tab,
  Toolbar,
  Tabs,
  CssBaseline,
  AppBar,
  Typography,
  Hidden,
  Drawer,
  ListItem, ListItemText, List, Paper
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {AuthContext} from '../context/authContext';
import {LocalizationContext} from '../context/localizationContext';
import {
  VerifiedUserOutlined as VerifiedUser,
  NoteAddOutlined as NoteAdd,
  HeadsetOutlined as Headset,
} from '@material-ui/icons';
import Responsive from "./responsive";

const drawerWidth = 240;

const DesktopAuth = (props) => {
  const classes = useStyles();
  const {loading} = useContext(AuthContext);
  const {getTranslation} = useContext(LocalizationContext);

  return (
    <>
      <CssBaseline/>
      <AppBar
        position="fixed"
        className={classes.appBar}
        elevation={3}
      >
        <Toolbar>
          <img alt="Logo" src="/images/logo.png" className={classes.logo} onClick={() => props.history.push('/')}/>

          <Hidden smDown>
            <div style={{width: '100%', justifyContent: 'center', display: 'flex'}}>
              <Tabs className={classes.menuBar} value={false}>
                <Tab
                  disableRipple
                  label={getTranslation('layout_auth_main_page_home')}
                  index={0}
                  onClick={() => window.open('https://www.prodia.se/', '_blank')}
                />
                <Tab
                  disableRipple
                  label={getTranslation('layout_auth_main_page_shop')}
                  index={2}
                  onClick={() => window.open('https://www.prodia.se/drogtester/multitester/', '_blank')}
                />
                <Tab
                  disableRipple
                  label={getTranslation('layout_auth_main_page_contact')}
                  index={3}
                  onClick={() => window.open('https://www.prodia.se/kontakt', '_blank')}
                />
              </Tabs>
            </div>
          </Hidden>

        </Toolbar>
        {loading && <LinearProgress/>}
      </AppBar>
      <main>
        <Grid container className={classes.container}>
          <Hidden xsDown>
            <Grid item xs={0} sm={6} className={classes.imgHolder}>
              <div>
                <Typography variant="h5" gutterBottom style={{marginBottom: 30, fontWeight: 'bold'}}>
                  {getTranslation('layout_auth_over_image_title')}
                </Typography>
                <Typography variant="body1" style={{marginBottom: 30, display: 'flex', alignItems: 'center'}}>
                  <VerifiedUser style={{fill: 'black', marginRight: 10, height: 27, width: 27}}/>
                  {getTranslation('layout_auth_over_image_first')}
                </Typography>
                <Typography variant="body1" style={{marginBottom: 30, display: 'flex', alignItems: 'center'}}>
                  <NoteAdd style={{fill: 'black', marginRight: 10, height: 27, width: 27}}/>
                  {getTranslation('layout_auth_over_image_second')}
                </Typography>
                <Typography variant="body1" style={{marginBottom: 30, display: 'flex', alignItems: 'center'}}>
                  <Headset style={{fill: 'black', marginRight: 10, height: 27, width: 27}}/>
                  {getTranslation('layout_auth_over_image_third')}
                </Typography>
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} className={classes.gridRight}>
            {props.children}
          </Grid>
        </Grid>
      </main>
    </>
  )
}

const MobileAuth = (props) => {
  const classes = useStyles();
  const {loading} = useContext(AuthContext);
  const {getTranslation} = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerMenuBox}>
          <List>
            <ListItem button>
              <ListItemText
                primary={getTranslation('layout_auth_main_page_home')}
                onClick={() => window.open('https://www.prodia.se/', '_blank')}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary={getTranslation('layout_auth_main_page_shop')}
                onClick={() => window.open('https://www.prodia.se/drogtester/multitester/', '_blank')}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary={getTranslation('layout_auth_main_page_contact')}
                onClick={() => window.open('https://www.prodia.se/kontakt', '_blank')}
              />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <Paper elevation={1} className={classes.mobileToolbar}>
        <img src="/images/small_logo.png" className={classes.mobileLogo} alt="logo" onClick={() => props.history.push('/')}/>
        <MenuIcon className={classes.menuIcon} onClick={() => setOpen(true)}/>
      </Paper>
      <main>
        <Grid container className={classes.mobileContainer}>
          <Grid item xs={12} className={classes.mobileMain}>
            {props.children}
          </Grid>
        </Grid>
      </main>
    </>
  )
}

const Auth = (props) => {
  return <Responsive desktop={DesktopAuth} tablet={MobileAuth} mobile={MobileAuth} {...props}/>
}
const useStyles = makeStyles(theme => ({
  logo: {
    cursor: 'pointer',
    maxHeight: 20
  },
  mobileLogo: {
    cursor: "pointer",
    maxHeight: 50
  },
  container: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },
  imgHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/background2.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
  },
  gridRight: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  mobileToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  menuIcon: {
    height: 40,
    width: 40,
    cursor: "pointer"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerMenuBox: {
    paddingTop: 50,
  },
  mobileMain: {
    display: "flex",
    alignItems: "flex-start",
    paddingTop: 100
  },
  mobileContainer: {
    height: "100%"
  }
}));
export default Auth;
