import React, {useContext, useEffect} from "react";
import {Button, Grid, Typography, Paper, CircularProgress} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../context/authContext';
import { LocalizationContext } from '../../context/localizationContext';

import colors from '../../styles/Theme-settings'

const useStyles = makeStyles(({
    inputGrid: {
      display: 'flex'
    },
    buttonGrid: {
      display: 'flex',
      justifyContent: 'center'
    },
    textInput: {
      width: '100%',
      padding: '10px 0px',
    },
    formWidth: {
      width: '100%',
    },
    buttonCancel: {
      borderRadius: 0,
      // borderColor: '#D5D5D5',
      // color: '#D5D5D5'
    },
    errorBox: {
      padding: 20,
      color: colors.primary,
      border: `1px solid ${colors.primary}`,
      textAlign: 'center',
      margin: '20px 0px',
    }
}));

const SithsForm = (props) => {
    const classes = useStyles();
    const { sithsLogin, sithsCode, sithsRedirectUrl, loading } = useContext(AuthContext);
    const { getTranslation } = useContext(LocalizationContext)

    const submitData = (e) => {
        try { e.preventDefault() } catch (err) { };
        sithsLogin();
      }

      useEffect(() => {
        if(!loading && sithsCode && sithsRedirectUrl) {
          window.open(sithsRedirectUrl, "_self")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [loading,sithsRedirectUrl])

    return(
        <>
            {!loading &&   
                <Button disabled={loading} onClick={submitData} type='submit' className={classes.buttons} variant="contained" color="primary">
                    {getTranslation('login_form_button_start_siths')}
                </Button>
            }
            {loading &&
                <Paper elevation={4} style={{ padding: 20, width: '100%' }}>
                    <Typography variant="h6" gutterBottom>
                        {getTranslation('login_form_siths_waiting_title')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {getTranslation('login_form_siths_waiting_msg')}
                    </Typography>
                    <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }} item xs={12}>
                        <CircularProgress />
                    </Grid>
                </Paper>
        }
      </>
    )
}

export default SithsForm