const server = window.location.origin;
let loginServer = "http://localhost:8082";
let apiServer = "http://localhost:8081";

//loginServer = "http://staging.prodiagnostics.cust.commerz.se:8082"
//apiServer = "http://staging.prodiagnostics.cust.commerz.se:8081"

if (server.includes("staging")) {
  loginServer = server.replace("web", "login.web")
  apiServer = server.replace("web", "api.web");
} else if (server.includes("demo")) {
  loginServer = server.replace("web", "login.web");
  apiServer = server.replace("web", "api.web");
} else if (server.includes("portal")) {
  apiServer = server.replace("portal", "api.portal");
  loginServer = server.replace("portal", "login.portal");
}

export {loginServer, apiServer};
