import React, {useContext, useEffect, useState} from "react";
import {Grid, TextField, Typography} from "@material-ui/core";
import {LocalizationContext} from "../../../context/localizationContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCodeSmall from "../../../components/svgs/barCodeSmall";
import {RefContext} from "../../../context/refContext";
import {OrgContext} from "../../../context/orgContext";
import {AuthContext} from "../../../context/authContext";
import APICall from "../../../functional/APIRequests";
import {useSnackbar} from "notistack";
import colors from "../../../styles/Theme-settings";

const ReferalId = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {setLoading, loading} = useContext(AuthContext);
  const {getTranslation} = useContext(LocalizationContext);
  const {
    referalData,
    setReferalData,
    changeStateStep,
    validId,
    setValidId,
  } = useContext(RefContext);
  const {selectedOrg} = useContext(OrgContext);
  const [submitedId, setSubmitedId] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "id") {
      if (!isNaN(e.target.value)) {
        setReferalData({...referalData, [e.target.name]: e.target.value});
        if (e.target.value.length === 10) {
          verifyReferalId(e.target.value);
        }
      }
    }
  };
  const verifyReferalId = (param) => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/referral/isvalid?referral_id=${param}`,
      verb: "get",
      server: "api",
    }).then((res) => {
      if (res.data) {
        setValidId(true);
      } else {
        setValidId(false);

        changeStateStep("validChange", 0, false);
      }
      setSubmitedId(true);
      setLoading(false);
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{fontWeight: "bold"}}
        >
          {getTranslation("referal_form_step_tree_title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          {getTranslation("referal_form_step_tree_subTitle")}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          variant="filled"
          autoFocus
          id="id-number"
          name="id"
          disabled={loading || validId}
          label={getTranslation("referal_form_label_id_number")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BarCodeSmall/>
              </InputAdornment>
            ),
          }}
          inputProps={{maxLength: 10}}
          value={(referalData && referalData.id) || ""}
          onChange={(e) => {
            if (Number.isInteger(Number(e.target.value))) {
              handleChange(e);
            }
          }}
          error={
            referalData && referalData.id ?
              !referalData ||
              (referalData.id && referalData.id.length === 0) ||
              referalData.id.length < 10
              :
              false
          }
          helperText={
            referalData && referalData.id ?
              !referalData || (referalData.id && referalData.id.length === 0)
                ? getTranslation("generic_error_required_field")
                : referalData.id.length < 10
                  ? getTranslation("generic_error_minimun_digits").replace(
                    "##chars##",
                    10
                  )
                  : ""
              :
              ""
          }
        />
      </Grid>

      {submitedId && !validId && (
        <Grid item xs={6}>
          <div
            style={{
              border: `1px solid ${colors.primary}`,
              margin: 10,
              padding: 15,
              minHeight: 56,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getTranslation("referal_form_step_one_error_id")}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default ReferalId;
