import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {debounce} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";
import colorConfig from "../../../styles/Theme-settings";
import {useSnackbar} from "notistack";
import {LocalizationContext} from "../../../context/localizationContext";
import {TestcaseContext} from "../../../context/testcaseContext";
import APICall from "../../../functional/APIRequests";
import {AuthContext} from "../../../context/authContext";
import {OrgContext} from "../../../context/orgContext";
import {PersonelContext} from "../../../context/personelContext";
import Mobile from "./mobile";
import Tablet from "./tablet";
import {cameraConfig} from "../../../functional/barcodeConfig";
import barcode from "../../../components/svgs/barcode";


const PersonalDetails = (props) => {
  const classes = useStyles();

  const mobile = useMediaQuery("(max-width: 767px)");
  const tablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px)");
  const desktop = useMediaQuery("(min-width: 1025px)");

  const {userId, id} = useParams();

  const {getTranslation} = useContext(LocalizationContext);

  const {
    userData,
    setUserData,
    fetchTestMethods,
    testMethods,
    getTestCase,
    fetchProducts,
    setProducts,
    personalDataFormValidator,
    barcodeInUse,
    setBarcodeInUse,
    handleSetUserTested,
    setUserTested,
    testCaseData,
    userInTestCase,
    setUserInTestCase,
    setTestCaseData
  } = useContext(TestcaseContext);

  const [changeTestCheck, setChangeTestCheck] = useState(1);
  const [selectedTestMethod, setSelectedTestMethod] = useState();
  const [testIds, setTestIds] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [barcodeOpen, setBarcodeOpen] = useState(false);

  const {setLoading} = useContext(AuthContext);
  const {getOrganizationById, selectedOrg} = useContext(OrgContext);
  const {enqueueSnackbar} = useSnackbar();
  const {
    rawProducts,
    fetchPersonnel,
    fetchReferral,
    identification_documents
  } = useContext(TestcaseContext);

  const canvasRef = useRef(null);

  const getPhotoDivStyle = (photo, closed, haveBeenTested) => {
    if (!photo || photo === '') {
      return {
        border: "1px solid rgba(0, 0, 0, 0.37)",
        height: 300,
        position: "relative",
        cursor: `${(closed || haveBeenTested === '1') ? 'default' : 'pointer'}`,
        pointerEvents: `${(closed || haveBeenTested === '1') ? 'none' : 'auto'}`,
      }
    } else {
      return {
        height: 300,
        pointerEvents: `${(closed || haveBeenTested === '1') ? 'none' : 'auto'}`,
        position: "relative",
        cursor: "pointer",
        backgroundImage: `url('${photo}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center"
      }
    }
  };

  const getProducts = () => {
    setLoading(true)
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `products?sort=sort_order asc`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      let tempArr = {}
      testMethods.forEach(test => {
        tempArr[test.sys_id] = []
      })
      res.data.forEach(item => tempArr[item.test_method_id].push(item));
      setProducts(tempArr);
      setLoading(false);
    })
  };


  const checkUserInTestList = useCallback(
    debounce(ssn => {
      setLoading(true);
      APICall({
        setLoading,
        enqueueSnackbar,
        url: `customer/${selectedOrg.id}/testcase/${id}/user/in_list?ssn=${ssn}`,
        verb: 'get',
        server: 'api'
      })
        .then(res => {
          if (res.data) {
            enqueueSnackbar(getTranslation('user_already_in_test_list'), {variant: "error"});
            setUserInTestCase(true);
          } else {
            setUserInTestCase(true);
          }
          setLoading(false);
        })
        .catch(err => console.log(err))
    }, 2500), []);

  const checkBarcodeInUse = barcode => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/referral/isvalid?referral_id=${barcode}`,
      verb: 'get',
      server: 'api'
    })
      .then(res => {
        if (res.data === 0) {
          enqueueSnackbar(getTranslation('testcase_barcode_used'), {variant: "error"});
          setBarcodeInUse(true);
        } else {
          setBarcodeInUse(false);
        }
        setLoading(false);
      })
      .catch(() => {
      })
  };

  const fetchUserInfo = () => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/testcase/users/to_test?data[limit]=1&data[offset]=0&data[test_case_id]=${id}&data[test_case_user_id]=${userId}`,
      verb: 'get',
      server: 'api'
    })
      .then(res => {
        if (res.data) {
          setUserData(prevState => ({
            ...prevState,
            have_been_tested: res.data[0].have_been_tested,
            sampler_check: res.data[0].have_been_tested === "1",
            ssn: res.data[0].ssn,
            name: res.data[0].name,
            phone: res.data[0].phone,
            email: res.data[0].email
          }))
          fetchReferral(res.data[0].ssn, id);
        }
      })
      .catch(e => setLoading(false))
  }

  const handleTextChange = e => {
    setUserData({...userData, [e.target.name]: e.target.value})

    if (e.target.name === "ssn" && e.target.value.length > 0)
      checkUserInTestList(e.target.value);
  }

  const handleDateChange = (e, input) => {
    setUserData({...userData, [input]: e});
  };

  const handleCheckboxChange = (e) => {
    try {
      e.preventDefault()
    } catch (err) {
    }
    ;

    if (e === 2) {
      getProducts()
    }
    setChangeTestCheck(e);
  }

  const handleCanvasChange = () => {
    const base64 = canvasRef.current.canvasContainer.childNodes[1].toDataURL();
    setUserData({...userData, ["signature"]: base64})
  };

  const handleSubmit = () => {
    if (personalDataFormValidator()) {
      handleSetUserTested(id)
        .then(() => {
          setUserTested(id, userId)
            .then(() => {
              setLoading(false);
              props.history.push(`/testcase/details/${id}`);
              enqueueSnackbar(getTranslation('generic_submited_success'), {variant: "success"});
            })
            .catch(() => {
              setLoading(false);
            })
        })
        .catch(() => {
          setLoading(false);
        })
    }
  }


  useEffect(() => {
    fetchProducts();
    fetchTestMethods();
    getTestCase(id, "personal");
    fetchPersonnel();
    fetchUserInfo();

    return () => {
      setUserData({});
      setTestCaseData({});
    };
  }, [])

  useEffect(() => {
    if (userData.organisation_id) {
      getOrganizationById({idOrg: userData.organisation_id})
        .then(res => setOrgName(res.name))
    }
  }, [userData.organisation_id])

  return (
    <>
      {desktop &&
      <Tablet
        getTranslation={getTranslation}
        userData={userData}
        setUserData={setUserData}
        handleTextChange={handleTextChange}
        handleDateChange={handleDateChange}
        handleSubmit={handleSubmit}
        userId={userId}
        documents={identification_documents}
        changeTestCheck={changeTestCheck}
        setChangeTestCheck={handleCheckboxChange}
        selectedTestMethod={selectedTestMethod}
        setSelectedTestMethod={setSelectedTestMethod}
        testIds={testIds}
        setTestIds={setTestIds}
        testMethods={testMethods}
        orgName={orgName}
        barcodeOpen={barcodeOpen}
        setBarcodeOpen={setBarcodeOpen}
        canvasRef={canvasRef}
        rawProducts={rawProducts}
        handleCanvasChange={handleCanvasChange}
        checkBarcodeInUse={checkBarcodeInUse}
        barcodeInUse={barcodeInUse}
        testCaseData={testCaseData}
        classes={classes}
        getPhotoDivStyle={getPhotoDivStyle}

        {...props}
      />
      }
      {tablet &&
      <Tablet
        getTranslation={getTranslation}
        userData={userData}
        setUserData={setUserData}
        handleTextChange={handleTextChange}
        handleDateChange={handleDateChange}
        handleSubmit={handleSubmit}
        userId={userId}
        documents={identification_documents}
        changeTestCheck={changeTestCheck}
        setChangeTestCheck={handleCheckboxChange}
        selectedTestMethod={selectedTestMethod}
        setSelectedTestMethod={setSelectedTestMethod}
        testIds={testIds}
        setTestIds={setTestIds}
        testMethods={testMethods}
        orgName={orgName}
        barcodeOpen={barcodeOpen}
        setBarcodeOpen={setBarcodeOpen}
        canvasRef={canvasRef}
        rawProducts={rawProducts}
        handleCanvasChange={handleCanvasChange}
        checkBarcodeInUse={checkBarcodeInUse}
        barcodeInUse={barcodeInUse}
        testCaseData={testCaseData}
        classes={classes}
        getPhotoDivStyle={getPhotoDivStyle}
        userInTestCase={userInTestCase}
        {...props}
      />
      }
      {mobile &&
      <Mobile
        getTranslation={getTranslation}
        userData={userData}
        setUserData={setUserData}
        handleTextChange={handleTextChange}
        handleDateChange={handleDateChange}
        handleSubmit={handleSubmit}
        userId={userId}
        documents={identification_documents}
        changeTestCheck={changeTestCheck}
        setChangeTestCheck={handleCheckboxChange}
        selectedTestMethod={selectedTestMethod}
        setSelectedTestMethod={setSelectedTestMethod}
        testIds={testIds}
        setTestIds={setTestIds}
        testMethods={testMethods}
        orgName={orgName}
        barcodeOpen={barcodeOpen}
        setBarcodeOpen={setBarcodeOpen}
        canvasRef={canvasRef}
        rawProducts={rawProducts}
        handleCanvasChange={handleCanvasChange}
        checkBarcodeInUse={checkBarcodeInUse}
        barcodeInUse={barcodeInUse}
        testCaseData={testCaseData}
        classes={classes}
        getPhotoDivStyle={getPhotoDivStyle}
        userInTestCase={userInTestCase}
        {...props}
      />
      }
    </>
  );
}

const useStyles = makeStyles(theme => ({
  backButton: {
    color: colorConfig.lightBlack
  },
  backIcon: {
    fill: colorConfig.lightBlack
  },
  marginBottom: {
    marginBottom: 20
  },
  cameraIcon: {
    fill: colorConfig.green,
    height: 70,
    width: 70,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  cameraIconMobile: {
    fill: colorConfig.green,
    height: 50,
    width: 50,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -30%)"
  },
  photoLabel: {
    color: "rgba(0, 0, 0, 0.23)",
    paddingLeft: 10,
    paddingTop: 10
  },
  photoLabelMobile: {
    color: "rgba(0, 0, 0, 0.23)",
    paddingLeft: 5,
    paddingTop: 0
  },
  paddingLeft: {
    paddingLeft: "0px !important"
  },
  paddingRight: {
    paddingRight: "24px !important"
  },
  datePicker: {
    "& .MuiInputBase-root": {
      paddingLeft: 12
    }
  },
  notchedOutline: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: "none"
  },
  emptySelect: {
    color: "#b8b8b8"
  },
  selectHelperText: {
    color: "#ff1744",
    marginLeft: 14
  },
  signatureReset: {
    position: "absolute",
    top: 3,
    right: 11,
    cursor: "pointer",
    zIndex: 999,
    color: colorConfig.textGray,
  },
  textFieldInputLabel: {
    color: `${colorConfig.textGray} !important`,
  },
  selectLabel: {
    color: `${colorConfig.textGray} !important`,
    fontSize: 12,
    marginBottom: 4
  },
  imageLabel: {
    color: `${colorConfig.textGray} !important`,
    fontSize: 12,
    marginBottom: 4,
    textAlign: "center"
  },
  certifiedByWrap: {
    marginTop: 18
  },
  testsWrap: {
    marginTop: 18
  },
  signatureImgWrapper: {
    width: "100%",
    height: 250,
  },
  barcodeInputWrap: {
    display: "flex"
  },
  barcodeTextInput: {
    width: "96%",
    paddingRight: 0
  },
  startBarcodeText: {
    textDecoration: "underline",
    float: "right",
    fontSize: 14,
    cursor: "pointer",
  },
  canvas: {
    width: "100% !important"
  }
}));

export default PersonalDetails;
