import React, {useContext} from "react";
import {
    Grid,
    Paper,
    LinearProgress,
    Typography,
    Button,
} from "@material-ui/core";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import {ErrorOutline, CheckCircleOutline} from '@material-ui/icons';
import clsx from "clsx";
import {AuthContext} from "../../../context/authContext";
import {LocalizationContext} from "../../../context/localizationContext";
import {RefContext} from "../../../context/refContext";
import colors from "../../../styles/Theme-settings";
import FormIdStep from "../steps/formId";
import FormDataStep from "../steps/formData";
import FormMethodsStep from "../steps/formMethods";
import ReviewStep from "../steps/reviewV3";
import FormTestsStep from "../steps/formTests";
import {OrgContext} from "../../../context/orgContext";

const Desktop = props => {
    const classes = useStyles();
    const {loading} = useContext(AuthContext);
    const {getTranslation} = useContext(LocalizationContext);
    const {selectedOrg} = useContext(OrgContext);

    const {
        steps,
        activeStep,
        setActiveStep,
        validId,
        pnrInUse,
        submited,
        formType,
        referalData,
        sendToLab,
        validSsn,
        formValidator,
    } = useContext(RefContext);

    const {
        handleBack,
        handleNext,
        id,
        quickTests,
        getQuickTestName,
        handleImageClick,
        pathName,
        openPDF
    } = props;

    let buttonVisible = [];
    if (activeStep > 0 && !submited) {
        buttonVisible.push(0)
    }
    if (steps[activeStep].valid && activeStep + 1 < steps.length && !submited) {
        buttonVisible.push(1)
    }
    if (!isNaN(id)) {
        buttonVisible.push(2)
    }
    if (!!isNaN(id) && submited) {
        buttonVisible.push(3)
    }
    if (steps[0].valid && steps[1].valid && steps[2].valid && activeStep === 3 && !submited) {
        buttonVisible.push(4)
    }
    let lastButtonVisible = buttonVisible[buttonVisible.length - 1];

    const clickableTab = (id) => {
        if (activeStep > id) {
            return true;
        } else if (activeStep === id) {
            return false;
        } else if (id - 1 === activeStep && steps[id - 1].valid) {
            return true;
        } else {
            return false;
        }
    };

    const showContinueButton = () => {
        if (activeStep !== 2) {
            if (buttonVisible.includes(1)) {
                return true;
            }
        } else {
            if (Number(selectedOrg.patientmodule) === 0) {
                return formValidator();
            } else {
                return formValidator() && validSsn && !pnrInUse;
            }
        }
    }

    return (
        <Grid item xs={12} sm={10}>
            {loading && <LinearProgress/>}
            <Paper className={classes.paperTitle} elevation={0}>
                <Typography variant="h6" gutterBottom>
                    {formType === 1 ? getTranslation('referal_form_box_title') : getTranslation('warranty_report_box_title')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {formType === 1 ? getTranslation('referal_form_box_subTitle') : getTranslation('warranty_report_box_subTitle')}
                </Typography>
            </Paper>

            {(pathName.search('warranty') > -1 && id) &&
                <Paper className={classes.paperTitle} elevation={0}>
                    <Typography variant="h6" gutterBottom>
                        {formType === 1 ? getTranslation('referal_form_box_subTitle') : getTranslation('warranty_information_title')}
                    </Typography>
                    {referalData &&
                        <Grid container>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                                    {`${getTranslation('referal_warranty_test_date_label')}:`}&nbsp;&nbsp;
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {moment(referalData.warrantyTestDate).format('YYYY-MM-DD HH:mm')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                                    {`${getTranslation('referal_warranty_lot_id_label')}:`}&nbsp;&nbsp;
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {referalData.warrantyLotId}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                                    {`${getTranslation('referal_warranty_comments_label')}:`}&nbsp;&nbsp;
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {referalData.warrantyComments}
                                </Typography>
                            </Grid>
                            {(quickTests && quickTests.length > 0 || referalData.quickTestIdName) &&
                                <Grid item xs={12} className={classes.displayFlex}>
                                    <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                                        {`${getTranslation('referal_warranty_quicktest_label')}:`}&nbsp;&nbsp;
                                    </Typography>
                                    {(quickTests.length > 0 && referalData.quickTestId) &&
                                        <Typography variant="body2" gutterBottom>
                                            {referalData.quickTestIdName || getQuickTestName(referalData.quickTestId)}
                                        </Typography>}
                                </Grid>
                            }
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                                    {`${getTranslation('referal_warranty_image_label')}:`}&nbsp;&nbsp;
                                </Typography>
                                <Typography variant="body2" onClick={() => handleImageClick()}
                                            className={referalData.file_id ? clsx(classes.pointer, classes.link) : ""}
                                            gutterBottom>
                                    {referalData.file_id ? getTranslation('referal_warranty_image_click_text') : getTranslation('referal_warranty_image_no_image')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                                    {`${getTranslation('referal_warranty_image_secondary_label')}:`}&nbsp;&nbsp;
                                </Typography>
                                { /* sending true so it knows it has to open secundary file */}
                                <Typography variant="body2" onClick={() => handleImageClick(true)}
                                            className={referalData.secundary_file_id ? clsx(classes.pointer, classes.link) : ""}
                                            gutterBottom>
                                    {referalData.secundary_file_id ? getTranslation('referal_warranty_image_secondary_click_text') : getTranslation('referal_warranty_image_secundary_no_image')}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </Paper>
            }

            <Paper className={classes.paper} elevation={0}>
                {!submited &&
                    <Grid container>
                        {steps.map(label => {
                            return <Grid id={label.id}
                                         className={`${classes.stepBox} ${(clickableTab(label.id) && label.id !== 3) || clickableTab(label.id) && label.id === 3 && validSsn ? classes.pointer : ''} ${activeStep === label.id ? classes.activeStepBox : ''}`}
                                         key={label.id} item xs={3}
                                         onClick={(e) => {
                                             // let selTab = 0;
                                             if ((clickableTab(label.id) && label.id !== 3) || clickableTab(label.id) && label.id === 3 && validSsn) {
                                                 setActiveStep(label.id)
                                             }
                                         }
                                         }>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography className={classes.stepTitle}
                                                    variant="body2">{label.title}</Typography>
                                        <Typography variant="caption">{label.subTitle}</Typography>
                                    </Grid>
                                    <Grid item xs={2}
                                          style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        {activeStep >= label.id && label.id < steps.length - 1 ?
                                            steps[label.id].valid && label.id < steps.length - 1 ?
                                                <CheckCircleOutline style={{
                                                    width: 25,
                                                    height: 25,
                                                    fill: (activeStep === label.id) ? colors.white : '#28A745'
                                                }}/> :
                                                <ErrorOutline style={{
                                                    width: 25,
                                                    height: 25,
                                                    fill: (activeStep === label.id) ? colors.white : colors.primary
                                                }}/> :
                                            false}
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                }
                <Grid container className={classes.container} style={{padding: 20}}>
                    {!submited && activeStep === 0 && <FormMethodsStep/>}
                    {!submited && activeStep === 1 && <FormTestsStep/>}
                    {!submited && activeStep === 2 && <FormIdStep/>}
                    {!submited && activeStep === 2 && validId && <FormDataStep/>}
                    {(submited || activeStep === 3) && <ReviewStep openPDF={openPDF} history={props.history}/>}
                </Grid>
                <Grid container className={classes.buttonGroup} style={{padding: 20}}>
                    {buttonVisible.includes(0) &&
                        <Button
                            style={{margin: '0px 5px'}}
                            variant={lastButtonVisible === 0 ? "contained" : "outlined"}
                            color="primary"
                            onClick={handleBack}
                            // startIcon={<ArrowBackIosIcon />}
                        >{getTranslation('generic_button_back')}</Button>
                    }
                    {showContinueButton() &&
                        <Button
                            style={{margin: '0px 5px'}}
                            variant={lastButtonVisible === 1 ? "contained" : "outlined"}
                            color="primary"
                            onClick={handleNext}
                            // startIcon={<ArrowForwardIosIcon />}
                        >{getTranslation('generic_button_continue')}</Button>
                    }
                    {buttonVisible.includes(2) &&
                        <Button
                            style={{margin: '0px 5px'}}
                            variant={lastButtonVisible === 2 ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => props.history.goBack()}
                            // startIcon={<ClearIcon />}
                        >{getTranslation('generic_button_back')}</Button>
                    }
                    {buttonVisible.includes(3) &&
                        <Button
                            style={{margin: '0px 5px'}}
                            variant={lastButtonVisible === 3 ? "contained" : "outlined"}
                            color="primary"
                            onClick={sendToLab}
                        >{getTranslation('generic_button_complete')}</Button>
                    }
                    {/*{buttonVisible.includes(4) &&*/}
                    {/*  <Button*/}
                    {/*    style={{ margin: '0px 5px' }}*/}
                    {/*    variant={lastButtonVisible === 4 ? "contained" : "outlined"}*/}
                    {/*    color="primary"*/}
                    {/*    onClick={submitForm}*/}
                    {/*  // startIcon={<SaveIcon />}*/}
                    {/*  >{getTranslation('generic_button_save')}</Button>*/}
                    {/*}*/}

                </Grid>
            </Paper>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    pointer: {
        cursor: 'pointer',
    },
    stepBox: {
        color: colors.textGray,
        padding: '10px 20px',
        border: '1px solid #E2E2E2',
        background: '#F9F9F9',
    },
    activeStepBox: {
        border: `1px solid ${colors.primary}`,
        background: colors.primary,
        color: colors.white,
    },
    stepTitle: {
        fontWeight: 'bold'
    },
    container: {
        display: "flex",
        justifyContent: "center"
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "flex-end"
    },
    paper: {
        width: "100%",
        // padding: 20
    },
    paperTitle: {
        width: "100%",
        padding: 20,
        marginBottom: 20,
    },
    cartButton: {
        width: '100%',
        margin: '5px 0'
    },
    selectTitle: {
        width: '100%',
        marginTop: 20,
    },
    selectGroup: {
        padding: 10
    },
    cartButtonActive: {
        width: '100%',
        margin: '5px 0',
        backgroundColor: '#FAD1D7'
    },
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    displayFlex: {
        display: 'flex'
    },
    fontWeightBold: {
        fontWeight: "bold"
    },
    link: {
        textDecoration: "underline",
        color: '#0000ee'
    }
}));
export default Desktop;
