import React, {useContext, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableFooter, TablePagination,
  TableRow, TextField,
  Typography,
  Dialog, DialogTitle, DialogContent, DialogActions
} from "@material-ui/core";
import {FilterList} from "@material-ui/icons";
import {LocalizationContext} from "../../../context/localizationContext";
import {PatientContext} from "../../../context/patientContext";
import EnhancedTableHead from "../../../components/Table/EnhancedTableHead";
import TablePaginationActions from "../../../components/Table/pagination";
import clsx from "clsx";

const PatientsList = props => {
  const classes = useStyles();
  const {getTranslation} = useContext(LocalizationContext);
  const {
    data,
    page,
    rowsPerPage,
    loading,
    sort,
    totalItems,
    search,
    setPage,
    setRowsPerPage,
    setSort,
    setSearch,
    fetchData,
    filtersOpen,
    setFiltersOpen,
    filtersData,
    setFiltersData
  } = useContext(PatientContext);

  const HEADER_CELLS = [
    {
      id: "pnr",
      name: getTranslation("patients_table_header_personal_id"),
      sort: true,
      visible: true
    },
    {
      id: "name",
      name: getTranslation("patients_table_header_name"),
      sort: true,
      visible: true
    },
    {
      id: "email",
      name: getTranslation("patients_table_header_email"),
      sort: true,
      visible: true
    },
    {
      id: "phone",
      name: getTranslation("patients_table_header_phone"),
      sort: true,
      visible: true
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortChange = (column, order) => {
    setSort({column: column, by: order})
  };
  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    setPage(0);
    fetchData();
  };

  const handleRowClick = id => {
    props.history.push(`/patients/${id}`);
  };

  const handleResetView = () => {
    fetchData(true);
  };

  const handleFiltersChange = e => {
    setFiltersData({...filtersData, [e.target.name]: String(e.target.value)});
  };

  const handleResetFilters = () => {
    setFiltersData({
      ...filtersData,
      ["email"]: "",
      ["phone"]: "",
      ["archived"]: "0"
    });
    setSearch(null);
  };

  const handleApplyFilters = () => {
    fetchData();
    setFiltersOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [sort, rowsPerPage, page, filtersData.archived]);

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Paper className={classes.paperTitle} elevation={0}>
            <Typography variant="h6" gutterBottom>
              {getTranslation('patients_list_page_title')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {getTranslation('patients_list_page_subtitle')}
            </Typography>
          </Paper>

          <Paper className={classes.paper} elevation={0}>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={7}>
                <Grid container>
                  <Grid item xs={12} sm={5} className={classes.inputFilterWrap}>
                    <TextField
                      variant="outlined"
                      size="small"
                      className={classes.inputFilter}
                      placeholder={getTranslation("patients_list_search_input_placeholder")}
                      onChange={handleSearchChange}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          handleSearchClick();
                        }
                      }}
                      value={search}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} className={classes.searchBtnWrap}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={loading}
                      onClick={handleSearchClick}
                    >
                      {getTranslation("generic_search_button_submit")}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={3} md={2} className={classes.resetSearchBtnWrap}>
                    <Button
                      variant="text"
                      color="primary"
                      fullWidth
                      disabled={loading}
                      onClick={handleResetView}
                    >
                      {getTranslation("generic_search_button_reset")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={5} className={classes.filtersBtnWrap}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.filterBtn}
                  startIcon={<FilterList/>}
                  onClick={() => setFiltersOpen(true)}
                >
                  {getTranslation("generic_table_toolbar_filter_table")}
                </Button>
              </Grid>

              <Grid item xs={12} className={classes.archivedBtnsWrap}>
                <Typography
                  color="primary"
                  className={clsx({
                    [classes.handledFilterBtns]: true,
                    [classes.notArchivedBtn]: true,
                    [classes.underlinedArchiveBtns]: filtersData.archived === "0"
                  })}
                  onClick={() => setFiltersData({...filtersData, archived: "0"})}
                >
                  {getTranslation("patients_list_table_filter_not_archived")}
                </Typography>
                <Typography
                  color="primary"
                  className={clsx({
                    [classes.handledFilterBtns]: true,
                    [classes.underlinedArchiveBtns]: filtersData.archived === "1"
                  })}
                  onClick={() => setFiltersData({...filtersData, archived: "1"})}
                >
                  {getTranslation("patients_list_table_filter_archived")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {loading && <LinearProgress/>}
                <TableContainer>
                  <Table aria-label="collapsible table" size="small">
                    <EnhancedTableHead
                      cells={HEADER_CELLS}
                      sort={sort}
                      onRequestSort={handleSortChange}
                    />
                    <TableBody>
                      {data && data.map(row => (
                        <TableRow key={row.id} className={classes.forcePointer} onClick={() => handleRowClick(row.id)}>
                          <TableCell>{row.pnr || "-"}</TableCell>
                          <TableCell>{row.name || "-"}</TableCell>
                          <TableCell>{row.email || "-"}</TableCell>
                          <TableCell>{row.phone || "-"}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                          count={totalItems}
                          colSpan={HEADER_CELLS.length + 1}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          labelRowsPerPage='Number per page'
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          sort={sort}
                          ActionsComponent={TablePaginationActions}
                          SelectProps={{
                            inputProps: {'aria-label': 'rows per page'},
                            native: true,
                          }}
                          labelDisplayedRows={({
                                                 from,
                                                 to,
                                                 count
                                               }) => getTranslation('generic_table_pagination_totals').replace('##from##', from).replace('##to##', to).replace('##count##', count)}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        fullWidth={true}
        width={"lg"}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>{getTranslation('generic_search_title')}</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label={getTranslation("patients_filter_phone")}
                name="phone"
                value={filtersData.phone}
                onChange={handleFiltersChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label={getTranslation("patients_filter_email")}
                name="email"
                value={filtersData.email}
                onChange={handleFiltersChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetFilters} color="primary">
            Återställ
          </Button>
          <Button onClick={() => setFiltersOpen(false)} color="primary">
            Stäng
          </Button>
          <Button onClick={handleApplyFilters} color="primary">
            Sök
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  forcePointer: {
    cursor: "pointer"
  },
  inputFilterWrap: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 15,
    }
  },
  inputFilter: {
    paddingLeft: 0
  },
  searchBtnWrap: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 15,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    }
  },
  resetSearchBtnWrap: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 15,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10,
    }
  },
  filtersBtnWrap: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      marginBottom: 15,
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end"
    }
  },
  filterBtn: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  handledFilterBtns: {
    fontSize: 13,
    fontWeight: 500,
    cursor: "pointer",
  },
  archivedBtnsWrap: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    marginBottom: 20
  },
  notArchivedBtn: {
    [theme.breakpoints.up("sm")]: {
      marginRight: 15
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10
    }
  },
  underlinedArchiveBtns: {
    textDecoration: "underline"
  }
}));

export default PatientsList;
