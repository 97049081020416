import React from "react";
import MUIDataTable from "mui-datatables";
import { TableRow, TableCell, FormLabel, TextField } from "@material-ui/core";
import TablableLocalizationFunc from '../tableLocalization';
import { LocalizationContext } from '../../context/localizationContext';
const SimpleTable = () => {
  const { getTranslation } = React.useContext(LocalizationContext);
  const TablableLocalization = TablableLocalizationFunc(getTranslation)

  const columns = [
    {
      name: getTranslation('invoices_detail_table_header_id'),
      options: {
        sortDirection: "desc",
        filter: true,
        filterList: [],
        customFilterListOptions: { render: v => `${getTranslation('invoices_detail_table_filter_id')} ${v}` },
        filterType: "textField" // set filterType's at the column level
      }
    },
    {
      name: getTranslation('invoices_detail_table_header_reg_date'), //TODO: convert to date filtering
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => value,
        filterType: "custom",
        customFilterListRender: v => {
          if (v[0] && v[1]) {
            return `${getTranslation('invoices_detail_table_header_reg_date')} - ${getTranslation('generic_table_filter_start_date')} ${v[0]}, ${getTranslation('generic_table_filter_end_date')} ${v[1]}`;
          } else if (v[0]) {
            return `${getTranslation('invoices_detail_table_header_reg_date')} - ${getTranslation('generic_table_filter_start_date')} ${v[0]}`;
          } else if (v[1]) {
            return `${getTranslation('invoices_detail_table_header_reg_date')} - ${getTranslation('generic_table_filter_end_date')} ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);

            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>{getTranslation('tableHeaders_sampleDate')}</FormLabel>
              <TextField
                id="startDate"
                label={getTranslation('generic_label_start_date')}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ""}
                onChange={event => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: "100%" }}
              />
              <TextField
                id="endDate"
                label={getTranslation('generic_label_end_date')}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ""}
                onChange={event => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: "100%" }}
              />
            </div>
          )
        },
        print: false
      }
    },
    {
      name: getTranslation('invoices_detail_table_header_arrival_date'), //TODO: convert to date filtering
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => value,
        filterType: "custom",
        customFilterListRender: v => {
          if (v[0] && v[1]) {
            return `${getTranslation('invoices_detail_table_header_arrival_date')} - ${getTranslation('generic_table_filter_start_date')} ${v[0]}, ${getTranslation('generic_table_filter_end_date')} ${v[1]}`;
          } else if (v[0]) {
            return `${getTranslation('invoices_detail_table_header_arrival_date')} - ${getTranslation('generic_table_filter_start_date')} ${v[0]}`;
          } else if (v[1]) {
            return `${getTranslation('invoices_detail_table_header_arrival_date')} - ${getTranslation('generic_table_filter_end_date')} ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);

            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>{getTranslation('tableHeaders_sampleDate')}</FormLabel>
              <TextField
                id="startDate"
                label={getTranslation('generic_label_start_date')}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ""}
                onChange={event => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: "100%" }}
              />
              <TextField
                id="endDate"
                label={getTranslation('generic_label_end_date')}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ""}
                onChange={event => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: "100%" }}
              />
            </div>
          )
        },
        print: false
      }
    },
    {
      name: getTranslation('invoices_detail_table_header_client'),
      options: {
        sortDirection: "desc",
        filter: true,
        filterList: [],
        customFilterListOptions: { render: v => `${getTranslation('invoices_detail_table_filter_client')} ${v}` },
        filterType: "textField" // set filterType's at the column level
      }
    },
    {
      name: getTranslation('invoices_detail_table_header_analysis'),
      options: {
        sortDirection: "desc",
        filter: false,
      }
    },
    {
      name: getTranslation('invoices_detail_table_header_analysis_quantity'),
      options: {
        sortDirection: "desc",
        filter: false,
      }
    },
  ];

  const data = [
    [
      "123456789",
      "2019-12-10",
      "2019-12-11",
      "Eva Hanson",
      "Stor opioid",
      "1 analysis",
      "See analysis response"
    ],
  ];

  const options = {
    ...TablableLocalization,
    responsive: "scroll",
    pagination: true,
    download: false,
    print: false,
    search: false,
    selectableRows: false,
    filter: true,
    expandableRowsOnClick: false,
    expandableRows: false,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={rowData.length}>
            {/* <SubDetailTable /> */}
          </TableCell>
        </TableRow>
      );
    },
  };

  return (
    <MUIDataTable
      title={getTranslation("invoices_detail_table_title")}
      data={data}
      className="forceWidth"
      style={{ background: "red" }}
      columns={columns}
      options={options}
    />
  );
};

export default SimpleTable;
