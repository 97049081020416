import {Html5QrcodeScanType} from "html5-qrcode";

export const cameraConfig = {
  fps: 2,
  qrbox: { width: 250, height: 200 },
  aspectRatio: 1.0,
  disableFlip: false,
  rememberLastUsedCamera: true,
  supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
  showTorchButtonIfSupported: true,
  facingMode: "environment"
};
