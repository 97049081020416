import React, {useContext} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText} from "@material-ui/core";
import { LocalizationContext } from '../../../context/localizationContext';

function ConfirmDeletePopup({ open, onClose, onDelete }) {
    const {getTranslation} = useContext(LocalizationContext);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{getTranslation('testcase_confirm_popup_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {getTranslation('testcase_confirm_popup_body')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {getTranslation('testcase_confirm_popup_cancel_button')}
                </Button>
                <Button onClick={onDelete} color="primary" autoFocus>
                    {getTranslation('testcase_confirm_popup_confirm_button')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDeletePopup;
