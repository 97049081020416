import React, { useContext } from 'react';
import { Grid, Paper, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../styles/Theme-settings';
import classNames from 'classnames';
import { AuthContext } from '../context/authContext'
import { LocalizationContext } from '../context/localizationContext';

const NotFound = (props) => {
  const classes = useStyles();
  const { authType } = useContext(AuthContext)
  const { getTranslation } = useContext(LocalizationContext);

  return (
    <Grid container justify="center" className={classes.gridRight}>
      <Paper className={classes.paper}>
        <Grid className={classes.inputGrid} item xs={12}>
          <Typography variant="h5" gutterBottom>404</Typography>
        </Grid>
        <Grid className={classes.inputGrid} item xs={6}>
          <Button
            className={classNames(classes.buttons, authType.type === 1 ? classes.activeButton : '')}
            variant="contained"
            color="primary"
            onClick={() => {
              props.history.goBack();
            }}
          >{getTranslation('404_back')}</Button>
        </Grid>

      </Paper>
    </Grid>
  )
}
const useStyles = makeStyles(theme => ({
  gridLeft: {
  },
  gridRight: {
    padding: 30,
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex'
  },
  paper: {
    // display: 'flex',
    height: '100%',
    width: '100%',
    padding: 30,
    // justifyContent: 'center',
  },
  inputGrid: {
    display: 'flex'
  },
  buttonGroup: {
    marginTop: 60,
    display: 'flex'
  },
  textInput: {
    width: '100%',
    padding: '10px 0px',
  },
  buttons: {
    width: '100%',
    maxHeight: 38,
  },
  activeButton: {
    backgroundColor: colors.halfPrimary
  },
  topGroup: { padding: '60px 0px' },
  formroup: {
    paddingBottom: 90
    // padding: '30px 0px 90px 0px'
  },
  bottomGroup: { padding: '60px 0px' },
}));
export default NotFound;
