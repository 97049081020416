import React from "react";
import { CircularProgress } from '@material-ui/core';

const Loading = props => {
  const styles = {
    page: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }
  };
  return (
    <div style={styles.page}>
      <CircularProgress />
    </div>
  );
};
export default Loading;
