import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, Grid, Typography, CardContent} from '@material-ui/core';

const useStyles = makeStyles({
    card: {
        minWidth: 275,
        minHeight: 285
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    item: {padding: 20},
    image: {
        width: 37,
        height: 45,
        cursor: "pointer"
    },
    imageWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10
    },
    content: {
        minHeight: 285,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
});

const YoutubePreview = (props) => {
    const classes = useStyles();

    const handleDownloadClick = () => {
        // Create a URL for the file you want to download
        const fileUrl = "/files/Provtagning_Quantisal.pdf";

        const fileName = fileUrl.split("/")[2];

        // Create a link element
        const link = document.createElement('a');

        // Set the href attribute of the link to the file URL
        link.href = fileUrl;

        // Set the download attribute to specify the file name
        link.download = fileName;

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger a click event on the link
        link.click();

        // Remove the link from the document body
        document.body.removeChild(link);
    }

    return (
        <Grid item xs={12} sm={6} md={4} className={classes.item}>
            <Card className={classes.card} variant="outlined">
                <CardContent className={classes.content}>
                    <iframe title={props.title} width="100%" height="100%"
                            src={`https://player.vimeo.com/video/${props.urlId}`} frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    {props.pdfFile &&
                        <div className={classes.imageWrapper}>
                            <img className={classes.image} onClick={() => handleDownloadClick()}
                                 src="/images/download-pdf-icon.png" alt="pdf-icon"/>
                        </div>
                    }
                    <div>
                        <Typography variant="subtitle2" gutterBottom>
                            {props.title}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {props.description}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );
}
export default YoutubePreview;