import React, { useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LocalizationContext } from '../../context/localizationContext';
import { AuthContext } from '../../context/authContext';
import colors from '../../styles/Theme-settings';
import ResetForm from '../../components/Reset-form';
import classNames from 'classnames';

const Reset = (props) => {
  const classes = useStyles();
  const { authType, setAuthType, loading } = useContext(AuthContext)
  const { getTranslation } = useContext(LocalizationContext);
  document.title = getTranslation('reset_browser_title') + ' | Prodiagnostics';
  return (
    <Grid container>
      <Grid container className={classes.topGroup}>
        {/* Title */}
        <Grid className={classes.inputGrid} item xs={12}>
          <Typography variant="h5" gutterBottom>{getTranslation('reset_password_form_page_title')}</Typography>
        </Grid>

      </Grid>
      {/* Form Holder */}
      <Grid container className={classes.formroup}>
        <Grid item xs={10} sm={6} >
          <ResetForm {...props} />
        </Grid>
      </Grid>
      {/* Footer */}
      <Grid container className={classes.bottomGroup}>
        <Grid item xs={10} sm={8} className={classes.otherMethodsGroup}>
          <div style={{ height: 1, background: '#707070', marginBottom: -13 }} />
          <span style={{ background: colors.bgGray, padding: 15, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
            {getTranslation('login_form_other_methods')}
          </span>
        </Grid>
        <Grid item xs={10} sm={6} className={classes.buttonGroup}>
          <Grid className={classes.inputGrid} item xs={12} sm={6} >
            <Button
              className={classNames(classes.buttons)}
              variant="outlined"
              color="primary"
              onClick={() => {
                setAuthType({
                  ...authType,
                  type: 1,
                  step: 0,
                  complete: false
                });

                props.history.push('login')
              }}
              disabled={loading}
            >{getTranslation('login_form_button_password')}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
const useStyles = makeStyles(theme => ({

  inputGrid: {
    display: 'flex',
    justifyContent: 'center'
  },
  bottomGroup: {
    display: 'flex',
    justifyContent: 'center'
  },
  textInput: {
    width: '100%',
    padding: '10px 0px',
  },
  buttons: {
    width: '100%',
    maxHeight: 38,
  },
  activeButton: {
    backgroundColor: colors.halfPrimary
  },
  topGroup: { padding: '20px 0px' },
  formroup: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center'
  },
  otherMethodsGroup: {
    margin: '45px 0px',
    textAlign: 'center'
  }
}));
export default Reset;
