import React, {useState, useRef, useCallback, useEffect} from "react";
import Webcam from "react-webcam";
import {Button, Modal} from "@material-ui/core"
import CameraIcon from '@material-ui/icons/Camera';
import {makeStyles} from '@material-ui/core/styles';
import colorConfig from "../../styles/Theme-settings";

export default function WebcamCapture(props) {

    const videoConstraints = {
        width: 1920,
        height: 1080,
        facingMode: "environment",
    };

    const classes = useStyles(props);

    const {open, handleClose, handleCapture} = props;

    const webcamRef = useRef();

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        handleCapture(imageSrc);
        handleClose();
    }
    // useEffect(() => {
    //   if (navigator.permissions && navigator.permissions.query) {
    //     navigator.permissions
    //       .query({name: "cameraComp"})
    //       .then((result) => {
    //         console.log(result);
    //       })
    //       .catch((error) => {
    //         console.log("Got error :", error);
    //       });
    //   }
    // }, [])

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <div className={classes.paper}>
                    {open &&
                        <Webcam
                            audio={false}
                            height="100%"
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width="100%"
                            videoConstraints={videoConstraints}
                        />
                    }
                    <CameraIcon style={{
                        fill: "rgba(255, 255, 255, 0.8)",
                        position: "relative",
                        height: 50,
                        width: 50,
                        transform: "translate(-50%, 0%)",
                        left: "50%",
                        top: -60,
                        cursor: "pointer"
                    }} onClick={capture}/>
                    <div style={{display: "flex", justifyContent: "center", padding: "0px 10px 10px", marginTop: -45}}>
                        {/*<Button size="small" variant="contained" color="primary">*/}
                        {/*  Switch cameraComp*/}
                        {/*</Button>*/}
                        <Button size="small" variant="contained" color="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: props => props.width || 400,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        backgroundColor: theme.palette.background.paper,
        background: colorConfig.bgGray
    },
}));