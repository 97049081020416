import React from "react";
import {
  Grid,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Preview from '../components/YoutubePreview';
import videoList from '../functional/videoList';
import { LocalizationContext } from '../context/localizationContext';
import PdfPreview from "../components/Pdfpreview";
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    padding: 20
  }
}));
const Educational = props => {
  const classes = useStyles();
  const { getTranslation } = React.useContext(LocalizationContext);
  document.title = getTranslation('educational_browser_title') + ' | Prodiagnostics';

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        <Paper className={classes.paper}>
          <Grid container>
            <PdfPreview/>
            {videoList.data.map((item, i) => {
              return <Preview key={i} urlId={item.urlId} title={item.title} description={item.description} pdfFile={item.pdfFile}/>
            })}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default Educational;
