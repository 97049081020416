import React, {useContext, useEffect, useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    TableFooter,
    TableContainer,
    Collapse,
    Box,
    Grid, ButtonGroup, Button, LinearProgress, TextField, Tooltip, Avatar
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';
import clsx from "clsx";
import TablePaginationActions from "../Table/pagination";
import {LocalizationContext} from "../../context/localizationContext";
import {FilterList} from "@material-ui/icons";
import {AuthContext} from "../../context/authContext";
import ReceiptIcon from "@material-ui/icons/Receipt";
import APICall from "../../functional/APIRequests";
import {useSnackbar} from "notistack";
import {OrgContext} from "../../context/orgContext";
import colorConfig from "../../styles/Theme-settings";
import {apiServer} from "../../functional/generateEnvUrls";

const headCells = [
    {id: "organisation_id", numeric: true, label: "Kund ID"},
    {id: "organisation.name", numeric: false, label: "Kund"},
    {id: "title", numeric: true, label: "generic_table_column_title"},
    {id: "date", numeric: true, label: "generic_table_test_date"},
    {id: "tests_done", numeric: true, label: "generic_table_quantity"},
    {id: "closed", numeric: true, label: "generic_table_header_status"},
    {id: "button", numeric: true, label: ""}
];

const subHeaderCells = [
    {id: "referral_id", numeric: false, label: "analysis_list_table_header_id"},
    {id: "arrival_date", numeric: false, label: "analysis_list_table_arrived_at_lab"},
    {id: "result_date", numeric: false, label: "analysis_list_table_response_date"},
    {id: "approved", numeric: false, label: "analysis_list_table_checked_for_mro"},
    {id: "handled", numeric: false, label: "analysis_list_table_handled_by_mro"},
    {id: "status_id", numeric: false, label: "generic_table_header_status"},
    {id: "is_positive", numeric: false, label: "analysis_list_table_header_result"},
    {id: "pdf", numeric: false, label: ""}
];

const EnhancedTableHead = (props) => {
    const classes = useStyles();
    const {sort, onRequestSort, cells} = props;

    const {getTranslation} = useContext(LocalizationContext);

    return (
        <TableHead>
            <TableRow>
                <TableCell/>
                {cells && cells.map(headCell => (
                    <TableCell
                        className={classes.forcePointer}
                        key={headCell.id}
                        sortDirection={sort.column === headCell.id ? sort.by : false}
                        align='left'
                    >
                        <TableSortLabel
                            active={sort.column === headCell.id}
                            direction={sort.column === headCell.id ? sort.by : "asc"}
                            onClick={() => onRequestSort(headCell.id, sort.by === "asc" ? "desc" : "asc")}
                            hideSortIcon={headCell.id === "tests_done" || headCell.id === "organisation_name"}
                        >
                            {getTranslation(headCell.label)}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
};

const Row = (props) => {
    const classes = useStyles();
    const {getTranslation} = useContext(LocalizationContext);
    const {selectedOrg} = useContext(OrgContext);

    const {
        row,
        subSort,
        onRequestSort,
        referrals,
        positives,
        onTestCaseClick,
        onReferralClick
    } = props;
    const [open, setOpen] = React.useState(false);

    const handleExpandRow = (id) => {
        setOpen(!open);
    }

    const handlePdfClick = (e, analysisId) => {
        e.stopPropagation();
        let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/analysis/labreport?analysis_id=${analysisId}`;
        window.open(url, "_blank");
    }

    return (
        <>
            <TableRow style={open ? {background: 'rgba(244, 67, 54, 0.3)'} : {}}>
                <TableCell className={classes.expandCell}>
                    <div className={open ? classes.expandIconWrapperOpen : classes.expandIconWrapper}
                         onClick={() => handleExpandRow(row.sys_id)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </div>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.customer_number}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.organisation_name}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.title}
                </TableCell>
                <TableCell>{moment.unix(row.date).format('YYYY-MM-DD')}</TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item sm={7} md={4}
                              className={clsx({[classes.quantityValue]: row.nr_tests === row.tests_total && row.nr_tests > 0 && row.tests_total > 0})}>
                            {`${row.nr_tests}(${row.tests_total})`}
                        </Grid>
                        {positives[row.sys_id] === '1' &&
                            <Grid item sm={5} md={6}>
                                <Tooltip title={getTranslation('analysis_table_positive_tooltip')}>
                                    <ReceiptIcon style={{
                                        fill: "#f44336",
                                        marginLeft: 8
                                    }}/>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                </TableCell>
                <TableCell>
                    {row.closed ? getTranslation("analysis_table_test_case_closed") : getTranslation("analysis_table_test_case_open")}
                </TableCell>
                <TableCell align="right">
                    <div className={classes.avatarWrapper}>
                        <Avatar className={classes.avatar}>
                            <ArrowForwardIcon className={classes.avatarIcon}
                                              onClick={() => onTestCaseClick(row.sys_id)}/>
                        </Avatar>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow className={classes.collapsableRow}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table aria-label="tested-people" className={classes.innerTable}>
                                <TableHead>
                                    <TableRow>
                                        {subHeaderCells && subHeaderCells.map(head => (
                                            <TableCell
                                                className={classes.forcePointer}
                                                key={head.id}
                                                sortDirection={subSort.column === head.id ? subSort.by : false}
                                                align='left'
                                            >
                                                <TableSortLabel
                                                    active={subSort.column === head.id}
                                                    direction={subSort.column === head.id ? subSort.by : "asc"}
                                                    onClick={() => onRequestSort(head.id, subSort.by === "asc" ? "desc" : "asc", row.sys_id)}
                                                    hideSortIcon={head.id === "referral.analysis" || head.id === "pdf"}
                                                >
                                                    {getTranslation(head.label)}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {referrals && referrals[row.sys_id] && referrals[row.sys_id].map((referral) => (
                                        referral.sys_id !== null && (
                                            <TableRow key={referral.referral_id}
                                                      onClick={() => onReferralClick(referral.sys_id)}
                                                      className={classes.forcePointer}>
                                                <TableCell component="th" scope="row">
                                                    {referral.referral_id ? referral.referral_id : '-'}
                                                </TableCell>
                                                <TableCell>{referral.arrival_date ? moment.unix(referral.arrival_date).format('YYYY-MM-DD') : '-'}</TableCell>
                                                <TableCell>{referral.result_date ? referral.result_date : '-'}</TableCell>
                                                <TableCell style={
                                                    referral.mro_started_date && !referral.attest_date
                                                        ? { color: 'orange', fontWeight: 'bold' }
                                                        : {}
                                                    }
                                                >
                                                    {referral.status_id.id !== "3" ? "-" : (referral.approved == "1" ? getTranslation("generic_filter_yes") : getTranslation("generic_filter_no"))}
                                                </TableCell>
                                                <TableCell>{referral.status_id.id !== "3" ? "-" : (referral.attest_date ? getTranslation("generic_filter_yes") : getTranslation("generic_filter_no"))}</TableCell>
                                                <TableCell>
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <FiberManualRecordIcon style={{
                                                            fill: referral.status_id ? referral.status_id.color : '',
                                                            marginRight: 5
                                                        }}/>
                                                        {referral.status_id ? referral.status_id.name : '-'}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {referral.is_positive === "1" &&
                                                        <Tooltip
                                                            title={getTranslation("analysis_table_positive_tooltip")}>
                                                            <ReceiptIcon style={{fill: "#f44336", margin: "0px 5px",}}/>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                                {referral.status_id.id === "3" ?
                                                    <TableCell onClick={e => handlePdfClick(e, referral.test_result_id)}>
                                                        <img src="/images/pdf_icon_black.svg" alt="pdf icon"
                                                             width={18}
                                                             height={18}/>
                                                    </TableCell>
                                                    :
                                                    <TableCell/>
                                                }
                                            </TableRow>
                                        )
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
};

const WorkplaceAnalysesTable = props => {

        const {getTranslation} = useContext(LocalizationContext);
        const {setLoading, loading} = useContext(AuthContext);
        const {enqueueSnackbar} = useSnackbar();
        const {selectedOrg} = useContext(OrgContext);
        const {user} = useContext(AuthContext);

        const [data, setData] = useState([]);
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(20);
        const [count, setCount] = useState(0);
        const [sort, setSort] = useState({column: 'date', by: 'desc'});
        const [subSort, setSubSort] = useState({column: 'referral_id', by: 'desc'});
        const [referrals, setReferrals] = useState({});
        const [status, setStatus] = useState([]);
        const [positives, setPositives] = useState({});
        const [filter, setFilter] = useState(false);
        const [searchState, setSearchState] = useState(false)
        const [searchField, setSearchField] = useState("");

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = event => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const handleSortChange = (column, order) => {
            if (column !== "tests_done") {
                setSort({column: column, by: order})
            }
        }

        const handleSubSortChange = (column, order, testCase) => {
            if (column !== "referral.analysis") {
                setSubSort({column: column, by: order});
                fetchReferralsCombined(testCase, column, order);
            }
        }

        const clearFilter = () => {
            setFilter(false);
            setPage(0);
            setRowsPerPage(20);
        };

        const handleTestCaseClick = id => {
            props.history.push(`/testcase/${id}`);
        };

        const handleReferralClick = id => {
            props.history.push(`/analyses/detail/${id}`);
        }

        const fetchTestCases = () => {
            setLoading(true);
            let sortInPayload = sort.column + " " + sort.by
            let url = `customer/${selectedOrg.id}/testcase${selectedOrg.mro ? `/mro/${user.user_id}/approved` : ""}?data[sort]=${sortInPayload}&data[limit]=${rowsPerPage}&data[offset]=${rowsPerPage * page}`;

            APICall({
                setLoading,
                enqueueSnackbar,
                url: url,
                verb: "get",
                server: "api",
                formData: false
            })
                .then(res => {
                    if (res) {
                        //If not prodiagnostics set only testcases for the current organisation
                        if (selectedOrg.id !== '1') {
                            let _data = [];
                            let filteredTestCases = [];
                            let count = res.data.filter(testCase => testCase.organisation_id === selectedOrg.id).length;

                            res.data.forEach(testCase => {
                                if (testCase.organisation_id === selectedOrg.id)
                                    filteredTestCases.push(testCase);
                            });

                            for (let i = rowsPerPage * page; i < filteredTestCases.length; i++) {
                                let testCase = filteredTestCases[i];
                                if (_data.length < rowsPerPage) {
                                    _data.push(testCase);
                                }
                            }

                            setData(_data);
                            setCount(count);
                        }
                        //If prodianostics show all testcases
                        else {
                            setData(res.data);
                            setCount(res.total_records || 0);
                        }
                    }
                    setLoading(false);
                })
                .catch(() => setLoading(false))
        }

        const fetchReferralsCombined = (testcaseId, column, order) => {
            setLoading(true);
            const sort = column || subSort.column;
            const by = order || subSort.by;
            let url = `customer/${selectedOrg.id}/express_referrals/combined?&sort=${sort} ${by}&limit=${rowsPerPage}&offset=${page * rowsPerPage}&filter[test_case_id]=${testcaseId}&workplace=1`;

            APICall({
                setLoading,
                enqueueSnackbar,
                url: url,
                verb: "get",
                server: "api",
                formData: false
            })
                .then(res => {
                    const testCaseId = testcaseId;
                    // Normalize the data so that we always have a 'referral' key
                    const normalizedData = res.data.map(item => {
                        if (item.expressreferral && !item.referral) {
                            return {...item, referral: item.expressreferral};
                        }
                        return item;
                    });

                    setReferrals(prevState => ({
                        ...prevState,
                        [testCaseId]: normalizedData.map(data => {
                            let analysis = [];
                            if (Array.isArray(data.analysis)) {
                                data.analysis.forEach(a => {
                                    analysis.push(a.name);
                                })
                            }

                            if (data.test_date) {
                                data.test_date = data.test_date === '0' || !data.test_date ? '-' : moment.unix(data.test_date).format('YYYY-MM-DD');
                            }

                            if (data.registration_date) {
                                data.registration_date = data.registration_date === '0' || !data.registration_date ? '-' : moment.unix(data.registration_date).format('YYYY-MM-DD');
                            }

                            if (data.result_date) {
                                data.result_date = data.result_date === '0' || !data.result_date ? '-' : moment.unix(data.result_date).format('YYYY-MM-DD');
                            }

                            if (data.status_id) {
                                data.status_id = {
                                    color: data.status_id === '1' ? '#FFEE00' : data.status_id === '2' ? '#EFA700' : '#28A745',
                                    name: status.find(st => st.id === data.status_id)?.text,
                                    id: data.status_id
                                };
                            }

                            return {...data};
                        })
                    }))

                    for (let i in res.data) {
                        let ref = res.data[i];

                        setPositives(prevState => ({
                            ...prevState,
                            [testCaseId]: ref.is_positive
                        }))
                        break;
                    }

                    setLoading(false);
                })
                .catch(() => setLoading(false));
        };

        const fetchStatus = () => {
            setLoading(true)
            APICall({
                setLoading,
                hasRefresh: true,
                enqueueSnackbar,
                url: `customer/${selectedOrg.id}/referral/statuses`,
                verb: 'get',
                server: 'api',
            }).then((res) => {
                setLoading(false);
                if (res && res.data) {
                    setStatus(res.data.map(d => {
                        return {id: d.sys_id, text: d.status}
                    }));
                }
            })
        }

        useEffect(() => {
            fetchTestCases();
        }, [rowsPerPage, page, sort]);

        useEffect(() => {
            if (data.length > 0 && status.length > 0) {
                for (let i in data) {
                    let testCase = data[i];
                    if (testCase.tests_total > 0) {
                        fetchReferralsCombined(testCase.sys_id);
                    }
                }
            }
        }, [data, status]);

        useEffect(() => {
            fetchStatus();
        }, [])

        return (
            <Grid container spacing={1} alignItems="center">
                {/*<Grid item xs={6}>*/}
                {/*  <div style={{display: "flex"}}>*/}
                {/*    <TextField*/}
                {/*      size="small"*/}
                {/*      variant="outlined"*/}
                {/*      placeholder={getTranslation("workplace_table_search_placeholder")}*/}
                {/*      name="search"*/}
                {/*      value={searchField}*/}
                {/*      onChange={e => setSearchField(e.target.value)}*/}
                {/*    />*/}
                {/*    <Button*/}
                {/*      color="primary"*/}
                {/*      size="small"*/}
                {/*    >*/}
                {/*      {getTranslation("workplace_table_search_button")}*/}
                {/*    </Button>*/}
                {/*  </div>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={6}>*/}
                {/*  <ButtonGroup size="small" color="primary" className={classes.pageHeader}>*/}
                {/*    <Button onClick={() => clearFilter}> {getTranslation('generic_clear_filter')} </Button>*/}
                {/*    <Button startIcon={<FilterList/>}*/}
                {/*            onClick={() => setSearchState(true)}> {getTranslation('generic_button_filter')} </Button>*/}
                {/*  </ButtonGroup>*/}
                {/*  {loading && <LinearProgress/>}*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <TableContainer>
                        {loading && <LinearProgress/>}
                        <Table aria-label="collapsible table">
                            <EnhancedTableHead
                                sort={sort}
                                onRequestSort={handleSortChange}
                                cells={headCells}
                            />
                            <TableBody>
                                <>
                                    {Array.isArray(data) && data.length > 0 && data.map((row, index) => (
                                        <Row key={index} row={row} subSort={subSort} referrals={referrals}
                                             positives={positives}
                                             onRequestSort={handleSubSortChange} onTestCaseClick={handleTestCaseClick}
                                             onReferralClick={handleReferralClick}
                                        />
                                    ))}
                                    {data.length === 0 &&
                                        <TableRow>
                                            <TableCell align="center" colSpan={headCells.length + 1}>
                                                {getTranslation("generic_table_body_no_match")}
                                            </TableCell>
                                        </TableRow>
                                    }
                                </>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                        colSpan={headCells.length + 1}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        labelRowsPerPage={getTranslation('generic_table_pagination_rows_per_page')}
                                        labelDisplayedRows={({
                                                                 from,
                                                                 to,
                                                                 count
                                                             }) => getTranslation('generic_table_pagination_totals').replace('##from##', from).replace('##to##', to).replace('##count##', count)}
                                        SelectProps={{
                                            inputProps: {'aria-label': 'rows per page'},
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        handleSortChange={handleSortChange}
                                        sort={sort}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    }
;

const useStyles = makeStyles(theme => ({
    forcePointer: {
        cursor: 'pointer',
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    expandCell: {
        height: 32,
        padding: 0
    },
    expandIconWrapper: {
        width: "80%",
        height: "100%",
        background: "#e6e6e6",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    expandIconWrapperOpen: {
        width: "80%",
        height: "100%",
        background: "rgba(244, 67, 54, 0.3)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    collapsableRow: {
        background: "#e6e6e6"
    },
    innerTable: {
        background: "#ffffff"
    },
    avatar: {
        width: 20,
        height: 20,
        backgroundColor: "#E41937",
        cursor: "pointer"
    },
    avatarIcon: {
        fill: "#FFFFFF"
    },
    avatarWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        marginRight: 5
    },
    allTestsDoneText: {
        color: colorConfig.green,
        fontWeight: 'bold'
    },
    quantityValue: {
        fontWeight: "bold",
        color: colorConfig.green
    }
}));

export default WorkplaceAnalysesTable;