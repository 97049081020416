import React from "react";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {emailRegex} from "../../../functional/etc";

const Tablet = props => {
  const {
    getTranslation,
    userData,
    orgName,
    handleTextChange,
    onSubmit,
    onSubmitContinue,
    classes,
    ssnInUse
  } = props;

  return (
    <Grid item xs={12}>
      <Grid container style={{paddingBottom: 30}}>
        <Grid item xs={12} className={classes.marginBottom}>
          <Button
            className={classes.backButton}
            startIcon={<KeyboardBackspaceIcon className={classes.backIcon}/>}
            onClick={() => props.history.goBack()}
          >
            {getTranslation('testcase_button_go_back')}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <Typography variant="h5"><b>{getTranslation("test_list_add_person")}, {orgName}</b></Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={4} className={classes.paddingLeft}>
              <TextField
                variant="outlined"
                name="ssn"
                label={userData.ssn ? getTranslation("testcase_personal_ssn_identification_placeholder") : ""}
                InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                value={userData.ssn || ""}
                onChange={handleTextChange}
                placeholder={getTranslation("testcase_personal_ssn_identification_placeholder")}
                error={(userData.ssn && ssnInUse) || (!userData.ssn || (userData.ssn && userData.ssn.length === 0))}
                helperText={(userData.ssn && ssnInUse) ? getTranslation("testcase_user_in_test_case_error") : (!userData.ssn || (userData.ssn && userData.ssn.length === 0))}
              />
            </Grid>
            <Grid item xs={8} className={classes.paddingRight}>
              <TextField
                variant="outlined"
                name="name"
                label={userData.name ? getTranslation("testcase_personal_name_placeholder") : ""}
                InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                value={userData.name || ""}
                onChange={handleTextChange}
                placeholder={getTranslation("testcase_personal_name_placeholder")}
                error={!userData.name || (userData.ssn && userData.name.length === 0)}
                helperText={!userData.name || userData.name.length === 0 ? getTranslation('generic_error_required_field') : ''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={4} className={classes.paddingLeft}>
              <TextField
                variant="outlined"
                placeholder={getTranslation("testcase_personal_phone_placeholder")}
                name="phone"
                label={userData.phone ? getTranslation("testcase_personal_phone_placeholder") : ""}
                InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                value={userData.phone || ""}
                onChange={handleTextChange}
                error={!userData.phone || (userData.phone && userData.phone.length === 0)}
                helperText={!userData.phone || userData.phone.length === 0 ? getTranslation('generic_error_required_field') : ''}
              />
            </Grid>
            <Grid item xs={8} className={classes.paddingRight}>
              <TextField
                variant="outlined"
                placeholder={getTranslation("testcase_personal_email_placeholder")}
                name="email"
                label={userData.email ? getTranslation("testcase_personal_email_placeholder") : ""}
                InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                value={userData.email || ""}
                onChange={handleTextChange}
                error={!userData.email || (userData.email.length > 0 && !emailRegex.test(userData.email))}
                helperText={!userData.email || userData.email.length === 0 ? getTranslation('generic_error_required_field') : userData.email.length > 0 && !emailRegex.test(userData.email) ? getTranslation('generic_email_regex_fail') : ''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center", marginTop: 30}}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={onSubmit}
              >
                {getTranslation("testcase_create_save_button")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={onSubmitContinue}
              >
                {getTranslation("testcase_create_save_and_continue_button")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tablet;
