import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";
import {ThemeProvider} from "@material-ui/core";
import MUITheme from "./styles/MUI-theme";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
// import context
import {LocalizationProvider} from './context/localizationContext';
import {ActivitiesLogsProvider} from './context/activitieslogsContext';
import {AuthProvider} from './context/authContext';
import {OrgProvider} from './context/orgContext';
import {PersonelProvider} from './context/personelContext';
import {RefProvider} from './context/refContext';
import {InvoicesProvider} from './context/invoicesContext';
import {PatientProvider} from "./context/patientContext";
import {TestcaseProvider} from "./context/testcaseContext";
import {SnackbarProvider} from 'notistack';
import './styles/cssFixes.css';

ReactDOM.render(
  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
    <ThemeProvider theme={MUITheme}>
      <SnackbarProvider maxSnack={3} hideIconVariant={true} dense={false} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
        <LocalizationProvider>
          <AuthProvider>
            <OrgProvider>
              <PersonelProvider>
                <RefProvider>
                  <InvoicesProvider>
                    <TestcaseProvider>
                      <PatientProvider>
                        <ActivitiesLogsProvider>
                          <Router/>
                        </ActivitiesLogsProvider>
                      </PatientProvider>
                    </TestcaseProvider>
                  </InvoicesProvider>
                </RefProvider>
              </PersonelProvider>
            </OrgProvider>
          </AuthProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </MuiPickersUtilsProvider>
  , document.getElementById("root")
);
