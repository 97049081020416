const tableLocalize =(gt)=>{
 return {
  textLabels: {
    body: {
      noMatch: gt('generic_table_body_no_match'),
      toolTip: gt('generic_table_body_tooltip'),
      columnHeaderTooltip: column => `${gt('generic_table_body_colum_header_tooltip')} ${column.label}`
    },
    pagination: {
      next: gt('generic_table_pagination_next'),
      previous: gt('generic_table_pagination_previous'),
      rowsPerPage: gt('generic_table_pagination_rows_per_page'),
      displayRows: gt('generic_table_pagination_display_rows'),
    },
    toolbar: {
      search: gt('generic_table_toolbar_search'),
      downloadCsv: gt('generic_table_toolbar_downloadcsv'),
      print: gt('generic_table_toolbar_print'),
      viewColumns: gt('generic_table_toolbar_view_columns'),
      filterTable: gt('generic_table_toolbar_filter_table'),
    },
    filter: {
      all: gt('generic_table_filter_all'),
      title:gt('generic_table_filter_title'),
      reset: gt('generic_table_filter_reset'),
    },
    viewColumns: {
      title: gt('generic_table_view_columns_title'),
      titleAria: gt('generic_table_view_columns_title_aria'),
    },
    selectedRows: {
      text: gt('generic_table_selected_row_text'),
      delete: gt('generic_table_selected_row_delete'),
      deleteAria: gt('generic_table_selected_row_delete_aria'),
    },
  }
}
}
export default tableLocalize;