import React, { useState, createContext } from 'react';

const InvoicesContext = createContext({});

const InvoicesProvider = (props) => {
  //States
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [invoiceList, setinvoiceList] = useState();

  return (
    <InvoicesContext.Provider value={{
      //States
      selectedInvoice,
      invoiceList,
      //UpdateStates
      setSelectedInvoice,
      setinvoiceList
      //Functions
    }}>
      {props.children}
    </InvoicesContext.Provider >
  );
};

export { InvoicesContext, InvoicesProvider };

