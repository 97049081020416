import React, {useContext, useEffect} from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import colors from '../styles/Theme-settings';
import PasswordFrom from '../components/loginForms/passwordForm';
import BankIDForm from '../components/loginForms/bankIDform';
import TwoStepAuth from '../components/loginForms/twoStepAuth';
import SithsForm from '../components/loginForms/sithsForm';
import classNames from 'classnames';
import { AuthContext } from '../context/authContext';
import { LocalizationContext } from '../context/localizationContext';

const Login = (props) => {
  const classes = useStyles();
  const { authType, setAuthType, loading, fetchLoginInfo, getAccess } = useContext(AuthContext)
  const { getTranslation, lang } = useContext(LocalizationContext)
  document.title = getTranslation('login_browser_title') + ' | Prodiagnostics';

  const matches = useMediaQuery('(max-width: 1024px)');

  useEffect(() => {
    // fetchLoginInfo()
    getAccess();
  },[])

  return (
    <Grid container>
      <Grid container className={classes.topGroup}>
        {/* Title */}
        <Grid className={classes.inputGrid} container >
          <Typography variant="h5" gutterBottom>{matches ? getTranslation('login_form_page_title_mobile') : getTranslation('login_form_page_title')}</Typography>
        </Grid>
      </Grid>
      {/* Form Holder */}
      <Grid container className={classes.formroup}>
        <Grid item xs={10} sm={6} style={{textAlign: "center"}}>
          {authType.type === 0 && <BankIDForm {...props} />}
          {(authType.type === 1 && authType.step === 0) && <PasswordFrom {...props} />}
          {(authType.type === 1 && authType.step === 1) && <TwoStepAuth {...props} />}
          {authType.type === 2 && authType.step === 0 && <SithsForm {...props}/>}
        </Grid>
        {authType.type === 1 &&
          <Grid item xs={12} style={{ textAlign: 'center', fontWeight: 'bold', textDecoration: 'none', color: '#333', marginTop: 20, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
            <Link style={{ textDecoration: 'none', color: '#333' }} to={`/recover/${lang === 'en_US' ? 'en' : 'se'}`}>{getTranslation('login_form_forgot_password')}</Link>
          </Grid>
        }
      </Grid>
      {/* Footer */}
      <Grid container className={classes.bottomGroup}>
        <Grid item xs={10} sm={8} className={classes.otherMethodsGroup}>
          <div style={{ height: 1, background: '#707070', marginBottom: -13 }} />
          <span style={{ background: colors.bgGray, padding: 15, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
            {getTranslation('login_form_other_methods')}
          </span>
        </Grid>
        <Grid item xs={10} sm={6} className={classes.buttonGroup}>
          {authType.type === 0 &&
            <Grid container
            direction="column"
            justify="space-between"
            alignItems="center"
            style={{height: "100px"}}
          >
             <Grid item xs={12} sm={6}>
              <Button
                className={classNames(classes.buttons, authType.type === 2 ? classes.activeButton : '')}
                variant="outlined"
                color="primary"
                onClick={() => { setAuthType({ ...authType, type: 2 }) }}
                disabled={loading}
              >{getTranslation('login_form_button_siths')}</Button>
            </Grid>
            <Grid className={classes.inputGrid} item xs={12} sm={6} >
              <Button
                className={classNames(classes.buttons, authType.type === 1 ? classes.activeButton : '')}
                variant="outlined"
                color="primary"
                onClick={() => { setAuthType({ ...authType, type: 1 }) }}
                disabled={loading}
              >{getTranslation('login_form_button_password')}</Button>
            </Grid>
            </Grid>
          }
          {authType.type === 1 &&
            <Grid container
            direction="column"
            justify="space-between"
            alignItems="center"
            style={{height: "100px"}}
          >
            <Grid item xs={12} sm={6}>
              <Button
                className={classNames(classes.buttons, authType.type === 2 ? classes.activeButton : '')}
                variant="outlined"
                color="primary"
                onClick={() => { setAuthType({ ...authType, type: 2 }) }}
                disabled={loading}
              >{getTranslation('login_form_button_siths')}</Button>
            </Grid>
              <Grid className={classes.inputGrid} item xs={12} sm={6}>
                <Button
                  className={classNames(classes.buttons, authType.type === 0 ? classes.activeButton : '')}
                  variant="outlined"
                  color="primary"
                  onClick={() => { setAuthType({ ...authType, type: 0 }) }}
                  disabled={loading}
                >{getTranslation('login_form_button_bankId')}</Button>
              </Grid>
            </Grid>
          }
          {authType.type === 2 &&
            <Grid container
              direction="column"
              justify="space-between"
              alignItems="center"
              style={{height: "100px"}}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  className={classNames(classes.buttons, authType.type === 0 ? classes.activeButton : '')}
                  variant="outlined"
                  color="primary"
                  onClick={() => { setAuthType({ ...authType, type: 0 }) }}
                  disabled={loading}
                >{getTranslation('login_form_button_bankId')}</Button>
              </Grid>
              <Grid className={classes.inputGrid} item xs={12} sm={6}>
                <Button
                  className={classNames(classes.buttons, authType.type === 1 ? classes.activeButton : '')}
                  variant="outlined"
                  color="primary"
                  onClick={() => { setAuthType({ ...authType, type: 1 }) }}
                  disabled={loading}
                >{getTranslation('login_form_button_password')}</Button>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}
const useStyles = makeStyles(theme => ({

  inputGrid: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: "center"
  },
  bottomGroup: {
    display: 'flex',
    justifyContent: 'center'
  },
  textInput: {
    width: '100%',
    padding: '10px 0px',
  },
  buttons: {
    width: '100%',
    maxHeight: 38,
  },
  activeButton: {
    backgroundColor: colors.halfPrimary
  },
  topGroup: { padding: '20px 0px' },
  formroup: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center'
  },
  otherMethodsGroup: {
    margin: '45px 0px',
    textAlign: 'center'
  }
}));
export default Login;
