import React, { useState, useContext, useEffect } from "react";
import { Grid, LinearProgress, Button, ButtonGroup } from "@material-ui/core";
import { LocalizationContext } from '../../context/localizationContext';
import { AuthContext } from '../../context/authContext';
import moment from 'moment';
import Table from '../Table/index';
import { FilterList, } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '../SeachDialog'
import SearchChips from '../SearchChips'

const SimpleTable = (props) => {
  const { loading } = useContext(AuthContext);
  const { getTranslation, lang } = useContext(LocalizationContext);

  moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sort, setSort] = useState({ column: 'customer_id', by: 'asc' })
  const [searchState, setSearchState] = useState(false)
  const [filter, setFilter] = useState({})
  const [data, setData] = useState([]);
  // const [nestedId, setNestedId] = useState(null);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSortChange = (column, order) => {
    setSort({ column: column, by: order })
  }
  const columns = [
    //customer_id
    {
      name: getTranslation('mvz_table_header_customer_id'),
      functionalName: 'customer_id',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //customer_name
    {
      name: getTranslation('mvz_table_header_customer'),
      functionalName: 'customer_name',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //lab_id
    {
      name: getTranslation('mvz_table_header_lab_id'),
      functionalName: 'lab_id',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //mvz_count
    {
      name: getTranslation('mvz_table_header_mvz_count'),
      functionalName: 'mvz_count',
      visible: true,
      align: 'left',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
    },
    //lab_count
    {
      name: getTranslation('mvz_table_header_lab_count'),
      functionalName: 'lab_count',
      visible: true,
      align: 'left',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
    },
    //diff
    {
      name: getTranslation('mvz_table_header_dif'),
      functionalName: 'diff',
      visible: true,
      align: 'left',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
    },
  ]
  const sortFunc = (a, b) => {
    let itemA, itemB;
    if (sort.column === 'customer_name') {
      itemA = a[sort.column].toUpperCase();
      itemB = b[sort.column].toUpperCase();
    } else if (sort.column === 'lab_id' || sort.column === 'diff') {
      itemA = Number(a[sort.column].name);
      itemB = Number(b[sort.column].name);
    } else {
      itemA = Number(a[sort.column]);
      itemB = Number(b[sort.column]);
    }

    let comparison = 0;
    if (itemA > itemB) {
      comparison = 1;
    } else if (itemA < itemB) {
      comparison = -1;
    }
    if (sort.by === 'asc') {
      return comparison;
    } else {
      return comparison * -1;
    }
  }
  // eslint-disable-next-line
  const fetchData = (e) => {
    let dt = props.data || [];
    let filteredData = [];
    let filterKeys = Object.keys(filter)
    if (filterKeys.length === 0) {
      filteredData = dt;
    } else {
      dt.forEach(item => {
        let isMatch = true;
        filterKeys.forEach(f => {
          // lab_count mvz_count diff
          if (f === 'lab_id') {
            if (item[f].name.search(filter[f]) === -1) {
              isMatch = false;
            }
          } else if (f === 'diff') {
            if (item[f].name !== filter[f]) {
              isMatch = false;
            }
          } else {
            if (f === 'lab_count' || f === 'mvz_count') {
              if (item[f] !== filter[f]) {
                isMatch = false;
              }
            } else {
              if (item[f].search(filter[f]) === -1) {
                isMatch = false;
              }
            }
          }
        })
        if (isMatch) {
          filteredData.push(item);
        }
      })
    }
    let from = page * rowsPerPage;
    let to = ((page + 1) * rowsPerPage) - 1;
    filteredData = filteredData.sort(sortFunc);
    setCount(filteredData.length);
    setData(filteredData.slice(from, to));
  }
  const clearFilter = () => {
    setFilter({});
  }
  // eslint-disable-next-line
  useEffect(() => fetchData(), [filter, sort, page, rowsPerPage])
  // eslint-disable-next-line
  useEffect(() => { if (page > 0) { setPage(0) } }, [filter])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ButtonGroup size="small" color="primary" className={classes.pageHeader}>
          <Button onClick={clearFilter} > {getTranslation('generic_clear_filter')} </Button>
          <Button startIcon={<FilterList />} onClick={() => setSearchState(true)} > {getTranslation('generic_button_filter')} </Button>
        </ButtonGroup>
        {Object.keys(filter).length > 0 &&
          <SearchChips
            filter={filter}
            setFilter={setFilter}
            columns={columns} />
        }
        {loading && <LinearProgress />}
        <Table
          rows={data}
          page={page}
          count={count}
          columns={columns}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortChange={handleSortChange}
          sort={sort}
        // onLineClick={'/invoices/'}
        />
        <Dialog
          filter={filter}
          setFilter={setFilter}
          open={searchState}
          onClose={setSearchState}
          columns={columns}
        />
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(theme => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  searchIcon: {
    height: 24,
    width: 24,
    cursor: 'pointer',
  }
}));
export default SimpleTable;
