const colorConfig = {
	primary: "#E41937",
  halfPrimary:'#FAD1D7',
  rgbaHalfPrimary:'rgba(228, 25, 55, 0.2)',
  secondary: "#000", //"#f4f4f4"
  white: "#fff",
  bgGray: "#f5f5f5",
  textGray:'#727272',
  green:'#28A745',
  
};
export default colorConfig;