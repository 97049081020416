import React, {useState, useEffect, useContext, createContext} from 'react';
import {useSnackbar} from 'notistack';
import APICall from '../functional/APIRequests';
import moment from 'moment';
import {OrgContext} from './orgContext';
import {LocalizationContext} from './localizationContext';
import axios from "axios";
import {apiServer} from "../functional/generateEnvUrls";

const AuthContext = createContext({});

const AuthProvider = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {selectedOrg} = useContext(OrgContext);
  const {getTranslation} = useContext(LocalizationContext)
  //States
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [perm, setPerm] = useState({options: [], acl: {}});
  const [bankIdCode, setBankIdCode] = useState(null);
  const [sithsCode, setSithsCode] = useState(null);
  const [sithsRedirectUrl, setSithsRedirectUrl] = useState();
  const [tempCustNr, setTempCustNr] = useState();
  const [authType, setAuthType] = useState({
    type: 0,
    step: 0,
    complete: false,
  });
  const [retryBA, setRetryBA] = useState(-1);
  const [phoneNumbersOpen, setPhoneNumberOpen] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState({});

  // let reftimer;

  //Functions
  const updateStore = () => {
    if (user) {
      localStorage.setItem(process.env.REACT_APP_GUID + 'ud', JSON.stringify(user));
      // if (user.status_id) { refreshTimer() }
    } else {
      localStorage.removeItem(process.env.REACT_APP_GUID + 'ud');
      localStorage.removeItem(process.env.REACT_APP_GUID + 'so');
      // clearInterval(reftimer);
    }
  };
  const rehydrate = (params) => {
    let lsUser = localStorage.getItem(process.env.REACT_APP_GUID + 'ud');
    if (!!lsUser) {
      setUser({...JSON.parse(lsUser)});
      setAuthType({
        type: 0,
        step: 0,
        complete: true,
      })
      getAccessLevel();
    }
  }

  const sithsLogin = () => {
    setLoading(true);
    axios({
      url: "https://login.portal.prodia.se/se/siths/init",
      method: "post",
      headers: {'Content-Type': 'application/json',}
    })
      .then(res => {
        setSithsCode(res.data.sessionId);
        setSithsRedirectUrl(res.data.redirectUrl);
        setLoading(false);
        enqueueSnackbar(getTranslation('auth_siths_submited_success'), {variant: "success"});
        // sithsIdCheck(res.sessionId).then(r => {});
      })
      .catch(err => {
        setLoading(false)
      })
    //  APICall({
    //   enqueueSnackbar,
    //   url: "siths/init",
    //   verb: "post",
    //   server: "login"
    // })
    // .then(res => {
    //   setSithsCode(res.sessionId);
    //   setSithsRedirectUrl(res.redirectUrl);
    //   setLoading(false);
    //   enqueueSnackbar(getTranslation('auth_siths_submited_success'), { variant: "success" });
    //   // sithsIdCheck(res.sessionId).then(r => {});
    // })
    // .catch(err => {
    //   setLoading(false)
    // })
  }

  // const sithsIdCheck = (code) => {
  //   return APICall({
  //     setLoading,
  //     enqueueSnackbar,
  //     url: `siths/poll?session=${code}`,
  //     verb: "get",
  //     server: "login",
  //     ignoreCatch: true
  //   })
  //     .then(res => {
  //       setAuthType({...authType, complete: true})
  //       getAccessLevel();
  //       setSithsCode(null);
  //       setLoading(false);
  //       return true;
  //     })
  //     .catch(err => setLoading(false))
  // }

  const login = (params) => {
    setLoading(true)
    APICall({
      enqueueSnackbar,
      url: 'password',
      verb: 'post',
      server: 'login',
      data: {
        email: params.email,
        password: params.password,
      },
    }).then((res) => {
      if (typeof res.data === 'object') {
        setPhoneNumbers(res.data);
        setPhoneNumberOpen(true);
      } else {
        setUser({...user, id: res.user_id, lastLogin: moment()});
        setAuthType({...authType, step: 1});
      }
      enqueueSnackbar(getTranslation('auth_loginData_submited_success'), {variant: "success"});
      setLoading(false);
    }).catch(err =>
      setLoading(false)
    )
  }
  const twofactor = (params) => {
    setLoading(true)
    return APICall({
      setLoading,
      enqueueSnackbar,
      url: 'twofactor',
      verb: 'post',
      server: 'login',
      data: {
        user_id: user.id,
        code: params.code,
      },
    }).then((res) => {
      setAuthType({...authType, complete: true})
      getAccessLevel();
      enqueueSnackbar(getTranslation('auth_twoFactor_submited_success'), {variant: "success"});
      return true;
    })
  }

  const twofactorPhoneToken = token => {
    setLoading(true);
    return APICall({
      setLoading,
      enqueueSnackbar,
      url: 'twofactor/phone-token',
      verb: 'post',
      server: 'login',
      data: {
        token
      }
    })
      .then(res => {
        setUser({...user, id: res.user_id, lastLogin: moment()});
        setAuthType({...authType, complete: true, step: 1});
        twofactor()
          .then((res) => {
            if (res) {
              props.history.push('/')
            }
          })
        return true;
      })
      .catch(() => setLoading(false))
  }

  const cancelBankIdCheck = (params) => {
    setRetryBA(990);
    let code = bankIdCode ? params.code : "";
    return APICall({
      setLoading,
      enqueueSnackbar,
      url: `/bankid/cancel?session=${code}`,
      verb: 'get',
      server: 'login',
      ignoreCatch: true,
    }).then((res) => {
      setLoading(false)
      return true;
    }).catch(err => {
      setRetryBA(999);
      setLoading(false)
    })
  }
  const bankIdAuth = (params) => {
    setLoading(true);
    return APICall({
      setLoading,
      enqueueSnackbar,
      url: '/bankid/init',
      verb: 'post',
      server: 'login',
      data: {
        pnr: params.bankid,
      },
    }).then((res) => {
      if (res.status === 400) {
        setLoading(false);
        setRetryBA(999);
      } else {
        setBankIdCode(res.sessionId)
        setRetryBA(0);
        enqueueSnackbar(getTranslation('auth_loginData_submited_success'), {variant: "success"});
        // bankIdCheck({ code: res.sessionId });
      }
    }).catch(err => {
      setRetryBA(999);
      setLoading(false)
    })
  }
  const bankIdCheck = (params) => {
    let code = bankIdCode || params.code;
    return APICall({
      setLoading,
      enqueueSnackbar,
      url: `bankid/poll?session=${code}`,
      verb: 'get',
      server: 'login',
      ignoreCatch: true,
    }).then((res) => {
      if (res.access && res.refresh) {
        setAuthType({...authType, complete: true})
        getAccessLevel();
        setBankIdCode(null)
        setLoading(false);
        return true;
      } else {
        checkForRetry()
      }
    }).catch(err => {
      checkForRetry()
    })
  }
  const checkForRetry = () => {
    setTimeout(() => {
      if (retryBA >= 200) {
        setLoading(false);
      } else {
        // retryBA += 1;
        setRetryBA(oldRBA => oldRBA + 1);
      }
    }, 1000);
  }
  useEffect(() => {
    if (retryBA >= 0 && retryBA <= 200) {
      bankIdCheck()
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [retryBA])

  // useEffect(() => {
  //   if (sithsCode) {
  //     sithsIdCheck(sithsCode);
  //   }
  // }, [sithsCode])

  const logout = (params) => {
    setLoading(true);
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: 'logout',
      verb: 'get',
      server: 'login',
    }).then((res) => {
      setUser(null);
      setLoading(false)
      setAuthType({
        type: 0,
        step: 0,
        complete: false,
      })
      localStorage.removeItem(process.env.REACT_APP_GUID + 'ud');
      localStorage.removeItem(process.env.REACT_APP_GUID + 'so');
      return true;
    })
  }
  const fetchUserInfo = (params) => {
    let orgUse;
    if (selectedOrg) {
      orgUse = selectedOrg.id;
    } else {
      let obj = perm.options.find(item => !!item.current_login_method_valid);
      if (obj) {
        orgUse = obj.id;
      }
    }
    if (!orgUse) {
      return false;
    } else {
      return APICall({
        setLoading,
        hasRefresh: true,
        enqueueSnackbar,
        url: `${String(orgUse) === '1' ? 'prodiagnostics' : 'customer'}/${orgUse}/profile`,
        verb: 'get',
        server: 'API',
      }).then((res) => {
        setUser({
          ...user,
          pnr: res.data.pnr,
          title: res.data.title,
          name: res.data.name,
          status_id: res.data.status_id,
          id: res.data.user_id,
          user_id: res.data.user_id,
          organisation_id: res.data.organisation_id,
          email: res.data.email,
          cellphone: res.data.cellphone,
          address: res.data.address
        });
        setLoading(false)
        return true;
      })
    }
  }

  const getAccess = () => {
    //let url = `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}`;
    let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}`;
    axios({
      url: `${url}/general/access-options`,
      verb: 'get',
    })
      .then(async (res) => {
        if (res.error === 1) {
        } else {
          setPerm(res.data.data)
          try {
            if (res.data.data.options.length === 1) {
              let opt = res.data.data.options[0];
              let cust = false;
              localStorage.setItem(process.env.REACT_APP_GUID + 'so', JSON.stringify({
                id: opt.id,
                custNr: false,
                type: opt.section === "prodiagnostics" ? 1 : 2,
                perm: (opt.superuser || opt.admin) ? 1 : 2,
              }));
              if (Number(opt.id) !== 1) {
                cust = await getOrganizationById({idOrg: opt.id})
              }
              localStorage.setItem(process.env.REACT_APP_GUID + 'so', JSON.stringify({
                id: opt.id,
                custNr: cust ? cust.customer_number : false,
                type: opt.section === "prodiagnostics" ? 1 : 2,
                perm: (opt.superuser || opt.admin) ? 1 : 2,
                mro: opt.mro ? opt.mro : false,
                patientmodule: cust.patientmodule ? Number(cust.patientmodule) : false
              }));
              setTempCustNr(cust ? cust.customer_number : false,)
            }
            setAuthType({...authType, complete: true})

          } catch (err) {
            console.log("error: " + err.message)
          }
          return true;
        }
        setLoading(false)
      })
      .catch(() => {})
  }


  const getAccessLevel = (params) => {
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: 'general/access-options',
      verb: 'get',
      server: 'API',
    }).then(async (res) => {
      if (res.error === 1) {
      } else {
        setPerm(res.data)
        try {
          if (res.data.options.length === 1) {
            let opt = res.data.options[0];
            let cust = false;
            localStorage.setItem(process.env.REACT_APP_GUID + 'so', JSON.stringify({
              id: opt.id,
              custNr: false,
              type: opt.section === "prodiagnostics" ? 1 : 2,
              perm: (opt.superuser || opt.admin) ? 1 : 2,
             }));
            if (Number(opt.id) !== 1) {
              cust = await getOrganizationById({idOrg: opt.id})
            }
            localStorage.setItem(process.env.REACT_APP_GUID + 'so', JSON.stringify({
              pro: cust ? cust.pro : false,
              workplace: cust ? cust.workplace : false,
              mro: opt.mro ? opt.mro : false,
              id: opt.id,
              custNr: cust ? cust.customer_number : false,
              type: opt.section === "prodiagnostics" ? 1 : 2,
              perm: (opt.superuser || opt.admin) ? 1 : 2,
              patientmodule: cust.patientmodule ? Number(cust.patientmodule) : false,
              consult: opt.consult ? opt.consult : false,
              admin: opt.admin ? opt.admin : false,
              customer: opt.customer ? opt.customer : false,
            }));
            setTempCustNr(cust ? cust.customer_number : false,)
          }

        } catch (err) {
          console.log(err.message)
        }
        return true;
      }
      setLoading(false)
    })
  }
  const changePassword = (params) => {
    setLoading(true)
    var form = new FormData();
    form.append("uid", user.user_id);
    form.append("password", params.password);
    return APICall({
      setLoading,
      enqueueSnackbar,
      url: 'password/update',
      verb: 'post',
      server: 'login',
      formData: true,
      data: form,
    }).then((res) => {
      setLoading(false)
      enqueueSnackbar(getTranslation('auth_loginData_submited_success'), {variant: "success"});
    })
  }
  const recoverPassword = (params) => {
    setLoading(true)
    var form = new FormData();
    form.append("email", params.email);
    return APICall({
      setLoading,
      enqueueSnackbar,
      url: 'password/forgot',
      verb: 'post',
      server: 'login',
      formData: true,
      data: form,
    }).then((res) => {
      setLoading(false)
      enqueueSnackbar(getTranslation('auth_loginData_submited_success_mail'), {variant: "success"});
      return res;
    })
  }
  const resetPassword = (params) => {
    setLoading(true)
    var form = new FormData();
    form.append("password", params.password);
    form.append("token", params.token);
    return APICall({
      setLoading,
      enqueueSnackbar,
      url: 'password/set',
      verb: 'post',
      server: 'login',
      formData: true,
      data: form,
    }).then((res) => {
      setLoading(false)
      enqueueSnackbar(getTranslation('auth_twoFactor_submited_success'), {variant: "success"});
      return res;
    })
  }
  const getOrganizationById = (params) => {
    setLoading(true);
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `customer/${params.idOrg}/customerprofile`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false);
      return res.data;
    })
  }

  const startHostedGrandId = () => {
    setLoading(true);
    APICall({
      setLoading,
      url: "bankid/startHostedGrandId",
      verb: "post",
      server: "login"
    })
        .then(res => {
          setLoading(false);
          if(res.data){
            window.location = res.data.redirectUrl;
          }
        })
        .catch(() => {
          setRetryBA(999);
          setLoading(false);
        })
  }

  //LifeCicles
  useEffect(() => {
    rehydrate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    updateStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    if (perm && perm.options && perm.options.length > 0) {
      fetchUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perm]);
  return (
    <AuthContext.Provider value={{
      //States
      user, perm, authType, loading, bankIdCode, retryBA, tempCustNr, sithsCode, sithsRedirectUrl, phoneNumbersOpen, phoneNumbers,
      //UpdateStates
      setUser, setAuthType, setLoading, sithsLogin,
      login, twofactor, logout, getAccessLevel, bankIdAuth, changePassword, recoverPassword, resetPassword,
      setRetryBA,
      setPhoneNumberOpen,
      //Functions
      cancelBankIdCheck, fetchUserInfo, getAccess, startHostedGrandId, bankIdCheck, setBankIdCode
    }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export {AuthContext, AuthProvider};
