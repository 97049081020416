import React from "react";

const NoPictureReferral = () => {
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
            <img src="/images/express_referral_fallback.png" width={930} alt="referral picture"/>
        </div>
    )
}

export default NoPictureReferral;