import React, {useState, useContext} from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination,
    Button,
    Tooltip, Checkbox,
} from "@material-ui/core";
import {
    ExpandLess,
    ExpandMore,
    NavigateNext,
    PictureAsPdf,
    Launch,
} from "@material-ui/icons";
import TablePaginationActions from "../../components/Table/pagination";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";
import {LocalizationContext} from "../../context/localizationContext";
import {Markup} from "interweave";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AnalysisNestedComponent from "../analysis/AnalysisNestedComponent";
import WarningIcon from "@material-ui/icons/Warning";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import moment from "moment";
import clsx from "clsx";
import colorConfig from "../../styles/Theme-settings";
import {apiServer} from "../../functional/generateEnvUrls";
import {OrgContext} from "../../context/orgContext";

const useStyles = makeStyles((theme) => ({
    forcePointer: {
        cursor: "pointer",
        // verticalAlign: 'middle',
    },
    centerText: {
        display: "flex",
        alignItems: "center",
    },
    disabledPointer: {
        cursor: "not-allowed",
    },
    displayNegativeFeedback: {
        background: "#FAD1D7",
    },
    infoSolutionsSynced: {
        padding: "4px 8px",
        display: "inline-flex",
        lineHeight: "2.2",
        color: "#28A745",
        verticalAlign: "bottom",
    },
    infoSolutionsSyncing: {
        padding: "4px 8px",
        display: "inline-flex",
        lineHeight: "2.2",
        color: "#B7B7B7",
        verticalAlign: "bottom",
    },
    reassignCheckboxIcon: {
        marginTop: 4,
        fill: colorConfig.textGray
    },
    analysesCell: {
        "& .MuiTableCell-sizeSmall": {
            padding: "6px !important"
        }
    }
}));
const TableComponent = (props) => {
    const classes = useStyles();
    const {selectedOrg} = useContext(OrgContext);
    // const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.rows.length - props.page * props.rowsPerPage);
    const tableCols = [];
    const [send, setSend] = useState(false);
    props.columns.forEach((col, ind) => {
        if (col.visible) {
            tableCols.push({
                ind: ind,
                fn: col.functionalName,
                displayRed: col.displayRed,
            });
        }
    });
    const history = useHistory();
    const {getTranslation} = useContext(LocalizationContext);

    const getHandledTooltip = (referral) => {
        if (referral.referral.handled_by && !referral.referral.archived_by) {
            return getTranslation("analysis_table_handled_tooltip")
                .replace(
                    "##date##",
                    moment.unix(referral.referral.handled_at).format("YYYY-MM-DD")
                )
                .replace("##username##", referral.user_handled.name);
        } else if (!referral.referral.handled_by && referral.referral.archived_by) {
            return getTranslation("analysis_table_archived_tooltip")
                .replace(
                    "##date##",
                    moment.unix(referral.referral.archived_at).format("YYYY-MM-DD")
                )
                .replace("##username##", referral.user_archived.name);
        } else {
            return (
                <>
                    <div>
                        {`${getTranslation("analysis_table_handled_tooltip")
                            .replace(
                                "##date##",
                                moment.unix(referral.referral.handled_at).format("YYYY-MM-DD")
                            )
                            .replace("##username##", referral.user_handled.name)}`}
                    </div>
                    <div>
                        {`${getTranslation("analysis_table_archived_tooltip")
                            .replace(
                                "##date##",
                                moment.unix(referral.referral.archived_at).format("YYYY-MM-DD")
                            )
                            .replace("##username##", referral.user_archived.name)}`}
                    </div>
                </>
            );
        }
    };

    const handlePdfClick = (e, analysisId) => {
        e.stopPropagation();
        let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/analysis/labreport?analysis_id=${analysisId}`;
        window.open(url, "_blank");
    }

    return (
        <TableContainer>
            <Table size="small">
                {!props.noHeader && (
                    <TableHead>
                        <TableRow>
                            {props.isNested && <TableCell/>}
                            {props.columns.map((col) => {
                                if (col.visible) {
                                    if (col.type === "check") {
                                        return (
                                            <TableCell
                                                key={`col_${col.functionalName}`}
                                                style={{
                                                    paddingBottom: 0,
                                                    paddingTop: 10,
                                                    cursor: "pointer",
                                                }}
                                                onClick={props.handleSelectAll}
                                            >
                                                {props.allChecked ? (
                                                    <CheckBoxIcon/>
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon/>
                                                )}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell
                                                className={
                                                    col.sortable
                                                        ? classes.forcePointer
                                                        : classes.disabledPointer
                                                }
                                                key={`col_${col.functionalName}`}
                                                align={col.align || "left"}
                                                style={col.style || {}}
                                                onClick={() => {
                                                    if (col.sortable) {
                                                        props.handleSortChange(
                                                            col.functionalName,
                                                            props.sort.column !== col.functionalName ||
                                                            (props.sort.column === col.functionalName &&
                                                                props.sort.by === "desc")
                                                                ? "asc"
                                                                : "desc"
                                                        );
                                                    }
                                                }}
                                            >
                                                <div className={classes.centerText}>
                                                    {col.name}
                                                    {!props.notSortable && (
                                                        <>
                                                            {props.sort.column === col.functionalName &&
                                                                props.sort.by === "desc" && (
                                                                    <ExpandMore color="secondary"/>
                                                                )}
                                                            {props.sort.column === col.functionalName &&
                                                                props.sort.by === "asc" && (
                                                                    <ExpandLess color="secondary"/>
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                            </TableCell>
                                        );
                                    }
                                } else {
                                    return <React.Fragment key={`col_${col.functionalName}`}/>;
                                }
                            })}
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {props.rows.map((row, i) => {
                        return (
                            <React.Fragment key={"bodyRow_" + i}>
                                <TableRow
                                    className={clsx({
                                        [classes.forcePointer]: (props.onLineClick || props.hasCheck || props.forcePointer),
                                        [classes.analysesCell]: props.analysisTable
                                    })
                                    }
                                    onClick={(e) => {
                                        if (props.hasCheck) {
                                            props.handleSelectLine(row.sys_id);
                                        } else if (
                                            props.isNested &&
                                            !props.specialPDFDownloadField
                                        ) {
                                            props.setNestedId(
                                                props.nestedId === row.sys_id ? null : row.sys_id
                                            );
                                        } else if (props.onLineClick) {
                                            history.push(
                                                props.onLineClick +
                                                (props.analysisTable
                                                    ? row.referral.sys_id
                                                    : row.sys_id
                                                        ? row.sys_id
                                                        : row.id)
                                            );
                                        } else if (props.onRowClick) {
                                            props.onRowClick(row);
                                        }
                                    }}
                                >
                                    {props.isNested && (
                                        <TableCell
                                            onClick={() => {
                                                if (!!props.specialPDFDownloadField) {
                                                    props.setNestedId(
                                                        props.nestedId === row.sys_id ? null : row.sys_id
                                                    );
                                                }
                                            }}
                                            width={18}
                                            className={classes.forcePointer}
                                            key={`col_expand`}
                                        >
                                            {!(props.nestedId === row.sys_id) && (
                                                <NavigateNext color="secondary"/>
                                            )}
                                            {props.nestedId === row.sys_id && (
                                                <ExpandMore color="secondary"/>
                                            )}
                                        </TableCell>
                                    )}
                                    {tableCols.map((col, ind) => {
                                        if (col.fn === "check") {
                                            return (
                                                <TableCell key={"check" + ind}>
                                                    {row[col.fn] ? (
                                                        <CheckBoxIcon/>
                                                    ) : (
                                                        <CheckBoxOutlineBlankIcon/>
                                                    )}
                                                </TableCell>
                                            );
                                        } else if (col.fn === "referral.is_positive") {
                                            return (
                                                <TableCell key={"referral" + ind}>
                                                    {row.referral.is_positive === "1" && (
                                                        <Tooltip
                                                            title={getTranslation(
                                                                "analysis_table_positive_tooltip"
                                                            )}
                                                        >
                                                            <ReceiptIcon
                                                                style={{
                                                                    fill: "#f44336",
                                                                    margin: "0px 5px",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    {row.referral.warranty === "1" && (
                                                        <Tooltip
                                                            title={getTranslation(
                                                                "analysis_table_warranty_tooltip"
                                                            )}
                                                        >
                                                            <WarningIcon
                                                                style={{fill: "#ff9800", margin: "0px 5px"}}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            );
                                        } else if (
                                            col.fn === "referral.infosolutions_export_date"
                                        ) {
                                            return (
                                                <TableCell key={"info" + ind}>
                                                    {row.referral.infosolutions_export_date && (
                                                        <Tooltip
                                                            title={`${getTranslation(
                                                                "analysis_table_infosolutions_synced_tooltip"
                                                            )} ${moment
                                                                .unix(row.referral.infosolutions_export_date)
                                                                .format("DD-MM-YYYY")}`}
                                                        >
                                                            <ExitToAppIcon
                                                                style={{
                                                                    fill: "#28A745",
                                                                    margin: "0px 5px",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            );
                                        } else if (col.fn === "referral.test_method_id") {
                                            let displayData = row.referral.test_method_id;
                                            let quantity = row.referral.analysis;
                                            return (
                                                <TableCell key={"test_method_id" + ind}>
                                                    <Markup
                                                        content={
                                                            quantity
                                                                ? `${displayData} (${quantity})`
                                                                : displayData || ""
                                                        }
                                                    />
                                                </TableCell>
                                            );
                                        } else if (col.fn === "referral.handled") {
                                            if (row.referral.handled_by || row.referral.archived_by) {
                                                return (
                                                    <TableCell key={`row_${ind}`}>
                                                        <Tooltip title={getHandledTooltip(row)}>
                                                            <PeopleOutlineRoundedIcon
                                                                style={{margin: "0px 5px"}}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                );
                                            } else {
                                                return <TableCell></TableCell>;
                                            }
                                        } else if (col.fn === "organisation.id") {
                                            return (
                                                <TableCell key={`row_${ind}`} onClick={e => {
                                                    e.stopPropagation();
                                                    props.handleCheckboxClick(row.referral.referral_id);
                                                }}
                                                >
                                                    {props.checkedRows.includes(row.referral.referral_id) ?
                                                        <CheckBoxIcon className={classes.reassignCheckboxIcon}/>
                                                        :
                                                        <CheckBoxOutlineBlankIcon
                                                            className={classes.reassignCheckboxIcon}/>
                                                    }
                                                </TableCell>
                                            )

                                        } else if (col.fn === "send_digital") {
                                            return (
                                                <TableCell>
                                                    {(row.send_digital && row.send_digital === "1") ? getTranslation("generic_filter_yes") : getTranslation("generic_filter_no")}
                                                </TableCell>
                                            )
                                        } else if (col.fn === "hsaid") {
                                            return (
                                                <TableCell>
                                                    {(row.hsaid && row.send_digital !== "") ? getTranslation("generic_filter_yes") : getTranslation("generic_filter_no")}
                                                </TableCell>
                                            )
                                        } else if (col.fn === "referral.sys_id") {
                                            if (row.referral.status_id.id === "3") {
                                                return (
                                                    <TableCell align={props.columns[col.ind].align || "left"}
                                                               onClick={e => handlePdfClick(e, row.referral.test_result_id)}>
                                                        <img src="/images/pdf_icon_black.svg" alt="pdf icon" width={18}
                                                             height={18}/>
                                                    </TableCell>
                                                )
                                            } else {
                                                return (
                                                    <TableCell>
                                                    </TableCell>
                                                )
                                            }
                                        } else {
                                            let displayData = props.analysisTable
                                                ? row[col.fn.split(".")[0]][col.fn.split(".")[1]]
                                                : row[col.fn];
                                            return (
                                                <TableCell
                                                    onClick={() => {
                                                        if (!!props.specialPDFDownloadField) {
                                                            props.setNestedId(
                                                                props.nestedId === row.sys_id
                                                                    ? null
                                                                    : row.sys_id
                                                            );
                                                        }
                                                    }}
                                                    key={`row_${ind}`}
                                                    style={
                                                        props.noHeader
                                                            ? {
                                                                fontWeight: "bold",
                                                                cursor: !!props.specialPDFDownloadField
                                                                    ? "pointer"
                                                                    : "default",
                                                            }
                                                            : {}
                                                    }
                                                    className={
                                                        col.displayRed && row.displayRed
                                                            ? classes.displayNegativeFeedback
                                                            : ""
                                                    }
                                                    align={props.columns[col.ind].align || "left"}
                                                    component={ind === 0 ? "th" : ""}
                                                    scope="row"
                                                >
                                                    {typeof displayData === "object" && !!displayData && (
                                                        <div style={{display: "flex"}}>
                                                            {displayData.color && (
                                                                <FiberManualRecordIcon
                                                                    style={{
                                                                        fill: displayData.color,
                                                                        marginRight: 5,
                                                                    }}
                                                                />
                                                            )}
                                                            <Markup content={displayData.name || ""}/>
                                                            {displayData.icon && (
                                                                <Launch
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        marginLeft: 5,
                                                                        fill: "#222",
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        window.open(displayData.url, "_blank");
                                                                    }}
                                                                />
                                                            )}
                                                            {props.analysisTable &&
                                                                <>
                                                                    {
                                                                        row.referral.send_digital === "1" ||
                                                                        row.referral.send_digital === 1 ? (
                                                                            <Tooltip
                                                                                title={
                                                                                    row.referral.mvz_export_date &&
                                                                                    row.referral.mvz_export_date !== 0 &&
                                                                                    row.referral.mvz_export_date !== "0"
                                                                                        ? getTranslation(
                                                                                            "analysis_table_export_date_with_date_tooltip"
                                                                                        ).replace(
                                                                                            "##date##",
                                                                                            moment
                                                                                                .unix(row.referral.mvz_export_date)
                                                                                                .format("YYYY-MM-DD HH:mm")
                                                                                        )
                                                                                        : getTranslation(
                                                                                            "analysis_table_export_date_no_date_tooltip"
                                                                                        )
                                                                                }
                                                                            >
                                                                                <CloudUploadIcon
                                                                                    style={{
                                                                                        fill: "#727272",
                                                                                        margin: "0px 0px 0px 7px",
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    )}
                                                    {!(typeof displayData === "object") && (
                                                        <Markup content={displayData || ""}/>
                                                    )}
                                                </TableCell>
                                            );
                                        }
                                    })}
                                    {/* {prosp.isNested} */}
                                    {(!!props.specialPDFDownloadField ||
                                        (props.infoSolutions && props.infoSolutions.active)) && (
                                        <TableCell align="right">
                                            {props.infoSolutions &&
                                                props.infoSolutions.active &&
                                                !!row[props.infoSolutions.send] &&
                                                !!row[props.infoSolutions.date] && (
                                                    <div className={classes.infoSolutionsSynced}>
                                                        <ExitToAppIcon
                                                            style={{
                                                                fill: "#28A745",
                                                                marginRight: 8,
                                                                marginTop: 6,
                                                            }}
                                                        />
                                                        {`${getTranslation(
                                                            "analysis_detail_cart_infosolutions_synced"
                                                        )} ${moment
                                                            .unix(row[props.infoSolutions.date])
                                                            .format("DD-MM-YYYY")}`}
                                                    </div>
                                                )}
                                            {props.infoSolutions &&
                                                props.infoSolutions.active &&
                                                (send ||
                                                    (!!row[props.infoSolutions.send] &&
                                                        !row[props.infoSolutions.date])) && (
                                                    <div className={classes.infoSolutionsSyncing}>
                                                        <ExitToAppIcon
                                                            style={{
                                                                fill: "#B7B7B7",
                                                                marginRight: 8,
                                                                marginTop: 6,
                                                            }}
                                                        />
                                                        {`${getTranslation(
                                                            "analysis_detail_cart_infosolutions_processing"
                                                        )}`}
                                                    </div>
                                                )}
                                            {props.infoSolutions &&
                                                props.infoSolutions.active &&
                                                !send &&
                                                !row[props.infoSolutions.send] && (
                                                    <Button
                                                        color="secondary"
                                                        startIcon={
                                                            <ExitToAppIcon style={{fill: "#222222"}}/>
                                                        }
                                                        onClick={(e) => {
                                                            setSend(true);
                                                            props.infoSolutions.callBack(row.sys_id);
                                                        }}
                                                    >
                                                        {getTranslation(
                                                            "analysis_detail_cart_button_infosolutions_sync"
                                                        )}
                                                    </Button>
                                                )}
                                            {!!props.specialPDFDownloadField && (
                                                <Button
                                                    color="secondary"
                                                    startIcon={
                                                        <PictureAsPdf style={{fill: "#222222"}}/>
                                                    }
                                                    onClick={(e) => {
                                                        props.specialPDFDownloadField(row.sys_id);
                                                    }}
                                                >
                                                    {getTranslation(
                                                        "analysis_detail_cart_button_lab_report"
                                                    )}
                                                </Button>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                                {props.isNested &&
                                    props.nestedId === row.sys_id &&
                                    props.nestedAnalysisComponent && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={
                                                    props.columns.length + (props.isNested ? 1 : 0)
                                                }
                                            >
                                                <AnalysisNestedComponent
                                                    id={props.nestedId}
                                                    testMethod={props.testMethod}
                                                    rowData={props.rows.find(
                                                        (item) => item.sys_id === props.nestedId
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                {props.isNested &&
                                    props.nestedId === row.sys_id &&
                                    !props.nestedAnalysisComponent && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={
                                                    props.columns.length + (props.isNested ? 1 : 0)
                                                }
                                            >
                                                {props.nestedTable}
                                            </TableCell>
                                        </TableRow>
                                    )}
                            </React.Fragment>
                        );
                    })}
                    {props.rows.length === 0 && (
                        <TableRow>
                            <TableCell
                                align="center"
                                colSpan={props.columns.length + (props.isNested ? 1 : 0)}
                            >
                                {getTranslation("generic_table_body_no_match")}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                {!props.hideControls && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                                colSpan={props.columns.length + (props.isNested ? 1 : 0)}
                                count={props.count}
                                rowsPerPage={props.rowsPerPage}
                                page={props.page}
                                labelRowsPerPage={getTranslation(
                                    "generic_table_pagination_rows_per_page"
                                )}
                                labelDisplayedRows={({from, to, count}) =>
                                    getTranslation("generic_table_pagination_totals")
                                        .replace("##from##", from)
                                        .replace("##to##", to)
                                        .replace("##count##", count)
                                }
                                SelectProps={{
                                    inputProps: {"aria-label": "rows per page"},
                                    native: true,
                                }}
                                onPageChange={props.handleChangePage}
                                onRowsPerPageChange={props.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    );
};
export default TableComponent;
