import React from "react";
import {
  Grid,
  Paper,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import LabelBox from "../../components/LabeledBox";
import InvoicesDetailTable from "../../components/invoices/InvoicesDetailTable";
import { LocalizationContext } from '../../context/localizationContext';

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  grid: {
    maxWidth: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  divider: {
    width: "80%"
  },
  cartButton: {
    width: '100%',
    marginBottom: 10
  }
}));
const OrganizationPage = props => {
  const classes = useStyles();
  const { getTranslation } = React.useContext(LocalizationContext);
  document.title = getTranslation('invoices_detail_browser_title') + ' | Prodiagnostics';

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <LabelBox hasCancel={true} label={getTranslation('invoices_detail_box_title')}>
                  <Grid container spacing={6} xs={12}>
                    <Grid item xs={6}>
                      <div>
                        <b>{getTranslation('invoices_detail_box_customer_label')} </b>23212545862
                      </div>
                      <div>
                        <b>{getTranslation('invoices_detail_box_date_label')} </b>191204
                      </div>
                      <div>
                        <b>{getTranslation('invoices_detail_box_perioud_label')} </b>191206
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div>
                        <b>{getTranslation('invoices_detail_box_quantity_label')}  </b>123456
                      </div>
                      <div>
                        <b>{getTranslation('generic_table_filter_status')} </b>Active
                      </div>
                    </Grid>
                  </Grid>
              </LabelBox>
            </Grid>
          </Grid>
          <InvoicesDetailTable {...props} />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default OrganizationPage;
