import React from "react";
import colors from "../styles/Theme-settings";
import { Fab, withWidth, Hidden, Button } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { LocalizationContext } from '../context/localizationContext';
import { withRouter } from "react-router";
const LabeledBox = props => {
  const { getTranslation } = React.useContext(LocalizationContext);
  let hasActionArea = props.hasCancel || props.hasBack || props.delFunc || props.continueFunc || props.editFunc || props.previewFunc || props.printFunc;
  const styles = {
    boxDiv: {
      margin: hasActionArea ? '26px 0px' : "10px 0px",
      paddingTop: props.padding + 3 || 13,
      paddingBottom: hasActionArea ? 20 : (props.padding || 10),
      paddingLeft: props.padding || 10,
      paddingRight: props.padding || 10,
      width: props.forceWidth || "100%",
      border: props.border || `1px solid ${colors.primary}`,
      height: props.height || "auto",
      borderRadius: 5
    },
    labelTitle: {
      position: "absolute",
      marginTop: -24,
      marginLeft: props.marginLeft - 10 || 5,
      background: "white",
      padding: "0 5px"
    },
    actionsArea: {
      // marginTop: -10,
      float: "right",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end"
    },
    buttonStyle: {
      marginLeft: 7,
      backgroundColor: 'white',
      border: '1px solid red'
    },
  };
  return (
    <div style={styles.boxDiv}>
      {props.label && (
        < b >
          <span style={styles.labelTitle}>{props.label} </span>
        </b>
      )
      }
      {props.children}
      <div style={styles.actionsArea}>
        <Hidden smUp>
          {props.hasCancel && (
            <Fab
              style={styles.buttonStyle}
              size="small"
              color="secondary"
              aria-label="edit"
              onClick={props.hasCancel ? () => props.history.goBack() : false}
            >
              <ClearIcon />
            </Fab>
          )}
          {props.hasBack && (
            <Fab
              style={styles.buttonStyle}
              size="small"
              color="secondary"
              aria-label="edit"
              onClick={props.hasBack ? () => props.history.goBack() : false}
            >
              <ClearIcon />
            </Fab>
          )}
          {props.stepBackFunc && (
            <Fab
              style={styles.buttonStyle}
              size="small"
              color="secondary"
              aria-label="edit"
              onClick={props.stepBackFunc ? () => props.stepBackFunc() : false}
            >
              <ArrowBackIosIcon />
            </Fab>
          )}
          {props.delFunc && (
            <Fab
              style={styles.buttonStyle}
              size="small"
              color="secondary"
              aria-label="edit"
              onClick={props.delFunc ? () => props.delFunc() : false}
            >
              <DeleteIcon />
            </Fab>
          )}
          {props.continueFunc && (
            <Fab
              style={styles.buttonStyle}
              size="small"
              color="primary"
              aria-label="add"
              onClick={props.continueFunc ? () => props.continueFunc() : false}
            >
              <ArrowForwardIosIcon />
            </Fab>
          )}
          {props.editFunc && (
            <Fab
              style={styles.buttonStyle}
              size="small"
              color="primary"
              aria-label="add"
              onClick={props.editFunc ? () => props.editFunc() : false}
            >
              <SaveIcon />
            </Fab>
          )}
          {props.previewFunc && (
            <Fab
              style={styles.buttonStyle}
              size="small"
              color="primary"
              aria-label="add"
              onClick={props.previewFunc ? () => props.previewFunc() : false}
            >
              <VisibilityIcon />
            </Fab>
          )}
          {props.printFunc && (
            <Fab
              style={styles.buttonStyle}
              size="small"
              color="primary"
              aria-label="add"
              onClick={props.printFunc ? () => props.printFunc() : false}
            >
              <PrintIcon />
            </Fab>
          )}
          {props.completeFunc && (
            <Fab
              style={styles.buttonStyle}
              size="small"
              color="primary"
              aria-label="add"
              onClick={props.completeFunc ? () => props.completeFunc() : false}
            >
              <ArrowForwardIosIcon />
            </Fab>
          )}
        </Hidden>
        <Hidden xsDown>
          {props.hasCancel && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.hasCancel ? () => props.history.goBack() : false}
              startIcon={<ClearIcon />}
            >{getTranslation('generic_button_cancel')}</Button>
          )}
          {props.hasBack && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.hasBack ? () => props.history.goBack() : false}
              startIcon={<ClearIcon />}
            >{getTranslation('generic_button_back')}</Button>
          )}
          {props.stepBackFunc && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.stepBackFunc ? () => props.stepBackFunc() : false}
              startIcon={<ArrowBackIosIcon />}
            >{getTranslation('generic_button_back')}</Button>
          )}
          {props.delFunc && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.delFunc ? () => props.delFunc() : false}
              startIcon={<DeleteIcon />}
            >{getTranslation('generic_button_delete')}</Button>
          )}
          {props.editFunc && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.editFunc ? () => props.editFunc() : false}
              startIcon={<SaveIcon />}
            >{getTranslation('generic_button_save')}</Button>
          )}
          {props.continueFunc && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.continueFunc ? () => props.continueFunc() : false}
              startIcon={<ArrowForwardIosIcon />}
            >{getTranslation('generic_button_continue')}</Button>
          )}
          {props.previewFunc && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.previewFunc ? () => props.previewFunc() : false}
              startIcon={<VisibilityIcon />}
            >{getTranslation('generic_button_preview')}</Button>
          )}
          {props.printFunc && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.printFunc ? () => props.printFunc() : false}
              startIcon={<PrintIcon />}
            >{getTranslation('generic_button_print')}</Button>
          )}
          {props.completeFunc && (
            <Button
              variant="contained"
              color="primary"
              onClick={props.completeFunc ? () => props.completeFunc() : false}
              startIcon={<ArrowForwardIosIcon />}
            >{getTranslation('generic_button_complete')}</Button>
          )}
        </Hidden>

      </div>
    </div >
  );
};
const withWithProp = withWidth()(LabeledBox);
export default withRouter(withWithProp);
