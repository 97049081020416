import React from "react";
import { Grid, Paper, Button, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LabelBox from "../../components/LabeledBox";
import moment from 'moment';
import { DateTimePicker, } from "@material-ui/pickers";
import { LocalizationContext } from '../../context/localizationContext';

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  grid: {
    maxWidth: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  divider: {
    width: "80%"
  },
  cartButton: {
    width: '100%'
  }
}));

const Invoices = props => {
  const { getTranslation } = React.useContext(LocalizationContext);
  document.title = getTranslation('mvz_export_browser_title') + ' | Prodiagnostics';

  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={10}>
        <Paper className={classes.paper}>
          <Grid container className={classes.container}>
          <LabelBox hasCancel={true} label="Comparison MVZ Specification:">
              <form noValidate autoComplete="off">
                <Grid container xs={12}>
                  <DateTimePicker
                    margin="normal"
                    format="YYYY-MM-DD"
                    label="From"
                    value={moment().format('YYYY-MM-DD')}
                    // onChange={(e) => handleDateChange(e, 'date')}
                    error={false}
                  />
                  <DateTimePicker
                    margin="normal"
                    format="YYYY-MM-DD"
                    label="To"
                    value={moment().format('YYYY-MM-DD')}
                    // onChange={(e) => handleDateChange(e, 'date')}
                    error={false}
                  />
                  <Button
                    className={classes.cartButton}
                    variant="contained"
                    color="primary"
                    // disabled={data.id.length < 10}
                    // onClick={() => { }}
                  >
                    {getTranslation('button_exportComparationFile')}
                  </Button>
                </Grid>
              </form>
            </LabelBox>
          </Grid>
        </Paper>
      </Grid>
    </Grid >
  );
};
export default Invoices;
