import React, {useContext} from 'react';
import {Grid, Paper, Button, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
// import colors from '../styles/Theme-settings';
import classNames from 'classnames';
import {AuthContext} from '../context/authContext'
import {LocalizationContext} from '../context/localizationContext';

const NotFound = (props) => {
  const classes = useStyles();
  const {authType} = useContext(AuthContext)
  const {getTranslation} = useContext(LocalizationContext);

  return (
    <Grid container justify="center" className={classes.wrapper}>
      <Paper className={classes.paper}>
        <div className={classes.contentWrapper}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>{getTranslation("generic_error_label")}</Typography>
          </Grid>
          <Grid className={classes.textAlignCenter} item xs={12}>
            <Typography variant="subtitle1" style={{margin: '15px 0 20px 0'}}>{getTranslation("error_page_text")}</Typography>
          </Grid>
          <Grid className={classes.textAlignCenter} item xs={12}>
            <Button
              className={classNames(classes.buttons, authType.type === 1 ? classes.activeButton : '')}
              variant="contained"
              color="primary"
              onClick={() => {
                props.history.goBack();
              }}
            >
              {getTranslation('404_back')}
            </Button>
          </Grid>
        </div>
      </Paper>
    </Grid>
  )
}
const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: 20
  },
  paper: {
    width: "50%",
    height: 200,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 250
    }
  },
  contentWrapper: {
    textAlign: "center",
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '0px 32px'
  },
  textAlignCenter: {
    textAlign: 'center'
  },

  root: {
    backgroundColor: 'blue',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'red',
    },
  },
}));
export default NotFound;
