import React, { useContext, useState } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../context/authContext';
import { LocalizationContext } from '../../context/localizationContext';
import { emailRegex } from '../../functional/etc';
const useStyles = makeStyles(theme => ({
  inputGrid: {
    display: 'flex'
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center'
  },
  textInput: {
    width: '100%',
    padding: '10px 0px',
  },
  formWidth: {
    width: '100%',
  }
}));
const PasswordForm = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({ email: '', password: '' });
  const { login, loading } = useContext(AuthContext);
  const { getTranslation } = useContext(LocalizationContext)
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const formValidator = () => {
    let error = [];
    Object.keys(data).forEach((key) => {
      var value = data[key];
      switch (key) {
        case 'email':
          if (!value || value.length === 0 || !emailRegex.test(data.email)) {
            error.push('email')
          }
          break;
        case 'password':
          if (!value || value.length === 0) {
            error.push('password')
          }
          break;
        default:
          return true;
      }
    });
    return error.length === 0;
  }
  const submitData = (e) => {
    try { e.preventDefault() } catch (err) { };
    if (formValidator()) {
      login(data)
    }
  }
  return (
    <form onSubmit={submitData} className={classes.formWidth}>
      <Grid className={classes.inputGrid} item xs={12}>
        <TextField
          error={data.email.length > 0 && !emailRegex.test(data.email)}
          className={classes.textInput}
          onChange={handleChange}
          label={getTranslation('login_form_placeholder_email')}
          type='email'
          name='email'
          helperText={data.email.length > 0 && !emailRegex.test(data.email) ? getTranslation('generic_email_regex_fail') : ''}
          disabled={loading}
        />
      </Grid>
      <Grid className={classes.inputGrid} item xs={12}>
        <TextField
          className={classes.textInput}
          onChange={handleChange}
          label={getTranslation('login_form_placeholder_password')}
          type='password'
          name='password'
          disabled={loading}
        />
      </Grid>
      <Grid className={classes.buttonGrid} item xs={12}>
        <Button disabled={loading} type='submit' className={classes.buttons} variant="contained" color="primary">
          {getTranslation('login_form_button_submit')}
        </Button>
      </Grid>
    </form>
  )
};
export default PasswordForm;