import axios from 'axios';
import {apiServer, loginServer} from "./generateEnvUrls";
axios.defaults.withCredentials = true; //this is required for axios to be able to send auth cookies with request
const RefreshToken = async (params) => {
	return axios({
		//url: `${process.env.REACT_APP_PROD_LOGIN}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/refresh`,
		url: `${loginServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/refresh`,
		method: 'post',
		data: { section: 'prodiagnostics', organisation_id: 1 },
		headers: { 'Content-Type': 'application/json', }
	}).then(async response => {
		return await APICall(params, false)
	}).catch(error => {
		if (!error.response) {
			params.enqueueSnackbar('Server temporary unavailable', { variant: 'error' });
			sendToLogin();
		} else {
			if (error.response && error.response.status === 401) {
				sendToLogin();
			} else {
				let apiResponse = error.response.status + ' - ' + error.response.data.message;
				params.enqueueSnackbar(apiResponse, { variant: 'error' });
			}
		}
	})
}
const APICall = (params, firstTime = true) => {
	// if (params.hasRefresh && !RefreshToken(params)) {
	// 	if (params.setLoading) { params.setLoading(false) }
	// } else {
	// 	if (params.tokenRefresh) {
	// 		return true;
	// 	} else {
	//let url = `${params.server === 'login' ? process.env.REACT_APP_PROD_LOGIN : process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/${params.url}`;
	let url = `${params.server === 'login' ? loginServer : apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/${params.url}`;
	if (params.server === 'localhost') {
		url = `/${params.url}`
	}
	return axios({
		url: url,
		onUploadProgress: params.onUploadProgress,
		method: params.verb || 'get',
		data: params.data || null,
		headers: { 'Content-Type': params.formData ? 'multipart/form-data' : 'application/json', }
	}).then(response => {
		return response.data;
	}).catch(async error => {
		console.log(error);
		if (firstTime
			// && error.response.data.message === "Invalid authentication credentials"
		) {
			return await RefreshToken(params)
		} else {
			if (!!error.response) {
				if (params.setLoading) { params.setLoading(false) }
				if (params.ignoreCatch) {
					return true;
				}
				if (error.response && error.response.status === 400) {
					return error.response;
				} else if (error.response.status === 401) {
					if(params.enqueueSnackbar)params.enqueueSnackbar(error.response.data.message || '', { variant: 'error' });
					sendToLogin();
					throw error;
					// } else if (error.response.status > 499) {
					// 	return error.response;
				} else {
					if(params.enqueueSnackbar)params.enqueueSnackbar(error.response.data.message || '', { variant: 'error' });
					throw error;
				}
			} else {
				throw error;
			}
		}
	})
}
// }

const sendToLogin = () => {
	localStorage.removeItem(process.env.REACT_APP_GUID + 'ud');
	window.location.pathname = '/login';
}
export default APICall;
