import React, {useState, useEffect, useContext, useCallback} from "react";
import {
  Grid,
  LinearProgress,
  Button,
  ButtonGroup,
  Typography, Hidden, TextField,
} from "@material-ui/core";
import {LocalizationContext} from "../../context/localizationContext";
import {OrgContext} from "../../context/orgContext";
import {AuthContext} from "../../context/authContext";
import APICall from "../../functional/APIRequests";
import {useSnackbar} from "notistack";
import moment from "moment";
import Table from "../Table/index";
import {FilterList} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "../SeachDialog";
import SearchChips from "../SearchChips";
import clsx from "clsx";
import {Autocomplete} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  searchIcon: {
    height: 24,
    width: 24,
    cursor: "pointer",
  },
  headerButtons: {
    position: "static",
  },
  handledBtn: {
    marginLeft: 15,
  },
  seeAllBtn: {
    margin: "0px 15px",
  },
  handledFilterBtns: {
    fontSize: 13,
    fontWeight: 500,
    cursor: "pointer",
  },
  underlinedHandledBtn: {
    textDecoration: "underline",
  },
}));

const Analysis = props => {
  const {enqueueSnackbar} = useSnackbar();
  const {selectedOrg} = useContext(OrgContext);
  const {setLoading, loading} = useContext(AuthContext);
  const {getTranslation, lang} = useContext(LocalizationContext);
  moment.locale(lang === "sv_SE" ? "sv" : "en-gb");
  const classes = useStyles();

  const {
    checkedReferrals,
    setCheckedReferrals,
    setPage,
    setRowsPerPage,
    setSort,
    generetaQueryString,
    setFilter,
    setSelectedHandleFilters,
    filter,
    sort,
    page,
    rowsPerPage,
    fetchData,
    selectedHandledFilters,
    data,
    methods,
    status,
    setTotalRecords,
    setStatus,
    setMethods,
    setCount,
    count,
    totalRecords
  } = props;

  const [searchState, setSearchState] = useState(false);

  const [enterPressed, setEnterPressed] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSortChange = (column, order) => {
    setSort({column: column, by: order});
  };

  const columns = [
    //Move referral checkbox
    {
      id: 13,
      name: "",
      functionalName: "organisation.id",
      type: "text",
      filter: false,
      sortable: false,
      visible: selectedOrg.id === "1",
      //align: "center"
    },
    //Referal Id
    {
      name: "",
      functionalName: "referral.sys_id",
      filter: false,
      sortable: false,
      visible: false,
    },
    //Referal Id
    {
      name: getTranslation("analysis_list_table_header_id"),
      functionalName: "referral.referral_id",
      type: "text",
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: "left",
    },
    //Customer number
    {
      name: getTranslation("analysis_list_table_header_customer_number"),
      functionalName: "organisation.customer_number",
      type: "text",
      filter: selectedOrg.id === "1",
      sortable: true,
      visible: selectedOrg.id === "1",
    },
    //Org Id
    {
      name:
        selectedOrg.id === "1"
          ? getTranslation("analysis_list_table_header_client")
          : getTranslation("analysis_list_table_header_user"),
      functionalName:
        selectedOrg.id === "1" ? "organisation.name" : "user.name",
      type: "text",
      filter: true,
      filterOptions: {
        col: 6,
      },
      // interval: false,
      sortable: true,
      visible: true,
      align: "left",
    },
    //Test Time
    {
      name: getTranslation("analysis_list_table_header_sample_date"),
      functionalName: "referral.test_date",
      type: "date",
      filter: true,
      filterOptions: {
        col: 12,
      },
      intervalFunctionName: [
        {
          name: getTranslation("generic_table_filter_start_date"),
          functionalName: "referral.test_date_start",
          col: 6,
        },
        {
          name: getTranslation("generic_table_filter_end_date"),
          functionalName: "referral.test_date_end",
          col: 6,
        },
      ],
      interval: true,
      sortable: true,
      visible: true,
      align: "left",
    },
    // //Reg Date
    // {
    //   name: getTranslation('analysis_list_table_header_reg_date'),
    //   functionalName: 'referral.registration_date',
    //   type: 'date',
    //   filter: true,
    //   filterOptions: {
    //     col: 12,
    //   },
    //   intervalFunctionName: [
    //     {
    //       name: getTranslation('generic_table_filter_start_date'),
    //       functionalName: 'referral.registration_date_start',
    //       col: 6
    //     },
    //     {
    //       name: getTranslation('generic_table_filter_end_date'),
    //       functionalName: 'referral.registration_date_end',
    //       col: 6
    //     },
    //   ],
    //   interval: true,
    //   sortable: true,
    //   visible: true,
    //   align: 'left',
    // },
    //Incom. Date
    {
      name: getTranslation("analysis_list_table_header_incom_date"),
      functionalName: "referral.arrival_date",
      type: "date",
      filter: true,
      filterOptions: {
        col: 12,
      },
      intervalFunctionName: [
        {
          name: getTranslation("generic_table_filter_start_date"),
          functionalName: "referral.arrival_date_start",
          col: 6,
        },
        {
          name: getTranslation("generic_table_filter_end_date"),
          functionalName: "referral.arrival_date_end",
          col: 6,
        },
      ],
      interval: true,
      sortable: true,
      visible: true,
      align: "left",
    },
    //Reply Date
    {
      id: 6,
      name: getTranslation("analysis_list_table_header_reply_date"),
      functionalName: "referral.result_date",
      type: "date",
      filter: true,
      filterOptions: {
        col: 12,
      },
      intervalFunctionName: [
        {
          name: getTranslation("generic_table_filter_start_date"),
          functionalName: "referral.result_date_start",
          col: 6,
        },
        {
          name: getTranslation("generic_table_filter_end_date"),
          functionalName: "referral.result_date_end",
          col: 6,
        },
      ],
      interval: true,
      sortable: true,
      visible: true,
      align: "left",
    },
    //Analysis
    // {
    //   name: getTranslation('analysis_list_table_header_analysis'),
    //   functionalName: 'referral.analysis',
    //   type: 'number',
    //   visible: true,
    //   align: 'left',
    // },
    //Types
    {
      id: 8,
      name: getTranslation("analysis_list_table_header_methods"),
      functionalName: "referral.test_method_id",
      type: "select",
      list: methods,
      multi: true,
      filter: true,
      sortable: true,
      visible: true,
      align: "left",
    },
    //Status
    {
      id: 8,
      name: getTranslation("analysis_list_table_header_status"),
      functionalName: "referral.status_id",
      type: "select",
      list: status,
      multi: true,
      filter: true,
      sortable: true,
      visible: true,
      align: "left",
    },
    //Results
    {
      id: 9,
      name: getTranslation("analysis_list_table_header_result"),
      functionalName: "referral.is_positive",
      type: "select",
      list: [
        {id: "all", text: getTranslation("generic_filter_show_all")},
        {id: "1", text: getTranslation("generic_filter_positive")},
      ],
      filterOptions: {
        col:
          selectedOrg.infoSolutions && selectedOrg.infoSolutions.customerId
            ? 6
            : 12,
      },
      multi: false,
      filter: true,
      sortable: true,
      visible: true,
      align: "left",
    },
    {
      id: 9,
      name: getTranslation("analysis_list_table_header_warranty"),
      functionalName: "referral.warranty",
      type: "select",
      list: [
        {id: "0", text: getTranslation("generic_filter_no")},
        {id: "1", text: getTranslation("generic_filter_yes")},
      ],
      filterOptions: {
        col:
          selectedOrg.infoSolutions && selectedOrg.infoSolutions.customerId
            ? 6
            : 12,
      },
      multi: false,
      filter: true,
      sortable: false,
      visible: false,
      align: "left",
    },
    {
      id: 10,
      name: getTranslation("analysis_list_table_header_infosolutions_synced"),
      functionalName: "referral.infosolutions_export_date",
      type: "date",
      filter: false,
      sortable: false,
      visible:
        selectedOrg.infoSolutions && selectedOrg.infoSolutions.customerId,
      align: "left",
    },
    {
      id: 10,
      name: getTranslation("analysis_list_table_header_patient_name"),
      functionalName: "referral.patient_name",
      type: "text",
      filter: selectedOrg.infoSolutions && selectedOrg.infoSolutions.customerId,
      filterOptions: {
        col: 6,
      },
      sortable: false,
      visible: false,
      align: "left",
    },
    {
      id: 11,
      name: getTranslation("analysis_list_table_header_patient_ssn"),
      functionalName: "referral.patient_pnr",
      type: "text",
      filter: selectedOrg.infoSolutions && selectedOrg.infoSolutions.customerId,
      filterOptions: {
        col: 6,
      },
      sortable: false,
      visible: false,
      align: "left",
    },
    {
      id: 12,
      name: "",
      functionalName: "referral.handled",
      type: "select",
      list: status,
      multi: true,
      filter: false,
      sortable: true,
      visible: true,
      align: "left",
    },
    {
      id: 13,
      functionalName: "referral.sys_id",
      type: "select",
      multi: false,
      filter: false,
      sortable: false,
      visible: true,
      align: "left"
    }
  ];

  const fetchUnhandledCounter = () => {
    let url = `customer/${
      selectedOrg.id
    }/referrals${generetaQueryString()}&filter[handled]=0`;

    APICall({
      url: url,
      verb: "get",
      server: "api",
    }).then((res) => {
      if (res) setTotalRecords(res.total_records);
    });
  };

  const fetchStatus = () => {
    setLoading(true);
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/referral/statuses`,
      verb: "get",
      server: "api",
    }).then((res) => {
      setLoading(false);
      fetchMethods();
      if (res && res.data) {
        setStatus(
          res.data.map((d) => {
            return {id: d.sys_id, text: d.status};
          })
        );
      }
    });
  };
  const fetchMethods = () => {
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `products/methods`,
      verb: "get",
      server: "api",
    }).then((res) => {
      let tempMethods = [];
      if (res && res.data)
        res.data.forEach((d) => {
          if (d.active === "1") {
            tempMethods.push({id: d.sys_id, text: d.name});
          }
        });
      setMethods(tempMethods);
    });
  };
  const clearFilter = () => {
    sessionStorage.removeItem("aLFilter");
    sessionStorage.removeItem("aLSort");
    sessionStorage.removeItem("aLPage");
    sessionStorage.removeItem("aLCount");
    sessionStorage.removeItem("aLRowsPP");
    setFilter(false);
    setSort({column: "referral.result_date", by: "desc"});
    setPage(0);
    setCount(0);
    setRowsPerPage(20);
    setSelectedHandleFilters({handled: 0, archived: 0});
  };

  const handleCheckboxClick = referralId => {
    //Isn't checked
    if (checkedReferrals.indexOf(referralId) === -1) {
      setCheckedReferrals(existingItems => {
        return [...existingItems, referralId]
      });
    }
    //Is checked
    else {
      let idx = checkedReferrals.indexOf(referralId);
      setCheckedReferrals(existingItems => {
        return [
          ...existingItems.slice(0, idx),
          ...existingItems.slice(idx + 1),
        ]
      })
    }
  };

  const handleViewAll = () => {
    setSelectedHandleFilters({handled: null, archived: null});
  }

  useEffect(() => {
    if (filter) {
      sessionStorage.setItem("aLFilter", JSON.stringify(filter));
    }
    if (sort) {
      sessionStorage.setItem("aLSort", JSON.stringify(sort));
    }
    if (data.length > 0) {
      sessionStorage.setItem("aLPage", JSON.stringify(page));
    }
    if (data.length > 0) {
      sessionStorage.setItem("aLRowsPP", JSON.stringify(rowsPerPage));
    }

    if (count) {
      sessionStorage.setItem("aLCount", JSON.stringify(count));
    }
    if (status.length > 0) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [filter, sort, page, rowsPerPage, selectedHandledFilters]);
  // eslint-disable-next-line
  useEffect(() => {
    if (status.length === 0 || methods.length === 0) {
      fetchStatus();
    } else {
      fetchData();
    }
  }, [status]);

  useEffect(() => {
    if (selectedHandledFilters.handled !== 0) fetchUnhandledCounter();
  }, [selectedHandledFilters]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>

        {/*Desktop*/}
        <Hidden smDown>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              style={{display: "flex", alignItems: "center"}}
            >
              <Typography
                color="primary"
                className={clsx({
                  [classes.handledFilterBtns]: true,
                  [classes.underlinedHandledBtn]:
                  selectedHandledFilters.handled === 0 &&
                  selectedHandledFilters.archived === 0,
                })}
                onClick={() =>
                  setSelectedHandleFilters({
                    ...selectedHandledFilters,
                    ["handled"]: 0,
                    ["archived"]: 0,
                  })
                }
              >
                {`${getTranslation(
                  "analysis_list_table_filter_not_handled"
                )} (${totalRecords})`}
              </Typography>
              <Typography
                color="primary"
                className={clsx({
                  [classes.handledBtn]: true,
                  [classes.handledFilterBtns]: true,
                  [classes.underlinedHandledBtn]:
                  selectedHandledFilters.handled === 1,
                })}
                onClick={() =>
                  setSelectedHandleFilters({
                    ...selectedHandledFilters,
                    ["handled"]: 1,
                    ["archived"]: 0,
                  })
                }
              >
                {getTranslation("analysis_list_table_filter_handled")}
              </Typography>
              <Typography
                color="primary"
                className={clsx({
                  [classes.seeAllBtn]: true,
                  [classes.handledFilterBtns]: true,
                })}
                onClick={handleViewAll}
              >
                {getTranslation("generic_filter_show_all")}
              </Typography>
              <Typography
                color="primary"
                className={clsx({
                  [classes.handledFilterBtns]: true,
                  [classes.underlinedHandledBtn]:
                  selectedHandledFilters.archived === 1,
                })}
                onClick={() =>
                  setSelectedHandleFilters({
                    ...selectedHandledFilters,
                    ["handled"]: 0,
                    ["archived"]: 1,
                  })
                }
              >
                {getTranslation("analysis_list_table_filter_archived")}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <ButtonGroup
                size="small"
                color="primary"
                className={classes.pageHeader}
              >
                <Button onClick={clearFilter} className={classes.headerButtons}>
                  {getTranslation("generic_clear_filter")}{" "}
                </Button>
                <Button
                  startIcon={<FilterList/>}
                  onClick={(e) => {
                    if (!enterPressed) {
                      setEnterPressed(true);
                    } else {
                      setEnterPressed(false);
                    }
                    setSearchState(true);
                  }}
                  className={classes.headerButtons}
                >
                  {" "}
                  {getTranslation("generic_button_filter")}{" "}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Hidden>

        {/*Mobile*/}
        <Hidden mdUp>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                color="primary"
                className={clsx({
                  [classes.handledFilterBtns]: true,
                  [classes.underlinedHandledBtn]:
                  selectedHandledFilters.handled === 0 &&
                  selectedHandledFilters.archived === 0,
                })}
                onClick={() =>
                  setSelectedHandleFilters({
                    ...selectedHandledFilters,
                    ["handled"]: 0,
                    ["archived"]: 0,
                  })
                }
              >
                {`${getTranslation(
                  "analysis_list_table_filter_not_handled"
                )} (${totalRecords})`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="primary"
                className={clsx({
                  [classes.handledFilterBtns]: true,
                  [classes.underlinedHandledBtn]:
                  selectedHandledFilters.handled === 1,
                })}
                onClick={() =>
                  setSelectedHandleFilters({
                    ...selectedHandledFilters,
                    ["handled"]: 1,
                    ["archived"]: 0,
                  })
                }
              >
                {getTranslation("analysis_list_table_filter_handled")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="primary"
                className={clsx({
                  [classes.handledFilterBtns]: true,
                })}
                onClick={clearFilter}
              >
                {getTranslation("generic_filter_show_all")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="primary"
                className={clsx({
                  [classes.handledFilterBtns]: true,
                  [classes.underlinedHandledBtn]:
                  selectedHandledFilters.archived === 1,
                })}
                onClick={() =>
                  setSelectedHandleFilters({
                    ...selectedHandledFilters,
                    ["handled"]: 0,
                    ["archived"]: 1,
                  })
                }
              >
                {getTranslation("analysis_list_table_filter_archived")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup
                size="small"
                color="primary"
                className={classes.pageHeader}
              >
                <Button onClick={clearFilter} className={classes.headerButtons}>
                  {getTranslation("generic_clear_filter")}{" "}
                </Button>
                <Button
                  startIcon={<FilterList/>}
                  onClick={(e) => {
                    if (!enterPressed) {
                      setEnterPressed(true);
                    } else {
                      setEnterPressed(false);
                    }
                    setSearchState(true);
                  }}
                  className={classes.headerButtons}
                >
                  {" "}
                  {getTranslation("generic_button_filter")}{" "}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>

      <Grid item xs={12}>
        {methods &&
        methods.length > 0 &&
        status &&
        status.length > 0 &&
        Object.keys(filter).length > 0 && (
          <SearchChips
            filter={filter}
            setFilter={setFilter}
            columns={columns}
          />
        )}
        {loading && <LinearProgress/>}
        <Table
          analysisTable={true}
          rows={data}
          page={page}
          count={count}
          columns={columns}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortChange={handleSortChange}
          sort={sort}
          onLineClick={"/analyses/detail/"}
          handleCheckboxClick={handleCheckboxClick}
          checkedRows={checkedReferrals}
        />
        <Dialog
          filter={filter}
          setFilter={setFilter}
          open={searchState}
          onClose={setSearchState}
          columns={columns}
          setEnterPressed={setEnterPressed}
          restrictHeight={true}
        />
      </Grid>
    </Grid>
  );
};
export default Analysis;
