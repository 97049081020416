import React, {useRef} from "react";
import {Camera} from "react-camera-pro";
import {makeStyles} from "@material-ui/core/styles";
import colorConfig from "../../styles/Theme-settings";
import CameraIcon from "@material-ui/icons/Camera";
import CloseIcon from '@material-ui/icons/Close';

const CameraComp = props => {
    const classes = useStyles();

    const cameraRef = useRef(null);

    const {handleOnClick, onClose, isMobile} = props;

    let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    return (
        <>
            {isMobile &&
                <>
                    <Camera ref={cameraRef} facingMode='environment'/>
                    <div className={classes.captureBtnWrapMobile}>
                        <CameraIcon
                            className={classes.captureBtnMobile}
                            style={isIOS ? {bottom: 120} : {bottom: 70}}
                            onClick={() => {
                                if (cameraRef.current) {
                                    const photo = cameraRef.current.takePhoto();
                                    handleOnClick(photo);
                                    onClose();
                                }
                            }}/>
                        <CloseIcon className={classes.closeBtnMobile} onClick={onClose}/>
                    </div>
                </>
            }
            {!isMobile &&
                <div className={classes.cameraWrap}>
                    <Camera ref={cameraRef} aspectRatio={16 / 9} facingMode='environment'/>
                    <div className={classes.captureBtnWrap}>
                        <CameraIcon
                            className={classes.captureBtn}
                            onClick={() => {
                                if (cameraRef.current) {
                                    const photo = cameraRef.current.takePhoto();
                                    handleOnClick(photo);
                                    onClose();
                                }
                            }}/>
                    </div>
                </div>
            }
        </>
    )
}

const useStyles = makeStyles(theme => ({
        cameraWrap: {
            //zIndex: 1101,
            //position: "absolute",
            width: "100vw",
            //height: "100vh",
            //left: 0,
            //top: 0
        },
        captureBtnWrapMobile: {
            zIndex: 1100,
            position: "relative",
            width: "100%",
            height: "100%"
        },
        captureBtnMobile: {
            fill: "rgba(255, 255, 255, 0.8)",
            position: "absolute",
            height: 50,
            width: 50,
            transform: "translate(-50%, 0%)",
            left: "50%",
            cursor: "pointer"
        },
        closeBtnMobile: {
            fill: "rgba(255, 255, 255, 0.8)",
            position: "absolute",
            height: 45,
            width: 45,
            top: "-100px",
            right: "-30px",
            cursor: "pointer"
        },
        captureBtnWrap: {
            zIndex: 100,
            position: "relative",
        },
        captureBtn: {
            fill: "rgba(255, 255, 255, 0.8)",
            position: "absolute",
            height: 50,
            width: 50,
            transform: "translate(-50%, 0%)",
            left: "50%",
            bottom: 15,
            cursor: "pointer"
        }
    }))
;

export default CameraComp;