import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography, Grid, Button, Paper, CircularProgress} from "@material-ui/core";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';
import clsx from "clsx";
import {useSnackbar} from "notistack";
import colorConfig from "../../styles/Theme-settings";
import {LocalizationContext} from "../../context/localizationContext";
import Item from "./Item";
import APICall from "../../functional/APIRequests";
import {AuthContext} from "../../context/authContext";
import {OrgContext} from "../../context/orgContext";
import moment from "moment";

const ReferralsSlider = () => {
  const [open, setOpen] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [methods, setMethods] = useState([]);

  const classes = useStyles({open: open});
  const {enqueueSnackbar} = useSnackbar();
  const {getTranslation} = useContext(LocalizationContext);
  const {setLoading, loading} = useContext(AuthContext);
  const {selectedOrg} = useContext(OrgContext);

  const handleCheckClick = (id) => {
    let refs = referrals;
    let index = 0;

    for (let i in refs) {
      let ref = refs[i];

      if (ref.referral.sys_id === id) {
        index = parseInt(i);
        setReferrals(prevState => {
          ref.checked = !ref.checked;
          return [
            ...prevState.slice(0, index),
            prevState[index] = ref,
            ...prevState.slice(index + 1)
          ]
        })
        break;
      }
    }
  }

  const fetchReferrals = () => {
    setLoading(true);
    APICall({
      setLoading,
      url: `customer/${selectedOrg.id}/referrals?limit=1000&offset=0&filter[referral.status_id]=1&filter[workplace]=0&filter[referral.hidden]=0`,
      verb: 'get',
      server: 'api'
    })
      .then(res => {
        if (res.data) {
          setReferrals(res.data.map(data => {
            let analysis = data.analysis;
            if (Array.isArray(data.analysis)) {
              analysis = data.analysis;
            } else {
              Object.keys(data.analysis).forEach(key => {
                analysis.push(data.analysis[key]);
              })
            }

            data.referral.test_date = data.referral.test_date === '0' || !data.referral.test_date ? '-' : moment.unix(data.referral.test_date).format('YYYY-MM-DD')
            data.referral.test_method_id = (methods.find(st => st.id === data.referral.test_method_id) || {text: ''}).text;
            data.referral.analysis = analysis.length === 0 ? false : analysis.length
            data.checked = false
            return {
              ...data
            }
          }))
        }
        setLoading(false);
      })
      .catch(() => setLoading(false))
  };

  const removeReferral = (refId) => {
    setLoading(true);

    let formData = new FormData();
    formData.append('data[hidden]', '1');

    APICall({
      setLoading,
      url: `customer/${selectedOrg.id}/referral/${refId}/update`,
      verb: 'post',
      server: 'api',
      data: formData
    })
      .then(res => {
        if (res.data) {
          fetchReferrals();
        }
      })
      .catch(() => setLoading(false))
  };

  const handleSubmit = () => {
    let checkedReferrals = referrals.filter(item => item.checked);

    let formData = new FormData();
    formData.append("data[skip_signature]", '1');
    formData.append("data[send_digital]", '1');

    checkedReferrals.map(ref => {
      APICall({
        enqueueSnackbar,
        url: `customer/${selectedOrg.id}/referral/${ref.referral.sys_id}/setcomplete`,
        verb: 'post',
        server: 'api',
        data: formData
      })
        .then(res => {
          if (res) {
            enqueueSnackbar(getTranslation('referral_sent_success'), {variant: "success"});
          }
        })
        .catch(err => console.log(err))
    })
  };

  const fetchMethods = () => (
    APICall({
      enqueueSnackbar,
      url: `products/methods`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      let tempMethods = []
      if (res && res.data)
        res.data.forEach(d => {
          if (d.active === '1') {
            tempMethods.push({id: d.sys_id, text: d.name})
          }
        })
      setMethods(tempMethods);
    })
  );

  const handleButtonClick = async () => {
    await handleSubmit();
    fetchReferrals();
  }

  useEffect(() => {
    fetchMethods();

  }, []);

  useEffect(() => {
    if (methods.length > 0) {
      fetchReferrals();
    }
  }, [methods]);

  return (
    <>
      <div
        className={clsx(classes.drawerButton, classes.zIndex,{
          [classes.displayNone]: (referrals.length <= 0 && !open)
        })} onClick={() => setOpen(!open)}>
        <Typography className={classes.drawerText} variant="subtitle1">
          {getTranslation('analysis_slider_drawer_text')}
        </Typography>
        {open ? <CloseIcon className={classes.tabIcon}/> : <ArrowUpwardIcon className={classes.tabIcon}/>}
      </div>
      {open &&
      <Paper elevation={3} className={clsx(classes.drawerContent, classes.zIndex,{
        [classes.displayNone]: (referrals.length <= 0 && !open)
      })}>
        <Grid container>
          <Grid item xs={12} className={classes.drawerContentHeaderWrapper}>
            <ErrorOutlineIcon className={classes.icon}/>
            <Typography className={classes.headerTitle}
                        variant="h6">{getTranslation('analysis_slider_header_title')}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.subtitleWrapper}>
            <Typography variant="body2">{getTranslation('analysis_slider_header_subtitle')}</Typography>
          </Grid>
          {loading ?
            <Grid item xs={12} className={classes.loadingWrapper}>
              <CircularProgress/>
            </Grid>
            :
            <>
              {referrals.length > 0 ?
                <Grid item xs={12} className={classes.referralsListWrapper}>
                  {referrals.map(ref => (
                    <Item
                      key={ref.referral.referral_id}
                      data={ref}
                      checkReferral={handleCheckClick}
                      onRemove={removeReferral}
                    />
                  ))}
                </Grid>
                :
                <Typography className={classes.noReferralsWrapper}
                            variant='subtitle1'>{getTranslation('analysis_slider_no_referrals_text')}</Typography>
              }
              <Grid item xs={12} className={classes.saveButtonWrapper}>
                {referrals.length > 0 &&
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.sendButton}
                  onClick={() => handleButtonClick()}
                  disabled={referrals.filter(item => item.checked).length <= 0}
                >
                  {getTranslation('analysis_slider_button')}
                </Button>
                }
              </Grid>
            </>
          }
        </Grid>
      </Paper>
      }
    </>
  )
}

const useStyles = makeStyles(() => ({
  drawerButton: {
    position: "absolute",
    right: (props) => props.open ? 250 : -100,
    top: 240,
    width: 250,
    height: 50,
    transform: "rotate(-90deg)",
    background: colorConfig.primary,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  drawerText: {
    color: colorConfig.white,
  },
  drawerContent: {
    position: "absolute",
    right: 0,
    top: 140,
    background: colorConfig.white,
    width: 350,
    borderLeft: `3px solid ${colorConfig.primary}`,
    paddingTop: 10,
    borderRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    minHeight: 250
  },
  drawerContentHeaderWrapper: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginRight: 5,
    width: 38,
    height: 38,
  },
  headerTitle: {
    color: colorConfig.primary
  },
  sendButton: {
    borderRadius: 0,
    margin: "10px 0px"
  },
  saveButtonWrapper: {
    textAlign: "center"
  },
  subtitleWrapper: {
    paddingLeft: 6
  },
  referralsListWrapper: {
    height: 354,
    overflowY: "scroll"
  },
  noReferralsWrapper: {
    paddingLeft: 6,
    marginTop: 20
  },
  displayNone: {
    display: 'none'
  },
  tabIcon: {
    fill: colorConfig.white,
    marginLeft: 10,
    width: 22,
    height: 22
  },
  loadingWrapper: {
    marginTop: 60,
    textAlign: 'center'
  },
  zIndex: {
    zIndex: 10
  }
}));

export default ReferralsSlider;