import React, {useState, useContext, useEffect} from "react";
import {
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Typography,
    Grid, Button
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Help, Edit} from "@material-ui/icons";
import colors from '../../../styles/Theme-settings'
import moment from 'moment';
import {LocalizationContext} from '../../../context/localizationContext';
import {useSnackbar} from 'notistack';
import APICall from '../../../functional/APIRequests';
import {AuthContext} from '../../../context/authContext';
import {RefContext} from '../../../context/refContext';
import Dialog from '../../../components/Dialog'
import DialogAmphetamine from '../../../components/DialogAmphetamine'
import DialogOpiad from '../../../components/DialogOpiad'
import {Markup} from 'interweave';

const ProdList = props => {
    const classes = useStyles();
    const {getTranslation} = React.useContext(LocalizationContext);
    const {lang} = useContext(LocalizationContext);
    const {setLoading} = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar();
    const {
        referalData,
        setSelectedProduct,
        selectedProduct,
        testMethods,
        otherChemIntake,
        setOtherChemIntake,
        formType,
        verifiedCheck,
        setVerifiedCheck
    } = useContext(RefContext);

    const {handleNext, handleBack} = props;

    const [products, setProducts] = useState();
    const [otherDialog, setOtherDialog] = useState(false);
    const [dialogData, setDialogData] = useState(false);
    // const [lastVerifiedSelection, setLastVerifiedSelection] = useState([]);
    const [infoDialog, setInfoDialog] = useState(null);
    const [amphetamineDialog, setAmphetamineDialog] = useState(null);
    const [linkedTestsDialog, setLinkedTestsDialog] = useState(false)
    const [lengthOfSelect, setLenghtOfSelect] = useState(0);
    const [opiadModal, setOpiadModal] = useState(false);
    moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');
    const fetchData = (e) => {
        setLoading(true)
        APICall({
            setLoading,
            enqueueSnackbar,
            url: `products?sort=sort_order asc`,
            verb: 'get',
            server: 'api',
        }).then((res) => {
            let tempArr = {}
            testMethods.forEach(test => {
                tempArr[test.sys_id] = []
            })
            res.data.forEach(item => tempArr[item.test_method_id].push(item))
            setProducts(tempArr)
            setLoading(false)
        })
    }

    const handleCheck = (e) => {
        if (selectedProduct.findIndex(v => v.sys_id === e.target.value) > -1) {
            if (otherChemIntake) {
                let index = otherChemIntake.findIndex(v => v.sys_id === e.target.value)
                if (index > -1) {
                    otherChemIntake.splice(index, 1);
                    setOtherChemIntake([...otherChemIntake]);
                }
            }
            selectedProduct.splice(selectedProduct.findIndex(v => v.sys_id === e.target.value), 1)
            setSelectedProduct([...selectedProduct]);
        } else {

            let index = products[referalData.testMethodId].findIndex(p => p.sys_id === e.target.value);
            let product = products[referalData.testMethodId][index];

            if (product.test_type === 'free') {
                setOtherDialog({id: e.target.value, type: 1, title: product.name});
            } else if (product.test_type === 'dateAndDose') {
                setDialogData({id: e.target.value, type: 2, infoBox: null, title: product.name});
            }
            setSelectedProduct([...selectedProduct, {
                sys_id: e.target.value,
                name: e.target.name,
                verified: product.test_type === "verify",
                verifiedTestsTitle: product.note
            }]);
            generateDialog(e.target.value);
            let keys = Object.keys(products);
            keys.forEach(index => {
                products[index].forEach(s => {
                    if (s.sys_id === e.target.value) {
                        if (s.prefill_tests) {
                            let prefillTest = products[index].find(prod => prod.sys_id === s.prefill_tests)
                            setSelectedProduct([...selectedProduct,
                                {
                                    sys_id: prefillTest.sys_id, name: prefillTest.name,
                                    verified: prefillTest.test_type === "verify", verifiedTestsTitle: prefillTest.note
                                },
                                {
                                    sys_id: e.target.value, name: e.target.name,
                                    verified: product.test_type === "verify", verifiedTestsTitle: product.note
                                }]);
                        }
                    }
                })
            })
        }
    }

    const generateDialog = (params) => {
        let keys = Object.keys(products);
        let message = getTranslation("referal_product_selection_dialog_warning_message_body");
        keys.forEach(index => {
            products[index].forEach(s => {
                if (s.sys_id === params) {
                    if (s.prefill_tests) {
                        if (!linkedTestsDialog) {
                            let linkedTest = products[index].find(prod => prod.sys_id === s.prefill_tests)
                            setLinkedTestsDialog({
                                title: getTranslation("referal_product_selection_dialog_warning_message_prefilled_title"),
                                info: `${message} <br/>${linkedTest.name}`,
                                infoBox: false,
                            })
                        }
                    } else if (s.linked_tests && s.linked_tests !== '') {
                        if (!linkedTestsDialog) {
                            let arr = []
                            if (s.linked_tests.indexOf(',') > 0) {
                                arr = arr.concat(s.linked_tests.split(','));
                                setLinkedTestsDialog({
                                    title: getTranslation("referal_product_selection_dialog_warning_message_linked_title"),
                                    info: message + arr.map(test => {
                                        return products[index].find(prod => prod.sys_id === test).name + ' '
                                    }),
                                    infoBox: false,
                                })
                            } else {
                                let linkedTest = products[index].find(prod => prod.sys_id === s.linked_tests)
                                setLinkedTestsDialog({
                                    title: getTranslation("referal_product_selection_dialog_warning_message_linked_title"),
                                    info: message + linkedTest.name,
                                    infoBox: false,
                                })
                            }
                        }
                    }
                }
            })

        })
    }
    const otherDialogCallback = (params) => {
        if (!params) {
            if (otherDialog && otherDialog.id) {
                let product = null;
                if (otherChemIntake)
                    product = otherChemIntake.find(x => x.sys_id === otherDialog.id);
                if (!product) {
                    const index = selectedProduct.findIndex(x => x.sys_id === otherDialog.id);
                    if (index > -1) {
                        selectedProduct.splice(index, 1);
                        setSelectedProduct([...selectedProduct]);
                    }
                }
            }
        }

        return false
    }
    const dialogCallback = (params) => {
        if (!params) {
            if (dialogData && dialogData.id) {
                let product = null;
                if (otherChemIntake)
                    product = otherChemIntake.find(x => x.sys_id === dialogData.id);
                if (!product) {
                    const index = selectedProduct.findIndex(x => x.sys_id === dialogData.id);
                    if (index > -1) {
                        selectedProduct.splice(index, 1);
                        setSelectedProduct([...selectedProduct]);
                    }
                }
            }
        }

        return false
    }

    const infoDialogCallback = (params) => {
        return false
    }


    const linkedTestsDialogCallback = (params) => {
        return false
    }

    useEffect(() => {
        if (testMethods) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [testMethods]);

    // useEffect(() => {

    // }, [selectedProduct])

    const checkForLinked = (id) => {
        let arr = [];
        let keys = Object.keys(products);
        keys.forEach(index => {
            products[index].forEach(product => {
                if (selectedProduct.findIndex(ct => ct.sys_id === product.sys_id) > -1) {
                    if (!!product.linked_tests) {
                        arr = arr.concat(product.linked_tests.split(','));
                    }
                }
            })
        })
        return arr.includes(id)
    }

    let verifiedProds = []
    let lastVerifiedSelection = []
    if (Number(referalData.testMethodId) === 2) {
        if (products) {
            products[referalData.testMethodId].forEach(product => {
                if (product.test_type === 'verify') {
                    verifiedProds.push(product)
                }
            })
            selectedProduct.forEach((selProd, index) => {
                if (verifiedProds.findIndex(vP => vP.sys_id === selProd.sys_id) > -1) {
                    lastVerifiedSelection.push(selProd.sys_id)
                }
            });
        }
    }
    const handleAmphetamineChange = (option) => {
        let newProds = [];
        if (option === 1 || option === 3) {
            let ampID = '';
            switch (Number(amphetamineDialog)) {
                case 7:
                    ampID = '3'
                    break;
                case 39:
                    ampID = '38'
                    break;
                case 117:
                    ampID = '91'
                    break;
                default:
                    ampID = String(amphetamineDialog);
                    break;
            }
            let index1 = products[referalData.testMethodId].findIndex(p => p.sys_id === String(ampID));
            if (index1 > -1) {
                let product1 = products[referalData.testMethodId][index1];
                if (selectedProduct.findIndex(item => item.sys_id === String(ampID)) === -1) {
                    newProds.push(
                        {
                            sys_id: String(ampID),
                            name: product1.name,
                            verified: product1.test_type === "verify",
                            verifiedTestsTitle: product1.note
                        }
                    )
                }
            }
        }
        if (option === 2 || option === 3) {
            let alternativeOpt = '';
            switch (Number(amphetamineDialog)) {
                case 3:
                    alternativeOpt = '7'
                    break;
                case 38:
                    alternativeOpt = '39'
                    break;
                case 91:
                    alternativeOpt = '117'
                    break;

                case 7:
                    alternativeOpt = '7'
                    break;
                case 39:
                    alternativeOpt = '39'
                    break;
                case 117:
                    alternativeOpt = '117'
                    break;
                default:
                    break;
            }
            let index2 = products[referalData.testMethodId].findIndex(p => p.sys_id === String(alternativeOpt));
            if (index2 > -1) {
                let product2 = products[referalData.testMethodId][index2];
                if (selectedProduct.findIndex(item => item.sys_id === String(alternativeOpt)) === -1) {
                    newProds.push(
                        {
                            sys_id: String(alternativeOpt),
                            name: product2.name,
                            verified: product2.test_type === "verify",
                            verifiedTestsTitle: product2.note
                        }
                    )
                }
            }
        }
        setSelectedProduct([...selectedProduct, ...newProds]);
    }

    const getVerifiedTests = () => {
        return <React.Fragment>
            {products && Number(referalData.testMethodId) === 2 && formType !== 2 &&
                <FormControl style={{padding: '0px 20px'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={verifiedCheck}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        let selProduct = [];
                                        selectedProduct.forEach(selProd => {
                                            if (!(verifiedProds.findIndex(vP => vP.sys_id === selProd.sys_id) > -1)) {
                                                selProduct.push(selProd)
                                            }
                                        });
                                        setSelectedProduct([...selProduct]);
                                    }
                                    setVerifiedCheck(e.target.checked)
                                }}
                                name={verifiedProds[0].note}
                                disabled={props.disabled}
                            />
                        }
                        label={
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{marginRight: 20}}>{verifiedProds[0].note}</span>
                                {(verifiedProds[0].info.length > 0 || !!verifiedProds[0].info_box) &&
                                    <Help onClick={(e) => {
                                        e.preventDefault();
                                    }}/>
                                }
                            </div>
                        }
                    />
                </FormControl>
            }
            {verifiedCheck && Number(referalData.testMethodId) === 2 &&
                <FormControl>
                    <Select
                        style={{width: '50%'}}
                        multiple
                        value={lastVerifiedSelection}
                        onChange={e => {
                            let del = lengthOfSelect > e.target.value.length;
                            let tempselectedProduct = JSON.parse(JSON.stringify(selectedProduct));
                            lastVerifiedSelection.forEach(item => {
                                if (tempselectedProduct.findIndex(v => v.sys_id === item) > -1) {
                                    tempselectedProduct.splice(tempselectedProduct.findIndex(v => v.sys_id === item), 1)
                                }
                            })
                            e.target.value.forEach(item => {
                                if (item === "32" || item === "31" || item === "23" || item === "16" || item === "18") {
                                    const _tests = tempselectedProduct.filter(t => t.sys_id === setUnlinkedVerifyTests(item).sys_id);
                                    if (_tests.length === 0) {
                                        let test = setUnlinkedVerifyTests(item);
                                        tempselectedProduct.push(test)
                                    }
                                }

                                tempselectedProduct.push({
                                    sys_id: item,
                                    name: verifiedProds.find(vp => vp.sys_id === item).name,
                                    verified: true,
                                    verifiedTestsTitle: verifiedProds[0].note
                                })
                            })

                            if (!del) {
                                let id = e.target.value[e.target.value.length - 1];
                                if (Number(id) !== 13 && Number(id) !== 22) {
                                    generateDialog(id);
                                    if (Number(id) === 32 || Number(id) === 31 || Number(id) === 23 || Number(id) === 16 || Number(id) === 18) {
                                        let _test = products[referalData.testMethodId].find(item => item.sys_id === setUnlinkedVerifyTests(id).sys_id);
                                        let message = getTranslation("referal_product_selection_dialog_warning_message_body");
                                        setLinkedTestsDialog({
                                            title: getTranslation("referal_product_selection_dialog_warning_message_linked_title"),
                                            info: `${message} <br/>${_test.name}`,
                                            infoBox: false,
                                        });
                                    }
                                    let workinProd = products[referalData.testMethodId].find(item => item.sys_id === id);
                                    if (workinProd && workinProd.prefill_tests) {
                                        let prefillTest = products[referalData.testMethodId].find(prod => prod.sys_id === workinProd.prefill_tests)
                                        if (selectedProduct.findIndex(item => item.sys_id === prefillTest.sys_id) === -1) {
                                            tempselectedProduct.push({
                                                sys_id: prefillTest.sys_id,
                                                name: prefillTest.name
                                            })
                                        }
                                    }
                                } else if (Number(id) === 13) {
                                    setAmphetamineDialog(38)
                                } else {
                                    setOpiadModal(true);
                                }
                            }

                            setLenghtOfSelect(e.target.value.length)
                            setSelectedProduct(tempselectedProduct);
                        }}
                        renderValue={selected => {
                            let returnV = [];
                            selected.forEach(sel => {
                                returnV.push(verifiedProds.find(st => st.sys_id === sel).name)
                            })
                            return returnV.join(', ')
                        }}
                    >
                        <MenuItem disabled={true}
                                  value={''}>{getTranslation('referral_test_methods_select_one')}</MenuItem>
                        {verifiedProds.map(item => {
                            return <MenuItem key={item.sys_id} value={item.sys_id}>{item.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            }
        </React.Fragment>
    }

    const handleOpiadChange = (option) => {
        let newProds = [];
        let ids;
        if (option === 1) {
            ids = ['36']
        } else {
            ids = ['36', '84']
        }
        ids.forEach(testId => {
            let index1 = products[referalData.testMethodId].findIndex(p => p.sys_id === String(testId));
            if (index1 > -1) {
                let product1 = products[referalData.testMethodId][index1];
                if (selectedProduct.findIndex(item => item.sys_id === String(testId)) === -1) {
                    newProds.push(
                        {
                            sys_id: String(testId),
                            name: product1.name,
                            verified: product1.test_type === "verify",
                            verifiedTestsTitle: product1.note
                        }
                    )
                }
            }
        })
        setSelectedProduct([...selectedProduct, ...newProds]);
    }

    const setUnlinkedVerifyTests = id => {
        let productId;

        switch (id) {
            case '32':
                productId = '40';
                break;
            case '31':
                productId = '42';
                break;
            case '23':
                productId = '99';
                break;
            case '16':
                productId = "98";
                break;
            case '18':
                productId = "100";
                break;
            default:
                break;
        }

        let verifiedTest = products[referalData.testMethodId].find(t => t.sys_id === productId);

        return verifiedTest;
    }

    const methodName = () => {
        const method = testMethods.find(item => (item.active === '1' && item.sys_id !== '0' && item.sys_id === referalData.testMethodId))
        if (method)
            return ` - ${method.name}`;
        return '';
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                    {`${getTranslation('referal_form_step_two_title')}${methodName()}`}
                </Typography>
                {referalData.testMethodId === "4" &&
                    <Typography variant="caption" gutterBottom className={classes.helblodSubTitle}>
                        OBS! FÖR VARJE ANALYS I KAPILLÄRT HELBLOD KRÄVS 1 GLASRÖR FÖR A-PROV OCH 1 GLASRÖR FÖR B-PROV
                        (om B-prov önskas). UNDANTAG FÖR SEPARATION AV D-/L-AMFETAMIN, DÄR 2+2 GLASRÖR KRÄVS! LÄGG
                        ALLTID ENDAST 1 GLASRÖR PER PLASTTUB!
                    </Typography>
                }
            </Grid>
            <Grid item xs={12}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                        {(products && referalData.testMethodId) &&
                            products[referalData.testMethodId].map((product, ind) => {
                                if ((formType === 1 && product.test_type !== "verify") || (formType === 2 && product.warranty === '1')) {
                                    return <React.Fragment key={product.sys_id}>
                                        {ind === 0 && getVerifiedTests()}
                                        <div style={{
                                            padding: '0px 0px',
                                            backgroundColor: (selectedProduct.findIndex(v => v.sys_id === product.sys_id) > -1 && !!product.info_box) ? '#FEFFEB' : 'transparent'
                                        }}
                                             className={classes.product}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            selectedProduct.findIndex(v => v.sys_id === product.sys_id) > -1}
                                                        onChange={(e) => {
                                                            let prodId = Number(product.sys_id);
                                                            if (
                                                                (prodId === 3 && selectedProduct.findIndex(v => v.sys_id === String(prodId)) === -1) ||
                                                                (prodId === 38 && selectedProduct.findIndex(v => v.sys_id === String(prodId)) === -1) ||
                                                                (prodId === 91 && selectedProduct.findIndex(v => v.sys_id === String(prodId)) === -1)
                                                            ) {
                                                                setAmphetamineDialog(prodId)
                                                            } else if (
                                                                (prodId === 36 && selectedProduct.findIndex(v => v.sys_id === String(prodId)) === -1)
                                                            ) {
                                                                setOpiadModal(true);
                                                            } else {
                                                                if (formType === 2) {
                                                                    switch (prodId) {
                                                                        //THC
                                                                        case 32:
                                                                            handleCheck('40');
                                                                            break;
                                                                        //Spice
                                                                        case 31:
                                                                            handleCheck('42');
                                                                            break;
                                                                        //Cocaine
                                                                        case 16:
                                                                            handleCheck('98');
                                                                            break;
                                                                        //Etg
                                                                        case 18:
                                                                            handleCheck('100');
                                                                            break;
                                                                        default:
                                                                            handleCheck(e);
                                                                            break;
                                                                    }
                                                                } else {
                                                                    handleCheck(e)
                                                                }
                                                            }
                                                        }}
                                                        name={product.name}
                                                        disabled={checkForLinked(product.sys_id) || props.disabled}
                                                        value={product.sys_id}
                                                    />
                                                }
                                                label={
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <span style={{marginRight: 20}}>{product.name}</span>
                                                        {(product.test_type === 'free') &&
                                                            selectedProduct.findIndex(v => v.sys_id === product.sys_id) > -1 &&
                                                            <Edit onClick={(e) => {
                                                                e.preventDefault();
                                                                setOtherDialog({
                                                                    id: product.sys_id,
                                                                    type: 1,
                                                                    title: product.name
                                                                });
                                                            }}/>
                                                        }
                                                        {(product.test_type === 'dateAndDose') &&
                                                            selectedProduct.findIndex(v => v.sys_id === product.sys_id) > -1 &&
                                                            <Edit onClick={(e) => {
                                                                e.preventDefault();
                                                                setDialogData({
                                                                    id: product.sys_id,
                                                                    type: 2,
                                                                    title: product.name
                                                                });
                                                            }}/>
                                                        }
                                                        {product.info.length > 0 &&
                                                            <Help onClick={(e) => {
                                                                e.preventDefault();
                                                                setInfoDialog({
                                                                    title: product.name,
                                                                    info: !!product.info && product.info.length > 0 ? product.info : false,
                                                                    infoBox: false,
                                                                })
                                                            }}/>
                                                        }
                                                    </div>
                                                }
                                            />
                                            {!!product.info_box &&
                                                <Markup content={product.info_box.replace('border: solid 1px #DDDDDD',
                                                    selectedProduct.findIndex(v => v.sys_id === product.sys_id) > -1 ? 'border: solid 1px red' : 'border: solid 1px #DDDDDD'
                                                )}/>
                                            }
                                        </div>
                                    </React.Fragment>
                                } else {
                                    return false
                                }
                            })
                        }
                        {!referalData.testMethodId &&
                            <Typography variant="subtitle1" gutterBottom
                                        style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>
                                {getTranslation('referral_test_method_required')}
                            </Typography>
                        }
                    </FormGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} className={classes.btnsWrap}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleBack}
                    className={classes.backBtn}
                    style={selectedProduct.length > 0 ? {marginRight: 10} : {}}
                >
                    {getTranslation("referral_previous_step_btn")}
                </Button>
                {(Array.isArray(selectedProduct) && selectedProduct.length > 0) &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                    >
                        {getTranslation('generic_button_continue')}
                    </Button>
                }
            </Grid>

            <React.Fragment>
                {otherDialog &&
                    <Dialog
                        open={!!otherDialog}
                        setOpen={setOtherDialog}
                        dialogData={otherDialog}
                        maxWidth={'md'}
                        callback={otherDialogCallback}
                        hasReferralInputs={true}
                        hasValidateFields={true}
                        title={otherDialog.title}
                        note={getTranslation('digital_sign_not_allowed_with_other_analysis')}
                        cancelLabel={getTranslation('generic_button_cancel')}
                        submitLabel={getTranslation('generic_button_save')}
                    />
                }
                {dialogData &&
                    <Dialog
                        open={!!dialogData}
                        setOpen={setDialogData}
                        dialogData={dialogData}
                        maxWidth={'md'}
                        callback={dialogCallback}
                        hasReferralInputs={true}
                        hasValidateFields={true}
                        title={dialogData.title}
                        cancelLabel={getTranslation('generic_button_cancel')}
                        submitLabel={getTranslation('generic_button_save')}
                    />
                }
                {infoDialog &&
                    <Dialog
                        dialogData={{}}
                        open={!!infoDialog}
                        setOpen={setInfoDialog}
                        callback={infoDialogCallback}
                        title={infoDialog.title}
                        message={infoDialog.info}
                        html={infoDialog.infoBox}
                        cancelLabel={getTranslation('generic_button_ok')}
                    />
                }
                {linkedTestsDialog &&
                    <Dialog
                        dialogData={{}}
                        open={!!linkedTestsDialog}
                        maxWidth={'sm'}
                        setOpen={setLinkedTestsDialog}
                        callback={linkedTestsDialogCallback}
                        title={linkedTestsDialog.title}
                        message={linkedTestsDialog.info}
                        html={linkedTestsDialog.infoBox}
                        cancelLabel={getTranslation('generic_button_ok')}
                    />
                }
                {amphetamineDialog &&
                    <DialogAmphetamine
                        dialogData={{}}
                        open={!!amphetamineDialog}
                        selected={amphetamineDialog}
                        maxWidth={'sm'}
                        setOpen={setAmphetamineDialog}
                        callback={(e) => {
                            handleAmphetamineChange(e)
                        }}
                        cancelLabel={getTranslation('generic_button_cancel')}
                        submitLabel={getTranslation('generic_button_select')}
                    />
                }
                {opiadModal &&
                    <DialogOpiad
                        dialogData={{}}
                        open={!!opiadModal}
                        selected={opiadModal}
                        maxWidth={'sm'}
                        setOpen={setOpiadModal}
                        callback={(e) => {
                            handleOpiadChange(e)
                        }}
                        cancelLabel={getTranslation('generic_button_cancel')}
                        submitLabel={getTranslation('generic_button_select')}
                    />
                }
            </React.Fragment>
        </>
    );
};
const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "center"
    },
    paper: {
        width: "100%",
        padding: 20
    },
    cartButton: {
        width: '100%',
        margin: '5px 0'
    },
    selectTitle: {
        width: '100%',
        marginTop: 20,
    },
    selectGroup: {
        padding: 10
    },
    buttonGroup: {
        marginTop: 30,
        marginBottom: 30,
        paddingRight: 10,
        borderRightColor: colors.primary,
        borderRightStyle: 'solid',
        borderRightWidth: 1
    },
    btnsWrap: {
        textAlign: "right",
        marginTop: 15,
    },
    backBtn: {
        borderWidth: 2
    },
    formControl: {
        padding: 0
    },
    helblodSubTitle: {
        color: "#E41937",
        fontWeight: "bold"
    },
    product: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
    }
}));
export default ProdList;
