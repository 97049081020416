import React, {useContext, useRef, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CanvasDraw from "react-canvas-draw";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {LocalizationContext} from "../context/localizationContext";

const Canvas = props => {
  const classes = useStyles();
  let windowWidth = document.documentElement.clientWidth;

  const {getTranslation} = useContext(LocalizationContext);

  const {
    referalData,
    setReferalData,
    canvasOpen,
    setCanvasOpen,
    handleSetSignature
  } = props;

  const canvas = useRef(null);

  const handleCanvasChange = () => {
    const base64 = canvas.current.canvasContainer.childNodes[1].toDataURL();
    setReferalData({...referalData, ["canvasSignature"]: base64});
  };

  const clearCanvas = () => {
    setReferalData({...referalData, ["canvasSignature"]: undefined});
    canvas.current.clear();
  };

  useEffect(() => {
    if(referalData.canvasSignature) {
      canvas.current.loadSaveData(referalData.canvasSignature, false);
    }
  }, []);

  return (
    <Dialog
      open={canvasOpen}
      onClose={() => setCanvasOpen(false)}
      className={classes.canvasDialog}
    >
      <DialogTitle>{getTranslation('canvas_sign_modal_title')}</DialogTitle>
      <DialogContent className={classes.canvasDialogContent}>
        <CanvasDraw canvasHeight={150} canvasWidth={windowWidth - 14} hideGrid={true} hideInterface={true}
                    brushRadius={2}
                    ref={canvas} onChange={handleCanvasChange} style={{border: "1px solid black"}}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCanvasOpen(false)} color="primary">
          {getTranslation('canvas_signature_cancel')}
        </Button>
        <Button onClick={clearCanvas} color="primary">
          {getTranslation('canvas_signature_clear')}
        </Button>
        <Button onClick={handleSetSignature} color="primary" variant={"contained"}>
          {getTranslation('canvas_signature_submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const useStyles = makeStyles(theme => ({
  canvasDialog: {
    width: "100%",
    maxWidth: "100%",

    "& .MuiDialog-paper": {
      margin: 5
    }
  },
  canvasDialogContent: {
    [theme.breakpoints.down('xs')]: {
      padding: "0px 2px",
    },

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center"
    }
  }
}));

export default Canvas;
