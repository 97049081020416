import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Button from './button';
const scripts = [
	// {
	// 	id: 'wgssSigCaptX',
	// 	file: '/wacom/wgssSigCaptX.js'
	// },
	{
		id: 'base64',
		file: '/wacom/base64.js'
	},
	{
		id: 'SigCaptX-Utils',
		file: '/wacom/SigCaptX-Utils.js'
	},
	{
		id: 'SigCaptX-SessionControl',
		file: '/wacom/SigCaptX-SessionControl.js'
	},
	{
		id: 'SigCaptX-Globals',
		file: '/wacom/SigCaptX-Globals.js'
	},
	{
		id: 'SigCaptX-Functions',
		file: '/wacom/SigCaptX-Functions.js'
	},
]
const Wacom = (props) => {
	const { text,subText, onSign, hash, patient, referral } = props;
	const [online, setOnline] = useState();
	const checkForOnline = () => {
		setTimeout(() => {
			if (!online && window.isOnline) {
				setOnline(true)
			}else{
				checkForOnline();
			}
		}, 500);
	}
	const startService = () => {
		setTimeout(() => {
			if (window.bodyOnLoad && document.getElementById('imageBox')) {
				window.bodyOnLoad()
				checkForOnline();
			} else {
				startService();
			}
		}, 500);
	}
	const loadScripts = async () => {
		await scripts.forEach((data, index) => {
			const { id, file } = data;
			const script = document.createElement('script');
			script.src = file;
			script.id = id;
			document.body.appendChild(script);
		})
		setTimeout(() => { startService() }, 500);
	}
	const unloadScripts = () => {
		scripts.forEach(data => {
			document.getElementById(data.id).remove();
		})
	}
	useEffect(() => {
		loadScripts();
		return () => {
			let event = new Event("unload", {}); // (2)
			window.dispatchEvent(event);
			unloadScripts();
		}
		// eslint-disable-next-line
	}, [])

	return <Fragment>
		<div id="imageBox" style={{
			height: "35mm",
			width: "60mm",
			border: "1px solid #d3d3d3",
			position: 'fixed',
			zIndex: -9999,
			visibility: 'hidden'
		}} />
		<Button text={text} subText={subText} disabled={!online} funcName={() => {
			if (online) {
				window.capture({ hash, patient, referral }, onSign)
			}
		}
		} />

	</Fragment>
}

export default Wacom