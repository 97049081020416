import React, {Fragment, useContext, useRef, useState} from 'react';
import Wacom from '../../../../components/wacom';
import {Print} from '@material-ui/icons';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, Hidden
} from "@material-ui/core";
import {LocalizationContext} from '../../../../context/localizationContext';
import {AuthContext} from '../../../../context/authContext';
import {RefContext} from '../../../../context/refContext';
import colors from '../../../../styles/Theme-settings';
import APICall from '../../../../functional/APIRequests';
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from 'react-router-dom';
import clsx from 'clsx'
import SVGSig from './sigSvg';
import Canvas from "../../../../components/Canvas";

const ReviewSignButtons = (props) => {
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const {id} = useParams();
  const {getTranslation} = useContext(LocalizationContext);
  const {setComplete, setSendStep, complete, sendStep} = useContext(RefContext);
  const {setLoading} = useContext(AuthContext);
  const {referalData, selectedOrg, otherIntake, sent, setSent, setReferalData, check, setCheck, submited} = props;
  const classes = useStyles();

  const submitSignature = (signature) => {
    setReferalData({...referalData, ["signature"]: signature});

    /*  setLoading(true);
      var form = new FormData();
      form.append("signature", signature);
      APICall({
        setLoading,
        url: `customer/${selectedOrg.id}/referral/${referalData.sys_id}/savesignature`,
        verb: 'post',
        server: 'api',
        formData: true,
        data: form,
      }).then((res) => {
        setLoading(false)
        setModal(true);
      })*/
  }
  /*const sendToLab = () => {
    setModal(false);
    setLoading(true);
    var form = new FormData();
    form.append("data[skip_signature]", check ? '0' : '1');
    form.append("data[send_digital]", '1');
    APICall({
      setLoading,
      url: `customer/${selectedOrg.id}/referral/${referalData.sys_id}/setcomplete`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    }).then((res) => {
      setComplete(true);
      setLoading(false)
      setModal(false);
      setSent(true);
    })

    setSendStep(3);
  }*/

  const handlePrintOnClick = () => {
    props.handlePrint();
  }

  const handleSignatureClick = () => {
    setSendStep(2);
  };

  const handleCanvasClick = () => {
    setCanvasOpen(true);
  };

  const handleSetSignature = () => {
    setCanvasOpen(false);
    setSendStep(2);
  };

  const isPossibleToUsePad = referalData.allowSignPad && !referalData.blockDigitalSign && !otherIntake;

  return (
    <Grid container>
      {/* <Dialog
        open={modal}
        onClose={() => setModal(false)}
      >
        <DialogTitle>{getTranslation('digital_sign_modal_title')}</DialogTitle>
        <DialogContent>
          {getTranslation('digital_sign_modal_body')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)} color="primary">
            {getTranslation('digital_sign_button_cancel')}
          </Button>
          <Button onClick={sendToLab} color="primary" variant={"contained"}>
            {getTranslation('digital_sign_modal_submit')}
          </Button>
        </DialogActions>
      </Dialog>*/}

      <Canvas
        referalData={referalData}
        setReferalData={setReferalData}
        canvasOpen={canvasOpen}
        setCanvasOpen={setCanvasOpen}
        handleSetSignature={handleSetSignature}
      />

      <Grid item xs={12} sm={12} style={{padding: 5}}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
              {getTranslation('sign_button_area_title')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!submited &&
      <>
        {/*Mobile view*/}
        {(!submited && !referalData.signature_file_id) &&
        <Hidden mdUp>
          {(!referalData.signature_file_id && sendStep >= 1) &&
          <Grid item xs={12} sm={12} style={{padding: '0px 15px'}}>
            {!referalData.canvasSignature &&
            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                    setReferalData({...referalData, ["canvasSignature"]: undefined, ["signature"]: undefined})
                  }}
                />
              }
              label={getTranslation('checklabel_require_digital_signature')}
            />
            }
          </Grid>
          }
        </Hidden>
        }
        <Hidden mdUp>
          {!referalData.signature_file_id &&
          <>
            {(check && !referalData.canvasSignature) &&
            <Grid item xs={12} sm={6} style={{padding: 5}}>
              <div
                className={clsx(classes.printButtonGroup, classes.green)} onClick={() => handleCanvasClick()}>
                <div className={classes.icon}>
                  <SVGSig/>
                </div>
                <div>
                  <Typography variant="subtitle1" className={classes.text}>
                    {getTranslation('wacom_signature_button')}
                  </Typography>
                  <Typography className={classes.underText}>
                    {getTranslation('canvas_signature_button_undertext')}
                  </Typography>
                </div>
              </div>
            </Grid>
            }
            {/*{(!check) &&
            <Grid item xs={12} sm={6} style={{padding: 5}}>
              <div
                className={clsx(classes.printButtonGroup, classes.green)} onClick={() => handleSignatureClick()}>
                <div className={classes.icon}>
                  <SVGSig/>
                </div>
                <div>
                  <Typography variant="subtitle1" className={classes.text}>
                    {getTranslation('button_send_digital_without_signature')}
                  </Typography>
                  <Typography className={classes.underText}>
                    {getTranslation('button_send_digital_without_signature_undertext')}
                  </Typography>
                </div>
              </div>
            </Grid>
            }*/}
          </>
          }
        </Hidden>

        <Hidden smDown>
          {(isPossibleToUsePad && !submited) &&
          <Grid item xs={12} sm={12} style={{padding: '0px 15px'}}>
            {!referalData.signature &&
            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                    setReferalData({...referalData, ["canvasSignature"]: undefined, ["signature"]: undefined})
                  }}
                />
              }
              label={getTranslation('checklabel_require_digital_signature')}
            />
            }
          </Grid>
          }
        </Hidden>

        <Hidden smDown>
          {/*{(!check) &&
          <Grid item xs={12} sm={6} style={{padding: 5}}>
            <div
              className={clsx(classes.printButtonGroup, classes.green)} onClick={() => handleSignatureClick()}>
              <div className={classes.icon}>
                <SVGSig/>
              </div>
              <div>
                <Typography variant="subtitle1" className={classes.text}>
                  {getTranslation('button_send_digital_without_signature')}
                </Typography>
                <Typography className={classes.underText}>
                  {getTranslation('button_send_digital_without_signature_undertext')}
                </Typography>
              </div>
            </div>
          </Grid>
          }*/}
          {(check && isPossibleToUsePad && !referalData.signature) &&
          <Grid item xs={12} sm={6} style={{padding: 5}}>
            <Wacom
              text={getTranslation('wacom_signature_button')}
              subText={getTranslation('wacom_signature_button_undertext')}
              onSign={submitSignature}
              hash={`${getTranslation('wacom_hash_label')}: ${referalData.hash}`}
              patient={referalData.initials}
              referral={`${getTranslation('wacom_referral_label')}: ${referalData.referral_id || referalData.id}`}
            />
          </Grid>
          }
        </Hidden>
      </>
      }
      {/*      <Grid item xs={12} sm={6} style={{padding: 5}}>
        {(id || referalData.sys_id) &&
        <Fragment>
          <div className={clsx(classes.printButtonGroup, isPossibleToUsePad ? classes.gray : classes.green)}
               onClick={() => handlePrintOnClick()}>
            <div className={classes.icon}>
              <Print style={{fill: colors.white, width: 30, height: 30}}/>
            </div>
            <div>
              <Typography variant="subtitle1" className={classes.text}>
                {getTranslation(isPossibleToUsePad ? 'referal_form_review_print' : 'referal_form_review_print_no_digital')}
              </Typography>
              <Typography className={classes.underText}>
                {getTranslation(isPossibleToUsePad ? 'referal_form_review_print_undertext' : 'referal_form_review_print_undertext_no_digital')}
              </Typography>
            </div>
          </div>
        </Fragment>
        }
      </Grid>*/}

      {(referalData.canvasSignature && check && !submited) &&
      <Hidden mdUp>
        <Grid item xs={12}>
          <Grid container alignItems="center" style={{marginTop: 20}}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><b>{getTranslation('referal_canvas_signature_header')}</b></Typography>
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={12}>
          <img src={referalData.canvasSignature} alt="Signature"/>
        </Grid>
      </Hidden>
      }
    </Grid>
  )
}
const useStyles = makeStyles(theme => ({
  printButtonGroup: {
    cursor: 'pointer',
    borderRadius: 3,
    color: colors.white,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row'
  }
  ,
  gray: {
    background: colors.textGray,
  }
  ,
  green: {
    background: colors.green,
  }
  ,
  text: {
    fontSize: 15,
  }
  ,
  underText: {
    fontSize: 10,
  },
  canvasDialog: {
    width: "90%",
    maxWidth: "90%"
  }
}));

export default ReviewSignButtons;
