import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {
  Button,
  Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, FormControlLabel, FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem, Radio, RadioGroup,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import {useLocation} from "react-router-dom";
import moment from "moment";
import {useSnackbar} from "notistack";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core/styles";
import colors from "../../../styles/Theme-settings";
import {LocalizationContext} from "../../../context/localizationContext";
import {RefContext} from "../../../context/refContext";
import {AuthContext} from "../../../context/authContext";
import {emailRegex, generatePreviewImgUrl} from "../../../functional/etc";
import APICall from "../../../functional/APIRequests";
import {OrgContext} from "../../../context/orgContext";
import {PersonelContext} from "../../../context/personelContext";
import {PatientContext} from "../../../context/patientContext";
import {Markup} from "interweave";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import colorConfig from "../../../styles/Theme-settings";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";

const FormData = props => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const {loading, setLoading, user} = useContext(AuthContext);
  const {getPersonel} = useContext(PersonelContext);
  const {getTranslation, lang} = useContext(LocalizationContext);
  const {patientData, setPatientData} = useContext(PatientContext);
  const {
    setReferalData,
    referalData,
    changeStateStep,
    formType,
    steps,
    activeStep,
    validSsn,
    setValidSsn,
    selectedPatientOption,
    setSelectedPatientOption,
    formValidator,
    pnrInUse,
    setPnrInUse
  } = useContext(RefContext);
  const {selectedOrg} = useContext(OrgContext);
  let location = useLocation();
  const [prodList, setProdList] = useState();
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState({
    email: '',
    name: '',
  });
  const [ssnModal, setSsnModal] = useState(false);
  const [existingSsnModal, setExistingSsnModal] = useState(false);
  const [initialExistingPatientInfo, setInitialExistingPatientInfo] = useState({name: "", pnr: ""});
  const [existingPatientInvalidPnr, setExistingPatientInvalidPnr] = useState(false);
  const [dateInfoModal, setDateInfoModal] = useState(false);
  const [patients, setPatients] = useState([]);

  const autoC = useRef(null);

  const {handleNext, handleBack} = props;

  moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');

  const handleChange = (e) => {
    setReferalData({...referalData, [e.target.name]: e.target.value});
  };

  const fetchOrgPatients = (refresh = false, getAnonymized = false) => {
    let url = `customer/${selectedOrg.id}/patients?limit=999999&page=1&sort=patient.name asc&filter[archivePatient]=0`;

    if(getAnonymized) {
      url += `&filter[anonymizePatient]=1`;
    } else {
      url += `&filter[anonymizePatient]=0`;
    }

    APICall({
      setLoading,
      enqueueSnackbar,
      url: url,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      if (res) {
        setPatients([{id: 0}, ...res.data.items])
      }
      setLoading(false)
    })
  }

  const handleDateChange = (e, input) => {
    setReferalData({...referalData, [input]: e});
    if (input === "date") {
      let now = moment();
      if (now.startOf("day").unix() > moment(e).unix()) {
        setDateInfoModal(true);
      }
    }
  };

  const handleSelectChange = (e) => {
    let selectedProd = prodList.find(product => product.sys_id === e.target.value);
    if (e.target.name === 'quickTestId') {
      setReferalData({...referalData, [e.target.name]: e.target.value, quickTestIdName: selectedProd.name})
    } else {
      setReferalData({...referalData, [e.target.name]: e.target.value})
    }
  };

  const handleBrandFilesChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (file.size > 20971520 || file.size < 1) {
        enqueueSnackbar(file.size > 20971520 ? getTranslation('generic_error_file_size_big') : getTranslation('generic_error_file_size_small'), {variant: 'error'})
      } else {
        let formatList = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff'];
        if (formatList.includes(file.type)) {
          generatePreviewImgUrl(file, previewImgUrl => {
            setReferalData({
              ...referalData,
              image: file,
              imageBase64: previewImgUrl
            });
            document.getElementById('fileUpload').value = "";
          })
        } else {
          enqueueSnackbar(getTranslation('generic_error_file_type'), {variant: 'error'})
        }
      }
    }
  };

  const fetchData = (e) => {
    try {
      e.preventDefault()
    } catch (err) {
    }
    ;
    setLoading(true)
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `quicktest`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      if (res) {
        setProdList(res.data)
      }
      setLoading(false)
    })
  };

  const fetchPersonnel = () => {
    getPersonel('', selectedOrg.id).then(res => {
      let usersNotify = referalData.usersNotify ? JSON.parse(JSON.stringify(referalData.usersNotify)) : []
      if (!referalData.usersNotify && !usersNotify.includes(user.id)) {
        usersNotify.push(user.id)
      }
      if (patientData && location.pathname.indexOf("filled") > -1) {
        setReferalData(prevState => ({
          ...prevState,
          patient_name: patientData.name,
          patient_pnr: patientData.pnr,
          usersNotify: usersNotify || [],
          patient_id: patientData.id
        }));
        setSelectedPatientOption(0);
        setValidSsn(true);
      } else {
        setReferalData({...referalData, usersNotify: usersNotify || []})
      }
      setUserList((res.data || []));
    })
  };

  const handleChangeNotSel = event => {
    setReferalData({...referalData, usersNotify: event.target.value})
  };

  const fetchProfile = (e) => {
    try {
      e.preventDefault()
    } catch (err) {
    }
    ;
    setLoading(true)
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `${`${selectedOrg.id === 1 ? 'prodiagnostics' : 'customer'}`}/${selectedOrg.id}/profile`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      if (res) {
        setUserData({
          email: res.data.email,
          name: res.data.name
        })
      }
      setLoading(false)
    })
  };

  const applySelfInfo = (e) => {
    e.preventDefault();
    setReferalData({...referalData, ...userData})
  };

  const isSsnValid = (name, pnr, update = false, firstCheck = false) => {
    APICall({
      url: `customer/${selectedOrg.id}/referral/patientpnr?pnr=${pnr ? pnr : referalData.patient_pnr}`,
      verb: 'get',
      server: 'api',
    }).then(res => {
      setValidSsn(res.data);
      if (selectedPatientOption === 0) {
        setInitialExistingPatientInfo({name: name, pnr: pnr});
        setExistingSsnModal(!res.data);
        if (!res.data && !firstCheck) {
          setExistingPatientInvalidPnr(true);
          return;
        }
        if (update) {
          updatePatient();
        }
      } else {
        setSsnModal(!res.data);
      }
    })
  };

  const isSsnInUse = () => {
    APICall({
      url: `customer/${selectedOrg.id}/referral/patientpnr?pnr=${referalData.patient_pnr}&organisation_id=${selectedOrg.id}`,
      verb: "get",
      server: "api"
    })
      .then(res => {
        setPnrInUse(res.data);
      })
  }

  const updatePatient = () => {
    let formData = new FormData();
    formData.append("data[name]", referalData.patient_name);
    formData.append("data[pnr]", referalData.patient_pnr);

    APICall({
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/patients/${referalData.patient_id}`,
      verb: "post",
      server: "api",
      data: formData
    })
      .then(res => {
        if (res) {
          setReferalData({...referalData, ["patient_pnr"]: res.data.pnr, ["patient_name"]: res.data.name});
          fetchOrgPatients(true, false);
          setExistingSsnModal(false);
          setExistingPatientInvalidPnr(false)
        }
      })
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 200,
  });

  useEffect(() => {
    if (formValidator() && !steps[activeStep].valid) {
      setLoading(true)
      changeStateStep('validChange', 0, true)
      setTimeout(() => {
        setLoading(false)
      }, 100);
    } else if (!formValidator() && !!steps[activeStep].valid) {
      setLoading(true)
      changeStateStep('validChange', 0, false)
      setTimeout(() => {
        setLoading(false)
      }, 1);
    }
    // eslint-disable-next-line
  }, [referalData]);

  const handleCloseExistingSsnModal = () => {
    setReferalData({
      ...referalData,
      ["patient_name"]: "",
      ["patient_pnr"]: "",
      ["patient_id"]: ""
    });
    const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (ele) ele.click();
    setExistingSsnModal(false);
  };

  useEffect(() => {
    if (formType === 2) {
      fetchData()
    }
    fetchPersonnel();
    fetchProfile();

    return () => {
      setPatientData({
        id: "",
        pnr: "",
        name: "",
        email: "",
        phone: "",
        cdate: "",
        mdate: "",
      })
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Number(selectedOrg.patientmodule) === 1 && patients.length === 0)
      fetchOrgPatients(true, false);
  }, []);

  const showContinueButton = () => {
    if (Number(selectedOrg.patientmodule) === 1) {
      return formValidator() && validSsn;
    } else {
      return formValidator();
    }
  }

  return (
    <>
      {formType === 2 &&
      <React.Fragment>
        <Grid item xs={12} style={{marginTop: 20}}>
          <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
            {getTranslation('referal_form_step_two_title_warranty')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            className={classes.formControlSelectInput}
            error={
              !referalData.quickTestId ||
              (referalData.quickTestId && referalData.quickTestId.length === 0)
            }>
            <InputLabel id="quickTestLabel"
                        style={{paddingLeft: 10}}>{getTranslation('warranty_report_form_test_type')}</InputLabel>
            <Select
              labelId="quickTestLabel"
              style={{paddingLeft: 10}}
              // disabled={loading}
              value={referalData.quickTestId || ''}
              name='quickTestId'
              onChange={handleSelectChange}
              error={!referalData.quickTestId || (referalData.quickTestId && referalData.quickTestId.length === 0)}
            >
              <MenuItem disabled={true} value={''}>{getTranslation('generic_select_one')}</MenuItem>
              {prodList &&
              prodList.map(item => {
                return <MenuItem value={item.sys_id} key={item.sys_id}>{item.name}</MenuItem>
              })}
            </Select>
            {(!referalData.quickTestId || (referalData.quickTestId && referalData.quickTestId.length === 0)) &&
            <FormHelperText style={{marginLeft: 10}}>{getTranslation('generic_error_required_field')}</FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <KeyboardDateTimePicker
            className={classes.dateInput}
            format="YYYY-MM-DD HH:mm"
            autoOk
            InputAdornmentProps={{position: "start"}}
            label={getTranslation('warranty_report_form_test_date')}
            // disabled={loading}
            value={referalData.dateWarranty || null}
            onChange={(e) => {
              if (e.isValid()) {
                handleDateChange(e, 'dateWarranty')
              } else {
                handleDateChange(null, 'dateWarranty')

              }
            }}
            error={!referalData.dateWarranty || (referalData.dateWarranty && referalData.dateWarranty.length === 0)}
            helperText={(!referalData.dateWarranty || (referalData.dateWarranty && referalData.dateWarranty.length === 0)) ? getTranslation('generic_error_required_field') : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            className={classes.textInPut}
            label={getTranslation('warranty_report_form_test_id')}
            name='warrantyLotId'
            // disabled={loading}
            value={referalData.warrantyLotId}
            onChange={handleChange}
            error={!referalData.warrantyLotId || (referalData.warrantyLotId && referalData.warrantyLotId.length === 0)}
            helperText={(!referalData.warrantyLotId || (referalData.warrantyLotId && referalData.warrantyLotId.length === 0)) ? getTranslation('generic_error_required_field') : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            className={classes.textInPut}
            label={getTranslation('warranty_report_form_test_description')}
            name='warrantyComments'
            multiline
            rowsMax="6"
            // disabled={loading}
            value={referalData.warrantyComments}
            onChange={handleChange}
            error={false}
          />
        </Grid>
        <Grid item xs={6} style={{marginTop: 10}}>
          <Button
            name="image"
            disabled={loading}
            className={classes.cartButton}
            variant="contained"
            color="primary"
            onClick={() => {
              document.getElementById('fileUpload').click();
            }}
          >
            {getTranslation('warranty_report_form_button_upload')}
          </Button>
          {!referalData.imageBase64 &&
          <Typography variant="body2"
                      className={classes.imageErrorText}>{getTranslation('generic_error_required_field')}</Typography>
          }
          <input id='fileUpload' onChange={handleBrandFilesChange} hidden type='file'
                 accept=".jpg,.jpeg,.png,.gif,.tiff"/>
        </Grid>
        {referalData.imageBase64 &&
        <Grid container justify='center' xs={12}>
          <div className={classes.imageContainer} style={{backgroundImage: `url("${referalData.imageBase64}")`}}/>
        </Grid>
        }
      </React.Fragment>
      }

      <Grid item xs={12} className={classes.marginTop}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1"><b>{getTranslation("referal_form_test_info_header")}</b></Typography>
          </Grid>

          <Grid item xs={12} sm={6} className={classes.importInfoWrap}>
            <PersonAddOutlinedIcon className={classes.importInfoIcon}/>
            <Typography
              variant="caption"
              className={classes.importInfoText}
              onClick={applySelfInfo}
            >
              {getTranslation("referral_form_import_self_data")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="filled"
          label={getTranslation('referal_form_label_tester_name')}
          name='name'
          className={classes.textInPut}
          disabled={loading}
          value={referalData.name || ''}
          onChange={handleChange}
          error={!referalData.name || (referalData.name && referalData.name.length === 0)}
          helperText={!referalData.name || referalData.name.length === 0 ? getTranslation('generic_error_required_field') : ''}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="filled"
          label={getTranslation('referal_form_label_email')}
          error={!referalData.email || (referalData.email.length > 0 && !emailRegex.test(referalData.email))}
          name='email'
          className={classes.textInPut}
          // disabled={loading}
          value={referalData.email || ''}
          onChange={handleChange}
          type='email'
          helperText={!referalData.email || referalData.email.length === 0 ? getTranslation('generic_error_required_field') : referalData.email.length > 0 && !emailRegex.test(referalData.email) ? getTranslation('generic_email_regex_fail') : ''}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl className={classes.formControlSelectInput}>
          <InputLabel style={{marginLeft: 10}}>{getTranslation('referal_form_label_notifications')}</InputLabel>
          <Select
            disabled={loading}
            style={{paddingLeft: 10}}
            value={referalData.usersNotify || []}
            multiple={true}
            onChange={handleChangeNotSel}
            input={<Input/>}
            renderValue={selected => {
              let renderV = [];
              selected.forEach(s => {
                if (userList) {
                  let tempHold = userList.find(u => u.sys_id === s);
                  if (tempHold) {
                    renderV.push(tempHold.name)
                  }
                }
              })
              return renderV.join(', ')
            }}
          >
            {userList &&
            userList.map((u, i) => {
              // if (u.sys_id !== user.id) {
              return <MenuItem key={u.sys_id} value={u.sys_id}>
                <Checkbox checked={
                  referalData.usersNotify ? referalData.usersNotify.findIndex(nu => nu === u.sys_id) > -1 : false
                }
                />
                <div style={{float: "left"}}>
                  {u.name}
                </div>
              </MenuItem>
              // }
            })
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <KeyboardDateTimePicker
          className={classes.dateInput}
          format="YYYY-MM-DD HH:mm"
          label={getTranslation('referal_form_label_sample_date')}
          ampm={false}
          autoOk
          InputAdornmentProps={{position: "start"}}
          // disabled={loading}
          value={referalData.date || null}
          onChange={(e) => {
            if (e.isValid()) {
              handleDateChange(e, 'date')
            } else {
              handleDateChange(null, 'date')
            }
          }}
          error={!referalData.date || (referalData.date && referalData.date.length === 0)}
          helperText={!referalData.date || referalData.date.length === 0 ? getTranslation('generic_error_required_field') : ''}
        />
      </Grid>

      <Grid item xs={12} className={classes.marginTop}>
        <Typography variant="subtitle1"><b>{getTranslation("referral_form_patient_info_header")}</b></Typography>
      </Grid>

      {Number(selectedOrg.patientmodule) === 1 &&
      <>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.radioFormControl}>
            <RadioGroup row aria-label="patient" name="patient" value={selectedPatientOption}
                        onChange={(e) => {
                          if (Number(e.target.value) === 0) {
                            fetchOrgPatients(true, false);
                          }
                          setReferalData({...referalData, ["patient_name"]: "", ["patient_pnr"]: ""})
                          setSelectedPatientOption(Number(e.target.value));
                          setValidSsn(false);
                        }}
                        className={classes.radioGroup}
            >
              <FormControlLabel value={0} control={<Radio/>}
                                label={getTranslation("referral_form_existing_patient_checkbox")}
                                style={{marginRight: 20}}/>
              <FormControlLabel value={1} control={<Radio/>}
                                label={getTranslation("referral_form_new_patient_checkbox")}/>
            </RadioGroup>
          </FormControl>

          {(selectedPatientOption === 0 && patients.length > 0) &&
          <Autocomplete
            ref={autoC}
            classes={{
              option: classes.autocompleteOption,
              listbox: classes.listboxAutocomplete
            }}
            options={patients}
            filterOptions={filterOptions}
            value={patients.find(p => p.id === referalData.patient_id)}
            onChange={(e, newValue) => {
              if (newValue) {
                setReferalData({
                  ...referalData,
                  ["patient_name"]: newValue.name,
                  ["patient_pnr"]: newValue.pnr,
                  ["patient_id"]: newValue.id
                });
                isSsnValid(newValue.name, newValue.pnr, false, true);
              }
            }}
            renderInput={params => <TextField {...params}
                                              label={getTranslation("referral_form_new_patient_dropdown_label")}
                                              variant="filled"
                                              error={!referalData.patient_pnr || !referalData.patient_name || ((referalData.patient_pnr && referalData.patient_pnr.length === 0) || (referalData.patient_name && referalData.patient_name.length === 0))}
                                              helperText={!referalData.patient_pnr || !referalData.patient_name || referalData.patient_pnr.length === 0 || referalData.patient_name.length === 0 ? getTranslation('generic_error_required_field') : ''}
            />}
            getOptionSelected={(option, value) => option.id !== 0 && option.id === value.id}
            renderOption={(selected) => (
              <>
                {selected.id === 0 ?
                  <Grid container>
                    <Grid item xs={7} className="referral-patient-label-option">
                      <b>{getTranslation("referral_form_existing_patient_name_label")}</b>
                    </Grid>
                    <Grid item xs={5} className="referral-patient-label-option">
                      <b>{getTranslation("referral_form_existing_patient_pnr_label")}</b>
                    </Grid>
                  </Grid>
                  :
                  <Grid container>
                    <Grid item xs={7}>
                      {selected.name}
                    </Grid>
                    <Grid item xs={5}>
                      {selected.pnr}
                    </Grid>
                  </Grid>
                }
              </>
            )}
            getOptionLabel={(selected) =>
              (referalData.patient_name && referalData.patient_pnr) ? `${referalData.patient_name}  ${referalData.patient_pnr}` : ""
            }
          />
          }
          {selectedPatientOption === 1 &&
          <TextField
            variant="filled"
            className={classes.textInPut}
            label={getTranslation('referal_form_label_patient_name')}
            name='patient_name'
            // disabled={loading}
            value={referalData.patient_name || ''}
            onChange={handleChange}
            error={!referalData.patient_name || (referalData.patient_name && referalData.patient_name.length === 0)}
            helperText={!referalData.patient_name || referalData.patient_name.length === 0 ? getTranslation('generic_error_required_field') : ''}
          />
          }
        </Grid>
        <Grid item xs={12}>
          {selectedPatientOption === 1 &&
          <TextField
            variant="filled"
            className={clsx(classes.textInPut, classes.ssnInput)}
            label={getTranslation('referal_form_label_patient_ssn')}
            name='patient_pnr'
            // disabled={loading}
            value={referalData.patient_pnr || ''}
            onChange={handleChange}
            onBlur={() => {
              if (referalData.patient_pnr) {
                isSsnValid("", "", false, false);
                isSsnInUse();
              }
            }}
            error={!referalData.patient_pnr || (referalData.patient_pnr && referalData.patient_pnr.length === 0)}
            helperText={!referalData.patient_pnr || referalData.patient_pnr.length === 0 ? getTranslation('generic_error_required_field') : (pnrInUse ? getTranslation("referal_form_ssn_used") : '')}
          />
          }
        </Grid>
      </>
      }

      {Number(selectedOrg.patientmodule) === 0 &&
      <Grid item xs={12}>
        <TextField
          variant="filled"
          label={getTranslation('referal_form_label_patient')}
          name='initials'
          className={classes.textInPut}
          // disabled={loading}
          value={referalData.initials || ''}
          onChange={handleChange}
          inputProps={{maxLength: 5}}
          error={!referalData.initials || (referalData.initials && referalData.initials.length === 0)}
          helperText={!referalData.initials || referalData.initials.length === 0 ? getTranslation('generic_error_required_field') : ''}
        />
      </Grid>
      }

      <Grid item xs={12}>
        <TextField
          variant="filled"
          label={getTranslation('referal_form_label_drug_history')}
          name='historic'
          className={classes.textInPut}
          multiline
          rowsMax="4"
          // disabled={loading}
          value={referalData.historic}
          onChange={handleChange}
          error={false}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="filled"
          label={getTranslation('referal_form_label_comments_mobile')}
          name='labComments'
          className={classes.textInPut}
          multiline
          rowsMax="4"
          value={referalData.labComments}
          onChange={handleChange}
          error={false}
        />
      </Grid>

      <Grid item xs={12} className={classes.nextBtnWrap}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleBack}
          className={classes.backBtn}
        >
          {getTranslation("referral_previous_step_btn")}
        </Button>
        {showContinueButton() &&
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
        >
          {getTranslation("referral_next_step_btn")}
        </Button>
        }
      </Grid>
      <Dialog open={ssnModal} onClose={() => setSsnModal(false)}>
        <DialogTitle>
          <Markup
            content={selectedOrg.perm === 1 ? getTranslation('referal_form_invalid_ssn_modal_title_admin') : getTranslation('referal_form_invalid_ssn_modal_title_user')}
          />
        </DialogTitle>
        <DialogContent>
          <Markup
            content={selectedOrg.perm === 1 ? getTranslation('referal_form_invalid_ssn_modal_content_admin') : getTranslation('referal_form_invalid_ssn_modal_content_user')}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSsnModal(false)}
          >
            {getTranslation('referal_form_invalid_ssn_modal_ok_button')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={existingSsnModal} onClose={handleCloseExistingSsnModal} maxWidth="md">
        <DialogContent className={classes.existingPatientModalContent}>
          <Grid container spacing={4}>
            <Grid item xs={6} style={{display: "flex"}}>
              <div>
                <Typography variant="h5"
                            className={classes.modalSectionTitle}>{getTranslation("referal_form_modal_patient_info")}</Typography>
                <Typography
                  variant="subtitle2">{getTranslation('referal_form_existing_patient_modal_name')}</Typography>
                <TextField
                  variant="outlined"
                  name='patient_name'
                  value={referalData.patient_name || ''}
                  onChange={handleChange}
                  className={clsx(classes.updatePatientInput, classes.updatePatientInputName)}
                />
                <Typography variant="subtitle2">{getTranslation('referal_form_existing_patient_modal_pnr')}</Typography>
                <TextField
                  variant="outlined"
                  name='patient_pnr'
                  value={referalData.patient_pnr || ''}
                  onChange={handleChange}
                  className={classes.updatePatientInput}
                />
                {existingPatientInvalidPnr &&
                <Typography variant="body2"
                            className={classes.invalidPnrText}>{getTranslation('referal_form_existing_patient_modal_invalid_pnr_text')}</Typography>
                }

                <Button
                  variant="contained"
                  onClick={() => isSsnValid("", "", true, false)}
                  fullWidth
                  className={classes.updatePatientBtn}
                >
                  {getTranslation("referal_form_modal_update_patient_button")}
                </Button>
              </div>
              <div className={classes.divider}></div>
            </Grid>

            <Grid item xs={6} className={classes.infoWrap}>
              <Typography variant="h5"
                          className={classes.modalSectionTitle}>{getTranslation("referal_form_modal_info")}</Typography>
              <Markup
                content={selectedOrg.perm === 1 ? getTranslation('referal_form_existing_patient_invalid_ssn_modal_content_admin') : getTranslation('referal_form_existing_patient_invalid_ssn_modal_content_user')}
              />
              <CloseIcon className={classes.cancelModalIcon} onClick={handleCloseExistingSsnModal}/>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={dateInfoModal} onClose={() => setDateInfoModal(false)} maxWidth="md">
        <DialogTitle>
          {getTranslation("referal_form_date_modal_header")}
        </DialogTitle>
        <DialogContent>
          {getTranslation("referal_form_date_modal_content")}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDateInfoModal(false)}
          >
            {getTranslation('referal_form_date_modal_ok_button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
};

const useStyles = makeStyles(theme => ({
  importInfoWrap: {
    background: "#222222",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: colors.white,
    padding: 7,
  },
  importInfoIcon: {
    fill: colors.white,
    marginRight: 10,
    width: 22,
    height: 22
  },
  importInfoText: {
    fontSize: 13
  },
  textInPut: {
    paddingLeft: 0,
    "& .MuiFormLabel-root": {
      marginLeft: "-10px"
    }
  },
  marginTop: {
    marginTop: 20
  },
  cartButton: {
    padding: "10px 15px"
  },
  imageErrorText: {
    color: "#ff1744",
    fontSize: 14,
    fontWeight: 400,
    marginTop: 5
  },
  imageContainer: {
    marginTop: 10,
    height: 200,
    width: 300,
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  nextBtnWrap: {
    textAlign: "right",
    marginTop: 15
  },
  formControlSelectInput: {
    marginLeft: "-10px",
    [theme.breakpoints.down('xs')]: {
      "& .MuiSelect-root": {
        paddingTop: 15
      },
      "& .MuiFormLabel-root": {
        width: "100%"
      }
    }
  },
  dateInput: {
    marginLeft: "-10px",
    "& .MuiFormHelperText-root": {
      marginLeft: 10
    },
    "& .MuiFormLabel-root": {
      marginLeft: 10
    }
  },
  backBtn: {
    borderWidth: 2,
    marginRight: 10
  },
  radioFormControl: {
    paddingBottom: 0
  },
  radioGroup: {
    padding: "0px 10px"
  },
  autocompleteOption: {
    "& .referral-patient-label-option": {
      color: colorConfig.primary,
      cursor: "default",
    }
  },
  listboxAutocomplete: {
    "& > *:first-child": {
      backgroundColor: "white !important",
      pointerEvents: "none",
      "&:hover": {
        backgroundColor: "white !important"
      },
      "&:focus": {
        backgroundColor: "white !important"
      }
    },
    '& > *:nth-child(n+2)': {
      padding: "0px 16px"
    }
  },
  updatePatientBtn: {
    background: "#588d2d",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "rgba(88, 141, 45, 0.9)"
    },
  },
  updatePatientInput: {
    padding: "4px 0px 10px 0px"
  },
  updatePatientInputName: {
    marginBottom: 10
  },
  modalSectionTitle: {
    marginBottom: 20,
    fontWeight: "bold"
  },
  infoWrap: {
    position: "relative",
    paddingLeft: "44px !important",
    marginBottom: 30
  },
  existingPatientModalContent: {
    overflowY: "hidden"
  },
  cancelModalIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
    height: 24,
    width: 24,
    fill: "#707070"
  },
  invalidPnrText: {
    color: "#ff0000",
    marginBottom: 5,
    marginTop: "-5px"
  },
  divider: {
    borderRight: "1px solid #DCDCDC",
    marginLeft: 24,
    marginBottom: 15
  },
  ssnInput: {
    "& .MuiFormHelperText-root": {
      color: "#ff1744"
    }
  }
}));

export default FormData;
