import React, {useState, useEffect, useContext, createContext} from 'react';
import {useSnackbar} from 'notistack';
import APICall from '../functional/APIRequests';
import {OrgContext} from './orgContext';
import {LocalizationContext} from "./localizationContext";

const PatientContext = createContext({});

const PatientProvider = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const {selectedOrg} = useContext(OrgContext);
    const {getTranslation} = useContext(LocalizationContext);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [sort, setSort] = useState({column: 'id', by: 'asc'});
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [patientData, setPatientData] = useState({
        id: "",
        pnr: "",
        name: "",
        email: "",
        phone: "",
        cdate: "",
        mdate: "",
        archivedpatient_by: "",
        archivedpatient_at: "",
        user_archivedpatient: "",
        organisation_id: ""
    });
    const [filtersData, setFiltersData] = useState({
        phone: "",
        email: "",
        archived: "0"
    });

    const fetchData = (resetView = false) => {
        setLoading(true);
        let column = sort.column === "email" ? `patient.email` : sort.column;

        let url = `customer/${selectedOrg.id}/patients?limit=${rowsPerPage}&page=${page + 1}&sort=${column} ${sort.by}&filter[anonymizePatient]=0`;

        if (resetView) {
            setFiltersData({
                ...filtersData,
                ["email"]: "",
                ["phone"]: "",
                ["archived"]: "0"
            });

            setSearch("");

            url += `&filter[archivePatient]=0`
        } else {
            if (search) {
                url += `&filter[search]=${search}`
            }

            if (filtersData.phone) {
                url += `&filter[phone]=${filtersData.phone}`
            }
            if (filtersData.email) {
                url += `&filter[email]=${filtersData.email}`
            }

            if (filtersData.archived === "0") {
                url += `&filter[archivePatient]=0`
            } else if (filtersData.archived === "1") {
                url += `&filter[archivePatient]=1`
            }
        }

        APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: url,
            verb: "get",
            server: "api"
        })
            .then(res => {
                if (res) {
                    setData(res.data.items);
                    setTotalItems(res.data.total_items);
                }
                setLoading(false);
            })
    };

    const fetchPatient = id => {
        setLoading(true);

        APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/patients?limit=1&page=1&sort=pnr desc&filter[id]=${id}`,
            verb: "get",
            server: "api"
        })
            .then(res => {
                setPatientData(res.data.items[0]);
                setLoading(false);
            })
    };

    const updatePatient = id => {
        setLoading(true);

        let formData = new FormData();
        formData.append("data[name]", patientData.name);
        formData.append("data[phone]", patientData.phone);
        formData.append("data[email]", patientData.email);

        APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/patients/${patientData.id}`,
            verb: "post",
            server: "api",
            data: formData
        })
            .then(res => {
                if (res) {
                    enqueueSnackbar(getTranslation('generic_submited_success'), {variant: "success"});
                }
                setLoading(false);
            })
    };

    const fetchPatientReferrals = params => {
        setLoading(true);

        return APICall({
            setLoading,
            hasRefresh: true,
            enqueueSnackbar,
            url: `customer/${selectedOrg.id}/patients/${params.id}/referrals?limit=${params.rowsPerPage}&page=${params.page + 1}&sort=${params.sort.column} ${params.sort.by}`,
            verb: "get",
            server: "api"
        })
    };

    return (
        <PatientContext.Provider value={{
            //States
            data,
            page,
            rowsPerPage,
            loading,
            sort,
            totalItems,
            search,
            patientData,
            filtersOpen,
            filtersData,
            //Update States
            setPage,
            setRowsPerPage,
            setSort,
            setSearch,
            setPatientData,
            setLoading,
            setFiltersOpen,
            setFiltersData,
            //Functions
            fetchData,
            fetchPatient,
            fetchPatientReferrals,
            updatePatient
        }}>
            {props.children}
        </PatientContext.Provider>
    )
};

export {PatientContext, PatientProvider};
