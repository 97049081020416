import React, {useContext, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Media from "react-media";
import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import Mobile from "./mobile";
import Desktop from "./desktop";
import {LocalizationContext} from "../../context/localizationContext";
import {RefContext} from "../../context/refContext";
import APICall from "../../functional/APIRequests";
import {OrgContext} from "../../context/orgContext";
import {apiServer} from "../../functional/generateEnvUrls";

const Referral = props => {
  const classes = useStyles();
  const {id} = useParams();
  const {selectedOrg} = useContext(OrgContext);
  const {getTranslation} = useContext(LocalizationContext);
  const {enqueueSnackbar} = useSnackbar();
  const {
    activeStep,
    setActiveStep,
    setFormType,
    setSubmited,
    cleanStorage,
    formType,
    referalData,
    setReferalData,
    fetchData,
  } = useContext(RefContext);

  const [modal, setModal] = useState(false);
  const [quickTests, setQuickTests] = useState([]);

  const pathName = props.history.location.pathname;
  formType === 1 ? document.title = getTranslation('referral_form_browser_title') : document.title = getTranslation('warranty_form_browser_title') + ' | Prodiagnostics';

  const _handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }
  const handleBlockDigitalSign = () => {
    setModal(false);
    setReferalData(prevState => ({...prevState, blockDigitalSign: true}));
    _handleNext();
  };

  const handleNext = () => {
    if (activeStep === 0 && referalData.allowSignPad && !(!referalData.labComments || referalData.labComments.length === 0)) {
      setModal(true);
    } else {
      _handleNext();
    }
  };
  const fetchQuickTests = () => {
    APICall({
      enqueueSnackbar,
      url: `quicktest`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      if (res) {
        setQuickTests(res.data)
      }
    })
  };

  const getQuickTestName = id => {
    let quickTest = quickTests.find(item => item.sys_id === id);

    return quickTest.name || "";
  };

  const handleImageClick = (openSecundary = false) => {
    if(openSecundary){
        if(!referalData.secundary_file_id)
            return

        let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${id}/secundaryfile`;
        return window.open(url, '_blank')
    }

    if (!referalData.file_id)
      return;
    //let url = `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${id}/file`;
    let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${id}/file`;
    window.open(url, '_blank')
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const openPDF = () => {
    //let url = `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${id || referalData.sys_id}/pdf`;
    let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/referral/${id || referalData.sys_id}/pdf`;
    window.open(url, "_blank");
  }

  useEffect(() => {
    if (pathName.search('warranty') > -1 && id) {
      fetchData(id)
      fetchQuickTests();
    }
  }, []);

  useEffect(() => {
    if (!id) {
      cleanStorage();
    } else {
      setSubmited(true);
    }
    setFormType(pathName.search('referral') > -1 ? 1 : 2);
    // eslint-disable-next-line
  }, [pathName]);

  useEffect( () => () => setReferalData({}), [] );

  return (
    <Grid container className={classes.container}>
      <Dialog
        open={modal}
        onClose={() => setModal(false)}
      >
        <DialogTitle>{getTranslation('digital_sign_not_allowed_with_comments_modal_title')}</DialogTitle>
        <DialogContent>
          {getTranslation('digital_sign_not_allowed_with_comments_body')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)} color="primary">
            {getTranslation('digital_sign_not_allowed_with_comments_cancel')}
          </Button>
          <Button onClick={handleBlockDigitalSign} color="primary" variant={"contained"}>
            {getTranslation('digital_sign_not_allowed_with_comments_submit')}
          </Button>
        </DialogActions>
      </Dialog>

      <Media queries={{
        desktop: "(min-width: 1025px)",
        tablet: "(min-width: 768px) and (max-width: 1024px)",
        mobile: "(max-width: 767px)"
      }}>
        {matches => (
          <>
            {matches.desktop &&
            <Desktop
              handleBack={handleBack}
              handleNext={handleNext}
              id={id}
              quickTests={quickTests}
              getQuickTestName={getQuickTestName}
              handleImageClick={handleImageClick}
              pathName={pathName}
              openPDF={openPDF}
              {...props}
            />}
            {(matches.tablet || matches.mobile) &&
            <Mobile
              handleBack={handleBack}
              handleNext={handleNext}
              id={id}
              quickTests={quickTests}
              getQuickTestName={getQuickTestName}
              handleImageClick={handleImageClick}
              pathName={pathName}
              openPDF={openPDF}
              {...props}
            />}
          </>
        )}
      </Media>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default Referral;
