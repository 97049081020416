import React, {useContext, useEffect, useState} from "react";
import {Button, ButtonGroup, Grid, Typography} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";
import {LocalizationContext} from "../../context/localizationContext";
import {makeStyles} from "@material-ui/core/styles";
import {AuthContext} from "../../context/authContext";
import {OrgContext} from "../../context/orgContext";
import {Markup} from "interweave";

const Workplace = props => {
  const classes = useStyles();
  const {getTranslation} = useContext(LocalizationContext);
  const {logout, user} = useContext(AuthContext);
  const {selectedOrg, getOrganizationById} = useContext(OrgContext);
  const [orgName, setOrgName] = useState(null);

  const phone = useMediaQuery('(max-width: 767px)');

  const getOrgName = () => {
    getOrganizationById({idOrg: selectedOrg.id})
      .then(res => {
        setOrgName(res.name);
      })
  }

  useEffect(() => {
    getOrgName();
  }, [])

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="center" alignContent="space-between"
            style={{height: "100%", paddingBottom: 30, paddingTop: 75}}>
        {/*Page Title*/}
        <Grid item xs={12} className={clsx(classes.row, classes.titleRow)}>
          <Typography variant="h4" className={classes.title}>
            <Markup content={getTranslation('workplace_title')}/>
          </Typography>
          <ButtonGroup orientation="vertical" size="large" color="primary">
            <Button
              className={clsx(classes.buttonMargin, phone ? classes.phoneButtons : classes.tabletButtons)}
              variant="contained"
              onClick={() => {
                props.history.push("/testcase/list")
              }}>
              {getTranslation('workplace_button_start_here')}
            </Button>
            {!selectedOrg.consult &&
              <Button
                className={phone ? classes.phoneButtons : classes.tabletButtons}
                variant="outlined"
                onClick={() => props.history.push('/testcase')}>
                {getTranslation('workplace_button_create_test_case')}
              </Button>
            }
          </ButtonGroup>
        </Grid>
        {/*Footer*/}
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={12} className={classes.row}>
              <Typography variant="body2" display="block" gutterBottom>
                {getTranslation('workplace_user_signed')}{': '}{user && user.name}{' ('}{orgName}{')'}
              </Typography>
              {/*<Typography variant="body1" gutterBottom className={classes.userInfo}>Inloggad som: Susanne Nidemar (Prodiagnostics)</Typography>*/}
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <Button
                className={phone ? classes.phoneButtons : classes.tabletButtons}
                color="primary"
                variant="outlined"
                size="large"
                onClick={() => logout().then(() =>
                  props.history.push("/login")
                )}>
                {getTranslation('workplace_button_logout')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Workplace;

const useStyles = makeStyles(theme => ({
  row: {
    textAlign: "center"
  },
  title: {
    fontWeight: "bold",
    marginBottom: 50,
    textTransform: "uppercase"
  },
  buttonMargin: {
    marginBottom: "20px !important"
  },
  phoneButtons: {
    textTransform: "uppercase",
    minWidth: 285
  },
  tabletButtons: {
    textTransform: "uppercase",
    minWidth: 435
  },
  userInfo: {
    color: "#222222"
  }
}));