import React, {useContext, useEffect} from "react";
import {TestcaseContext} from "../../../context/testcaseContext";
import Webcam from "../../../components/webcam";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import clsx from "clsx";
import CanvasDraw from "react-canvas-draw";
import {Html5Qrcode} from "html5-qrcode";
import {emailRegex} from "../../../functional/etc";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import {DateTimePicker} from "@material-ui/pickers";
import moment from "moment";
import CheckIcon from "@material-ui/icons/Check";
import colorConfig from "../../../styles/Theme-settings";
import {cameraConfig} from "../../../functional/barcodeConfig";

let html5QrCode;

const Mobile = props => {
  const {
    getTranslation,
    userData,
    setUserData,
    handleTextChange,
    handleDateChange,
    handleSubmit,
    documents,
    changeTestCheck,
    setChangeTestCheck,
    selectedTestMethod,
    setSelectedTestMethod,
    testIds,
    setTestIds,
    testMethods,
    orgName,
    barcodeOpen,
    setBarcodeOpen,
    canvasRef,
    rawProducts,
    handleCanvasChange,
    checkBarcodeInUse,
    barcodeInUse,
    classes,
    testCaseData,
    getPhotoDivStyle,
  } = props;

  const {
    products,
    getMethodByProductId,
    getTestsById,
    idOpen,
    setIdOpen,
    portraitOpen,
    setPortraitOpen,
    personalDataFormValidator,
    usersList
  } = useContext(TestcaseContext);

  const handleStartCamera = () => {
    if (barcodeOpen) {
      setBarcodeOpen(false);
      handleStop();
    } else {
      setBarcodeOpen(true);
      const qrCodeSuccessCallback = (decodedText, decodedResult) => {
        setUserData({...userData, ["barcode"]: decodedText})
        setBarcodeOpen(false);
        if (decodedText.length >= 10) {
          checkBarcodeInUse(decodedText);
        }
        handleStop();
      };
      html5QrCode.start(
        {facingMode: "environment"},
        cameraConfig,
        qrCodeSuccessCallback
      );
    }
  };

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          html5QrCode.clear();
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    html5QrCode = new Html5Qrcode("reader");
  }, []);

  return (
    <>
      <Webcam open={idOpen} handleClose={() => setIdOpen(false)}
              handleCapture={e => setUserData({...userData, ["id_photo"]: e})} width={325}/>
      <Webcam open={portraitOpen} handleClose={() => setPortraitOpen(false)}
              handleCapture={e => setUserData({...userData, ["portrait_photo"]: e})} width={325}/>
      <Grid item xs={12}>
        <Grid container style={{paddingBottom: 30}}>
          <Grid item xs={12} style={{marginBottom: 10}}>
            <Button
              className={classes.backButton}
              startIcon={<KeyboardBackspaceIcon className={classes.backIcon}/>}
              onClick={() => props.history.goBack()}
            >
              {getTranslation('testcase_button_go_back')}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.marginBottom}>
            <Typography variant="h5"><b>{userData.name}, {orgName}</b></Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={clsx(classes.paddingLeft, classes.paddingRight)}>
                <TextField
                  variant="outlined"
                  disabled={testCaseData.closed || userData.have_been_tested === '1'}
                  label={userData.ssn ? getTranslation("testcase_personal_ssn_identification_placeholder") : ""}
                  InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                  value={userData.ssn || ""}
                  name="ssn"
                  onChange={handleTextChange}
                  placeholder={getTranslation("testcase_personal_ssn_identification_placeholder")}
                  error={!userData.ssn || (userData.ssn && userData.ssn.length === 0)}
                  helperText={!userData.ssn || userData.ssn.length === 0 ? getTranslation('generic_error_required_field') : ''}
                />
              </Grid>
              <Grid item xs={12} className={clsx(classes.paddingLeft, classes.paddingRight)}>
                <TextField
                  variant="outlined"
                  disabled={testCaseData.closed || userData.have_been_tested === '1'}
                  label={userData.name ? getTranslation("testcase_personal_name_placeholder") : ""}
                  InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                  name="name"
                  value={userData.name || ""}
                  onChange={handleTextChange}
                  placeholder={getTranslation("testcase_personal_name_placeholder")}
                  error={!userData.name || (userData.ssn && userData.name.length === 0)}
                  helperText={!userData.name || userData.name.length === 0 ? getTranslation('generic_error_required_field') : ''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={clsx(classes.paddingLeft, classes.paddingRight)}>
                <TextField
                  variant="outlined"
                  disabled={testCaseData.closed || userData.have_been_tested === '1'}
                  label={userData.phone ? getTranslation("testcase_personal_phone_placeholder") : ""}
                  InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                  placeholder={getTranslation("testcase_personal_phone_placeholder")}
                  name="phone"
                  value={userData.phone || ""}
                  onChange={handleTextChange}
                  error={!userData.phone || (userData.phone && userData.phone.length === 0)}
                  helperText={!userData.phone || userData.phone.length === 0 ? getTranslation('generic_error_required_field') : ''}
                />
              </Grid>
              <Grid item xs={12} className={clsx(classes.paddingLeft, classes.paddingRight)}>
                <TextField
                  variant="outlined"
                  disabled={testCaseData.closed || userData.have_been_tested === '1'}
                  label={userData.email ? getTranslation("testcase_personal_email_placeholder") : ""}
                  InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                  placeholder={getTranslation("testcase_personal_email_placeholder")}
                  name="email"
                  value={userData.email || ""}
                  onChange={handleTextChange}
                  error={!userData.email || (userData.email.length > 0 && !emailRegex.test(userData.email))}
                  helperText={!userData.email || userData.email.length === 0 ? getTranslation('generic_error_required_field') : userData.email.length > 0 && !emailRegex.test(userData.email) ? getTranslation('generic_email_regex_fail') : ''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} style={{paddingTop: 10, paddingRight: 12, paddingBottom: 10, marginLeft: 12}}>
                {userData.id_document &&
                <div
                  className={classes.selectLabel}>{getTranslation("testcase_personal_identification_placeholder")}</div>
                }
                <Select
                  displayEmpty
                  disabled={testCaseData.closed || userData.have_been_tested === '1'}
                  value={userData.id_document || null}
                  className={!userData.id_document ? classes.emptySelect : ""}
                  variant="outlined"
                  fullWidth
                  onChange={e => setUserData({...userData, ["id_document"]: e.target.value})}
                  renderValue={selected => (
                    <>
                      {!selected ?
                        getTranslation("testcase_personal_identification_placeholder")
                        :
                        selected.name
                      }
                    </>
                  )}
                  error={!userData.id_document}
                >
                  {documents.map(doc => (
                    <MenuItem key={doc.id} value={doc}>
                      {doc.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {userData.id_document && userData.id_document.id === "3" &&
              <Grid item xs={12} className={classes.paddingRight}>
                <TextField
                  variant="outlined"
                  disabled={testCaseData.closed || userData.have_been_tested === '1'}
                  label={userData.certified_by ? getTranslation("testcase_personal_certified_by_label") : ""}
                  InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                  value={userData.certified_by || ""}
                  placeholder={getTranslation("testcase_personal_certified_by_label")}
                  name="certified_by"
                  onChange={handleTextChange}
                  error={!userData.certified_by || (userData.certified_by && userData.certified_by.length === 0)}
                  helperText={!userData.certified_by || userData.certified_by.length === 0 ? getTranslation('generic_error_required_field') : ''}
                />
              </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center" spacing={3}
                  style={{paddingTop: 20, paddingBottom: 20}}>
              <Grid item xs={9}>
                {userData.id_photo &&
                <div className={classes.imageLabel}>
                  {getTranslation("testcase_personal_id_photo_label")}
                </div>
                }
                <div
                  style={getPhotoDivStyle(userData.id_photo, testCaseData.closed, userData.have_been_tested)}
                  onClick={() => setIdOpen(true)}
                >
                  {(!userData.id_photo || userData.id_photo === "") &&
                  <>
                    <Typography variant="subtitle1"
                                className={classes.photoLabelMobile}>{getTranslation("testcase_personal_id_photo_label")}</Typography>
                    <CameraAltOutlinedIcon className={classes.cameraIconMobile}/>
                  </>
                  }
                </div>
              </Grid>
              <Grid item xs={9}>
                {userData.portrait_photo &&
                <div className={classes.imageLabel}>
                  {getTranslation("testcase_personal_portrait_label")}
                </div>
                }
                <div
                  style={getPhotoDivStyle(userData.portrait_photo, testCaseData.closed, userData.have_been_tested)}
                  onClick={() => setPortraitOpen(true)}
                >
                  {(!userData.portrait_photo || userData.portrait_photo === "") &&
                  <>
                    <Typography variant="subtitle1"
                                className={classes.photoLabelMobile}>{getTranslation("testcase_personal_portrait_label")}</Typography>
                    <CameraAltOutlinedIcon className={classes.cameraIconMobile}/>
                  </>
                  }
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={clsx(classes.paddingLeft, classes.paddingRight)}>
                <TextField
                  variant="outlined"
                  disabled={testCaseData.closed || userData.have_been_tested === '1'}
                  label={userData.location ? getTranslation("testcase_personal_place_of_sapling_placeholder") : ""}
                  InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                  placeholder={getTranslation("testcase_personal_place_of_sapling_placeholder")}
                  name="location"
                  value={userData.location || ""}
                  onChange={handleTextChange}
                  error={!userData.location || (userData.location && userData.location.length === 0)}
                  helperText={!userData.location || userData.location.length === 0 ? getTranslation('generic_error_required_field') : ''}
                />
              </Grid>
              <Grid item xs={12} className={classes.paddingRight}>
                <DateTimePicker
                  disabled={testCaseData.closed || userData.have_been_tested === '1'}
                  className={clsx(classes.datePicker, "fixSelectAndDates")}
                  placeholder={getTranslation("testcase_personal_date_time_placeholder")}
                  label={userData.date ? getTranslation("testcase_time_for_sampling_placeholder") : ""}
                  InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                  format="YYYY-MM-DD HH:mm"
                  autoOk
                  // disabled={loading}
                  value={moment.unix(userData.date) || null}
                  onChange={e => handleDateChange(e, "date")}
                  error={!userData.date || (userData.date && userData.date.length === 0)}
                  helperText={!userData.date || userData.date.length === 0 ? getTranslation('generic_error_required_field') : ''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginTop: 10, marginLeft: 12}}>
            <Grid container>
              {changeTestCheck === 1 ?
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1">{getTranslation("testcase_personal_analyzes_to_perform_label")}:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {rawProducts && rawProducts.length > 0 && testMethods && testMethods.length > 0 && userData.tests && userData.tests.map((item, index) => (
                      <div style={{display: "flex", alignItems: "center"}} key={index}>
                        <CheckIcon/>
                        <Typography
                          variant="subtitle1">{getMethodByProductId(getTestsById(item).test_method_id).name}, {getTestsById(item).name}</Typography>
                      </div>
                    ))}
                  </Grid>
                </>
                :
                <>
                  {testMethods &&
                  <>
                    {selectedTestMethod &&
                    <div className={classes.selectLabel}>
                      {getTranslation("testcase_create_select_sample_type_placeholder")}
                    </div>
                    }
                    <Select
                      displayEmpty
                      disabled={testCaseData.closed || userData.have_been_tested === '1'}
                      fullWidth
                      className={!selectedTestMethod ? classes.emptySelect : ""}
                      variant='outlined'
                      value={selectedTestMethod ? selectedTestMethod.name : ""}
                      onChange={e => setSelectedTestMethod(e.target.value)}
                      renderValue={selected => {
                        if (!selectedTestMethod) {
                          return (
                            <>
                              {selected ? selectedTestMethod.name : getTranslation("testcase_create_select_sample_type_placeholder")}
                            </>
                          )
                        } else {
                          return (
                            <>
                              {selectedTestMethod.name}
                            </>
                          )
                        }
                      }}
                      error={!selectedTestMethod}
                    >
                      {testMethods.map(test => {
                        if (test.active === "1" && test.sys_id !== "0") {
                          return (
                            <MenuItem key={test.name} value={test}>
                              {test.name}
                            </MenuItem>)
                        }
                      })}
                    </Select>
                    {!selectedTestMethod &&
                    <FormHelperText
                      className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
                    }
                  </>
                  }
                  {selectedTestMethod &&
                  <>
                    {testIds.length > 0 &&
                    <div className={classes.selectLabel}>
                      {getTranslation("testcase_tests_placeholder")}
                    </div>
                    }
                    <Select
                      style={{marginTop: 20}}
                      displayEmpty
                      disabled={testCaseData.closed || userData.have_been_tested === '1'}
                      multiple
                      fullWidth
                      value={testIds || []}
                      onChange={e => setTestIds(e.target.value)}
                      className={(!testIds || testIds.length === 0) ? classes.emptySelect : ""}
                      variant='outlined'
                      renderValue={(selected) => {
                        let renderV = [];
                        if (!testIds || testIds.length === 0) {
                          selected.forEach((s) => {
                            let tempHold = products[selectedTestMethod.sys_id].find((t) => t.sys_id === s);
                            if (tempHold) {
                              renderV.push(tempHold.name);
                            }
                          });
                        } else {
                          testIds.forEach(s => {
                            let tempHold = products[selectedTestMethod.sys_id].find(t => t.sys_id === s);
                            if (tempHold) {
                              renderV.push(tempHold.name);
                            }
                          })
                        }
                        return (
                          <>{(testIds && selected.length > 0) ? renderV.join(", ") : getTranslation("testcase_tests_placeholder")}</>
                        );
                      }}
                      error={!testIds || testIds.length === 0}
                    >
                      {products && selectedTestMethod &&
                      products[selectedTestMethod.sys_id].map(product => (
                        <MenuItem key={product.sys_id} value={product.sys_id}>
                          <Checkbox
                            checked={
                              testIds
                                ? testIds.findIndex((nu) => nu === product.sys_id) > -1
                                : false
                            }
                          />
                          <div style={{float: "left"}}>{product.name}</div>
                        </MenuItem>
                      ))}
                    </Select>
                    {(!testIds || testIds.length === 0) &&
                    <FormHelperText
                      className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
                    }
                  </>
                  }
                </>
              }
              {userData.have_been_tested !== '1' &&
              <Grid item xs={12} style={{marginTop: 20}}>
                {changeTestCheck === 1 ?
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setChangeTestCheck(2)}
                  >
                    {getTranslation("testcase_personal_edit_tests_button")}
                  </Button>
                  :
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{marginRight: 10}}
                      onClick={() => {
                        setUserData({...userData, tests: testIds})
                        setTestIds([]);
                        setSelectedTestMethod(null);
                        setChangeTestCheck(1);
                      }}
                    >
                      {getTranslation("generic_button_save")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setTestIds([]);
                        setSelectedTestMethod(null);
                        setChangeTestCheck(1);
                      }}
                    >
                      {getTranslation("generic_button_cancel")}
                    </Button>
                  </>
                }
              </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginTop: 20}}>
            <Grid container>
              <Grid item xs={12} style={{paddingLeft: 12}}>
                <Typography variant="subtitle1"
                            gutterBottom>{getTranslation("testcase_personal_sampler_label")}</Typography>
              </Grid>
              <Grid item xs={12} style={{paddingLeft: 12, paddingRight: 12}}>
                <Select
                  displayEmpty
                  disabled={testCaseData.closed || userData.have_been_tested === '1'}
                  multiple
                  className={(userData.tester_ids && userData.tester_ids.length > 0) ? classes.fullWidth : clsx(classes.emptySelect, classes.fullWidth)}
                  name="tester_ids"
                  variant="outlined"
                  fullWidth
                  value={userData.tester_ids || []}
                  onChange={e => setUserData({...userData, [e.target.name]: e.target.value})}
                  renderValue={selected => {
                    let renderV = [];
                    selected.forEach(s => {
                      let tempHold = usersList.find(u => u.sys_id === s);
                      if (tempHold) {
                        renderV.push(tempHold.name);
                      }
                    })
                    // return renderV.join(", ")
                    return (
                      <>
                        {(userData && userData.tester_ids && selected.length > 0) ? renderV.join(", ") : getTranslation("testcase_testers_placeholder")}
                      </>
                    )
                  }}
                  error={!userData.tester_ids || userData.tester_ids.length === 0}
                >
                  {usersList.map(user =>
                    <MenuItem key={user.user_id} value={user.sys_id}>
                      <Checkbox
                        checked={userData.tester_ids ? userData.tester_ids.findIndex(nu => nu === user.sys_id) > -1 : false}
                      />
                      <div style={{float: "left"}}>
                        {user.name}
                      </div>
                    </MenuItem>
                  )}
                </Select>
                {(!userData.tester_ids || userData.tester_ids.length === 0) &&
                <FormHelperText
                  className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
                }
                {userData.sampler_check !== undefined &&
                <Grid item xs={12} style={{marginTop: 10}}>
                  <FormControlLabel
                    style={{marginLeft: -10}}
                    value="end"
                    control={
                      <Checkbox
                        disabled={testCaseData.closed || userData.have_been_tested === '1'}
                        color="primary"
                        checked={userData.sampler_check}
                        onChange={e => setUserData({...userData, ["sampler_check"]: e.target.checked})}
                      />
                    }
                    label={getTranslation("testcase_personal_sampler_checkbox")}
                    labelPlacement="end"
                  />
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  name="barcode"
                  label={userData.barcode ? getTranslation("testcase_personal_enter_id_placeholder") : ""}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  inputProps={{maxLength: 10}}
                  value={userData.barcode || ""}
                  className={clsx(classes.paddingLeft, classes.barcodeTextInput)}
                  InputLabelProps={{classes: {root: classes.textFieldInputLabel}}}
                  placeholder={getTranslation("testcase_personal_enter_id_placeholder")}
                  disabled={userData.have_been_tested === "1" || testCaseData.closed}
                  onChange={(e) => {
                    if (Number.isInteger(Number(e.target.value))) {
                      handleTextChange(e);
                    }
                    if (e.target.value.length === 10) {
                      checkBarcodeInUse(e.target.value);
                    }
                  }}
                  error={!userData.barcode || barcodeInUse}
                  helperText={!userData.barcode ? getTranslation('generic_error_required_field') : (barcodeInUse ? getTranslation("testcase_barcode_in_use") : "")}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  style={{
                    backgroundColor: colorConfig.green,
                    height: "calc(100% - 19px)",
                    marginTop: 9,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    maxHeight: 58
                  }}
                  disabled={userData.have_been_tested === "1" || testCaseData.closed}
                  onClick={handleStartCamera}
                >
                  <CameraAltOutlinedIcon style={{fill: colorConfig.white, width: "65%", height: "65%"}}/>
                </Button>
              </Grid>
              {barcodeOpen &&
              <Grid item xs={12}>
                <Typography variant="caption" className={classes.startBarcodeText} onClick={handleStartCamera}>
                  {getTranslation("referral_form_close_camera")}
                </Typography>
              </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} style={{textAlign: "center"}}>
            <div id="reader"/>
          </Grid>
          <Grid item xs={12} style={{marginTop: 20, paddingLeft: 12, paddingRight: 12}}>
            <Typography variant="subtitle1">{getTranslation("testcase_personal_disclaimer")}</Typography>
          </Grid>
          <Grid item xs={12} style={{marginTop: 20, paddingLeft: 12, paddingRight: 12}}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1"
                            gutterBottom>{getTranslation("testcase_personal_signature_label")}</Typography>
              </Grid>
              <Grid item xs={12}>
                {((userData.signature && userData.have_been_tested === "1") || testMethods.closed) ?
                  (<div
                    style={{
                      backgroundImage: `url('${userData.signature}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      width: "100%",
                      height: 150
                    }}
                  >
                  </div>)
                  :
                  (<div
                    style={userData.signature ? {
                        width: "100%",
                        height: 150,
                        border: "1px solid rgba(0, 0, 0, 0.87)",
                        position: "relative"
                      }
                      :
                      {
                        width: "100%",
                        height: 150,
                        border: "1px solid #ff1744",
                        position: "relative"
                      }}
                  >
                    <Typography
                      variant="subtitle1"
                      className={classes.signatureReset}
                      onClick={() => {
                        setUserData({...userData, ["signature"]: null});
                        canvasRef.current.clear();
                      }}>
                      {getTranslation("testcase_personal_reset_signature")}
                    </Typography>
                    <CanvasDraw className={classes.canvas} canvasHeight={150} hideGrid={true} hideInterface={true}
                                brushRadius={2} disabled={testCaseData.closed}
                                ref={canvasRef} onChange={handleCanvasChange}/>
                  </div>)
                }
              </Grid>
              {!userData.signature &&
              <FormHelperText
                className={classes.selectHelperText}>{getTranslation('generic_error_required_field')}</FormHelperText>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} style={{textAlign: "center", marginTop: 30}}>
            <Button
              size="large"
              variant="contained"
              disabled={!personalDataFormValidator() || userData.have_been_tested === "1" || testCaseData.closed || userData.barcode.length < 10}
              color="primary"
              style={{textTransform: "uppercase", padding: "8px 70px"}}
              onClick={() => handleSubmit()}
            >
              {getTranslation("testcase_personal_save_button")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Mobile;
