import React, {createContext, useContext, useState} from 'react';
import { useSnackbar } from 'notistack';
import APICall from '../functional/APIRequests';
import { AuthContext } from '../context/authContext';
import { LocalizationContext } from '../context/localizationContext';
const PersonelContext = createContext({});

const PersonelProvider = (props) => {
  const { setLoading } = useContext(AuthContext);
  const { getTranslation } = useContext(LocalizationContext)
  //States
  const [isMRO, setIsMRO] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const getPersonel = (filter = '', idOrg = false) => {
    setLoading(true);
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `${(idOrg && idOrg > 1) ? `customer/${idOrg}` : 'prodiagnostics/1'}/users${filter}`,
      // url: `${(Number(selectedOrg.id) > 1) ? `customer/${idOrg}` : `prodiagnostics/${idOrg}`}/users${filter}`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false)
      return res;
    })
  }
  const getPersonelById = (props) => {
    setLoading(true);

    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `${props.idOrg > 1 ? 'customer' : 'prodiagnostics'}/${props.idOrg}/user/${props.idPersonel}`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false);
      return res.data;
    })
  }
  const postPersonel = (params, body) => {
    setLoading(true)
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `${params.idOrg > 1 ? 'customer' : 'prodiagnostics'}/${params.idOrg}/user${params.idPersonel !== 'new' ? `/${params.idPersonel}` : ''}`,
      verb: 'post',
      data: body,
      server: 'api',
    }).then((res) => {
      setLoading(false)
      if (res && (res.error === 0 || res.data.error !== 1)) {
        enqueueSnackbar(getTranslation('generic_submited_success'), { variant: "success" });
        return res;
      } else {
        enqueueSnackbar((res && res.data) ? (res.data.message || '') : '', { variant: "error" });

        return false
      }
    })
  }
  const patchStatusPersonel = (params) => {
    setLoading(true)
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `${params.idOrg > 1 ? 'customer' : 'prodiagnostics'}/${params.idOrg}/user/${params.idPersonel}/${params.status ? 'activate' : 'deactivate'}`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false)
      enqueueSnackbar(getTranslation('generic_submited_success'), { variant: "success" });
      return res;
    })
  }
  const deletePersonel = (params) => {
    setLoading(true)
    return APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `${params.idOrg > 1 ? 'customer' : 'prodiagnostics'}/${params.idOrg}/user/${params.idPersonel}/remove`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false)
      enqueueSnackbar(getTranslation('generic_submited_success'), { variant: "success" });
      return res;
    })
  }

  const setPersonnelAsMRO = params => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${params.orgId}/user/${params.idPersonel}/mro`,
      verb: "post",
      server: "api"
    })
        .then(() => setLoading(false))
        .catch(() => setLoading(false))
  }

  return (
    <PersonelContext.Provider value={{
      //States
      isMRO,
      //UpdateStates
      setIsMRO,
      //Functions
      getPersonel, getPersonelById, postPersonel, patchStatusPersonel, deletePersonel, setPersonnelAsMRO
    }}>
      {props.children}
    </PersonelContext.Provider >
  );
};

export { PersonelContext, PersonelProvider };