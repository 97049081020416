import React, { forwardRef, useContext, useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Grid, TextField } from '@material-ui/core';
import { DateTimePicker } from "@material-ui/pickers";
import { LocalizationContext } from '../context/localizationContext';
import { RefContext } from '../context/refContext';
import { Markup } from 'interweave';
import moment from 'moment';
import "moment/locale/sv";
import "moment/locale/en-gb";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AlertDialogSlide = (props) => {
  const { getTranslation, lang } = useContext(LocalizationContext);
  const { setOtherChemIntake, otherChemIntake } = useContext(RefContext);
  const [data, setDataType] = useState(
    props.dialogData.type === 1 ?
      {
        sys_id: '',
        type: props.dialogData.type,
        freeText: '',
      } : {
        sys_id: '',
        type: props.dialogData.type,
        chemName: '',
        chemAmmount: '',
        date: null,
        chemIntakeDate: null,
      })
  const [invalidFields, setInvalidFields] = useState([]);
  const handleClose = (params) => {
    props.setOpen(false);
    if (params) {
      if (props.hasReferralInputs) {
        if (otherChemIntake) {
          let index = otherChemIntake.findIndex(v => v.sys_id === data.sys_id);
          if (index === -1) {
            setOtherChemIntake([...otherChemIntake, data])
          } else {
            let brakeRef = JSON.parse(JSON.stringify(otherChemIntake));
            otherChemIntake[index] = data;
            setOtherChemIntake([...brakeRef])
          }
        } else {
          setOtherChemIntake([data])
        }
      }
    }
    if (params || [1, 2].includes(data.type)) {
      props.callback(params)
    }
  };
  const checkValid = () => {
    let fieldsToValidate = ['chemName', 'chemAmmount', 'date', 'chemIntakeDate'];
    let tempInvalidFields = [];
    fieldsToValidate.forEach(field => {
      if (data[field] === null || data[field] === '') {
        tempInvalidFields.push(field)
      }
      setInvalidFields(tempInvalidFields)
    })
  }
  const handleInputs = (e) => {
    setDataType({ ...data, [e.target.name]: e.target.value, sys_id: props.dialogData.id })
  }
  const handleDateChange = (e, input) => {
    setDataType({ ...data, [input]: e })
  }
  moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');
  useEffect(() => {
    if (otherChemIntake) {
      let index = otherChemIntake.findIndex(oci => oci.sys_id === props.dialogData.id)
      if (index > -1) {
        setDataType({ ...data, ...otherChemIntake[index] })
      }
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    checkValid();
    // eslint-disable-next-line
  }, [data])
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth={props.maxWidth || (props.html ? 'lg' : 'sm')}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
      <DialogContent>
        {(props.message || props.html) &&
          <DialogContentText id="alert-dialog-slide-description">
            <Markup content={props.message} />
            {props.html &&
              <Markup content={props.html} />
            }
          </DialogContentText>
        }
        {props.note &&
           <Markup content={props.note} />
        }
        {props.hasReferralInputs && props.dialogData.type === 1 &&
          <Grid container>
            <Grid item xs={12}>
              <TextField
                autoFocus
                id="freeText"
                name='freeText'
                rows="2"
                multiline
                label={getTranslation('referral_form_modal_free_text')}
                value={data.freeText}
                onChange={handleInputs}
                error={false}
              />
            </Grid>
          </Grid>
        }
        {props.hasReferralInputs && props.dialogData.type === 2 &&
          <Grid container>
            <Grid item xs={12}>
              <TextField
                autoFocus
                id="chemName"
                name='chemName'
                label={getTranslation('referal_dialog_label_chem_name')}
                value={data.chemName}
                onChange={handleInputs}
                error={!data.chemName || (data.chemName && data.chemName.length === 0)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="chemAmmount"
                name='chemAmmount'
                label={getTranslation('referal_dialog_label_chem_ammount')}
                value={data.chemAmmount}
                onChange={handleInputs}
                error={!data.chemAmmount || (data.chemAmmount && data.chemAmmount.length === 0)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                margin="normal"
                format="YYYY-MM-DD HH:mm"
                label={getTranslation('referal_dialog_label_chem_current_date')}
                ampm={false}
                value={data.date}
                onChange={(e) => handleDateChange(e, 'date')}
                error={!data.date || (data.date && data.date.length === 0)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                margin="normal"
                format="YYYY-MM-DD HH:mm"
                label={getTranslation('referal_dialog_label_chem_intake_date')}
                ampm={false}
                value={data.chemIntakeDate}
                onChange={(e) => handleDateChange(e, 'chemIntakeDate')}
                error={!data.chemIntakeDate || (data.chemIntakeDate && data.chemIntakeDate.length === 0)}
              />
            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        {props.cancelLabel &&
          <Button onClick={() => handleClose(false)} color="primary">
            {props.cancelLabel}
          </Button>
        }
        {props.submitLabel &&
          <Button disabled={props.hasValidateFields === true && invalidFields.length > 0} onClick={() => handleClose(true)} color="primary">
            {props.submitLabel}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}
export default AlertDialogSlide;