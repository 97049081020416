import React from 'react';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  // static contextType = OrgContext;
  componentDidCatch(error, info, e) {
    try {
    } catch (err) { }
    this.setState({ hasError: true })
      this.props.history.push('/error-page')
      window.location.reload();
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return '';
    } else {
      return this.props.children;
    }

  }
}
export default ErrorBoundary;