import React, { useState, useContext, useEffect } from "react";
import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { OrgContext } from "../../context/orgContext";
import { AuthContext } from '../../context/authContext';
import { LocalizationContext } from '../../context/localizationContext';
import { Markup } from 'interweave';
import APICall from '../../functional/APIRequests';
const AnalysisNestedComponent = props => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrg } = useContext(OrgContext);
  const { setLoading, } = useContext(AuthContext);
  const { getTranslation } = useContext(LocalizationContext);
  const [data, setData] = useState(
    { information: {}, analysis: {} }
  );
  const fetchData = () => {
    setLoading(true)
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/analysis/rows?analysis_id=${props.id}`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false);
      if (res) {
        // let dataAnalysis = res.data.analysis;
        // let dataInfo = res.data.information;
        // let keysAnalysis = Object.keys(dataAnalysis);
        // let keysInformation = Object.keys(dataInfo);
        // let data={
        //   analysis:keysAnalysis.map(key => { return dataAnalysis[key] }),
        //   information:keysInformation.map(key => { return dataInfo[key] }),
        // }
        setData(res.data)
      }
    })
  }
  useEffect(fetchData, [])
  const convertMissFormatedData = (date) => {
    let dt = date.split('.');
    let day = dt[0], month = dt[1], year = dt[2].split(',')[0], prevD = date.split(',');
    prevD.splice(0, 1);
    // ${prevD.map(item => `, ${item}`)}
    let newString = `${year}-${month}-${day}`
    prevD.forEach(item => { newString += `, ${item}` })
    return newString;
  }  
    if(props.testMethod ==='2' && !!props.rowData.old_report_id){
      return <Grid container>
      <Grid item xs={12}>
      <Typography variant="body2" gutterBottom style={{padding:20}}>
      {getTranslation('analysis_detail_nested_table_old_urine_unavailable')}
      </Typography>
      </Grid>
      </Grid>
    }else{
      return (
     <React.Fragment>
      {Object.keys(data.information).map(key =>
        <Grid container key={key} style={{ padding: 10 }}>
          {Object.keys(data.information[key].sub_rows).length === 0 &&
            <Grid container key={key}>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  <b>{data.information[key].ObservationDescription}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  {data.information[key].ObservationValue}
                </Typography>
              </Grid>
            </Grid>
          }
          {Object.keys(data.information[key].sub_rows).length > 1 &&
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="body2" gutterBottom>
                <b>{data.information[key].ObservationDescription}</b>
              </Typography>
            </Grid>
          }
          {Object.keys(data.information[key].sub_rows).map(subkey =>
            <Grid container key={subkey}>
              <Grid item xs={3}>
                <Typography variant="body2" gutterBottom>
                  {data.information[key].sub_rows[subkey].ObservationDescription}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2" gutterBottom>
                  {data.information[key].sub_rows[subkey].ObservationIdentifier === 'ENTDAT' ?
                    convertMissFormatedData(data.information[key].sub_rows[subkey].ObservationValue)
                    :
                    data.information[key].sub_rows[subkey].ObservationValue}
                </Typography>
              </Grid>
              {data.information[key].sub_rows[subkey] && data.information[key].sub_rows[subkey].ObservationValueDescription.length > 1 &&
                <Grid item xs={12} style={{ padding: '5px 30px 10px 30px' }}>
                  <Markup content={data.information[key].sub_rows[subkey].ObservationValueDescription} />
                </Grid>
              }
            </Grid>
          )}
        </Grid>
      )}
      {Object.keys(data.analysis).map(key =>
        <Grid container key={key} style={{ padding: 10 }}>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              <b>{data.analysis[key].ObservationDescription}</b>
            </Typography>
          	{Object.keys(data.analysis[key].sub_rows).length === 0 &&
                <Grid item xs={12}>
                  <Typography variant="body2" gutterBottom>
                    {data.analysis[key].ObservationValue}
                  </Typography>
              	</Grid>
            }
          </Grid>

          {Object.keys(data.analysis[key].sub_rows).map(subkey =>
            <Grid container key={subkey}>
              <Grid item xs={3}>
                <Typography variant="body2" gutterBottom>
                  {data.analysis[key].sub_rows[subkey].ObservationDescription}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" gutterBottom>
                  {data.analysis[key].sub_rows[subkey].ObservationValue}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" gutterBottom>
                  {data.analysis[key].sub_rows[subkey].Units}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" gutterBottom>
                  {data.analysis[key].sub_rows[subkey].ReferenceRange}
                </Typography>
              </Grid>
              {data.analysis[key].sub_rows[subkey].ObservationValueDescription && data.analysis[key].sub_rows[subkey].ObservationValueDescription.length > 1 &&
                <Grid item xs={12} style={{ padding: '5px 30px 10px 30px' }}>
                  <Markup content={data.analysis[key].sub_rows[subkey].ObservationValueDescription} />
                </Grid>
              }
            </Grid>
          )}
        </Grid>
      )}
    </React.Fragment >
        )
      }  
  }
export default AnalysisNestedComponent;
