import React, { useEffect, useState, useContext } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import { LocalizationContext } from '../../context/localizationContext';
import { AuthContext } from '../../context/authContext';
import Table from '../Table/index';
import APICall from '../../functional/APIRequests';
import { useSnackbar } from 'notistack';
import { OrgContext } from "../../context/orgContext";
import moment from 'moment';
import {apiServer} from "../../functional/generateEnvUrls";
const AnalysisDetailTable = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading, loading } = useContext(AuthContext);
  const { selectedOrg } = useContext(OrgContext);
  const { getTranslation } = useContext(LocalizationContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // eslint-disable-next-line
  const [nestedId, setNestedId] = useState(null);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const columns = [
    //sys_id
    {
      name: getTranslation('analysis_detail_table_column_analysis'),
      functionalName: 'sys_id',
      filter: false,
      sortable: false,
      visible: false,
      align: 'left',
    },
    //substances
    {
      name: getTranslation('analysis_detail_table_column_substences'),
      functionalName: 'header',
      filter: false,
      sortable: false,
      visible: true,
      align: 'left',
    },
  ]

  const openResultPDF = (selid) => {
    //let url = `${process.env.REACT_APP_PROD_API}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/analysis/labreport?analysis_id=${selid}`;
    let url = `${apiServer}/${localStorage.getItem(process.env.REACT_APP_GUID + 'userLang') === 'sv_SE' ? 'se' : 'en'}/customer/${selectedOrg.id}/analysis/labreport?analysis_id=${selid}`;
    window.open(url, "_blank");
  }
  const infoSolutionsSync = (analysisId) => {
    setLoading(true)
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/analysis/${analysisId}/sendtoinfosolutions`,
      verb: 'post',
      server: 'api',
    }).then((res) => {
      setLoading(false);
      if (res) {
        console.log('infoSolutionsSync', res)
      }
    })
  }
  // eslint-disable-next-line
  useEffect(() => {
   if(props.id) {
     props.fetchData(props.id);
   }
  }, [props.id]);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {loading && <LinearProgress />}
          <Table
            specialPDFDownloadField={openResultPDF}
            infoSolutions={{ active: selectedOrg.infoSolutions && selectedOrg.infoSolutions.customerId, callBack: infoSolutionsSync, send: 'infosolutions_send', date: 'infosolutions_export_date' }}
            isNested={true}
            nestedId={nestedId}
            setNestedId={setNestedId}
            noHeader={true}
            nestedAnalysisComponent={true}
            testMethod={props.testMethod}
            rows={props.data}
            page={page}
            count={props.count}
            columns={columns}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sort={props.sort}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AnalysisDetailTable;
