import React, {useContext, useEffect, useState} from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid,
  Hidden, LinearProgress,
  Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow,
  TextField, Tooltip,
  Typography
} from "@material-ui/core";
import {ArrowBackIos} from "@material-ui/icons";
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import {useSnackbar} from "notistack";
import {LocalizationContext} from "../../../context/localizationContext";
import {PatientContext} from "../../../context/patientContext";
import EnhancedTableHead from "../../../components/Table/EnhancedTableHead";
import {OrgContext} from "../../../context/orgContext";
import APICall from "../../../functional/APIRequests";
import {Markup} from "interweave";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ReceiptIcon from "@material-ui/icons/Receipt";
import WarningIcon from "@material-ui/icons/Warning";
import TablePaginationActions from "../../../components/Table/pagination";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const PatientDetails = props => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const {getTranslation} = useContext(LocalizationContext);
    const {selectedOrg} = useContext(OrgContext);
    const {
      fetchPatient,
      patientData,
      setPatientData,
      updatePatient,
      loading,
      setLoading,
      fetchPatientReferrals
    } = useContext(PatientContext);
    const {id} = useParams();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [sort, setSort] = useState({column: 'result_date', by: 'desc'});
    const [rows, setRows] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [methods, setMethods] = useState([]);
    const [status, setStatus] = useState([]);
    const [archiveModal, setArchiveModal] = useState(false);

    const HEADER_CELLS = [
      {
        id: "referral_id",
        name: getTranslation("analysis_list_table_header_id"),
        sort: true,
        visible: true
      },
      //Tester name
      {
        id: "tester_name",
        name: getTranslation("analysis_list_table_header_user"),
        sort: true,
        visible: selectedOrg.id !== "1"
      },
      //Customer number
      {
        id: "",
        name: getTranslation("analysis_list_table_header_customer_number"),
        sort: true,
        /*visible: selectedOrg.id === "1"*/
        visible: false
      },
      //Client name (organisation.customer.name)
      {
        id: "",
        name: "",
        sort: true,
        /*visible: selectedOrg === "1"*/
        visible: false
      },
      {
        id: "test_date",
        name: getTranslation("analysis_list_table_header_sample_date"),
        sort: true,
        visible: true
      },
      {
        id: "arrival_date",
        name: getTranslation('analysis_list_table_header_incom_date'),
        sort: true,
        visible: true
      },
      {
        id: "result_date",
        name: getTranslation('analysis_list_table_header_reply_date'),
        sort: true,
        visible: true
      },
      {
        id: "test_method_id",
        name: getTranslation('analysis_list_table_header_methods'),
        sort: true,
        visible: true
      },
      {
        id: "status_id",
        name: getTranslation('analysis_list_table_header_status'),
        sort: true,
        visible: true
      },
      {
        id: "is_positive",
        name: getTranslation('analysis_list_table_header_result'),
        sort: true,
        visible: true
      },
      {
        id: "handled",
        name: "",
        sort: false,
        visible: true
      },
    ];

    const handleChange = e => {
      setPatientData({...patientData, [e.target.name]: e.target.value});
    };

    const handleBack = () => {
      props.history.goBack();
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleSortChange = (column, order) => {
      setSort({column: column, by: order})
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleRowClick = id => {
      props.history.push(`/analyses/detail/${id}`)
    };

    const fetchStatus = () => {
      setLoading(true)
      APICall({
        setLoading,
        hasRefresh: true,
        enqueueSnackbar,
        url: `customer/${selectedOrg.id}/referral/statuses`,
        verb: 'get',
        server: 'api',
      }).then((res) => {
        setLoading(false);
        fetchMethods();
        if (res && res.data) {
          setStatus(res.data.map(d => {
            return {id: d.sys_id, text: d.status}
          }));
        }
      })
    }

    const fetchMethods = () => {
      APICall({
        setLoading,
        enqueueSnackbar,
        url: `products/methods`,
        verb: 'get',
        server: 'api',
      }).then((res) => {
        let tempMethods = []
        if (res && res.data)
          res.data.forEach(d => {
            if (d.active === '1') {
              tempMethods.push({id: d.sys_id, text: d.name})
            }
          })
        setMethods(tempMethods);
      })
    };

    const getPatientReferrals = () => {
      fetchPatientReferrals({id, rowsPerPage, page, sort})
        .then(res => {
          if (res) {
            setRows(res.data.items.map(data => {
              data.test_date = data.test_date === '0' || !data.test_date ? '-' : moment.unix(data.test_date).format('YYYY-MM-DD')
              data.arrival_date = data.arrival_date === '0' || !data.arrival_date ? '-' : moment.unix(data.arrival_date).format('YYYY-MM-DD')
              data.result_date = data.result_date === '0' || !data.result_date ? '-' : moment.unix(data.result_date).format('YYYY-MM-DD')
              data.test_method = {
                name: (methods.find(st => st.id === data.test_method_id) || {text: ''}).text,
                id: data.test_method_id
              }
              data.status_id = {
                color: data.status_id === '1' ? '#FFEE00' : data.status_id === '2' ? '#EFA700' : '#28A745',
                name: status.find(st => st.id === data.status_id).text
              };
              data.archived_by = data.archived_by;
              data.archived_at = data.archived_at;
              data.handled_by = data.handled_by;
              data.handled_at = data.handled_at;
              data.user_handled = data.user_handled;
              data.user_archived = data.user_archived;
              return {...data}
            }));
            setTotalItems(res.data.total_items);
            setLoading(false);
          }
        })
    };

    const handleOnNewReferralClick = () => {
      props.history.push("/referral/filled");
    };

    const getHandledTooltip = referral => {
      if (referral.handled_by && !referral.archived_by) {
        return getTranslation("analysis_table_handled_tooltip").replace('##date##', moment.unix(referral.handled_at).format("YYYY-MM-DD")).replace("##username##", referral.user_handled);
      } else if (!referral.handled_by && referral.archived_by) {
        return getTranslation("analysis_table_archived_tooltip").replace('##date##', moment.unix(referral.archived_at).format("YYYY-MM-DD")).replace("##username##", referral.user_archived);
      } else {
        return (
          <>
            <div>
              {`${getTranslation("analysis_table_handled_tooltip").replace('##date##', moment.unix(referral.handled_at).format("YYYY-MM-DD")).replace("##username##", referral.user_handled)}`}
            </div>
            <div>
              {`${getTranslation("analysis_table_archived_tooltip").replace('##date##', moment.unix(referral.archived_at).format("YYYY-MM-DD")).replace("##username##", referral.user_archived)}`}
            </div>
          </>
        )
      }
    };

    const handleArchive = (anonymise = false) => {
      setLoading(true);

      APICall({
        setLoading,
        enqueueSnackbar,
        url: `customer/${selectedOrg.id}/patients/${id}/archive_patient`,
        verb: "post",
        server: "api"
      })
        .then(res => {
            if (res) {
              if (anonymise) {
                APICall({
                  setLoading,
                  enqueueSnackbar,
                  url: `customer/${selectedOrg.id}/patients/anonymize_patient?patient_id=${id}&organisation_id=${patientData.organisation_id}`,
                  verb: "post",
                  server: "api"
                })
                  .then(response => {
                    if (response) {
                      setPatientData({
                        ...patientData,
                        name: response.data.name,
                        email: response.data.email,
                        phone: response.data.phone,
                        pnr: response.data.pnr,
                        archivedpatient_by: res.data.archivedpatient_by,
                        archivedpatient_at: res.data.archivedpatient_at,
                        user_archivedpatient: res.data.user_archivedpatient
                      })
                      enqueueSnackbar(getTranslation('referral_archived_and_anonymize_success'), {variant: "success"});
                    }
                    setArchiveModal(false);
                    setLoading(false);
                  })
              } else {
                setPatientData({
                  ...patientData,
                  archivedpatient_by: res.data.archivedpatient_by,
                  archivedpatient_at: res.data.archivedpatient_at,
                  user_archivedpatient: res.data.user_archivedpatient
                })
                enqueueSnackbar(getTranslation('referral_archived_success'), {variant: "success"});
                setArchiveModal(false);
                setLoading(false);
              }
            }
          }
        )
    };

    const handleUnarchive = () => {
      setLoading(true);

      APICall({
        setLoading,
        enqueueSnackbar,
        url: `customer/${selectedOrg.id}/patients/${id}/unarchive_patient`,
        verb: "post",
        server: "api"
      })
        .then(res => {
          if (res) {
            setPatientData({
              ...patientData,
              archivedpatient_by: res.data.archivedpatient_by,
              archivedpatient_at: res.data.archivedpatient_at,
              user_archivedpatient: res.data.user_archivedpatient
            });
            enqueueSnackbar(getTranslation('referral_archived_success'), {variant: "success"});
          }
          setLoading(false);
        })
    };

    const handleOpenArchiveModal = () => {
      setArchiveModal(true);
    };

    const isAnnonymized = () => {
      if (patientData.email === "XXXXXXXXXX" || patientData.phone === "XXXXXXXXXX" || patientData.pnr === "XXXXXXXXXX")
        return true;
      return false;
    };

    useEffect(() => {
      fetchPatient(id);
    }, []);

    useEffect(() => {
      if (status.length === 0 || methods.length === 0) {
        fetchStatus()
      } else {
        getPatientReferrals();
      }
    }, [status, page, rowsPerPage, sort])

    return (
      <>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Paper className={classes.paperTitle} elevation={0}>
              <Grid container alignItems='center'>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    {getTranslation('patient_details_page_title')}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {getTranslation('patient_details_page_subtitle')}
                  </Typography>
                </Grid>
                <Hidden only={['xs']}>
                  <Grid item xs={6}>
                    <div style={{display: "flex", justifyContent: 'flex-end'}}>
                      {!isAnnonymized() &&
                      <Tooltip
                        title={patientData.user_archivedpatient ? getTranslation("patient_archive_tooltip").replace('##date##', moment.unix(patientData.archivedpatient_at).format("YYYY-MM-DD")).replace("##username##", patientData.user_archivedpatient) : ""}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.archiveBtn}
                          onClick={!patientData.archivedpatient_by ? handleOpenArchiveModal : handleUnarchive}
                          //onClick={!patientData.archivedpatient_by ? handleArchive : handleUnarchive}
                        >
                          {!patientData.archivedpatient_by ? getTranslation("patient_detail_archive_btn") : getTranslation("patient_detail_unarchive_btn")}
                        </Button>
                      </Tooltip>}


                      <Button
                        style={{float: 'right'}}
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                        startIcon={<ArrowBackIos style={{height: 12, width: 12}}/>}
                      >{getTranslation('generic_button_back')}
                      </Button>
                    </div>
                  </Grid>
                </Hidden>
                <Hidden smUp>
                  <div style={{display: "flex", justifyContent: 'flex-end', alignItems: "center", marginTop: 10}}>
                    {!isAnnonymized() &&
                    <Tooltip
                      title={patientData.user_archivedpatient ? getTranslation("patient_archive_tooltip").replace('##date##', moment.unix(patientData.archivedpatient_at).format("YYYY-MM-DD")).replace("##username##", patientData.user_archivedpatient) : ""}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.archiveBtn}
                        onClick={!patientData.archivedpatient_by ? handleOpenArchiveModal : handleUnarchive}
                      >
                        {!patientData.archivedpatient_by ? getTranslation("patient_detail_archive_btn") : getTranslation("patient_detail_unarchive_btn")}
                      </Button>
                    </Tooltip>
                    }
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleBack}
                      startIcon={<ArrowBackIos style={{height: 12, width: 12}}/>}
                    >{getTranslation('generic_button_back')}
                    </Button>
                  </div>
                </Hidden>
              </Grid>
            </Paper>

            <Paper className={classes.paper} elevation={0}>
              <Grid container spacing={1}>
                {selectedOrg.id !== "1" &&
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.createNewRefBtn}
                    onClick={handleOnNewReferralClick}
                  >
                    {getTranslation("patient_detail_new_referral_btn")}
                  </Button>
                </Grid>}
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="filled"
                    name="pnr"
                    className={classes.textField}
                    label={getTranslation("patient_details_form_pnr")}
                    value={patientData.pnr}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="filled"
                    name="name"
                    className={classes.textField}
                    label={getTranslation("patient_details_form_name")}
                    value={patientData.name}
                    onChange={handleChange}
                    disabled={loading || isAnnonymized()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="filled"
                    name="email"
                    className={classes.textField}
                    label={getTranslation("patient_details_form_email")}
                    value={patientData.email}
                    onChange={handleChange}
                    disabled={loading || isAnnonymized()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="filled"
                    name="phone"
                    className={classes.textField}
                    label={getTranslation("patient_details_form_phone")}
                    value={patientData.phone}
                    onChange={handleChange}
                    disabled={loading || isAnnonymized()}
                  />
                </Grid>
                {!isAnnonymized() &&
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveBtn}
                    onClick={updatePatient}
                    disabled={loading}
                  >
                    {getTranslation('generic_button_save')}
                  </Button>
                </Grid>
                }
              </Grid>
            </Paper>

            <Paper className={classes.paper} style={{marginTop: 20}}>
              <Grid container className={classes.container}>
                <Grid item xs={12} style={{marginBottom: 30}}>
                  <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
                    {getTranslation('patient_details_referrals_title')}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {getTranslation('patient_details_referrals_sub_title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {loading && <LinearProgress/>}
                  <TableContainer>
                    <Table aria-label="collapsible table" size="small">
                      <EnhancedTableHead
                        sort={sort}
                        onRequestSort={handleSortChange}
                        cells={HEADER_CELLS}
                      />
                      <TableBody>
                        {rows && rows.map(row => (
                          <TableRow key={row.sys_id} className={classes.forcePointer}
                                    onClick={() => handleRowClick(row.sys_id)}>
                            <TableCell>{row.referral_id}</TableCell>
                            <TableCell>{selectedOrg.id !== "1" ? row.tester_name : row.test_date}</TableCell>
                            {selectedOrg.id !== "1" && <TableCell>{row.test_date}</TableCell>}
                            <TableCell>{row.arrival_date}</TableCell>
                            <TableCell>{row.result_date}</TableCell>
                            <TableCell>
                              <Markup content={`${row.test_method.name} (${row.test_method.id})`}/>
                            </TableCell>
                            <TableCell>
                              <div style={{display: "flex"}}>
                                <FiberManualRecordIcon style={{fill: row.status_id.color, marginRight: 5}}/>
                                <Markup content={row.status_id.name || ''}/>
                                {(row.send_digital === "1" || row.send_digital === 1) ?
                                  <Tooltip
                                    title={(row.mvz_export_date && row.mvz_export_date !== 0 && row.mvz_export_date !== "0")
                                      ?
                                      getTranslation('analysis_table_export_date_with_date_tooltip').replace('##date##', moment.unix(row.mvz_export_date).format('YYYY-MM-DD'))
                                      :
                                      getTranslation('analysis_table_export_date_no_date_tooltip')
                                    }
                                  >
                                    <CloudUploadIcon style={{
                                      fill: "#727272",
                                      margin: "0px 7px"
                                    }}/>
                                  </Tooltip>
                                  :
                                  <></>
                                }
                              </div>
                            </TableCell>
                            <TableCell>
                              {row.is_positive === '1' &&
                              <Tooltip title={getTranslation('analysis_table_positive_tooltip')}>
                                <ReceiptIcon style={{
                                  fill: "#f44336",
                                  margin: "0px 5px"
                                }}/>
                              </Tooltip>
                              }
                              {row.warranty === '1' &&
                              <Tooltip title={getTranslation('analysis_table_warranty_tooltip')}>
                                <WarningIcon style={{fill: '#ff9800', margin: "0px 5px"}}/>
                              </Tooltip>}
                            </TableCell>
                            <TableCell>
                              {row.handled_by || row.archived_by ?
                                <Tooltip
                                  title={getHandledTooltip(row)}
                                >
                                  <PeopleOutlineRoundedIcon style={{margin: "0px 5px"}}/>
                                </Tooltip>
                                :
                                <></>
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[1, 2, 10, 20, 30, 40, 50, 100]}
                            count={totalItems}
                            colSpan={HEADER_CELLS.length + 1}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage='Number per page'
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sort={sort}
                            ActionsComponent={TablePaginationActions}
                            SelectProps={{
                              inputProps: {'aria-label': 'rows per page'},
                              native: true,
                            }}
                            labelDisplayedRows={({
                                                   from,
                                                   to,
                                                   count
                                                 }) => getTranslation('generic_table_pagination_totals').replace('##from##', from).replace('##to##', to).replace('##count##', count)}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Dialog open={archiveModal} onClose={() => setArchiveModal(false)}>
          <DialogTitle>{getTranslation('archive_patient_modal_title')}</DialogTitle>
          <DialogContent>
            <Markup content={getTranslation('archive_patient_modal_content')}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setArchiveModal(false)} color="primary">
              {getTranslation('generic_button_cancel')}
            </Button>
            <Button onClick={() => handleArchive(false)} color="primary" variant={"contained"}>
              {getTranslation('archive_patient_modal_archive_button')}
            </Button>
            <Button onClick={() => handleArchive(true)} color="primary" variant={"contained"}>
              {getTranslation('archive_patient_modal_anonymize_button')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
;

const useStyles = makeStyles(({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  textField: {
    paddingLeft: 0,
    "& .MuiFormLabel-root": {
      marginLeft: "-10px"
    }
  },
  saveBtn: {
    float: "right"
  },
  forcePointer: {
    cursor: "pointer"
  },
  createNewRefBtn: {
    float: "right"
  },
  archiveBtn: {
    margin: "0px 10px",
  },
}));

export default PatientDetails;
