import React, { useState, useEffect, createContext, useContext } from 'react';
import APICall from '../functional/APIRequests';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../context/authContext';
const LocalizationContext = createContext({
  sort: { column: 'name', by: 'asc' },
  filter: {}
});

const LocalizationProvider = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useContext(AuthContext);
  //States
  const [localizeList, setLocalizeList] = useState();
  const [lang, setLang] = useState();
  const [locListFilters, setLocListFilters] = useState({
    sort: { column: 'name', by: 'asc' },
    filter: {},
    page: 0,
    rowsPerPage: 20
  });

  //Funcitons
  const userLang = () => {
    let browserLang = navigator.language || navigator.userLanguage;
    changeLang(browserLang === 'en_US' ? 'en_US' : 'sv_SE')
  }
  const updateStore = () => {
    if (!!localizeList) localStorage.setItem(process.env.REACT_APP_GUID + 'loc', JSON.stringify(localizeList));
    if (!!lang) localStorage.setItem(process.env.REACT_APP_GUID + 'userLang', lang)
    if (!!locListFilters) localStorage.setItem(process.env.REACT_APP_GUID + 'llf', JSON.stringify(locListFilters))
  }
  const rehydrate = (props) => {
    let llf = localStorage.getItem(process.env.REACT_APP_GUID + 'llf');
    setLocListFilters(JSON.parse(llf) || {
      sort: { column: 'name', by: 'asc' },
      filter: {},
      page: 0,
      rowsPerPage: 20
    })
    let lsLoc = localStorage.getItem(process.env.REACT_APP_GUID + 'loc');
    let selLang = localStorage.getItem(process.env.REACT_APP_GUID + 'userLang');
    if (!!lsLoc) {
      setLocalizeList({ ...JSON.parse(lsLoc) })
    };
    if (!!selLang) { setLang(selLang); } else { userLang() }
  }
  const getTranslationListByLang = () => {
    let locObj = {};
    return APICall({
      setLoading,
      url: `translations?filter[code]=${lang}`,
      verb: 'get',
      server: 'api',
      enqueueSnackbar,
    }).then((res) => {
      if (res.error === 0) {
        res.data.map(item =>
          locObj[item.name] = item.translation
        )
        setLocalizeList({ ...locObj })
      }
    })
  }
  const getTranslationList = (filter = '') => {
    return APICall({
      setLoading,
      url: `translations${filter}`,
      verb: 'get',
      server: 'api',
      enqueueSnackbar,
    }).then((res) => {
      return { data: res.data || [], count: res.total_records };
    })
  }
  const getTranslationSystemList = (filter = '') => {
    return APICall({
      setLoading,
      url: `translations/systems`,
      verb: 'get',
      server: 'api',
      enqueueSnackbar,
    }).then((res) => {
      return { data: res.data || [] };
    })
  }
  const getTranslationKeyById = (id) => {
    return APICall({
      setLoading,
      url: `translations/` + id,
      verb: 'get',
      server: 'api',
      enqueueSnackbar,
    }).then((res) => {

      return res.data;
    })
  }
  const submitTranslationsEdit = (id, body) => {
    return APICall({
      setLoading,
      url: `translations/${id ? id : 'create'}`,
      verb: 'post',
      server: 'api',
      data: body,
      formData: true,
      enqueueSnackbar,
    }).then((res) => {
      enqueueSnackbar(getTranslation('auth_twoFactor_submited_success'), { variant: "success" });
      return res;
    })
  }
  const getTranslation = (key) => {
    if (localizeList && localizeList[key]) {
      return localizeList[key]
    } else {
      return key
    }
  }
  const changeLang = (param) => {
    if (param) {
      setLang(param);
    }
  }
  const availableLangs = [{ title: getTranslation('generic_sweedish_string'), value: 'sv_SE' }, { title: getTranslation('generic_english_string'), value: 'en_US' }];
  //LifeCicles
  useEffect(() => {
    rehydrate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    updateStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localizeList, lang, locListFilters]);
  useEffect(() => {
    if (lang) getTranslationListByLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  return (
    <LocalizationContext.Provider value={{
      //States
      availableLangs,
      lang,
      localizeList,
      locListFilters, setLocListFilters,
      //Functions
      changeLang,
      getTranslation,
      getTranslationList,
      getTranslationKeyById,
      submitTranslationsEdit,
      getTranslationSystemList
    }}>
      {props.children}
    </LocalizationContext.Provider >
  );
};
export { LocalizationContext, LocalizationProvider };
