import React, { useState, useEffect, createContext, useContext } from 'react';
import APICall from '../functional/APIRequests';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../context/authContext';
import moment from "moment";

const ActivitiesLogsContext = createContext({
  sort: { column: 'log_date', by: 'asc' },
  filter: {}
});

const ActivitiesLogsProvider = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useContext(AuthContext);

  // States
  const [activitiesFilters, setActivitiesFilters] = useState({
    sort: { column: 'log_date', by: 'asc' },
    filter: {},
    page: 0,
    rowsPerPage: 20
  });

  const buildQueryString = (params) => {
    const query = [];
    Object.keys(params).forEach(key => {
      if (typeof params[key] === 'object' && !Array.isArray(params[key])) {
        Object.keys(params[key]).forEach(subKey => {
          query.push(`params[${key}][${subKey}]=${encodeURIComponent(params[key][subKey])}`);
        });
      } else {
        if(key === "log_date") {
          query.push(`params[from_date]=${encodeURIComponent(moment(params[key]).startOf("day").unix())}`);
          query.push(`params[to_date]=${encodeURIComponent(moment(params[key]).endOf("day").unix())}`);
        } else {
          query.push(`params[${key}]=${encodeURIComponent(params[key])}`);
        }
      }
    });
    return query.join('&');
  };

  const getActivitiesLogs = (params) => {
    const queryString = buildQueryString(params);
    return APICall({
      setLoading,
      url: `api/log/activity/list?${queryString}`,
      verb: 'get',
      server: 'api',
      enqueueSnackbar,
    })
  };

  const getActivitiesLogsById = (id) => {
    return APICall({
      setLoading,
      url: `log/activity/${id}`,
      verb: 'get',
      server: 'api',
      enqueueSnackbar,
    }).then((res) => {
      return res.data;
    });
  };

  return (
    <ActivitiesLogsContext.Provider value={{
      // States
      activitiesFilters,
      setActivitiesFilters,
      // Functions
      getActivitiesLogs,
      getActivitiesLogsById,
    }}>
      {props.children}
    </ActivitiesLogsContext.Provider>
  );
};

export { ActivitiesLogsContext, ActivitiesLogsProvider };
