import React, { useState, useContext } from "react";
import { Grid, TextField, Typography, ButtonGroup, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Save } from '@material-ui/icons'
import { LocalizationContext } from '../context/localizationContext';
import { AuthContext } from '../context/authContext';
const Profile = props => {
  const { getTranslation } = useContext(LocalizationContext)
  const { changePassword } = useContext(AuthContext)
  const classes = useStyles();
  const [data, setData] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const formValidator = () => {
    let error = [];
    Object.keys(data).forEach((key) => {
      var value = data[key];
      switch (key) {
        case 'newPassword':
          if (value.length === 0
            || (data.newPassword !== data.confirmPassword)
          ) {
            error.push('newPassword')
          }
          break;
        case 'confirmPassword':
          if (value.length === 0
            || (data.newPassword !== data.confirmPassword)
          ) {
            error.push('confirmPassword')
          } break;
        default:
          return true;
      }
    });
    return error.length === 0;
  }
  const submitData = (e) => {
    try { e.preventDefault() } catch (err) { };
    if (formValidator()) {
      changePassword({ password: data.newPassword })
      props.closeDialog();
    }
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={6}>
        <TextField
          variant="filled"
          label={getTranslation('profile_new_password_label')}
          name='newPassword'
          // disabled={data.id.length < 10}
          value={data.newPassword}
          onChange={handleChange}
          error={data.newPassword && (data.newPassword !== data.confirmPassword)}
          type='password'
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="filled"
          label={getTranslation('profile_confirm_password_label')}
          name='confirmPassword'
          // disabled={data.id.length < 10}
          value={data.confirmPassword}
          onChange={handleChange}
          error={data.confirmPassword && (data.newPassword !== data.confirmPassword)}
          helperText={data.confirmPassword && (data.newPassword !== data.confirmPassword) ? getTranslation('profile_error_password_match') : ''}
          type='password'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.buttonGroup}>
          <ButtonGroup size="small" color="primary">
            <Button onClick={props.closeDialog} > {getTranslation('generic_button_cancel')} </Button>
            <Button startIcon={<Save />} onClick={submitData} > {getTranslation('generic_button_save')} </Button>
          </ButtonGroup>
        </Typography>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(theme => ({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
}));
export default Profile;
