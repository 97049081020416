import React, {useContext, useEffect, useRef, useState} from "react";
import {useSnackbar} from "notistack";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import BarCodeSmall from "../../../components/svgs/barCodeSmall";
import {AuthContext} from "../../../context/authContext";
import {LocalizationContext} from "../../../context/localizationContext";
import {RefContext} from "../../../context/refContext";
import {OrgContext} from "../../../context/orgContext";
import APICall from "../../../functional/APIRequests";
import colors from "../../../styles/Theme-settings";
import {Html5QrcodeScanType} from "html5-qrcode";
import {Html5Qrcode} from "html5-qrcode";
import Canvas from "../../../components/Canvas";
import BarcodeScanner from "../../../components/barcodeScanner";

let html5QrCode;

const FormId = (props) => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const {setLoading, loading} = useContext(AuthContext);
  const {getTranslation} = useContext(LocalizationContext);
  const {
    referalData,
    setReferalData,
    changeStateStep,
    validId,
    setValidId,
    setTestMethods,
  } = useContext(RefContext);

  const {selectedOrg} = useContext(OrgContext);
  const [submitedId, setSubmitedId] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);

  const cameraConfig = {
    fps: 10,
    qrbox: 250,
    disableFlip: false,
    rememberLastUsedCamera: true,
    supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
    showTorchButtonIfSupported: true,
    facingMode: "environment"
  };

  const fetchTestMethods = (e) => {
    try {
      e.preventDefault();
    } catch (err) {
    }
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `products/methods`,
      verb: "get",
      server: "api",
    }).then((res) => {
      if (res) {
        setTestMethods(res.data);
      }
    });
  };
  const handleChange = (e) => {
    if (e.target.name === "id") {
      if (!isNaN(e.target.value)) {
        setReferalData({...referalData, [e.target.name]: e.target.value});
        if (e.target.value.length === 10) {
          verifyReferalId(e.target.value);
        }
      }
    }
  };

  const verifyReferalId = (param) => {
    setLoading(true);
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `customer/${selectedOrg.id}/referral/isvalid?referral_id=${param}`,
      verb: "get",
      server: "api",
    }).then((res) => {
      if (res.data) {
        setValidId(true);
      } else {
        setValidId(false);
        changeStateStep("validChange", 0, false);
      }
      setSubmitedId(true);
      setLoading(false);
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <b>{getTranslation("referal_form_step_tree_title")}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.stepSubtitle}>
        <Typography variant="body1">
          {" "}
          {getTranslation("referal_form_step_tree_subTitle")}
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.startCameraTextWrap}>
        <Typography variant="caption" className={classes.startCameraText} onClick={() => setCameraOpen(!cameraOpen)}>
          {cameraOpen ? getTranslation("referral_form_close_camera") : getTranslation("referral_form_start_camera")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="filled"
          id="id-number"
          name="id"
          className={classes.paddingLeft}
          disabled={loading || validId || (referalData && referalData.id && referalData.id.length >= 10)}
          placeholder={getTranslation("referal_form_label_id_number_mobile")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BarCodeSmall className={classes.barcodeIcon}/>
              </InputAdornment>
            ),
          }}
          inputProps={{maxLength: 10}}
          value={(referalData && referalData.id) || ""}
          onChange={(e) => {
            if (Number.isInteger(Number(e.target.value))) {
              handleChange(e);
            }
          }}
          error={
            referalData && referalData.id ?
              !referalData ||
              (referalData.id && referalData.id.length === 0) ||
              referalData.id.length < 10
              :
              false
          }
          helperText={
            referalData && referalData.id ?
              !referalData || (referalData.id && referalData.id.length === 0)
                ? getTranslation("generic_error_required_field")
                : referalData.id.length < 10
                  ? getTranslation("generic_error_minimun_digits").replace(
                    "##chars##",
                    10
                  )
                  : ""
              :
              ""
          }
        />

        {/*Camera*/}
          {cameraOpen &&
            <BarcodeScanner
                checkBarcodeInUse={verifyReferalId}
                setData={setReferalData}
                data={referalData}
                setIsScanning={setCameraOpen}
            />
          }
      </Grid>
      <Dialog open={false}>
        <DialogTitle>title</DialogTitle>
        <DialogContent> <Canvas/></DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      {submitedId && !validId && (
        <Grid item xs={12}>
          <div
            style={{
              border: `1px solid ${colors.primary}`,
              margin: 10,
              padding: 15,
              minHeight: 25,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getTranslation("referal_form_step_one_error_id")}
          </div>
        </Grid>
      )}

      {/*{!submitedId && !validId && referalData && referalData.id &&
      <Grid item xs={12}>
        <div style={{
          border: `1px solid ${colors.primary}`,
          margin: 10,
          padding: 15,
          minHeight: 25,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          Invalid ID
        </div>
      </Grid>
      }*/}
    </>
  );
};

const useStyles = makeStyles({
  /*barcodeIcon: {
     marginRight: 10,
     marginBottom: 4,
   },*/
  startCameraTextWrap: {
    marginTop: 20,
  },
  startCameraText: {
    textDecoration: "underline",
    float: "right",
    fontSize: 14,
    cursor: "pointer",
  },
  paddingLeft: {
    paddingLeft: 0,
  },
});

export default FormId;
