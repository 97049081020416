import React, {useContext} from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import {LocalizationContext} from "../context/localizationContext";
import {useParams} from "react-router-dom";

const ReviewReferralModalV3 = props => {
  const {getTranslation} = useContext(LocalizationContext);

  const {open, onSave, onSend, onClose, submited} = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{getTranslation('create_referral_modal_title')}</DialogTitle>
      <DialogContent>
        {getTranslation('create_referral_modal_body')}
      </DialogContent>
      <DialogActions>
        {submited &&
        <>
          <Button onClick={onClose} color="primary" variant="contained">
            {getTranslation('digital_sign_button_cancel')}
          </Button>
          <Button onClick={onSend} color="primary" variant="contained">
            {getTranslation('create_referral_modal_send')}
          </Button>
        </>
        }
        {!submited &&
        <>
          <Button onClick={onSend} color="primary" variant="contained">
            {getTranslation('create_referral_modal_send')}
          </Button>
          <Button onClick={onSave} color="primary" variant="contained">
            {getTranslation('create_referral_modal_save')}
          </Button>
        </>
        }
      </DialogActions>
    </Dialog>
  );
}

export default ReviewReferralModalV3;
