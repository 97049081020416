import React, {useContext, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from "clsx";
import {Markup} from "interweave";
import {RefContext} from "../../../context/refContext";
import {LocalizationContext} from "../../../context/localizationContext";
import colors from "../../../styles/Theme-settings"
import {useSnackbar} from "notistack";
import {AuthContext} from "../../../context/authContext";
import APICall from "../../../functional/APIRequests";

const FormMethods = props => {
  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();
  const {setLoading} = useContext(AuthContext);

  const {
    referalData,
    setReferalData,
    testMethods,
    formType,
    setSelectedProduct,
    setTestMethods
  } = useContext(RefContext);
  const {getTranslation} = React.useContext(LocalizationContext);

  const infoGridRef = useRef(null);

  const {handleNext} = props;

  const [selectedId, setSelectedId] = useState("0");

  let tempTestMethods = [];
  if (testMethods) {
    testMethods.forEach(tM => {
      if (formType === 2) {
        if (tM.sys_id === '2') {
          tempTestMethods.push(tM)
        }
      } else {
        // 2021.03.02: temporary remove sys_id 5
        if (tM.sys_id !== '5')
          tempTestMethods.push(tM)

      }
    })
  }

  const onTestInfoClick = id => {
    setSelectedId(id);
    infoGridRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onTestClick = id => {
    setReferalData({...referalData, testMethodId: id});
    setSelectedProduct([]);
    handleNext();
  };

  const fetchTestMethods = (e) => {
    try {
      e.preventDefault();
    } catch (err) {
    }
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `products/methods`,
      verb: "get",
      server: "api",
    }).then((res) => {
      if (res) {
        setTestMethods(res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchTestMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle1"><b>{getTranslation('referal_form_step_one_title')}</b></Typography>
      </Grid>

      <Grid item xs={12} className={classes.marginTop}>
        <Grid container justify="center" spacing={2}>
          {tempTestMethods.map((test, index) => {
            if (test.active === "1" && test.sys_id !== "0") {
              return (
                <Grid item xs={5} key={index} className={classes.testWrap}>
                  <Paper className={classes.testPaper}>
                    <Grid container justify="center">
                      <Grid item xs={12} className={classes.justifyCenter}>
                        <Typography variant="subtitle1" className={classes.testNameText}><b>{test.name}</b></Typography>
                      </Grid>
                      <Grid item xs={12} className={clsx(classes.chooseTestBtn, classes.justifyCenter)}
                            onClick={() => onTestClick(test.sys_id)}>
                        <Typography
                          variant="subtitle1"><b>{getTranslation("referral_form_select_method_btn")}</b></Typography>
                      </Grid>
                      <Grid item xs={12} className={clsx(classes.justifyCenter, classes.infoBtnWrap)}
                            onClick={() => onTestInfoClick(test.sys_id)}>
                        <InfoOutlinedIcon className={classes.infoIcon}/>
                        <Typography variant="caption"
                                    className={classes.infoBtnText}>{getTranslation("referral_form_method_info_btn")}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )
            }
          })}
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.infoWrap} ref={infoGridRef}>
        {selectedId && selectedId === '1' &&
        <Markup content={getTranslation('referral_test_method_saliva_explanation')}/>
        }
        {selectedId && selectedId === '2' &&
        <Markup content={getTranslation('referral_test_method_urine_explanation')}/>
        }
        {selectedId && selectedId === '3' &&
        <Markup content={getTranslation('referral_test_method_venous_blood_explanation')}/>
        }
        {selectedId && selectedId === '4' &&
        <Markup content={getTranslation('referral_test_method_cap_whole_blood_explanation')}/>
        }
        {selectedId && selectedId === '5' &&
        <Markup content={getTranslation('referral_test_method_cap_blood_plasma_explanation')}/>
        }
      </Grid>

      {/*<Grid item xs={12} className={classes.nextBtnWrap}>
        {(referalData && referalData.testMethodId && referalData.testMethodId !== "") &&
        <Button
          variant="outlined"
          color="primary"
          onClick={handleNext}
          className={classes.nextBtn}
        >
          {getTranslation("referral_mobile_previous_step_btn")}
        </Button>
        }
      </Grid>*/}
    </>
  );
};

const useStyles = makeStyles(({
  marginTop: {
    marginTop: 20
  },
  testPaper: {
    padding: 20,
    width: "100%",
  },
  testNameText: {
    color: colors.primary
  },
  testWrap: {
    display: "flex",
    justifyContent: "center",
  },
  chooseTestBtn: {
    background: colors.secondary,
    color: colors.white,
    padding: 7,
    cursor: "pointer",
    marginTop: 20
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  infoBtnWrap: {
    marginTop: 15,
    display: "flex",
    cursor: "pointer",
    zIndex: 10
  },
  infoIcon: {
    marginRight: 5,
    fill: colors.secondary,
    marginTop: 2
  },
  infoBtnText: {
    fontSize: 13
  },
  infoWrap: {
    marginTop: 40
  },
  nextBtnWrap: {
    textAlign: "right",
    marginTop: 15,
  },
  nextBtn: {
    borderWidth: 2
  },
  container: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default FormMethods;
