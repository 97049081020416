import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import colorConfig from "./Theme-settings";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: colorConfig.primary
    },
    secondary: {
      main: colorConfig.secondary
    },
    error: {
      main: red.A400
    },
    background: {
      default: colorConfig.bgGray
    }
  },
  overrides: {
    MuiFormControlLabel: {
      root: {
        marginLeft: '-20px'
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colorConfig.white,
        // borderBottom: `1px solid ${colorConfig.primary}`
      }
    },
    MuiTabs: {
      root: {
        color: colorConfig.secondary
      }
    },
    MuiTab: {
      root: {
        fontSize: 16,
        textTransform: 'none !important',
        "&&:hover": {
          color: colorConfig.primary,
          // borderBottom: `1px solid ${colorConfig.primary}`
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      containedPrimary: {
        color: colorConfig.white,
        backgroundColor: colorConfig.primary,
        "&&:hover": {
          color: colorConfig.white,
          backgroundColor: colorConfig.primary,
          boxShadow: 'none',
        }
      },
      textPrimary: {
        "&&:hover": {
          // color: colorConfig.white,
          // backgroundColor: colorConfig.primary,
          boxShadow: 'none'
        }
      }
    },
    MuiSvgIcon: {
      root: {
        fill: colorConfig.primary,
        width: 18,
        height: 18,
        display: "inline-block",
        fontSize: 18,
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        flexShrink: 0,
        userSelect: "none"
      }
    },
    MuiTable: {
      root: {
        border: '1px solid #B7B7B7',
        borderRadius: '3px',
        borderCollapse: 'inherit',
      }
    },
    MuiTableHead: {
      root: {
        boxShadow: '0px 1px 4px #00000029',
        // cursor: 'pointer'
      }
    },
    MuiTableCell: {
      root: {
        fontSize: 14,
        padding: 5,
        borderBottomColor: '#B7B7B7'
        // cursor: 'pointer'
      }
    },
    MuiFormLabel: {
      root: {
        left: '10px !important',
        top: '10px !important',
        color: colorConfig.secondary,
        "&$focused": {
          // increase the specificity for the pseudo class
          color: colorConfig.secondary
        }
      }
    },
    MuiInput: {
      underline: {
        color: colorConfig.secondary,
        "&:after": {
          // increase the specificity for the pseudo class
          borderBottomColor: colorConfig.secondary,
        }
      },
    },
    MuiFormControl: {
      root: {
        // height:88,
        width: '100%',
        padding: 10,
      }
    },
    MuiSelect: {
      root: {
        "&$select": {
          "&:focus": {
            backgroundColor: 'transparent',
          }
        }
      }
    },
    MuiFormGroup: {
      root: {
        padding: 10
      },
    },
    MuiGrid: {
      container: {
        '&:focus': {
          outline: 'none'
        }
      }
    },
    MuiGridListTile: {
      root: { padding: '0 !important' }
    },
    MuiButtonGroup: {
      grouped: {
        borderRadius: 0,
        padding: '5px 15px !important',
        margin: '0px 5px !important',
        border: `1px solid ${colorConfig.primary} !important`,
        "&&:hover": {
          border: `1px solid ${colorConfig.primary} !important`,
          // backgroundColor: colorConfig.halfPrimary
        }
      },
      groupedHorizontal: {
        '&:first-child': {
          borderColor: 'transparent !important',
          marginLeft: '0px !important',
          // padding: '5px !important',
        },
        '&:last-child': {
          marginRight: '0px !important',
        }
      },
    },
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto !important'
      }
    }
  }
});

export default theme;
