import React from 'react';
import { Chip, Grid } from '@material-ui/core';

import { } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  }
}));

const SearchChips = (props) => {
  const classes = useStyles();
  const handleDelete = (filter, array = false, index) => {
    let newFilter = JSON.parse(JSON.stringify(props.filter))
    if (!array) {
      if (delete newFilter[filter]) {
        props.setFilter(newFilter);
      }
    } else {
      newFilter[filter].splice(index, 1)
      if (newFilter[filter].length > 0) {
        props.setFilter(newFilter);
      } else {
        if (delete newFilter[filter]) {
          props.setFilter(newFilter);
        }
      }
    }
  }
  let groupFilter = [];
  props.columns.forEach(col => { if (col.interval) { groupFilter.push(col) } });
  return (
    <Grid container className={classes.container}>
      {Object.keys(props.filter).map(filter => {
        let tempFilter = props.columns.find(col => col.functionalName === filter);
        if (tempFilter) {
          if (Array.isArray(props.filter[filter])) {
            return props.filter[filter].map((f, index) => {
              let list = props.columns.find(col => col.functionalName === filter).list;
              return <Chip key={filter} id={tempFilter.id} label={`${tempFilter.name}: ${list.find(l => l.id === f).text}`
              } onDelete={() => handleDelete(filter, true, index)} color="primary" variant="outlined" />
            })
          } else {
            let colData = props.columns.find(col => col.functionalName === filter);
            let label = props.filter[filter];
            if (colData.type === 'select') {
              label = colData.list.find(item => item.id === props.filter[filter]).text
            }
            return <Chip key={filter} id={tempFilter.id} label={`${tempFilter.name}: ${label}`
            } onDelete={() => handleDelete(filter)} color="primary" variant="outlined" />
          }
        } else {
          //SubArrays of groups
          let indexGroup;
          let tempFilter;
          groupFilter.forEach((gf, i) => {
            let temp = gf.intervalFunctionName.find(sgf => sgf.functionalName === filter);
            if (temp) {
              indexGroup = i;
              tempFilter = temp;
            }
          })
          return <Chip key={filter} label={`${groupFilter[indexGroup].name}-${tempFilter.name}: ${props.filter[filter]}`
          } onDelete={() => handleDelete(filter)} color="primary" variant="outlined" />
        }
      })}
    </Grid>
  )
}

export default SearchChips;
