import React from "react";
import Media from "react-media";

export default function({desktop: Desktop, tablet: Tablet, mobile: Mobile, ...props}) {
  return (
    <Media queries={{
      desktop: "(min-width: 1025px)",
      tablet: "(min-width: 768px) and (max-width: 1024px)",
      mobile: "(max-width: 767px)"
    }}>
      {matches => (
        <>
          {matches.desktop && <Desktop {...props}/>}
          {matches.tablet && <Tablet {...props}/>}
          {matches.mobile && <Mobile {...props}/>}
        </>
      )}
    </Media>
  );
}
