import React, {useContext, useState, useEffect} from "react";
import {Grid, Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import colors from '../../../styles/Theme-settings';
import {RefContext} from '../../../context/refContext';
import {AuthContext} from '../../../context/authContext';
import {LocalizationContext} from '../../../context/localizationContext';
import Dialog from '../../../components/Dialog'
import {Markup} from 'interweave';
import APICall from "../../../functional/APIRequests";
import {useSnackbar} from "notistack";

const FormTests = props => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const {loading, setLoading} = useContext(AuthContext);
  const {
    referalData,
    setReferalData,
    testMethods,
    formType,
    setSelectedProduct,
    selectedProduct,
    setVerifiedCheck,
    changeStateStep,
    setOtherChemIntake,
    setTestMethods
  } = useContext(RefContext);
  const {getTranslation} = React.useContext(LocalizationContext);
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedId, setSelectedId] = useState()
  let tempTestMethods = [];
  if (testMethods) {
    testMethods.forEach(tM => {
      if (formType === 2) {
        if (tM.sys_id === '2') {
          tempTestMethods.push(tM)
        }
      } else {
        // 2021.03.02: temporary remove sys_id 5
        if (tM.sys_id !== '5')
          tempTestMethods.push(tM)

      }
    })
  }

  const fetchTestMethods = (e) => {
    try {
      e.preventDefault();
    } catch (err) {
    }
    APICall({
      setLoading,
      enqueueSnackbar,
      url: `products/methods`,
      verb: "get",
      server: "api",
    }).then((res) => {
      if (res) {
        setTestMethods(res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchTestMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedId) {
      checkForModal();
    }
    // eslint-disable-next-line
  }, [selectedId])
  useEffect(() => {
    if (!openDialog) {
      setSelectedId(null)
    }
    // eslint-disable-next-line
  }, [openDialog])
  const checkForModal = () => {
    if (!referalData || selectedId !== referalData.testMethodId) {
      if ((selectedProduct && selectedProduct.length > 0)) {
        setOpenDialog(true)
      } else {
        changeMethod()
      }
    }
  }
  const changeMethod = (dialogOk = true) => {
    if (dialogOk) {
      setOpenDialog(false);
      setVerifiedCheck(false);
      setOtherChemIntake();
      changeStateStep('validChange', 0, true)
      setSelectedProduct([]);
      setReferalData({...referalData, testMethodId: selectedId});
    } else {
      setSelectedId(null)
    }
  }
  const getMarginTriangle = () => {
    const list = tempTestMethods.filter(item => item.active === '1' && item.sys_id !== '0');
    let selId = 1;
    if (referalData && referalData.testMethodId) {
      const index = list.findIndex(x => x.sys_id === referalData.testMethodId)
      if (index && index > -1)
        selId = index + 1;
    }
    let totalItems = list.length;
    let unityWidth = 100 / totalItems;
    let unityCenter = unityWidth / 2;
    let marginWidth = (Number(selId) - 1) * unityWidth;
    return `calc(${marginWidth + unityCenter}% - 10px)`
  }
  const getMargin = () => {
    const list = tempTestMethods.filter(item => item.active === '1' && item.sys_id !== '0');
    let selId = 1;
    if (referalData && referalData.testMethodId) {
      const index = list.findIndex(x => x.sys_id === referalData.testMethodId)
      if (index && index > -1)
        selId = index + 1;
    }
    let totalItems = list.length;
    return `${(100 / totalItems) * (Number(selId) - 1)}%`;
  }

  return (
    <Grid container style={{display: 'flex', justifyContent: 'center'}}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom style={{fontWeight: 'bold'}}>
          {getTranslation('referal_form_step_one_title')}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{display: 'flex', margin: '20px 0px'}}>
        {tempTestMethods.map((test, index) => {
          if (test.active === '1' && test.sys_id !== '0') {
            return < Button
              key={index}
              className={`${referalData && referalData.testMethodId === test.sys_id ? classes.cartButtonActive : classes.cartButton}`
              }
              // variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                setSelectedId(test.sys_id)
              }}
            >
              {test.name}
            </Button>
          } else {
            return false;
          }
        })}
      </Grid>
      {openDialog &&
      <Dialog
        open={!!openDialog}
        setOpen={setOpenDialog}
        dialogData
        message={getTranslation('referral_change_test_alert_msg')}
        maxWidth={'sm'}
        callback={changeMethod}
        title={getTranslation('referral_change_test_alert_title')}
        cancelLabel={getTranslation('generic_button_cancel')}
        submitLabel={getTranslation('generic_button_ok')}
      />
      }
      <Grid item xs={12}>
        <div className={!(referalData && referalData.testMethodId) ? '' : classes.triangle} style={{
          marginLeft: !(referalData && referalData.testMethodId) ? '' : getMarginTriangle()
        }}/>
        <div style={{
          padding: '0px 16px',
          marginLeft: getMargin(),
          width: `${100 / tempTestMethods.filter(item => item.active === '1' && item.sys_id !== '0').length}%`
        }}>

          <p className={!(referalData && referalData.testMethodId) ? '' : classes.infoHolder}>
            {!(referalData && referalData.testMethodId) &&
            <Markup content={getTranslation('referral_test_method_generic_explanation')}/>
            }
            {referalData && referalData.testMethodId === '1' &&
            <Markup content={getTranslation('referral_test_method_saliva_explanation')}/>
            }
            {referalData && referalData.testMethodId === '2' &&
            <Markup content={getTranslation('referral_test_method_urine_explanation')}/>
            }
            {referalData && referalData.testMethodId === '3' &&
            <Markup content={getTranslation('referral_test_method_venous_blood_explanation')}/>
            }
            {referalData && referalData.testMethodId === '4' &&
            <Markup content={getTranslation('referral_test_method_cap_whole_blood_explanation')}/>
            }
            {referalData && referalData.testMethodId === '5' &&
            <Markup content={getTranslation('referral_test_method_cap_blood_plasma_explanation')}/>
            }
          </p>
        </div>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(theme => ({
  cartButton: {
    width: '100%',
    margin: '0px 15px',
    borderColor: colors.primary,
    borderStyle: 'solid',
    borderWidth: 1
  },
  buttonGroup: {
    marginTop: 30,
    marginBottom: 30,
    paddingRight: 10,
  },
  cartButtonActive: {
    width: '100%',
    margin: '0px 15px',
    backgroundColor: colors.primary,
    color: colors.white,
    "&&:hover": {
      color: colors.primary,
      backgroundColor: colors.halfPrimary,
      boxShadow: 'none'
    }
  },
  infoHolder: {
    borderRadius: 5,
    border: `1px solid #999`, padding: 20,
    '-webkit-transform': 'rotate(0deg)',
    '-moz-transform': 'rotate(0deg)',
    '-o-transform': 'rotate(0deg)',
    '-ms-transform': 'rotate(0deg)',
    transform: 'rotate(0deg)',
    zIndex: 2,
    backgroundColor: 'white',
    // width: '25%'
  },
  triangle: {
    '-webkit-transform': 'rotate(45deg)',
    '-moz-transform': 'rotate(45deg)',
    '-o-transform': 'rotate(45deg)',
    '-ms-transform': 'rotate(45deg)',
    transform: 'rotate(45deg)',
    backgroundColor: '#999',
    marginBottom: -20,
    zIndex: 1,
    height: 10,
    width: 10
  }
}));
export default FormTests;
