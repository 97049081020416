import React, {useState, useContext, useEffect} from 'react';
import {Grid, TextField, Button, Paper, Typography, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {AuthContext} from '../../context/authContext';
import {isValid} from 'swedish-personal-identity-number-validator';
import {useSnackbar} from 'notistack';
import {LocalizationContext} from '../../context/localizationContext';
import colors from '../../styles/Theme-settings'

const useStyles = makeStyles(theme => ({
  inputGrid: {
    display: 'flex'
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center'
  },
  textInput: {
    width: '100%',
    padding: '10px 0px',
  },
  formWidth: {
    width: '100%',
  },
  buttonCancel: {
    borderRadius: 0,
    // borderColor: '#D5D5D5',
    // color: '#D5D5D5'
  },
  errorBox: {
    padding: 20,
    color: colors.primary,
    border: `1px solid ${colors.primary}`,
    textAlign: 'center',
    margin: '20px 0px',
  }
}));
const BankID = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({bankid: ''});
  const [cancel, setCancel] = useState(false);
  const {bankIdAuth, bankIdCode, loading, retryBA, cancelBankIdCheck, startHostedGrandId} = useContext(AuthContext);
  const {getTranslation} = useContext(LocalizationContext)
  const {enqueueSnackbar} = useSnackbar();
  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value})
  }
  const formValidator = () => {
    let error = [];
    Object.keys(data).forEach((key) => {
      var value = data[key];
      switch (key) {
        case 'bankid':
          if (!value || value.length === 0 || !isValid(convertBankID())) {
            error.push('bankid')
          }
          break;
        default:
          return true;
      }
    });
    return error.length === 0;
  }
  const submitData = (e) => {
    try {
      e.preventDefault()
    } catch (err) {
    }
    ;
    startHostedGrandId();
    if (formValidator()) {
      // setRetryBA(0);
      //bankIdAuth(data);
    }
  }
  const cancelCheck = () => {
    setCancel(true);
    cancelBankIdCheck()
  }
  const convertBankID = () => {
    let bankid = data.bankid;
    if (bankid.search('-') === -1) {
      let pos = bankid.length - 4;
      bankid = [bankid.slice(0, pos), '-', bankid.slice(pos)].join('');
    }
    if (bankid.length < 13) {
      if (Number(bankid.slice(0, 2) < 31)) {
        bankid = '20' + bankid;
      } else {
        bankid = '19' + bankid;
      }
    }
    return bankid;
  }
  useEffect(() => {
    if (!loading && bankIdCode) {
      if (!cancel && retryBA >= 999) {
        enqueueSnackbar(getTranslation('login_form_bankid_error_timeout'), {variant: "warning"});
      } else {
        setCancel(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  return (
    <form onSubmit={submitData} className={classes.formWidth}>
      <React.Fragment>
        {getTranslation('login_bank_id_error') === "testlogin_bank_id_error" &&
        <Grid style={{display: "flex", justifyContent: "center"}} item xs={12}>
          <Typography variant='body1' style={{
            border: '2px dashed #E41937',
            padding: '10px 20px',
            background: '#fcf9d9'
          }}>{getTranslation('login_bank_id_error')}</Typography>
        </Grid>}
       {/* <Grid className={classes.inputGrid} item xs={12}>
          <TextField
            className={classes.textInput}
            onChange={handleChange}
            label={getTranslation('login_form_placeholder_bankId')}
            name='bankid'
            disabled={loading}
          />
        </Grid>*/}
        {retryBA >= 999 &&
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom className={classes.errorBox}>
            {getTranslation('login_form_bankid_error_text')}
          </Typography>
        </Grid>
        }
        <Grid className={classes.buttonGrid} item xs={12}>
          <Button disabled={loading} type='submit' className={classes.buttons} variant="contained" color="primary">
            {getTranslation('login_form_button_submit_bankid')}
          </Button>
        </Grid>
      </React.Fragment>
      {/*{loading &&
      <Paper elevation={4} style={{ width: '100%'}}>
        <Grid container style={{ padding: 20 }}>
          <Grid style={{justifyContent: 'flex-center'}} item xs={12}>
            <Typography variant="h6" gutterBottom>
              {getTranslation('login_form_bankid_waiting_title')}
            </Typography>            
          </Grid>
          <Typography variant="body1" gutterBottom>
            {getTranslation('login_form_bankid_waiting_msg')}
          </Typography>
          <Grid style={{justifyContent: 'center', marginTop: 15}} item xs={12}>
            <CircularProgress/>
          </Grid>
          <Grid style={{display: 'flex', justifyContent: 'flex-end'}} item xs={12}>
            <Button className={classes.buttonCancel} onClick={cancelCheck} variant="outlined" color="secondary">
              {getTranslation('generic_button_cancel')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      }*/}
    </form>
  )
};
export default BankID;
