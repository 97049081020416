import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Card, CardContent, Typography} from "@material-ui/core";

const useStyles = makeStyles({
    item: {
        padding: 20
    },
    card: {
        minWidth: 275,
    },
    content: {
        minHeight: 285,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    imageWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 38
    },
    image: {
        width: 75,
        height: 90,
        cursor: "pointer"
    },
})

const PdfPreview = () => {
    const classes = useStyles();

    const handleDownloadClick = (index) => {
        // Create a URL for the file you want to download
        let fileUrl;
        switch (index) {
            case 0:
                fileUrl = '/files/Prodia_Blodanalys_Kapillär.pdf';
                break;
            case 1:
                fileUrl = '/files/Prodia_Blodanalys_Venös.pdf';
                break;
            case 2:
                fileUrl = '/files/Prodia_Salivanalys.pdf';
                break;
            case 3:
                fileUrl = '/files/Prodia_Urinanalys.pdf';
                break;
            default:
                fileUrl = "/files/Provtagning_Quantisal.pdf";
        }


        const fileName = fileUrl.split("/")[2];

        // Create a link element
        const link = document.createElement('a');

        // Set the href attribute of the link to the file URL
        link.href = fileUrl;

        // Set the download attribute to specify the file name
        link.download = fileName;

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger a click event on the link
        link.click();

        // Remove the link from the document body
        document.body.removeChild(link);
    }

    return (
        <>
            <Grid item xs={12} sm={6} md={4} className={classes.item}>
                <Card className={classes.card} variant="outlined">
                    <CardContent className={classes.content}>
                        <div className={classes.imageWrapper}>
                            <img className={classes.image} onClick={() => handleDownloadClick(0)}
                                 src="/images/download-pdf-icon.png" alt="pdf-icon"/>
                        </div>
                        <div>
                            <Typography variant="subtitle2" gutterBottom>
                                Produktblad - Kapillär blodanalys
                            </Typography>
                            <Typography variant="body2" component="p">
                                Produktblad för kapillär blodanalys hos Prodia
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4} className={classes.item}>
                <Card className={classes.card} variant="outlined">
                    <CardContent className={classes.content}>
                        <div className={classes.imageWrapper}>
                            <img className={classes.image} onClick={() => handleDownloadClick(1)}
                                 src="/images/download-pdf-icon.png" alt="pdf-icon"/>
                        </div>
                        <div>
                            <Typography variant="subtitle2" gutterBottom>
                                Produktblad - Venös blodanalys
                            </Typography>
                            <Typography variant="body2" component="p">
                                Produktblad för venös blodanalys hos Prodia
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4} className={classes.item}>
                <Card className={classes.card} variant="outlined">
                    <CardContent className={classes.content}>
                        <div className={classes.imageWrapper}>
                            <img className={classes.image} onClick={() => handleDownloadClick(2)}
                                 src="/images/download-pdf-icon.png" alt="pdf-icon"/>
                        </div>
                        <div>
                            <Typography variant="subtitle2" gutterBottom>
                                Produktblad - Salivanalys
                            </Typography>
                            <Typography variant="body2" component="p">
                                Produktblad för salivanalys hos Prodia
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4} className={classes.item}>
                <Card className={classes.card} variant="outlined">
                    <CardContent className={classes.content}>
                        <div className={classes.imageWrapper}>
                            <img className={classes.image} onClick={() => handleDownloadClick(3)}
                                 src="/images/download-pdf-icon.png" alt="pdf-icon"/>
                        </div>
                        <div>
                            <Typography variant="subtitle2" gutterBottom>
                                Produktblad - Urinanalys
                            </Typography>
                            <Typography variant="body2" component="p">
                                Produktblad för urinanalyshos Prodia
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4} className={classes.item}>
                <Card className={classes.card} variant="outlined">
                    <CardContent className={classes.content}>
                        <div className={classes.imageWrapper}>
                            <img className={classes.image} onClick={() => handleDownloadClick(4)}
                                 src="/images/download-pdf-icon.png" alt="pdf-icon"/>
                        </div>
                        <div>
                            <Typography variant="subtitle2" gutterBottom>
                                Salivinsamling för analys
                            </Typography>
                            <Typography variant="body2" component="p">
                                Instruktion från Prodia på hur du utför en salivinsamling till vårt ackrediterade
                                laboratorium.
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

export default PdfPreview;