import React, { useContext, useState } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../context/authContext';
import { LocalizationContext } from '../context/localizationContext';
const useStyles = makeStyles(theme => ({
  inputGrid: {
    display: 'flex'
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textInput: {
    width: '100%',
    padding: '10px 0px',
  },
  formWidth: {
    width: '100%',
  }
}));
const ResetForm = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({ password: '', confirmPassword: '' });
  const { resetPassword, loading } = useContext(AuthContext);
  const { getTranslation } = useContext(LocalizationContext)
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  const formValidator = () => {
    let error = [];
    Object.keys(data).forEach((key) => {
      var value = data[key];
      switch (key) {
        case 'password':
          if (value.length === 0
            || (data.password !== data.confirmPassword)
          ) {
            error.push('password')
          }
          break;
        case 'confirmPassword':
          if (value.length === 0
            || (data.password !== data.confirmPassword)
          ) {
            error.push('confirmPassword')
          } break;
        default:
          return true;
      }
    });
    return error.length === 0;
  }
  const submitData = (e) => {
    try { e.preventDefault() } catch (err) { };
    if (formValidator()) {
      resetPassword({
        password: data.password,
        token: props.match.params.token
      })
        .then(res => {
          props.history.push('/login')
        })
    }
  }
  return (
    <form onSubmit={submitData} className={classes.formWidth}>
      <Grid className={classes.inputGrid} item xs={12}>
        <TextField
          className={classes.textInput}
          onChange={handleChange}
          label={getTranslation('reset_password_form_placeholder_password')}
          type='password'
          name='password'
          error={!data.password && (data.password !== data.confirmPassword)}
          disabled={loading}
        />
      </Grid>
      <Grid className={classes.inputGrid} item xs={12}>
        <TextField
          className={classes.textInput}
          onChange={handleChange}
          label={getTranslation('reset_password_form_placeholder_confirm_password')}
          type='password'
          name='confirmPassword'
          error={!!data.confirmPassword && (data.password !== data.confirmPassword)}
          helperText={data.confirmPassword && (data.password !== data.confirmPassword) ? getTranslation('profile_error_password_match') : ''}
          disabled={loading}
        />
      </Grid>
      <Grid className={classes.buttonGrid} item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button disabled={loading || !formValidator()} type='submit' className={classes.buttons} variant="contained" color="primary">
          {getTranslation('reset_password_form_button_submit')}
        </Button>
      </Grid>
    </form>
  )
};
export default ResetForm;