import React, { forwardRef, useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Grid, FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { Markup } from 'interweave';

import { LocalizationContext } from '../context/localizationContext';
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AlertDialogSlide = (props) => {
  const { getTranslation } = useContext(LocalizationContext);
  const [data, setDataType] = useState(1)
  const handleClose = (params) => {
    props.setOpen(false);
    if (params) {
      props.callback(params)
    }
  };
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth={props.maxWidth || (props.html ? 'lg' : 'sm')}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{getTranslation('dialog_opiad_title')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <Markup content={getTranslation('dialog_opiad_message')} />
              <RadioGroup aria-label="amphetaminetypes" name="amphetaminetypes" value={data} onChange={(e) =>
                setDataType(Number(e.target.value))
              }>
                <FormControlLabel value={1} control={<Radio />} label={getTranslation('dialog_opiad_option_1')} />
                <FormControlLabel value={2} control={<Radio />} label={getTranslation('dialog_opiad_option_2')} />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {props.cancelLabel &&
          <Button onClick={() => handleClose(false)} color="primary">
            {props.cancelLabel}
          </Button>
        }
        {props.submitLabel &&
          <Button onClick={() => handleClose(data)} color="primary">
            {props.submitLabel}
          </Button>
        }
      </DialogActions>
    </Dialog >
  );
}
export default AlertDialogSlide;