import React from 'react';
import {IconButton} from '@material-ui/core';
import {
  LastPage as LastPageIcon,
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft, KeyboardArrowRight,
} from '@material-ui/icons';
import {makeStyles, useTheme} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
const TablePaginationActions = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const {count, page, rowsPerPage, onChangePage, onPageChange} = props;
  const handleFirstPageButtonClick = event => {
    if (onChangePage)
      onChangePage(event, 0);
    else
      onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    if (onChangePage)
      onChangePage(event, page - 1);
    else
      onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    if(onChangePage)
    onChangePage(event, page + 1);
    else
      onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    if(onChangePage)
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    else
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
      </IconButton>
    </div>
  );
}
export default TablePaginationActions;
