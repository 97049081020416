import React, { useState, useEffect, useContext } from "react";
import { Grid, Paper, Button, Typography, ListItemText, LinearProgress, ListItemIcon, List, IconButton, Collapse, ListItem, ListItemSecondaryAction } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from '../../styles/Theme-settings';
import { CloudUpload, ExpandLess, ExpandMore, Delete } from '@material-ui/icons';
import MvzFileImportTable from "../../components/invoices/MvzFileImportTable";
import { LocalizationContext } from '../../context/localizationContext';
import { AuthContext } from '../../context/authContext';
import APICall from '../../functional/APIRequests';
import { useSnackbar } from 'notistack';
import {apiServer} from "../../functional/generateEnvUrls";

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  divider: {
    width: "80%"
  },
  cartButton: {
    width: '100%'
  }
}));
const Invoices = props => {
  const classes = useStyles();
  const { getTranslation } = useContext(LocalizationContext);
  const { loading, setLoading } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  document.title = getTranslation('mvz_import_browser_title') + ' | Prodiagnostics';
  const [file, setFile] = useState();
  const [fileList, setFileList] = useState([]);
  const [data, setData] = useState();
  const [fileId, setFileId] = useState();
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const handleFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
    document.getElementById('fileInput').value = '';
  }
  const handleButonAction = () => {
    document.getElementById('fileInput').click();
  }
  const handleUpload = () => {
    if (file) {
      setLoading(true)
      var form = new FormData();
      form.append("file", file);
      APICall({
        setLoading,
        hasRefresh: true,
        enqueueSnackbar,
        url: `prodiagnostics/1/invoice/upload-diff`,
        verb: 'post',
        server: 'api',
        formData: true,
        data: form,
      }).then((res) => {
        setLoading(false)
        if (res && res.data) {
          setError(false)
          setFileId(res.data)
          setFile();
        } else {
          setError(true)
        }
      });
    }
  }
  const handleFileSelect = () => {
    if (file || fileId) {
      setLoading(true)
      setOpen(false)
      var form = new FormData();
      form.append("file_id", fileId);
      APICall({
        setLoading,
        hasRefresh: true,
        enqueueSnackbar,
        url: `prodiagnostics/1/invoice/diff`,
        verb: 'post',
        server: 'api',
        formData: true,
        data: form,
      }).then((res) => {
        setLoading(false)
        if (res && res.data) {
          let keys = Object.keys(res.data[0])
          let returnData = keys.map(key => {
            let url = `${apiServer}/analyses/detail/${res.data[0][key].referral_sys_id}`;
            return {
              customer_id: res.data[0][key].customer_number,
              customer_name: res.data[0][key].company_name,
              lab_id: {
                name: key,
                icon: true,
                url: url
              },
              mvz_count: String(res.data[0][key].diff_data.invoiced),
              lab_count: String(res.data[0][key].diff_data.analysis),
              diff: {
                color: res.data[0][key].diff ? '#D8102E' : '#28A745',
                name: String(Number(res.data[0][key].diff_data.analysis) - Number(res.data[0][key].diff_data.invoiced))
              }
            }
          })
          setData(returnData)
        }
      });
    }
  }
  const handleListExpand = (e) => {
    setOpen(!open);
  }
  const fetchFileList = () => {
    setLoading(true)
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/invoice/list-diff`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false)
      if (res && res.data) {
        setFileList(res.data)
      }
    });
  }
  const handleRemoveFile = (id) => {
    setLoading(true)
    var form = new FormData();
    form.append("file_id", id);
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `prodiagnostics/1/invoice/remove-diff`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    }).then((res) => {
      setLoading(false)
      fetchFileList();
    });
  }
  // eslint-disable-next-line
  useEffect(() => { handleUpload(); fetchFileList() }, [file])
  // eslint-disable-next-line
  useEffect(() => handleFileSelect(), [fileId])
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        <Paper className={classes.paperTitle} elevation={0}>
          <Typography variant="h6" gutterBottom>
            {getTranslation('mvz_page_title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {getTranslation('mvz_page_subtitle')}
          </Typography>
        </Paper>
        {loading && <LinearProgress />}
        <Paper className={classes.paperTitle} elevation={0}>
          <Grid container style={{ marginBottom: 15 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                {getTranslation('mvz_import_title')}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {getTranslation('mvz_import_subtitle')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 30 }}>
            <Grid container>
              <Grid item xs={4}>
                <Button
                  disabled={loading}
                  className={classes.cartButton}
                  variant="contained"
                  color="primary"
                  onClick={handleButonAction}
                  startIcon={<CloudUpload />}
                >
                  <input type='file' onChange={handleFile} id='fileInput' hidden />
                  {getTranslation('invoices_list_button_import_mvz')}
                </Button>
              </Grid>
            </Grid>
            {error &&
              <Grid container>
                <Grid item xs={4}>
                  <div style={{ border: `1px solid ${colors.primary}`, marginTop: 10, padding: 15, minHeight: 56, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                    {getTranslation('mvz_import_error')}
                  </div>
                </Grid>
              </Grid>
            }
            <Grid container>
              <Grid item xs={4}>
                <List

                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  className={classes.root}
                >
                  <ListItem button onClick={handleListExpand} disabled={loading}>
                    <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
                    <ListItemText primary={getTranslation('mvz_import_file_list_title')} />
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit disabled={loading}>
                    {fileList.map((item, ind) =>
                      <List key={ind} component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={() => setFileId(item.sys_id)}>
                          <ListItemText primary={item.name} />
                          <ListItemSecondaryAction onClick={() => handleRemoveFile(item.sys_id)}>
                            <IconButton edge="end" aria-label="delete" disabled={loading}>
                              <Delete />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    )}
                  </Collapse>
                </List>
              </Grid>
            </Grid>
          </Grid>
          {data &&
            <React.Fragment>
              <Grid container style={{ marginBottom: 30 }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold' }}>
                    {getTranslation('mvz_table_title')}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {getTranslation('mvz_table_subtitle')}
                  </Typography>
                </Grid>
              </Grid>
              <MvzFileImportTable data={data} {...props} />
            </React.Fragment>
          }
        </Paper>
      </Grid>
    </Grid >
  );
};
export default Invoices;
