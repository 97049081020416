import React, {useContext, useState} from "react";
import {Grid, LinearProgress, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {LocalizationContext} from "../../../context/localizationContext";
import {AuthContext} from "../../../context/authContext";
import {RefContext} from "../../../context/refContext";
import colors from "../../../styles/Theme-settings";
import FormId from "../steps-mobile/formId";
import FormMethodsStep from "../steps-mobile/formMethods";
import FormTestsStep from "../steps-mobile/formTests";
import ReviewStep from "../steps/reviewV3";
import FormData from "../steps-mobile/formData";
import moment from "moment";
import clsx from "clsx";

const Mobile = props => {
  const classes = useStyles();
  const {getTranslation} = useContext(LocalizationContext);
  const {loading} = useContext(AuthContext);
  const {
    steps,
    activeStep,
    setActiveStep,
    validId,
    submited,
    formType,
    referalData,
    validSsn,
    pnrInUse
  } = useContext(RefContext);

  const {handleNext, handleBack, openPDF, getQuickTestName, quickTests, pathName, handleImageClick, id} = props;

  const [showDropdown, setShowDropdown] = useState(false);

  const activeStepHeaderNumber = () => {
    let step = steps.find(s => s.id === activeStep);

    return step ? `${step.title}/4:` : "";
  };

  const activeStepHeaderText = () => {
    let step = steps.find(s => s.id === activeStep);

    return step ? step.subTitle : "";
  };

  const changeStep = id => {
    if (stepAvailable(id)) {
      setActiveStep(id);
      setShowDropdown(false);
    }
  };

  const stepAvailable = id => {
    if (activeStep > id) {
      return true;
    } else if (activeStep === id) {
      return true;
    } else if (id - 1 === activeStep && ((steps[id - 1].valid && id !== 3) || (id === 3 && validSsn && !pnrInUse))) {
      return true;
    } else {
      return false;
    }
  };

  const _handleNext = () => {
    handleNext();
    setShowDropdown(false);
  };

  const _handleBack = () => {
    handleBack();
    setShowDropdown(false);
  };

  return (
    <>
      <Grid sm={10}>
        {loading && <LinearProgress/>}
        <Paper elevation={0} className={classes.paperTitle}>
          <Typography variant="h6" gutterBottom>
            {formType === 1 ? getTranslation('referal_form_box_title_mobile') : getTranslation('warranty_report_box_title_mobile')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formType === 1 ? getTranslation('referal_form_box_subTitle_mobile') : getTranslation('warranty_report_box_subTitle_mobile')}
          </Typography>
        </Paper>

        {(pathName.search('warranty') > -1 && id) &&
        <Paper className={classes.paperTitle} elevation={0}>
          <Typography variant="h6" gutterBottom>
            {formType === 1 ? getTranslation('referal_form_box_subTitle') : getTranslation('warranty_information_title')}
          </Typography>
          {referalData &&
          <Grid container>
            <Grid item xs={12} className={classes.displayFlex}>
              <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                {`${getTranslation('referal_warranty_test_date_label')}:`}&nbsp;&nbsp;
              </Typography>
              <Typography variant="body2" gutterBottom>
                {moment(referalData.warrantyTestDate).format('YYYY-MM-DD HH:mm')}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.displayFlex}>
              <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                {`${getTranslation('referal_warranty_lot_id_label')}:`}&nbsp;&nbsp;
              </Typography>
              <Typography variant="body2" gutterBottom>
                {referalData.warrantyLotId}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.displayFlex}>
              <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                {`${getTranslation('referal_warranty_comments_label')}:`}&nbsp;&nbsp;
              </Typography>
              <Typography variant="body2" gutterBottom>
                {referalData.warrantyComments}
              </Typography>
            </Grid>
            {(quickTests && quickTests.length > 0 || referalData.quickTestIdName) &&
            <Grid item xs={12} className={classes.displayFlex}>
              <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                {`${getTranslation('referal_warranty_quicktest_label')}:`}&nbsp;&nbsp;
              </Typography>
              {(quickTests.length > 0 && referalData.quickTestId) && <Typography variant="body2" gutterBottom>
                {referalData.quickTestIdName || getQuickTestName(referalData.quickTestId)}
              </Typography>}
            </Grid>
            }
            <Grid item xs={12} className={classes.displayFlex}>
              <Typography variant="body2" className={classes.fontWeightBold} gutterBottom>
                {`${getTranslation('referal_warranty_image_label')}:`}&nbsp;&nbsp;
              </Typography>
              <Typography variant="body2" onClick={() => handleImageClick()}
                          className={referalData.file_id ? clsx(classes.pointer, classes.link) : ""} gutterBottom>
                {referalData.file_id ? getTranslation('referal_warranty_image_click_text') : getTranslation('referal_warranty_image_no_image')}
              </Typography>
            </Grid>
          </Grid>
          }
        </Paper>
        }

        <Grid container>
          {!submited &&
          <>
            <Grid item xs={12} sm={9} className={classes.selectedStepWrap}>
              <Typography variant="subtitle1"
                          className={classes.titleStepNumber}>{activeStepHeaderNumber()}</Typography>
              <Typography variant="subtitle1"
                          className={classes.titleStepText}>&nbsp;{activeStepHeaderText()}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} style={{position: "relative"}}>
              <div className={classes.selectorWrap} onClick={() => setShowDropdown(!showDropdown)}>
                <Typography variant="subtitle1">{getTranslation("referral_mobile_see_all_steps")}</Typography>
                <ExpandMoreIcon className={classes.dropdownIcon}/>
              </div>
              {showDropdown &&
              <div className={classes.stepsDropdownOptions}>
                {steps.map(s => (
                  <div className={classes.dropdownOptionWrap} key={s.id} onClick={() => changeStep(s.id)}>
                    <Typography variant="caption" className={classes.stepTitle}><b>{`${s.title}/4: `}</b></Typography>
                    <Typography variant="caption" className={classes.stepSubTitle}>&nbsp;{s.subTitle}</Typography>
                  </div>
                ))}
              </div>
              }
            </Grid>
          </>
          }
        </Grid>

        <Paper elevation={0} className={classes.contentPaper}>
          <Grid container>
            {!submited && activeStep === 0 && <FormMethodsStep handleNext={_handleNext}/>}
            {!submited && activeStep === 1 && <FormTestsStep handleNext={_handleNext} handleBack={_handleBack}/>}
            {!submited && activeStep === 2 && <FormId/>}
            {!submited && activeStep === 2 && validId && <FormData handleNext={_handleNext} handleBack={_handleBack}/>}
            {(submited || activeStep === 3) &&
            <ReviewStep openPDF={openPDF} history={props.history} handleBack={_handleBack} isMobile={true}/>}
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default Mobile;

const useStyles = makeStyles(theme => ({
  paperTitle: {
    padding: 15,
    marginBottom: 20,
  },
  selectedStepWrap: {
    background: colors.primary,
    color: colors.white,
    display: "flex",
    alignItems: "center",
    padding: "5px 7px"
  },
  titleStepText: {
    marginTop: 1
  },
  selectorWrap: {
    background: "#f9f9f9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 7px",
    cursor: "pointer",
  },
  dropdownIcon: {
    fill: "#787878",
    width: 24,
    height: 24
  },
  titleStepNumber: {
    fontWeight: "bold",
    fontSize: 18
  },
  contentPaper: {
    padding: "20px 15px 15px 15px"
  },
  stepSubtitle: {
    marginTop: 20
  },
  stepsDropdownOptions: {
    position: "absolute",
    top: 41,
    left: "-55px",
    right: 0,
    background: colors.white,
    boxShadow: "-5px 10px 8px #888888",
    color: "#787878",
    zIndex: 2,

    [theme.breakpoints.down('xs')]: {
      left: 0,
      top: 31
    }
  },
  dropdownOptionWrap: {
    display: "flex",
    borderBottom: "1px solid #787878",
    padding: "10px 5px",
    cursor: "pointer"
  },
  stepTitle: {
    fontSize: 13
  },
  stepSubTitle: {
    fontSize: 13
  },
  fontWeightBold: {
    fontWeight: "bold"
  },
  displayFlex: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    },
  },
  link: {
    textDecoration: "underline",
    color: '#0000ee'
  },
  pointer: {
    cursor: 'pointer',
  },
}));
