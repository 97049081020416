import React, { useContext, useState } from "react";
import { Grid, Typography, ButtonGroup, Button, TextField, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LocalizationContext } from '../../context/localizationContext';
import { OrgContext } from '../../context/orgContext';
import { AuthContext } from '../../context/authContext';
import APICall from '../../functional/APIRequests';
import { useSnackbar } from 'notistack';
import { Save } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
}));
const OrganizationInfo = props => {
  const classes = useStyles();
  const { getTranslation } = useContext(LocalizationContext);
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrg } = useContext(OrgContext);
  const { setLoading, } = useContext(AuthContext);
  const [data, setData] = useState(null);
  // let type = props.match.params.type.toLowerCase();
  const submitData = (e) => {
    try { e.preventDefault() } catch (err) { };
    setLoading(true)
    var form = new FormData();
    // form.append("notifications_data[1][user_id]", '199');
    form.append("notifications_data[1][organisation_id]", '1');
    form.append("notifications_data[1][subject]", getTranslation('notification_address_change'));
    form.append("notifications_data[1][message]", data);
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `notification/${selectedOrg.id}`,
      verb: 'post',
      server: 'api',
      formData: true,
      data: form,
    }).then((res) => {
      setLoading(false);
      props.closeDialog();
    })
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <TextField
          variant="filled"
          value={data}
          onChange={(e) => setData(e.target.value)}
          error={false}
          label={getTranslation('organization_address_form_label_change_address')}
          multiline
          rows="6"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" className={classes.buttonGroup}>
          <ButtonGroup size="small" color="primary">
            <Button onClick={props.closeDialog} > {getTranslation('generic_button_cancel')} </Button>
            <Button startIcon={<Save />} onClick={submitData} > {getTranslation('generic_button_save')} </Button>
          </ButtonGroup>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default OrganizationInfo;
    // <Grid container className={classes.container}>
    //   <Grid item xs={12} sm={10}>
    //     <Paper className={classes.paper}>
    //       {/* <LabelBox
    //         hasCancel={true}
    //         editFunc={submitData}
    //         label={type === 'invoice' ? getTranslation('organization_address_title_address') : getTranslation('organization_address_title_address_delivery')}
    //       > */}
    //       <form onSubmit={submitData} noValidate autoComplete="off">
    //         <Grid container>
    //           <Grid item xs={12}>
    //             <TextField
    //               value={data}
    //               onChange={(e) => setData(e.target.value)}
    //               error={false}
    //               label={getTranslation('organization_address_form_label_change_address')}
    //               multiline
    //               rows="6"
    //             />
    //           </Grid>
    //         </Grid>
    //       </form>
    //       {/* </LabelBox> */}
    //     </Paper>
    //   </Grid>
    // </Grid>

