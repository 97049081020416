import React, {forwardRef, useContext, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Grid,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography
} from '@material-ui/core';
import {Markup} from 'interweave';

import {LocalizationContext} from '../context/localizationContext';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const AlertDialogSlide = (props) => {
    const {getTranslation} = useContext(LocalizationContext);
    const [data, setDataType] = useState({
        sys_id: '',
        selectedOption: props.selected === 3 || props.selected === 38 || props.selected === 91 ? 1 : 3,
    })
    const handleClose = (params) => {
        props.setOpen(false);
        if (params) {
            props.callback(params)
        }
    };
    let type = props.selected === 3 || props.selected === 38 ? 1 : (props.selected === 91 ? 3 : 2)
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth={props.maxWidth || (props.html ? 'lg' : 'sm')}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle
                id="alert-dialog-slide-title">{getTranslation(type === 1 ? 'dialog_amphetamine_title' : (type === 3 ? "dialog_amphetamine_title_helblod" : 'dialog_separation_title'))}</DialogTitle>
            <DialogContent style={{fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"}}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <Markup
                                content={getTranslation(type === 1 ? 'dialog_amphetamine_message' : (type === 3 ? "dialog_amphetamine_message_helblod" : 'dialog_separation_message'))}/>
                            <RadioGroup aria-label="amphetaminetypes" name="amphetaminetypes"
                                        value={data.selectedOption} onChange={(e) =>{
                                setDataType({...data, selectedOption: Number(e.target.value)})
                            }}>
                                <FormControlLabel value={type === 1 ? 1 : (type === 3 ? 1 : 3)} control={<Radio/>}
                                                  label={getTranslation(type === 1 ? 'dialog_amphetamine_option_1' : (type === 3 ? "dialog_amphetamine_option_helblod_1" : 'dialog_separation_option_1'))}/>
                                {/* <FormControlLabel value={2} control={<Radio />} label={getTranslation('dialog_amphetamine_option_2')} /> */}
                                <FormControlLabel value={type === 1 ? 3 : (type === 3 ? 3 : 1)} control={<Radio/>}
                                                  label={getTranslation(type === 1 ? 'dialog_amphetamine_option_3' : (type === 3 ? "dialog_amphetamine_option_helblod_3" : 'dialog_separation_option_3'))}/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {type === 3 &&
                            <Markup content={getTranslation("dialog_amphetamine_bottom_text_helblod")}/>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.cancelLabel &&
                    <Button onClick={() => handleClose(false)} color="primary">
                        {props.cancelLabel}
                    </Button>
                }
                {props.submitLabel &&
                    <Button onClick={() => handleClose(data.selectedOption)} color="primary">
                        {props.submitLabel}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}
export default AlertDialogSlide;