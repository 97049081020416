import React from "react";
import colorConfig from "../../styles/Theme-settings";

const SVG = ({
  onClick,
  style = {},
  fill = colorConfig.primary,
  width = "20px",
  className = "",
  viewBox = "0 0 469.336 469.336"
  //   viewBox = "0 0 32 32"
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"width="36pt" height="24pt" viewBox="0 0 36 24" version="1.1">
    <g id="surface1">
    <path d="M 9.476562 2.570312 L 10.71875 2.570312 L 10.71875 21.398438 L 9.476562 21.398438 Z M 9.476562 2.570312 "/>
    <path d="M 6.945312 2.570312 L 7.566406 2.570312 L 7.566406 21.398438 L 6.945312 21.398438 Z M 6.945312 2.570312 "/>
    <path d="M 13.890625 2.570312 L 14.515625 2.570312 L 14.515625 21.398438 L 13.890625 21.398438 Z M 13.890625 2.570312 "/>
    <path d="M 12.625 2.570312 L 13.25 2.570312 L 13.25 21.398438 L 12.625 21.398438 Z M 12.625 2.570312 "/>
    <path d="M 1.886719 2.570312 L 2.53125 2.570312 L 2.53125 21.398438 L 1.886719 21.398438 Z M 1.886719 2.570312 "/>
    <path d="M 3.792969 2.570312 L 4.417969 2.570312 L 4.417969 21.398438 L 3.792969 21.398438 Z M 3.792969 2.570312 "/>
    <path d="M 0 2.570312 L 1.265625 2.570312 L 1.265625 21.414062 L 0 21.414062 Z M 0 2.570312 "/>
    <path d="M 15.15625 2.570312 L 15.777344 2.570312 L 15.777344 21.398438 L 15.15625 21.398438 Z M 15.15625 2.570312 "/>
    <path d="M 27.761719 2.570312 L 29.027344 2.570312 L 29.027344 21.398438 L 27.761719 21.398438 Z M 27.761719 2.570312 "/>
    <path d="M 30.933594 2.570312 L 32.820312 2.570312 L 32.820312 21.398438 L 30.933594 21.398438 Z M 30.933594 2.570312 "/>
    <path d="M 33.445312 2.570312 L 34.085938 2.570312 L 34.085938 21.398438 L 33.445312 21.398438 Z M 33.445312 2.570312 "/>
    <path d="M 34.710938 2.570312 L 35.972656 2.570312 L 35.972656 21.414062 L 34.710938 21.414062 Z M 34.710938 2.570312 "/>
    <path d="M 23.347656 2.570312 L 24.613281 2.570312 L 24.613281 21.398438 L 23.347656 21.398438 Z M 23.347656 2.570312 "/>
    <path d="M 25.875 2.570312 L 27.140625 2.570312 L 27.140625 21.398438 L 25.875 21.398438 Z M 25.875 2.570312 "/>
    <path d="M 20.816406 2.570312 L 22.082031 2.570312 L 22.082031 21.398438 L 20.816406 21.398438 Z M 20.816406 2.570312 "/>
    <path d="M 17.664062 2.570312 L 18.929688 2.570312 L 18.929688 21.398438 L 17.664062 21.398438 Z M 17.664062 2.570312 "/>
    </g>
    </svg>
  );
};

export default SVG;