import React, {useState, useEffect, useContext} from "react";
import {Typography, Paper, Grid, LinearProgress, Button, ButtonGroup} from "@material-ui/core";
import {LocalizationContext} from '../../context/localizationContext';
import {OrgContext} from '../../context/orgContext';
import {AuthContext} from '../../context/authContext';
import moment from 'moment';
import Table from '../../components/Table/index';
import {FilterList,} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '../../components/SeachDialog'
import SearchChips from '../../components/SearchChips'

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  grid: {
    maxWidth: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  divider: {
    width: "80%"
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  searchIcon: {
    height: 24,
    width: 24,
    cursor: 'pointer',
  },
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
}));
const OrganizationPage = props => {
  const {loading} = useContext(AuthContext);
  const {getTranslation, lang} = useContext(LocalizationContext);
  const {getOrganization} = useContext(OrgContext)
  moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sort, setSort] = useState({column: 'name', by: 'asc'})
  const [searchState, setSearchState] = useState(false)
  const [filter, setFilter] = useState({})
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  document.title = getTranslation('org_list_browser_title') + ' | Prodiagnostics';

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSortChange = (column, order) => {
    setSort({column: column, by: order})
  }
  const generetaQueryString = () => {
    let queryString = `?sort=${sort.column} ${sort.by}`;
    queryString += `&limit=${rowsPerPage}&offset=${rowsPerPage * page}`
    Object.keys(filter).forEach(f => {
      if (f === "searchTerm") {
        queryString += `&${f}=${filter[f]}`
      } else {
        queryString += `&filter[${f}]=${filter[f]}`
      }
    })
    return queryString;
  }
  const columns = [
    // User filter
    {
      id: 12,
      name: getTranslation("organisation_table_filter_user"),
      functionalName: "searchTerm",
      type: "text",
      multi: false,
      filter: true,
      filterOptions: {
        col: 12,
      },
      visible: false,
      sortable: false
    },
    //Referal Id
    {
      name: getTranslation('organizations_list_header_customer_id'),
      functionalName: 'customer_number',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    {
      name: getTranslation('organizations_list_header_name'),
      functionalName: 'name',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Org Id
    {
      name: getTranslation('organizations_list_header_org_number'),
      functionalName: 'orgnr',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Test Time
    {
      name: getTranslation('organizations_list_header_contact_person'),
      functionalName: 'contact_person',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Reg Date
    {
      name: getTranslation('organizations_list_header_city'),
      functionalName: 'city',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Status
    {
      id: 8,
      name: getTranslation('generic_table_header_status'),
      functionalName: 'status',
      type: 'select',
      list: [{id: 1, text: getTranslation('generic_table_string_active')}, {
        id: 0,
        text: getTranslation('generic_table_string_inactive')
      }],
      multi: true,
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //SignPad
    {
      id: 9,
      name: getTranslation('generic_table_header_allow_signpad'),
      functionalName: 'allow_signpad',
      type: 'select',
      list: [
        {id: '0', text: getTranslation('generic_filter_no')},
        {id: '1', text: getTranslation('generic_filter_yes')}
      ],
      multi: true,
      filter: false,
      sortable: true,
      visible: true,
      align: 'left',
    },
    //InfoSolutions
    {
      id: 10,
      name: getTranslation('generic_table_header_infosolutions'),
      functionalName: 'infosolutions_tosystemid',
      type: 'select',
      list: [
        {id: '0', text: getTranslation('generic_filter_no')},
        {id: '1', text: getTranslation('generic_filter_yes')}
      ],
      multi: true,
      filter: false,
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Sent digital
    {
      id: 11,
      name: getTranslation("generic_table_header_send_digital"),
      functionalName: "send_digital",
      type: "select",
      list: [
        {id: '0', text: getTranslation('generic_filter_no')},
        {id: '1', text: getTranslation('generic_filter_yes')}
      ],
      multi: false,
      filter: true,
      filterOptions: {
        col: 6,
      },
      visible: true,
      align: "left",
      sortable: true
    }
  ]
  const fetchData = () => {
    getOrganization(generetaQueryString()).then(res => {
      if (Number(res.total_records) > 0 && res.data.length > 0) {
        setCount(Number(res.total_records) || 0);
      }
      setData((res.data || []).map(data => {
        return {
          ...data,
          status: !data.deactivated_at ? getTranslation('generic_table_string_active') : getTranslation('generic_table_string_inactive'),
          allow_signpad: data.allow_signpad === "1" ? getTranslation('generic_filter_yes') : getTranslation('generic_filter_no'),
          infosolutions_tosystemid: data.infosolutions_tosystemid ? getTranslation('generic_filter_yes') : getTranslation('generic_filter_no')
        };
      }))
    })
  }
  const clearFilter = () => {
    setFilter({});
  }
  // eslint-disable-next-line
  useEffect(() => {
    fetchData()
  }, [filter, sort, page, rowsPerPage])
  // eslint-disable-next-line
  useEffect(() => {
    if (page > 0) {
      setPage(0)
    }
  }, [filter])
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        <Paper className={classes.paperTitle} elevation={0}>
          <Typography variant="h6" gutterBottom>
            {getTranslation('organoization_page_title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {getTranslation('organoization_page_subtitle')}
          </Typography>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ButtonGroup size="small" color="primary" className={classes.pageHeader}>
                <Button onClick={clearFilter}> {getTranslation('generic_clear_filter')} </Button>
                <Button startIcon={<FilterList/>}
                        onClick={() => setSearchState(true)}> {getTranslation('generic_button_filter')} </Button>
              </ButtonGroup>
              {Object.keys(filter).length > 0 &&
              <SearchChips
                filter={filter}
                setFilter={setFilter}
                columns={columns}/>
              }
              {loading && <LinearProgress/>}
              <Table
                count={count}
                rows={data}
                page={page}
                columns={columns}
                handleChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleSortChange={handleSortChange}
                sort={sort}
                onLineClick={'/organization/'}
              />
              <Dialog
                filter={filter}
                setFilter={setFilter}
                open={searchState}
                onClose={setSearchState}
                columns={columns}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default OrganizationPage;
