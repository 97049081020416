import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Divider, ListItemText, LinearProgress, ButtonGroup, Button, Typography, Grid, Paper, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import { OrgContext } from '../context/orgContext';
import { AuthContext } from '../context/authContext';
import { LocalizationContext } from '../context/localizationContext';
import { useSnackbar } from 'notistack';
import APICall from '../functional/APIRequests';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center"
  },
  paper: {
    width: "100%",
    padding: 20
  },
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
}));
const NotificationInbox = props => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([])
  const { selectedOrg } = useContext(OrgContext);
  const { loading, setLoading } = useContext(AuthContext);
  const { getTranslation } = useContext(LocalizationContext);
  document.title = getTranslation('notification_inbox_browser_title') + ' | Prodiagnostics';
  const fetchData = () => {
    setLoading(true)
    APICall({
      setLoading,
      hasRefresh: true,
      enqueueSnackbar,
      url: `notification/${selectedOrg.id}?limit=0`,
      verb: 'get',
      server: 'api',
    }).then((res) => {
      setLoading(false);
      if (res.data) {
        setData(res.data);
      }
    })
  }
  // eslint-disable-next-line
  useEffect(() => { fetchData() }, [])

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        {loading && <LinearProgress />}
        <Paper className={classes.paper}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.pageHeader}>
              {getTranslation('notifications_inbox_title')}
              <ButtonGroup size="small" color="primary" aria-label="small outlined button group">
                <Button startIcon={<RefreshIcon />} onClick={() => fetchData()} > {getTranslation('generic_button_refresh')} </Button>
              </ButtonGroup>
            </Typography>
          </Grid>
          <List className={classes.root}>
            {data.map(d =>
              <React.Fragment key={d.sys_id}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={d.subject}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {`${d.from_organisation_customer_number}, ${d.from_organisation_name} - ${d.from_user_name}`} 
                        </Typography>
                        <p style={{margin:0}}>{d.message}</p>
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            )}
            {data.length === 0 &&
              <>
                <ListItem >
                  <ListItemText style={{ textAlign: 'center' }}
                    primary={getTranslation('notifications_inbox_no_data')}
                  />
                </ListItem>
                <Divider component="li" />
              </>
            }

          </List>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default NotificationInbox