import React, {useState, useEffect, useRef, useContext} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox
} from '@material-ui/core';
import {KeyboardDatePicker} from "@material-ui/pickers";
import {LocalizationContext} from '../../context/localizationContext';

import moment from 'moment';
import {makeStyles} from "@material-ui/core/styles";
import colors from "../../styles/Theme-settings";

const SearchDialog = (props) => {
  const classes = useStyles();

  const {getTranslation, lang} = useContext(LocalizationContext);
  moment.locale(lang === 'sv_SE' ? 'sv' : 'en-gb');

  const [filter, setFilter] = useState({});
  const handleClose = () => {
    props.onClose(false);
  };
  const handleClean = () => {
    setFilter({});
  }

  const handleResetFilters = () => {
    props.setFilter({});
  }

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (props.open) {
      setFilter(props.filter);
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    // eslint-disable-next-line
  }, [props.open]);
  const submitFilter = () => {
    props.setFilter(filter);
    props.onClose(false);
  }

  const applyFilters = () => {
    props.applyFilters(0, props.rowsPerPage);
    props.onClose(false);
  }

  const renderInput = (params) => {
    switch (params.type) {
      case 'date':
        return <Grid item xs={params.xs}>
          <KeyboardDatePicker
            views={params.views || ["year", "month", "date"]}
            id={params.id}
            name={params.id}
            // error={true}
            label={params.label}
            value={filter[params.id] || null}
            InputAdornmentProps={{position: "start"}}
            openTo={params.openTo || 'date'}
            format={params.views ? 'YYYY-MM' : "YYYY-MM-DD"}
            // animateYearScrolling
            autoOk
            onChange={value => setFilter({...filter, [params.id]: moment(value).format("YYYY-MM-DD")})}
            style={{width: "100%"}}
          />
        </Grid>
      case 'select':
        return <Grid key={params.id} item xs={params.xs}><FormControl>
          <InputLabel>
            {params.label}
          </InputLabel>
          <Select
            id={params.id}
            name={params.id}
            value={filter[params.id] || (params.multi ? [] : '')}
            multiple={params.multi}
            onChange={(e) => {
              if (params.multi && e.target.value.length === 0) {
                let newFilter = JSON.parse(JSON.stringify(filter))
                if (delete newFilter[params.id]) {
                  setFilter(newFilter);
                }
              } else {
                setFilter({...filter, [params.id]: e.target.value})
              }
            }}
            renderValue={selected => {
              if (!params.multi) {
                return params.list.find(st => st.id === selected).text
              } else {
                let returnV = [];
                selected.forEach(sel => {
                  returnV.push(params.list.find(st => st.id === sel).text)
                })
                return returnV.join(', ')
              }
            }}
          >
            {params.list?.map(item => {
                return (
                  < MenuItem key={item.id} value={item.id}>
                    <Checkbox disabled checked={
                      !params.multi ?
                        filter[params.id] === item.id :
                        filter[params.id] ? filter[params.id].findIndex(filterId => filterId === item.id) > -1
                          : false}/>
                    <div style={{float: "left"}}>
                      {item.text}
                    </div>
                  </MenuItem>
                )
              }
            )}
          </Select>
        </FormControl>
        </Grid>
      default:
        return <Grid key={params.id} item xs={params.xs}>
          <TextField
            type={params.type}
            id={params.id}
            name={params.id}
            label={params.label}
            value={filter[params.id] || ''}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setFilter({...filter, [params.id]: e.target.value})
              } else {
                delete filter[params.id];
                setFilter({...filter})
              }
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                if (props.applyFilters) {
                  applyFilters();
                } else {
                  submitFilter();
                }
              }
            }}
          />
        </Grid>
    }
  }
  return (
    <>
      {props.open &&
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        width={"lg"}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={props.restrictHeight ? classes.height : ""}
      >
        <DialogTitle id="scroll-dialog-title">{getTranslation('generic_search_title')}</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={3} ref={descriptionElementRef}
                tabIndex={1}>
            {props.columns ?
              (props.columns.map(column => {
                if (column.filter) {
                  if (column.intervalFunctionName && column.intervalFunctionName.length > 0) {
                    return column.intervalFunctionName.map(interval => {
                      return renderInput({
                        id: interval.functionalName || 'MISSING TEXT',
                        xs: interval.col || 12,
                        type: column.type,
                        label: `${column.name} - ${interval.name}`,
                        multi: !!interval.multi,
                        list: column.list,
                      })
                    })
                  } else {
                    return renderInput({
                      id: column.functionalName || 'MISSING TEXT',
                      xs: (column.filterOptions && column.filterOptions.col) || 12,
                      type: column.type,
                      label: column.name,
                      multi: !!column.multi,
                      list: column.list,
                      views: column.views,
                      openTo: column.openTo,
                    })
                  }
                } else {
                  return false
                }
              }))
              :
              <>
                {props.children}
              </>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.resetFilters ? handleResetFilters : handleClean} color="primary">
            {getTranslation('generic_search_button_reset')}
          </Button>
          <Button onClick={handleClose} color="primary">
            {getTranslation('generic_search_button_cancel')}
          </Button>
          <Button onClick={props.applyFilters ? applyFilters : submitFilter} color="primary">
            {getTranslation('generic_search_button_submit')}
          </Button>
        </DialogActions>
      </Dialog>
      }
    </>
  );
};

const useStyles = makeStyles(theme => ({

  height: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        maxHeight: "75%"
      }
    }
  }
}));

export default SearchDialog;
